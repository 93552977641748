/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  Select,
  fetchDataMachine,
  FETCH_DATA_EVENT,
  FETCH_DATA_STATE,
  setSelectOptions,
  Pagination,
  PickListDropdown,
  Modal,
  Switch,
  sliceTableData,
  AuthProvider,
  EditCopyExamPaperProvider,
  CreateExamPaperParamsProvider,
  openNotificationWithIcon,
} from '@oneclass/ui-components';
import { Tooltip } from 'antd';
import { getQueryExamPaper, postExamGetData, postExamEditOne, deleteExamPaper } from 'api/examPaper';
import { FileDownloadSolid, CopySolid, FileEditSolid, GlobeAsiaSolid, EyeSolid, SlidersHSolid, RocketSolid, TrashAltOutline } from '@onedesign/icon';
import { Button, RadioGroup } from '@oneclass/onedesign';
import { useMachine } from '@xstate/react';
import { getTime, format, isWithinInterval, isSameDay } from 'date-fns';
import { useBoolean } from 'ahooks';
import { blobDownload, getCookie, deleteCookie } from 'utils/common';
import { useLocation, useHistory } from 'react-router-dom';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import classNames from 'classnames';
import { useCreateQuizExamModal } from 'utils/hooks/useCreateQuizExamModal';
import { StyledExamPaperListContent, StyledFilterBar, StyledActionIcon, StyledHintModal } from './ExamPaperListContent.style';
import { useLoadingContent } from 'utils/hooks/useLoadingContent';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { default as behaviorProvider } from 'providers/behaviorProvider.js';
import { default as UserProvider } from 'providers/UserProvider.js';
import * as GA from 'utils/googleAnalytics';
import DownloadFailedModal from 'components/DownloadFailedModal';
import { useTranslation } from 'react-i18next';
import { subjectMapper, educationMapper, propositionalApproachMapper } from 'locales/translationMapper';
import { bookNameMapper } from 'locales/translationMapper';
import { quizOutputTypeMapper } from 'locales/translationMapper';

// 能建立速測的測驗類型
const canCreateQuiz = (type) => ['Quick', 'Series'].includes(type);

const DownloadIcon = ({ isDownloadSuccess }) => {
  switch (isDownloadSuccess) {
    case true:
      return <FileDownloadSolid />;
    case false:
      return <img src='/assets/fileDownloadFalse.svg' alt='' style={{ margin: '0 4px 6px', cursor: 'pointer' }} />;
    default:
      return <FileDownloadSolid color='#4e4e5a' />;
  }
};
DownloadIcon.propTypes = {
  isDownloadSuccess: PropTypes.bool
};

export const ExamPaperListContent = ({ className, setEduSubjectModalToggle, tabType, tabChange }) => {
  const { t } = useTranslation();
  const { useAuthState } = AuthProvider;
  const authState = useAuthState();
  const location = useLocation();
  const { userData } = useContext(UserProvider.UserProviderContext);
  const { userInfo } = userData;
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const { behaviorData, behaviorDataProviderChange } = useContext(behaviorProvider.behaviorProviderContext);
  const { editCopyExamPaperData, editCopyEditExamPaperChange } = useContext(EditCopyExamPaperProvider.EditCopyExamPaperContext);
  const { createExamPaperParams, createExamPaperParamsChange } = useContext(CreateExamPaperParamsProvider.createExamPaperContext);
  const history = useHistory();
  const [filterParams, setFilterParams] = useState({});
  const [modalInfo, setModalInfo] = useState(null);
  const [pageState, setPageState] = useState({
    currentPage: 1,
    limit: 10,
  });
  const identity = authState.context.userDetails.userStatus?.identity;
  const showCreateQuizBtn =
    userInfo?.onePaperIdentity !== 'TestCram'
    && (identity === 'Editor' || identity === 'Teacher' || identity === 'Admin')
    && userInfo?.userType !== 'Premium';
  const [modalVisible, { toggle: setModalVisibleToggle, setFalse: setModalVisibleFalse }] = useBoolean(false);
  const { renderLoadingContent, isLoading, setIsLoading } = useLoadingContent();
  const oneExamUrl = `${process.env.REACT_APP_ONE_EXAM_DOMAIN}/paper/preview`;
  const [hintModalVisible, { toggle: setHintModalToggle, setFalse: setHintModalFalse }] = useBoolean(false);
  const [denyEditModalOpen, { setTrue: setDenyEditModalOpenTrue, setFalse: setDenyEditModalOpenFalse }] = useBoolean(false);
  const [questionErrorModalOpen, { setTrue: setQuestionErrorModalOpenTrue, setFalse: setQuestionErrorModalOpenFalse }] = useBoolean(false);
  const [downloadFailedPaperId, setDownloadFailedPaperId] = useState(null);

  const [stateGetQueryExamPaper, sendGetQueryExamPaper] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        setIsLoading(true);
        const res = await getQueryExamPaper();
        const { data, isSuccess, systemCode, message } = res;
        const { yearMap = [], eduSubjectMap = [], typeMap = [], patternMap = [], examPaper = [] } = data || {};
        if (isSuccess) {
          if (behaviorData?.createExam) {
            // if (getCookie('createExam') === 'true') {
            createQuizExamModalToggle(examPaper[0], 'create');
            // setCookie('createExam', false, 1);
            behaviorDataProviderChange('createExam', false);
          }
        }
        setIsLoading(false);
        return {
          isSuccess, systemCode, message,
          yearOptions: setSelectOptions(yearMap) || [],
          eduSubjectOptions: setSelectOptions(eduSubjectMap) || [],
          typeOptions: setSelectOptions(typeMap) || [],
          patternOptions: setSelectOptions(patternMap) || [],
          examPaper,
        };
      },
    },
  });

  useEffect(() => {
    if (authState.context.userDetails?.userStatus) {
      if (userInfo?.onePaperIdentity !== 'TestCram') {
        return;
      } else {
        if ((authState.value === 'loggedIn' || authState.value === 'settingEduSubject')) {
          if (getCookie('createNewPaperBackHome') !== null) {
            setEduSubjectModalToggle();
            deleteCookie('createNewPaperBackHome');
            deleteCookie('createNewPaper');
          }
        }
      }
    }
  }, [authState.value]);

  const [statePutExamPaperPublic, sendPutExamPaperPublic] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        const res = await postExamEditOne(event.payload);
        const { isSuccess, systemCode, message } = res;

        return {
          isSuccess, systemCode, message,
        };
      },
    },
  });
  //刪除api
  const [stateDeleteExamPaper, sendDeleteExamPaper] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_, event) => {
        const payload = {
          paperId: event.quizUID
        };
        const res = await deleteExamPaper(payload);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) {
          openNotificationWithIcon('success', t('containers.examPaperListContent.__deleteExamSuccess'));
          setHintModalFalse();
          setModalVisibleFalse();
          sendGetQueryExamPaper(FETCH_DATA_EVENT.FETCH, {
            payload: {
              jwt: JSON.parse(getCookie('nani_oneclass_login_token')).jwt,
            }
          });
        } else {
          openNotificationWithIcon('error', message);
        }
        return {
          isSuccess, systemCode, message
        };
      },
    },
  });


  const cancelHintModal = () => {
    setHintModalFalse();
    setCreateQuizExamModalFalse();
  };
  const openHintModal = () => {
    setHintModalToggle();
  };
  const doneHintModal = (quizUID) => {
    sendDeleteExamPaper(FETCH_DATA_EVENT.FETCH, {
      quizUID,
    });
  };


  // 取得 單一 試卷資料
  const [statePostExamGetData, sendPostExamGetData] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_, event) => {
        try {
          const res = await postExamGetData(event.payload);
          const { data, isSuccess, systemCode, message } = res;
          const { result2 } = data;
          const itemData = event.itemData;
          const newData = {
            ...result2,
            edu: itemData.attributes.education,
            subject: itemData.attributes.subject,
            output: itemData.attributes.outputType,
            eduSubjectName: {
              edu: itemData.attributes.eduName,
              subject: itemData.attributes.subjectName,
            }
          };
          createExamPaperParamsChange(
            itemData.attributes.education,
            itemData.attributes.subject,
            itemData.attributes.outputType,
            {
              edu: itemData.attributes.eduName,
              subject: itemData.attributes.subjectName,
            }
          );
          return {
            isSuccess, systemCode, message, examPaperData: { ...newData, paperData: itemData }, actionType: event.action
          };
        } catch (error) {
          setQuestionErrorModalOpenTrue();
          return {
            isSuccess: false
          };
        }
      },
    },
  });

  const {
    eduSubjectOptions,
    typeOptions,
    patternOptions,
    examPaper
  } = stateGetQueryExamPaper.context.result || {};

  // const {
  //   examPaperData,
  //   actionType
  // } = stateGetExamPaperEditCopy.context.result || {};

  const {
    examPaperData,
    actionType
  } = statePostExamGetData.context.result || {};

  const filterData = useMemo(() => {
    if (!examPaper) return [];

    const nextData = examPaper.filter((item) => {
      const checkResult = Object.entries(filterParams).map(([key, value]) => {
        if (value && value.length === 0) return true;
        if (!value && value !== 0) return true;
        if (key === 'eduSubject') {
          const listEduSubject = `${item.attributes.education} ${item.attributes.subject}`;
          return listEduSubject === value;
        } else if (key === 'createTimeRange') {
          if (value.includes('')) return true;
          const createTime = new Date(item.createTime);
          const startTime = new Date(value[0]);
          const endTime = new Date(value[1]);
          // date-fns 的 isWithinInterval 不包含 end
          return isWithinInterval(createTime, { start: startTime, end: endTime }) || isSameDay(endTime, createTime);
        } else {
          return item.attributes[key] === value;
        }
      });
      const checkResultSet = new Set(checkResult);
      if (!checkResultSet.has(false)) return item;
    });
    return nextData;
  }, [examPaper, filterParams]);

  const canOperateExamPaper = useMemo(() => {
    if (!authState.context.userDetails?.userStatus) return;
    const { identity } = authState.context.userDetails?.userStatus;
    return identity === 'Admin' ||
      identity === 'Editor' ||
      identity === 'Teacher';
  }, [authState.context.userDetails]);

  const goToExamPage = (examUID) => {
    if (!examUID) return;
    window.open(`${oneExamUrl}/${examUID}`);
  };

  const pageChange = (currentPage, pageSize) => {
    setPageState({
      ...pageState,
      currentPage,
      limit: pageSize,
    });
  };

  const setFilterCondition = (value, key) => {
    GA.setPaperListFilter(key);
    setFilterParams({
      ...filterParams,
      [key]: value
    });
  };
  const showDetailModal = (data) => {
    if (!data) return;
    setModalInfo(data, setModalVisibleToggle());
  };
  const changeExamPaperPublicStatus = (uid, checked) => {
    sendPutExamPaperPublic(FETCH_DATA_EVENT.FETCH, {
      payload: {
        examUID: uid,
        isPublic: checked
      }
    });
  };
  const copyEditExamPaper = (item, action) => {
    // sendGetExamPaperEditCopy(FETCH_DATA_EVENT.FETCH, {
    //   examUID: item.uid,
    //   action
    // });
    sendPostExamGetData(FETCH_DATA_EVENT.FETCH, {
      payload: {
        examUID: item.uid,
      },
      action,
      itemData: item,
    });
  };
  const redirectCreateExamPaper = (type) => {
    let url = '';
    switch (type) {
      case '手動命題':
        url = 'manualProposition';
        break;
      case '智能命題':
        url = 'studentIntelligentProposition';
        break;
      case '知識向度命題':
        url = 'knowledgeProposition';
        break;
      case '無限命題':
        url = 'equationDojo';
        break;
      case '電腦命題':
      default:
        url = 'computerQuestion';
        break;
    }
    return url;
  };

  useEffect(() => {
    if (location.pathname === '/examPaperList' && tabType === 'paperList') {
      sendGetQueryExamPaper(FETCH_DATA_EVENT.FETCH);
    }
  }, [location.pathname, tabType]);

  useEffect(() => {
    if (statePutExamPaperPublic.matches(FETCH_DATA_STATE.DONE)) {
      sendGetQueryExamPaper(FETCH_DATA_EVENT.FETCH);
    }
  }, [statePutExamPaperPublic.value]);

  useEffect(() => {
    if (examPaperData) {
      editCopyEditExamPaperChange({
        ...examPaperData,
        ...examPaperData?.examInfo,
        ...examPaperData?.searchCondition,
      }, actionType);
      // history.push(redirectCreateExamPaper(examPaperData.pattern));
      history.push(redirectCreateExamPaper(examPaperData.paperData.drawUpName));
      // .attribute
    }
  }, [examPaperData]);

  useEffect(() => {
    setPageState({
      currentPage: 1,
      limit: 10,
    });
  }, [filterParams]);

  // 手機版相關

  const [filterDrawerVisible, { toggle: filterDrawerToggle, setFalse: filterDrawerFalse }] = useBoolean(false);

  // 詳情彈窗開啟時 關閉背景滑動
  useEffect(() => {
    document.body.classList.remove('mobileScrollingStop');
    if (!deviceData.isDesktop) {
      if (modalVisible | filterDrawerVisible) {
        document.body.classList.add('mobileScrollingStop');
      }
    }
  }, [modalVisible, filterDrawerVisible]);

  // 重新組成 學制 / 科目 列表
  const eduOptions = useMemo(() => {
    let eduArray = [];
    let checkArray = [];
    if (eduSubjectOptions) {
      eduSubjectOptions.forEach((item) => {
        if (checkArray.indexOf(item.value.slice(0, 1)) === -1) {
          let order = 0;
          if (item.value.slice(0, 1) === 'J') order = 1;
          if (item.value.slice(0, 1) === 'H') order = 2;
          eduArray.push({
            label: item.name.slice(0, 2),
            value: item.value.slice(0, 1),
            order
          });
          checkArray.push(item.value.slice(0, 1));
        }
      });
    }
    return eduArray.sort((a, b) => {
      return a.order - b.order;
    });
  }, [eduSubjectOptions]);

  const subjectOptions = useMemo(() => {
    let subjectArray = {};
    if (eduSubjectOptions) {
      eduSubjectOptions.forEach((item) => {
        if (subjectArray[item.value.slice(0, 1)]) {
          let next = { ...subjectArray };
          next[item.value.slice(0, 1)].push({
            label: item.name.slice(2, 10),
            value: item.value.slice(1, 3)
          });
          subjectArray = next;
        } else {
          let next = { ...subjectArray };
          next[item.value.slice(0, 1)] = [{
            label: item.name.slice(2, 10),
            value: item.value.slice(1, 3)
          }];
          subjectArray = next;
        }
      });
    }
    return subjectArray;
  }, [eduSubjectOptions]);

  const [filterParamsMobile, setFilterParamsMobile] = useState({});
  const [eduSubjectMobile, setEduSubjectMobile] = useState({
    edu: '',
    subject: '',
  });

  useEffect(() => {
    if (eduSubjectMobile.edu && eduSubjectMobile.subject) {
      setFilterParamsMobile({
        ...filterParamsMobile,
        eduSubject: eduSubjectMobile.edu + eduSubjectMobile.subject,
      });
    }
  }, [eduSubjectMobile]);

  const transformMobileOptions = (array) => {
    let options = [];
    if (array && array.length > 0) {
      array.forEach((item) => {
        options.push({
          label: item.name,
          value: item.value
        });
      });
    }
    return options;
  };

  const changeEduSubjectMobile = (val, type) => {
    if (type === 'edu') {
      setEduSubjectMobile({
        edu: val,
        subject: '',
      });
    } else {
      setEduSubjectMobile({
        ...eduSubjectMobile,
        subject: val,
      });
    }
    setFilterIsChanged(true);
  };

  const changeFilterParamsMobile = (val, type) => {
    setFilterParamsMobile({
      ...filterParamsMobile,
      [type]: val,
    });
    setFilterIsChanged(true);
  };

  const showMobileFilter = () => {
    filterDrawerToggle();
    setFilterIsChanged(false);
    setEduSubjectMobile({
      edu: filterParams?.eduSubject ? filterParams?.eduSubject.slice(0, 1) : '',
      subject: filterParams?.eduSubject ? filterParams?.eduSubject.slice(1, 3) : '',
    });
    setFilterParamsMobile(filterParams);
  };

  const clearAllFilter = () => {
    if (Object.keys(filterParamsMobile).length !== 0 || eduSubjectMobile.edu) {
      setFilterParamsMobile({});
      setEduSubjectMobile({
        edu: '',
        subject: '',
      });
      setFilterIsChanged(true);
    }
  };

  const [filterIsChanged, setFilterIsChanged] = useState(false);

  const checkChangeFilterBtn = useMemo(() => {
    if (filterIsChanged) {
      if (eduSubjectMobile.edu) {
        if (eduSubjectMobile.subject) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }, [eduSubjectMobile, filterIsChanged]);

  const doneChangeFilter = () => {
    setFilterParams(filterParamsMobile);
    filterDrawerFalse();
    for (const type in filterParamsMobile) {
      GA.setPaperListFilter(type);
    }
  };

  const jumpToExamList = () => {
    setModalVisibleFalse();
    setTimeout(function () {
      tabChange('examList');
    }, 1000);
  };

  const eduSubjectNameMapper = (value) => {
    const eduStr = value.substr(0, 2);
    const subjectStr = value.substr(2, value.length - 2);
    const eduName = t(educationMapper(eduStr));
    const subjectName = t(subjectMapper(subjectStr));
    return eduName + ' ' + subjectName.toLocaleLowerCase();
  };

  const translatedEduSubjectOptions = eduSubjectOptions?.map(item => {
    return {
      ...item,
      name: t(eduSubjectNameMapper(item.name))
    };
  });

  const translatedPatternOptions = patternOptions?.map(item => {
    return {
      ...item,
      name: t(propositionalApproachMapper(item.name))
    };
  });

  const { renderCreateQuizExamModal, createQuizExamModalToggle, setCreateQuizExamModalFalse } = useCreateQuizExamModal(jumpToExamList);

  return (
    <StyledExamPaperListContent className={className} data-testid="ExamPaperListContent">
      {
        deviceData.isDesktop ?
          <Box mb={4}>
            <StyledFilterBar>
              <Select placeholder={t('containers.examPaperListContent.__academicSubject')} allowClear={filterParams?.eduSubject !== null} options={translatedEduSubjectOptions} value={filterParams?.eduSubject !== null && filterParams?.eduSubject} onChange={(val) => { setFilterCondition(val, 'eduSubject'); }} />
              <Select placeholder={t('containers.examPaperListContent.__questioningMethod')} allowClear={filterParams?.drawUp !== null} options={translatedPatternOptions} value={filterParams?.drawUp !== null && filterParams?.drawUp} onChange={(val) => { setFilterCondition(val, 'drawUp'); }} />
              <PickListDropdown.DatePickListDropdown
                targetStr={t('containers.examPaperListContent.__exam')}
                defaultTitle={t('containers.examPaperListContent.__issueTime')}
                onChange={(data) => {
                  setFilterCondition(data, 'createTimeRange');
                }}
              />
            </StyledFilterBar>
          </Box>
          :
          <div className='filterBtnBox'>
            <div className={Object.keys(filterParams).length !== 0 ? 'active' : ''}>
              <SlidersHSolid onClick={showMobileFilter} />
            </div>
          </div>
      }
      <Box>
        <Table className="tableZone">
          <Table.Header className='tableHeader'>
            <Table.Row isFixedWidth={true}>
              <Table.Item width={'302px'}>
                {t('containers.examPaperListContent.__examName')}
              </Table.Item>
              <Table.Item width={'89px'}>
                {t('containers.examPaperListContent.__academicSubject')}
              </Table.Item>
              <Table.Item width={'117px'}>{t('containers.examPaperListContent.__questioningMethod')}</Table.Item>
              <Table.Item width={'144px'}>{t('containers.examPaperListContent.__issueTime')}</Table.Item>
              <Table.Item width={'88px'}>{t('containers.examPaperListContent.__copyEdit')}</Table.Item>
              <Table.Item width={'120px'}>{t('containers.examPaperListContent.__operation')}</Table.Item>
              <Table.Item width={'120px'}>{t('containers.examPaperListContent.__seeMore')}</Table.Item>
            </Table.Row>
          </Table.Header>
          {
            isLoading
              ?
              renderLoadingContent()
              :
              <Table.Body bodyMaxHeight={true}>
                {
                  filterData && filterData.length > 0 ? sliceTableData(filterData, pageState.currentPage, pageState.limit).map(item => (
                    <Table.Row key={item.uid} isFixedWidth={true} className='tableRow' onClick={() => !deviceData.isDesktop && showDetailModal(item)}>
                      {
                        deviceData.isDesktop ?
                          <>
                            <Table.Item width={'302px'} >{item.name}</Table.Item>
                            <Table.Item width={'89px'} >{eduSubjectNameMapper(item.eduSubjectName)}</Table.Item>
                            <Table.Item width={'117px'}>{ t(propositionalApproachMapper(item.drawUpName) ) }</Table.Item>
                            <Table.Item width={'144px'}>{format(getTime(new Date(item.updateTime)), 'yyyy/dd/MM HH:mm')}</Table.Item>
                            <Table.Item width={'88px'}>
                              <Tooltip placement="top" title={t('containers.examPaperListContent.__copyExamPaper')} zIndex={100} color='#4e4e5a'>
                                <StyledActionIcon
                                  disabled={!canOperateExamPaper}
                                  onClick={() => {
                                    copyEditExamPaper(item, 'Copy');
                                    GA.copyPaper();
                                  }}
                                >
                                  <CopySolid />
                                </StyledActionIcon>
                              </Tooltip>
                              <Tooltip placement="top" title={t('containers.examPaperListContent.__editExamPaper')} zIndex={100} color='#4e4e5a'>
                                <StyledActionIcon
                                  disabled={!canOperateExamPaper}
                                  onClick={() => {
                                    if (item.readonly) {
                                      setDenyEditModalOpenTrue();
                                    } else {
                                      copyEditExamPaper(item, 'Edit');
                                      GA.editPaper();
                                    }
                                  }}
                                >
                                  <FileEditSolid />
                                </StyledActionIcon>
                              </Tooltip>
                            </Table.Item>
                            <Table.Item width={'120px'}>
                              <Tooltip placement="top" title={t('containers.examPaperListContent.__previewExamPaper')} zIndex={100} color='#4e4e5a'>
                                <StyledActionIcon
                                  onClick={() => {
                                    goToExamPage(item.uid);
                                    GA.previewPaper();
                                  }}
                                >
                                  <EyeSolid />
                                </StyledActionIcon>
                              </Tooltip>
                              <Tooltip placement="top" title={t('containers.examPaperListContent.__downloadExamPaper')} zIndex={100}>
                                <StyledActionIcon
                                  disabled={!(item.downloadName && item.download)}
                                  onClick={() => {
                                    // item.isDownloadSuccess: boolean | null
                                    if (item.isDownloadSuccess && item.downloadName && item.download) {
                                      blobDownload(
                                        item.download,
                                        item.downloadName,
                                        'GET'
                                      );
                                    } else if (item.isDownloadSuccess === false) {
                                      setDownloadFailedPaperId(item.uid);
                                    }
                                  }}
                                >
                                  <DownloadIcon isDownloadSuccess={item.isDownloadSuccess} />
                                </StyledActionIcon>
                              </Tooltip>
                              {
                                showCreateQuizBtn ?
                                  <Tooltip
                                    placement="top"
                                    title={<span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}
                                    >
                                      {t('containers.examPaperListContent.__createTest')}<br />{t('containers.examPaperListContent.__quickTestUseOnly')}</span>} zIndex={100} color='#4e4e5a'>
                                    <StyledActionIcon disabled={!canCreateQuiz(item.attributes.outputType)} className='createQuizBtn' onClick={!canCreateQuiz(item.attributes.outputType) ? null : () => createQuizExamModalToggle(item, 'create')}><RocketSolid /></StyledActionIcon>
                                  </Tooltip> : null
                              }
                            </Table.Item>
                            <Table.Item className='moreDetail' width={'120px'}><Button variant="brand1" onClick={() => { showDetailModal(item); }}>{t('containers.examPaperListContent.__seeMore')}</Button></Table.Item>
                          </>
                          :
                          <>
                            <Table.Item>{eduSubjectNameMapper(item.eduSubjectName)}</Table.Item>
                            <Table.Item>{item.name}</Table.Item>
                            <Table.Item>{format(getTime(new Date(item.updateTime)), 'yyyy/dd/MM HH:mm')}</Table.Item>
                          </>
                      }
                    </Table.Row>
                  )) :
                    <div className="tableEmpty">
                      <div>
                        {t('containers.examPaperListContent.__noExamsYet')}
                      </div>
                      {
                        authState?.context?.userDetails &&
                        <>
                          <Button borderRadius='24px' variant={'brand1'} className="makeATextPaperBtn" onClick={() => setEduSubjectModalToggle()}>
                            {t('containers.examPaperListContent.__createExam')}
                          </Button>
                        </>
                      }
                    </div>
                }
              </Table.Body>
          }
        </Table>
        <div className="pageBlock">
          <Pagination
            total={filterData?.length || 0}
            defaultCurrent={1}
            current={pageState.currentPage}
            onChange={pageChange}
            defaultPageSize={pageState.limit}
          />
        </div>
      </Box>
      {
        modalInfo ?
          <Modal
            id={'detailModal'}
            visible={modalVisible}
            width={'860px'}
            onCancel={setModalVisibleFalse}
            title={
              deviceData.isDesktop ? <div>{modalInfo.name}</div> : t('containers.examPaperListContent.__examDetails')
            }
          >
            <div className="modalContent">
              <div className="modalRow">
                <Box mb={4}>
                  <div className="modalInnerTitle">
                    {t('containers.examPaperListContent.__examData')}
                  </div>
                  <div className="modalInnerContent">
                    {
                      !deviceData.isDesktop ? <><div className="modalInfoRow"><div>{t('containers.examPaperListContent.__examName')}</div><span>{modalInfo.name}</span></div></> : null
                    }
                    <div className="modalInfoRow"><div>{t('containers.examPaperListContent.__academicSubject')}</div><span>{ eduSubjectNameMapper(modalInfo.eduSubjectName) }</span></div>
                    {/* <div className="modalInfoRow"><div>考試別</div><span>{modalInfo.examTypeName}</span></div> */}
                    <div className="modalInfoRow"><div>{t('containers.examPaperListContent.__questioningMethod')}</div><span>{ t(propositionalApproachMapper(modalInfo.drawUpName)) }</span></div>
                    <div className="modalInfoRow"><div>{t('containers.examPaperListContent.__examPaperType')}</div><span>{ t( quizOutputTypeMapper(modalInfo.outputTypeName) ) }</span></div>
                    <div className="modalInfoRow"><div>{t('containers.examPaperListContent.__issueTime')}</div><span>{format(getTime(new Date(modalInfo.updateTime)), 'yyyy/dd/MM HH:mm')}</span></div>
                    <div className="modalInfoRow"><div>{t('containers.examPaperListContent.__serialNumber')}</div><span>{modalInfo.uid}</span></div>
                  </div>
                </Box>
                <Box>
                  <div className="modalInnerTitle">{t('containers.examPaperListContent.__examContent')}</div>
                  <div className="modalInnerContent">
                    <div className="modalInfoRow"><div>{t('containers.examPaperListContent.__questionScope')}</div>
                      <span>
                        {modalInfo.attributes.bookNames.map((item, index, arr) => {
                          if (arr.length - 1 === index) {
                            return `${t(bookNameMapper(item), item)}`;
                          } else {
                            return `${t(bookNameMapper(item), item)}, `;
                          }
                        })}
                      </span>
                    </div>
                    <div className="modalInfoRow"><div>{t('containers.examPaperListContent.__numberOfQuestions')}</div><span> { modalInfo.attributes.questionAmount } </span></div>
                  </div>
                </Box>
              </div>

              {!deviceData.isDesktop ? <div className='blockDiv'></div> : null}

              <div className="modalRow controlBox">
                {
                  deviceData.isDesktop ? <div className="modalInnerTitle">{t('containers.examPaperListContent.__copyEdit')}</div> : null
                }
                <div className="modalInnerContent">
                  <Box mb={deviceData.isDesktop ? 32 : 0}>
                    <div className='copyEditBtnBox'>
                      <Button
                        disabled={!canOperateExamPaper}
                        variant={canOperateExamPaper ? 'brand1' : 'ui02'}
                        onClick={() => {
                          copyEditExamPaper(modalInfo, 'Copy');
                          GA.copyPaper();
                        }}
                      >
                        <CopySolid /> {t('containers.examPaperListContent.__copyExamPaper')}
                      </Button>
                      <Button
                        disabled={!canOperateExamPaper}
                        variant={canOperateExamPaper ? 'brand1' : 'ui02'}
                        onClick={() => {
                          if (modalInfo.readonly) {
                            setDenyEditModalOpenTrue();
                          } else {
                            copyEditExamPaper(modalInfo, 'Edit');
                            GA.editPaper();
                          }
                        }}
                      >
                        <FileEditSolid /> {t('containers.examPaperListContent.__editExamPaper')}
                      </Button>
                      <Button
                        disabled={!canOperateExamPaper}
                        variant={canOperateExamPaper ? 'brand1' : 'ui02'}
                        onClick={openHintModal}
                      >
                        <TrashAltOutline /> {t('containers.examPaperListContent.__deleteExamPaper')}
                      </Button>
                    </div>
                  </Box>
                </div>
                {
                  deviceData.isDesktop ? <div className="modalInnerTitle">{t('containers.examPaperListContent.__operation')}</div> : null
                }
                <div className="modalInnerContent">
                  <Box className="buttonZone" mb={deviceData.isDesktop ? 54 : 0}>
                    <Button
                      variant={'brand1'}
                      onClick={() => {
                        goToExamPage(modalInfo.uid);
                        GA.previewPaper();
                      }}
                    >
                      <EyeSolid /> {t('containers.examPaperListContent.__previewExamPaper')}
                    </Button>
                    {
                      (modalInfo.downloadName && modalInfo.download) &&
                      <Button variant={(modalInfo.downloadName && modalInfo.download) ? 'brand1' : 'ui02'}
                        disabled={!(modalInfo.downloadName && modalInfo.download)}
                        onClick=
                          {
                            () => {
                              blobDownload(
                                modalInfo.download,
                                modalInfo.downloadName,
                                'GET'
                              );
                            }
                          }
                      ><FileDownloadSolid /> {t('containers.examPaperListContent.__downloadExamPaper')}</Button>
                    }
                    {
                      modalInfo.attributes.outputType === 'Quick' && showCreateQuizBtn &&
                      <Button
                        disabled={false}
                        variant={'brand1'}
                        onClick={() => createQuizExamModalToggle(modalInfo, 'create')}
                      >
                        <RocketSolid />
                        {t('containers.examPaperListContent.__createQuickTest')}
                      </Button>
                    }
                  </Box>
                  {/* OPP-1424 公開試卷功能暫時隱藏 */}
                  {/* {
                    deviceData.isDesktop && modalInfo.attributes.outputType !== 'Quick' &&
                    <Box className="modalPublicContent">
                      <div>
                        <div className="modalPublicContentTitle"><GlobeAsiaSolid />公開試卷</div>
                        <div className="modalHint">公開後，所有使用者將可以檢視這份試卷</div>
                      </div>
                      <div><Switch defaultChecked={modalInfo.attributes.isPublic} onChange={(checked) => { changeExamPaperPublicStatus(modalInfo.uid, checked); }} /></div>
                    </Box>
                  } */}
                </div>
              </div>
            </div>
          </Modal> : null
      }

      {/* 篩選彈窗 */}
      <Modal
        id={'filterDrawer'}
        visible={filterDrawerVisible}
        zIndex={1001}
        onCancel={filterDrawerFalse}
        width='100%'
        title={
          <>
            <span>{t('containers.examPaperListContent.__filter')}</span>
            <div className={classNames('clearAllFilterBtn', (Object.keys(filterParamsMobile).length !== 0 || eduSubjectMobile.edu) ? 'active' : '')} onClick={clearAllFilter}>
              {t('containers.examPaperListContent.__clear')}
            </div>
          </>
        }
        footer={
          <>
            <Button
              className='reportModalBtn'
              variant={!checkChangeFilterBtn ? 'ui02' : 'brand2'}
              disabled={!checkChangeFilterBtn}
              onClick={doneChangeFilter}
            >
              {t('containers.examPaperListContent.__useBtn')}
            </Button>
          </>
        }
      >
        <div className='filterDrawerContent'>
          <div className='title'>
            {t('containers.examPaperListContent.__academicSubject')}
          </div>
          <div>
            <div className='subTitle'>{t('containers.examPaperListContent.__academic')}</div>
            <RadioGroup
              key={'eduOptions'}
              name={'eduOptions'}
              className='radioList' buttonStyle='solid'
              optionType='button'
              options={eduOptions}
              value={eduSubjectMobile.edu}
              onChange={(e) => { changeEduSubjectMobile(e.target.value, 'edu'); }}
            />
          </div>
          {
            eduSubjectMobile.edu &&
            <div>
              <div className='subTitle'>{t('containers.examPaperListContent.__subject')}</div>
              <RadioGroup
                key={'subjectOptions'}
                name={'subjectOptions'}
                className='radioList' buttonStyle='solid'
                optionType='button'
                options={subjectOptions[eduSubjectMobile.edu]}
                value={eduSubjectMobile.subject}
                onChange={(e) => { changeEduSubjectMobile(e.target.value, 'subject'); }}
              />
            </div>
          }
        </div>
        {/* <div className='filterDrawerContent'>
          <div className='title'>考試別</div>
          <RadioGroup
            key={transformMobileOptions(typeOptions)}
            className='radioList' buttonStyle='solid'
            optionType='button'
            options={transformMobileOptions(typeOptions)}
            value={filterParamsMobile.examType}
            onChange={(e) => { changeFilterParamsMobile(e.target.value, 'examType'); }}
          />
        </div> */}
        <div className='filterDrawerContent'>
          <div className='title'>{t('containers.examPaperListContent.__questioningMethod')}</div>
          <RadioGroup
            key={transformMobileOptions(patternOptions)}
            className='radioList' buttonStyle='solid'
            optionType='button'
            options={transformMobileOptions(patternOptions)}
            value={filterParamsMobile.drawUp}
            onChange={(e) => { changeFilterParamsMobile(e.target.value, 'drawUp'); }}
          />
        </div>
      </Modal>

      <StyledHintModal
        visible={hintModalVisible}
        title={t('containers.examPaperListContent.__confirmDeleteExamPaper')}
        onCancel={setHintModalFalse}
        isMobile={true}
        id='hintModal'
        className='hintModal'
        zIndex={1099}
        centered={deviceData.isDesktop}
        footer={
          <div className="footerBtn footerBtnBox">
            <Button variant='ui01'
              onClick={cancelHintModal}>
              {t('containers.examPaperListContent.__cancel')}
            </Button>
            <Button
              className={'confirmBtn'}
              onClick={() => {
                doneHintModal(modalInfo?.uid);
                GA.deletePaper();
              }}
              isLoading={isLoading}
            >
              {t('containers.examPaperListContent.__confirmDelete')}
            </Button>
          </div>
        }>
        {
          modalInfo?.sharingPackages?.length > 0 ? (
            <span className='hintModalContent'>
              <div className='title'>
                {t('containers.examPaperListContent.__modalContentTitle', { lengthSharingPackages: modalInfo?.sharingPackages.length })}
                <div className="list">
                  {
                    modalInfo?.sharingPackages.map((item) => (
                      <div>{item}</div>
                    ))
                  }
                </div>
              </div>
            </span>
          ) : (
            <span className='hintModalContent'>
              {t('containers.examPaperListContent.__deleteExamPaperWarning')}
            </span>
          )
        }

      </StyledHintModal>
      <StyledHintModal
        visible={denyEditModalOpen}
        title={t('containers.examPaperListContent.__cannotEditExamPaper')}
        onCancel={setDenyEditModalOpenFalse}
        centered={deviceData.isDesktop}
        footer={
          <div className="footerBtn footerBtnBox">
            <Button
              className={'confirmBtn'}
              onClick={setDenyEditModalOpenFalse}
            >
              {t('containers.examPaperListContent.__confirm')}
            </Button>
          </div>
        }>
        <span className='hintModalContent'>
          {t('containers.examPaperListContent.__cannotEditWarning')}
        </span>
      </StyledHintModal>
      <StyledHintModal
        visible={questionErrorModalOpen}
        title={t('containers.examPaperListContent.__cannotUseExamPaper')}
        onCancel={setQuestionErrorModalOpenFalse}
        centered={deviceData.isDesktop}
        footer={
          <div className="footerBtn footerBtnBox">
            <Button
              className={'confirmBtn'}
              onClick={setQuestionErrorModalOpenFalse}
            >
              {t('containers.examPaperListContent.__confirm')}
            </Button>
          </div>
        }>
        <span className='hintModalContent'>
          {t('containers.examPaperListContent.__cannotUseWarning')}
        </span>
      </StyledHintModal>
      {renderCreateQuizExamModal()}
      <DownloadFailedModal
        downloadFailedPaperId={downloadFailedPaperId}
        closeModal={() => setDownloadFailedPaperId(null)}
      />
    </StyledExamPaperListContent>
  );
};