import { Api } from '@oneclass/ui-components';
import { t } from 'i18next';
import { reportErrorTypeMapper } from 'locales/translationMapper';
export const getDrawUpFastSelections = async (pattern, payload) => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/${pattern}/Selection`, payload);
  return response;
};

export const getMemberEduSubject = async () => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Member/EduSubject`);
  return response;
};

export const getDrawUpFastCacheQuery = async (pattern, payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/${pattern}/CacheQuery`, payload);
  return response;
};

export const getDrawUpFastQuestionInfo = async (pattern, payload) => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/${pattern}/QuestionInfo`, payload);
  return response;
};
// 錯題回報 - 取得錯題回報相關選單
export const getAnomalySelectOption = async () => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Anomaly/Related`);
  if (response.data) {
    response.data = response.data.map(item => {
      return {
        ...item,
        name: t( reportErrorTypeMapper(item.code), item.name )
      };
    });
  }
  return response;
};
// 錯題回報 - 試題回報
export const postAnomalyReport = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Anomaly/Report`, payload);
  return response;
};

// 取得 試題分佈
export const postQuestionAnalysis = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Query/Charts`, payload);
  return response;
};