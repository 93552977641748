import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { StyledStudentWrongQuestionList, StyledAreaSelect, StyledFilter, StyledQuestionsList, StyledStudentWrongQuestionListContent, StylePreviewQuesModalContent } from './StudentWrongQuestionList.style';
import { Box, Modal, FETCH_DATA_EVENT, fetchDataMachine, setRadioOptions, AuthProvider, CreateExamPaperParamsProvider, TreeCheck, Pagination, sliceTableData, StepBox, openNotificationWithIcon } from '@oneclass/ui-components';
import { Button, RadioGroup, Checkbox } from '@oneclass/onedesign';
import { Radio, notification } from 'antd';
import { ThumbsUpSolid, EyeSolid, ChevronRightOutline } from '@onedesign/icon';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { useMachine } from '@xstate/react';
import { useBoolean, useSelections, useSize } from 'ahooks';
import classNames from 'classnames';
import { getFixSelection, getFixQuestionList, putFixUnderstand, postFixPractice } from 'api/fixWrongRecord';
import { getCookie } from 'utils/common';
import { postPracticeStart } from 'api/practice';
import { useTranslation } from 'react-i18next';

export const StudentWrongQuestionList = ({ className, handleChangeTab, currentTab }) => {
  const { t } = useTranslation();
  // const { isDesktop } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const [step, setStep] = useState(1);

  const transformOptions = (Array) => {
    return Array.map((item) => {
      return {
        label: item.name,
        value: item.code
      };
    });
  };

  const [sendParams, setSendParams] = useState({
    edu: '',
    year: '',
    subject: '',
    version: '',
  });

  const checkedSendParams = sendParams.edu && sendParams.year && sendParams.subject && sendParams.version;

  // 確認 題目顯示 是否使用 圖片顯示 （規則 全學制數學、國中理化、國小國文）
  const isShowQuestionImg = sendParams.subject === 'MA' || (sendParams.edu === 'J' && sendParams.subject === 'PY') || (sendParams.edu === 'E' && sendParams.subject === 'CH');

  useEffect(() => {
    if (sendParams.edu && sendParams.year && sendParams.subject) {
      sendGetFixQuestionList(FETCH_DATA_EVENT.FETCH, {
        payload: {
          eduSubject: sendParams.edu + sendParams.subject,
          year: sendParams.year,
        }
      });
    }
  }, [sendParams.edu, sendParams.year, sendParams.subject]);

  const onRadioChange = (type, e) => {
    switch (type) {
      case 'year':
        setSendParams({
          ...sendParams,
          [type]: e.target.value,
          edu: '',
          subject: '',
          version: ''
        });
        break;
      case 'edu':
        setSendParams({
          ...sendParams,
          [type]: e.target.value,
          subject: '',
          version: ''
        });
        break;
      case 'subject':
        setSendParams({
          ...sendParams,
          [type]: e.target.value,
          version: ''
        });
        break;
      default:
        setSendParams({
          ...sendParams,
          [type]: e.target.value,
        });
    }
  };

  // 打 api 取得 學制科目 選項
  const [stateGetFixSelection, sendGetFixSelection] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context) => {
        const res = await getFixSelection();
        const { data, isSuccess, systemCode, message } = res;
        const { eduMap, subjectMap, year } = data;
        return {
          isSuccess, systemCode, message, eduMap, subjectMap, yearMap: year
        };
      },
    },
  });

  const { eduMap, subjectMap, yearMap, isSuccess: step1IsSuccess } = stateGetFixSelection.context.result || {};

  const subjectOptions = useMemo(() => {
    if (!sendParams?.edu || !subjectMap) return [];
    if (!subjectMap[sendParams.edu]) return [];
    return setRadioOptions(subjectMap[sendParams.edu]);
  }, [sendParams.edu, subjectMap]);

  // 打 api 取得 錯題列表
  const [stateGetFixQuestionList, sendGetFixQuestionList] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        const res = await getFixQuestionList(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        const { versionMap, bookMap, searchKey } = data;
        return {
          isSuccess, systemCode, message, versionMap, bookMap, searchKey
        };
      },
    },
  });

  const { versionMap, bookMap, searchKey } = stateGetFixQuestionList.context.result || {};

  const bookChapters = useMemo(() => {
    if (!bookMap || !sendParams.version) return [];
    let array = [];
    Object.entries(bookMap[sendParams.version].chapterMap).forEach(([key, value]) => {
      array.push(value);
    });
    //  冊數 自行排序
    array.sort(function(a, b) {
      return parseInt(a.bookID.substr(9, 2)) - parseInt(b.bookID.substr(9, 2));
    });
    return array;
  }, [bookMap, sendParams.version]);

  const questions = useMemo(() => {
    if (bookChapters) {
      let questionsArray = [];
      bookChapters.forEach((book) => {
        book.chapters.forEach((chapter) => {
          if (chapter.questions) {
            chapter.questions.forEach((question) => {
              questionsArray.push({
                ...question,
                range: {
                  code: chapter.code,
                  name: chapter.name,
                },
                bookID: book.bookID,
              });
            });
          }
        });
      });
      return questionsArray;
    } else {
      return [];
    }
  }, [bookChapters]);

  // 打 api 刪除 錯題
  const [statePutFixUnderstand, sendPutFixUnderstand] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        const res = await putFixUnderstand(event.payload);
        const { isSuccess, systemCode, message } = res;
        sendGetFixQuestionList(FETCH_DATA_EVENT.FETCH, {
          payload: {
            eduSubject: sendParams.edu + sendParams.subject,
            year: sendParams.year,
          }
        });
        if (isSuccess)
          openNotification(
            t('containers.studentWrongQuestionList.__removedFromWrongQuestions'),
            'bottomLeft'
          );
        return {
          isSuccess, systemCode, message
        };
      },
    },
  });

  const clickUnderStandBtn = (item) => {
    sendPutFixUnderstand(FETCH_DATA_EVENT.FETCH, {
      payload: {
        eduSubject: sendParams.edu + sendParams.subject,
        ID: item.id
      }
    });
    selectedRemove(item.id);
  };

  // 打 建立錯題測驗 api
  const [statePostFixPractice, sendPostFixPractice] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        const res = await postFixPractice(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        const { uid } = data;
        if (isSuccess) {
          goToExamPage(uid);
          handleChangeTab('1');
          onStepClick(0);
        }
        return {
          isSuccess, systemCode, message
        };
      },
    },
  });

  const clickCreatePracticeBtn = () => {
    sendPostFixPractice(FETCH_DATA_EVENT.FETCH, {
      payload: {
        version: sendParams.version,
        bookIDs: selectedBookID,
        searchKey: searchKey,
        questionGroup: questionGroup,
      }
    });
  };

  // 將範圍轉換成 樹狀圖所需
  const JSON_Transform_Object = (Array) => {
    if (!Array) return [];
    let newArray = [];
    Array.forEach((item) => {
      if (item.indexOf(':') !== -1) {
        newArray.push(JSON.parse(item));
      }
    });
    return newArray;
  };

  const treeOptions = useMemo(() => {
    if (bookChapters) {
      const haveChapterData = bookChapters[0]?.chapters[bookChapters[0]?.chapters.length - 1]?.parentCode;
      return bookChapters.map((firItem) => {
        let parentChapters = [];
        let childrenChapters = [];
        let previewGrandparentChapters = [];
        let previewParentChapters = [];
        const makeChildren = () => {
          firItem.chapters.forEach((item) => {

            if ( item.parentCode === null ) {
              parentChapters.push({
                ...item,
                key: JSON.stringify(item),
              });
            } else {
              let previewChildrenChapters = [];
              previewChildrenChapters.push(JSON.stringify(item));
              childrenChapters.push({
                ...item,
                title: <div>{item.desc} ({item.amount})
                  <div className='previewBtn' onClick={(e) => {changePreviewChapters(previewChildrenChapters, e, item.desc);}}>
                    <EyeSolid/>
                    <span>{t('containers.studentWrongQuestionList.__viewQuestions')}</span>
                  </div>
                </div>,
                key: JSON.stringify(item),
              });
            }
            previewGrandparentChapters.push(JSON.stringify(item));
            haveChapterData && previewParentChapters.push(item);
          });

          return parentChapters.map((parentItem) => {
            if (haveChapterData) {
              let array = [];
              previewParentChapters.forEach((childrenItem) => {
                if (childrenItem.parentCode === parentItem.code) array.push(JSON.stringify(childrenItem));
              });
              return ({
                ...parentItem,
                title: <div>
                  {parentItem.desc} ({parentItem.amount})
                  <div className='previewBtn' onClick={(e) => {changePreviewChapters(array, e, parentItem.desc);}}>
                    <EyeSolid/>
                    <span>{t('containers.studentWrongQuestionList.__viewQuestions')}</span>
                  </div>
                </div>,
                children: childrenChapters.filter((childrenItem) =>
                  childrenItem.parentCode === parentItem.code
                )
              });
            } else {
              let emptyArray = [];
              emptyArray.push(JSON.stringify(parentItem));
              return ({
                ...parentItem,
                title: <div>
                  {parentItem.desc} ({parentItem.amount})
                  <div className='previewBtn' onClick={(e) => {changePreviewChapters(emptyArray, e, parentItem.desc);}}>
                    <EyeSolid/>
                    <span>{t('containers.studentWrongQuestionList.__viewQuestions')}</span>
                  </div>
                </div>,
                children: childrenChapters.filter((childrenItem) =>
                  childrenItem.parentCode === parentItem.code
                )
              });
            }
          });
        };
        return {
          ...firItem,
          title: <div>
            {firItem.bookDesc} ({firItem.amount})
            <div className='previewBtn' onClick={(e) => {changePreviewChapters(previewGrandparentChapters, e, firItem.bookDesc);}}>
              <EyeSolid/>
              <span>{t('containers.studentWrongQuestionList.__viewQuestions')}</span>
            </div>
          </div>,
          key: firItem.bookID,
          children: makeChildren()
        };
      });
    }
  }, [bookChapters]);

  const allChaptersArray = useMemo(() => {
    if (!bookChapters) return [];
    let allChapters = [];
    bookChapters.forEach((item) => {
      allChapters.push(item.bookID);
      item.chapters.forEach((childrenItem) => {
        allChapters.push(JSON.stringify(childrenItem));
      });
    });
    return allChapters;
  }, [bookChapters]);

  const allPreviewChapters = useMemo(() => {
    if (!bookChapters) return [];
    let allPreviewChaptersArray = [];
    bookChapters.forEach((item) => {
      item.chapters.forEach((childrenItem) => {
        allPreviewChaptersArray.push(JSON.stringify(childrenItem));
      });
    });
    return allPreviewChaptersArray;
  }, [bookChapters]);

  const [modalTitle, setModalTitle] = useState('');

  const changePreviewChapters = (Array, e, modalTitle) => {
    PreviewQuesModalToggle();
    setModalTitle(modalTitle);
    setPreviewChapters(JSON_Transform_Object(Array));
    e.stopPropagation();
  };

  const [treeData, setTreeData] = useState([]);
  const [filterChapters, setFilterChapters] = useState([]);

  const TreeChange = (Array) => {
    setTreeData(Array);
    setFilterChapters(JSON_Transform_Object(Array));
  };

  const checkedChapters = filterChapters && filterChapters?.length > 0;
  const checkedAllChapters = allChaptersArray?.length === treeData?.length;

  const toggleAllChapter = () => {
    if (checkedAllChapters) {
      setTreeData([]);
      setFilterChapters([]);
    } else {
      setFilterChapters(JSON_Transform_Object(allChaptersArray));
      setTreeData(allChaptersArray);
    }
  };

  // 試題列表
  const [pageState, setPageState] = useState({
    currentPage: 1,
    limit: 10,
  });

  const pageChange = (currentPage, pageSize) => {
    setPageState({
      ...pageState,
      currentPage,
      limit: pageSize,
    });
    const main = document.getElementById('mainLayout');
    main.scrollTop = 0;
  };

  const [previewChapters, setPreviewChapters] = useState([]);

  const [previewPageState, setPreviewPageState] = useState({
    currentPage: 1,
    limit: 10,
  });

  const previewPageChange = (currentPage, pageSize) => {
    setPreviewPageState({
      ...previewPageState,
      currentPage,
      limit: pageSize,
    });
  };

  const previewQuestionData = useMemo(() => {
    if (!questions) {
      return [];
    }
    if (!previewChapters.length > 0) {
      return [];
    }
    let nextData = questions
      .filter((question) => {
        const checkResult = () => {
          const codeArray = previewChapters.map(i => i.code) || [];
          const nameArray = previewChapters.map(i => i.name) || [];

          return codeArray.includes(question.range.code) && nameArray.includes(question.range.name);
        };
        if (checkResult()) return question;
      });
    return nextData;
  }, [questions, previewChapters]);

  const filterQuestionData = useMemo(() => {
    if (!questions) {
      return [];
    }
    if (!filterChapters.length > 0) {
      return [];
    }
    let nextData = questions
      .filter((question) => {
        const codeArray = filterChapters.map(i => i.code) || [];
        const nameArray = filterChapters.map(i => i.name) || [];
        const checkResult = () => {
          return (
            codeArray.includes(question.range.code) && nameArray.includes(question.range.name)
          );
        };
        if (checkResult()) return question;
      });
    return nextData;
  }, [questions, filterChapters]);


  const allQuestionID = useMemo(() => {
    if (!filterQuestionData) return [];
    return filterQuestionData.map(item => item.id);
  }, [filterQuestionData]);

  const { selected, allSelected, isSelected, toggle, toggleAll, partiallySelected, unSelectAll, setSelected } = useSelections(allQuestionID, []);

  const checkedSelected = selected && selected.length > 0;

  // 輸出 答案

  const renderAnswer = (item) => {
    const answer = JSON.parse(item.answer)[0];
    const userAnswer = JSON.parse(item.userAnswer)[0];
    if (item.typeName !== t('containers.studentWrongQuestionList.__trueFalse')) {
      const checkedClassName = (num) => {
        if (answer[0] === num) return 'rightAnswer';
        if (userAnswer[0] === num) return 'wrongAnswer';
      };
      return (
        <div>
          <div className={checkedClassName(1)}>A</div>
          <div className={checkedClassName(2)}>B</div>
          <div className={checkedClassName(3)}>C</div>
          <div className={checkedClassName(4)}>D</div>
          {
            userAnswer.length === 0 &&
            <div className='noAnswer'>{t('containers.studentWrongQuestionList.__unanswered')}</div>
          }
        </div>
      );
    } else {
      const checkedAnswer = userAnswer === '○';
      return (
        <div>
          <div className={checkedAnswer ? 'rightAnswer' : 'wrongAnswer'}>○</div>
          <div className={checkedAnswer ? 'wrongAnswer' : 'rightAnswer'}>Ｘ</div>
          {
            userAnswer.length === 0 &&
            <div className='noAnswer'>{t('containers.studentWrongQuestionList.__unanswered')}</div>
          }
        </div>
      );
    }
  };

  const renderContent = (str) => {
    return <div className='content' dangerouslySetInnerHTML={{ __html: str }} />;
  };

  // 移除陣列中 某一值
  const selectedRemove = (id) => {
    if (selected.length === 0) return null;
    const index = selected.indexOf(id);
    if (index !== -1) {
      selected.splice(index, 1);
    }
  };

  // 已選取的題目 Array
  const selectedQuestions = useMemo(() => {
    if (!selected) return [];
    const selectedQuestion = questions.filter((question) => {
      return selected.includes(question.id);
    });
    let data = [];
    selectedQuestion.forEach((question) => {
      if (!data.includes(question.bookID)) data.push(question);
    });
    return data;
  }, [selected, questions]);

  // 組成 questionGroup
  const typeQuestions = useMemo(() => {
    let data = {};
    selectedQuestions.forEach((question) => {
      if (selected.indexOf(question.id) !== -1) {
        if ( `${question.typeCode}-${question.typeName}` in data) {
          data[`${question.typeCode}-${question.typeName}`].push(question.id);
        } else {
          data = {
            ...data,
            [`${question.typeCode}-${question.typeName}`]: [question.id],
          };
        }
      }
    });
    return data;
  }, [selectedQuestions]);

  const questionGroup = useMemo(() => {
    let data = [];
    for (const [key, value] of Object.entries(typeQuestions)) {
      data.push({
        typeCode: key.split('-')[0],
        typeName: key.split('-')[1],
        scoreType: '',
        score: 0,
        questionList: value,
      });
    }
    return data;
  }, [typeQuestions]);


  const selectedBookID = useMemo(() => {
    if (!selected) return [];
    const selectedQuestion = questions.filter((question) => {
      return selected.includes(question.id);
    });
    let data = [];
    selectedQuestion.forEach((question) => {
      if (!data.includes(question.bookID)) data.push(question.bookID);
    });
    return data;
  }, [selected, questions]);

  useEffect(() => {
  }, [selectedBookID]);

  // 控制頁面 前進/後退 及相關動作

  const onStepClick = (current) => {
    switch (current) {
      case 0:
        setSendParams({
          edu: '',
          year: '',
          subject: '',
          version: '',
        });
        break;
      case 1:
        break;
      default:
        return null;
    }
    setPreviewPageState({
      currentPage: 1,
      limit: 10,
    });
    setPageState({
      currentPage: 1,
      limit: 10,
    });
    setSelected([]);
    setTreeData();
    setFilterChapters([]);
    setStep(current + 1);
  };

  const nextStep = () => {
    switch (step) {
      case 1:
        break;
      case 2:
        toggleAll();
        break;
      case 3:
        break;
      default:
        return null;
    }
    setStep(step + 1);
  };

  // 跳轉去 oneExam

  const [statePostPracticeStart, sendPostPracticeStart] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        const res = await postPracticeStart(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) window.open(data);
        return {
          isSuccess, systemCode, message
        };
      },
    },
  });

  const goToExamPage = (uid) => {
    const oneClubToken = JSON.parse(getCookie('nani_oneclass_login_token')).jwt;
    const payload = {
      uid,
      oneClubToken
    };
    sendPostPracticeStart(FETCH_DATA_EVENT.FETCH, {
      payload
    });
  };

  //  ------  試題預覽  -------

  const [previewQuesModalVisible, { toggle: PreviewQuesModalToggle, setFalse: PreviewQuesModalFalse }] = useBoolean(false);

  const stepTitle = deviceData.isDesktop
    ? [
      { title: t('containers.studentWrongQuestionList.__selectRange') },
      { title: t('containers.studentWrongQuestionList.__selectBookChapter') },
      { title: t('containers.studentWrongQuestionList.__chooseQuestions') }
    ]
    : [
      { title: '' },
      { title: '' },
      { title: '' }
    ];

  const goTop = () => {
    const main = document.getElementById('mainLayout');
    main.scrollTop = 0;
  };

  // 提示框
  const openNotification = ( message, placement) => {
    const root = document.querySelector('#StudentWrongQuestionList');
    notification.open({
      message,
      placement,
      getContainer: () => root,
    });
  };

  useEffect(() => {
    if (currentTab !== '1') {
      sendGetFixSelection(FETCH_DATA_EVENT.FETCH);
      goTop();
    }
  }, [currentTab]);

  useEffect(() => {
    goTop();
  }, [step]);

  useEffect(() => {
    if (!deviceData.isDesktop) {
      document.querySelector('.footer').style = 'display: none';
    } else {
      document.querySelector('.footer').style = 'display: block';
    }
  }, []);

  return (
    <StyledStudentWrongQuestionList className={className} data-testid="StudentWrongQuestionList" id={'StudentWrongQuestionList'}>
      <StepBox steps={stepTitle} current={step - 1} onChange={onStepClick} stepdisabled={step === 4 ? true : false}/>
      <StyledStudentWrongQuestionListContent className={step1IsSuccess ? '' : 'isEmpty'}>
        {
          step1IsSuccess
            ?
            <>
              {
                step === 1 &&
                <StyledAreaSelect>
                  <div className='stepTitle'>
                    {t('containers.studentWrongQuestionList.__selectRange')}
                  </div>
                  <Box mt={2} mb={4}>
                    <div className='title'>{t('containers.studentWrongQuestionList.__academicYear')}</div>
                  </Box>
                  <Box mb={8} className='boxBottomLine'>
                    <RadioGroup className='yearRadioList' buttonStyle='solid' optionType='button' options={yearMap ? transformOptions(yearMap) : []} value={sendParams?.year} onChange={(e) => {onRadioChange('year', e);}} />
                  </Box>
                  <Box mt={2} mb={4}>
                    <div className='title'>{t('containers.studentWrongQuestionList.__academicSubject')}</div>
                  </Box>
                  <Box mb={2}>
                    <div className='subTitle'>{t('containers.studentWrongQuestionList.__curriculum')}</div>
                  </Box>
                  <Box mb={8}>
                    <RadioGroup className='eduRadioList' buttonStyle='solid' optionType='button' options={eduMap ? transformOptions(eduMap) : []} value={sendParams?.edu} onChange={(e) => {onRadioChange('edu', e);}} />
                  </Box>
                  {
                    sendParams.edu &&
                    <>
                      <Box mb={2}>
                        <div className='subTitle'>{t('containers.studentWrongQuestionList.__subject')}</div>
                      </Box>
                      <Box mb={8} className='boxBottomLine'>
                        <RadioGroup className='subjectRadioList' buttonStyle='solid' optionType='button' value={sendParams?.subject} onChange={(e) => {onRadioChange('subject', e);}}>
                          {
                            subjectOptions && subjectOptions.map(item => (
                              <Radio.Button className={item.label.length > 5 ? 'longerRadio' : ''} value={item.value}>{item.label}</Radio.Button>
                            ))
                          }
                        </RadioGroup>
                      </Box>
                    </>
                  }
                  <Box mt={2} mb={4}>
                    <div className='title'>{t('containers.studentWrongQuestionList.__version')}</div>
                  </Box>
                  <Box mb={8}>
                    {
                      versionMap
                        ?
                        <RadioGroup className='versionRadioList' buttonStyle='solid' optionType='button' options={versionMap ? transformOptions(versionMap) : []} value={sendParams?.version} onChange={(e) => {onRadioChange('version', e);}} />
                        :
                        <span className='garyText'>{t('containers.studentWrongQuestionList.__selectYearCurriculumSubject')}</span>
                    }
                  </Box>
                  <div className='footerBtn'>
                    <Button
                      className='reportModalBtn'
                      variant={!checkedSendParams ? 'ui02' : 'brand2'}
                      disabled={!checkedSendParams}
                      onClick={() => nextStep()}
                    >{t('containers.studentWrongQuestionList.__nextStep')}</Button>
                  </div>

                </StyledAreaSelect>
              }

              {
                step === 2 && treeOptions &&
                <StyledFilter>
                  <div className='stepTitle'>
                    {t('containers.studentWrongQuestionList.__selectBookChapter')}
                  </div>
                  {
                    questions &&
                    <div className='allSelected'>
                      <div>
                        <Checkbox checked={checkedAllChapters} onClick={() => {toggleAllChapter();}} indeterminate={treeData?.length > 0 && !checkedAllChapters}/>
                        {t('containers.studentWrongQuestionList.__allRanges')} ({questions ? questions?.length : 0})
                      </div>
                      <div className='previewBtn' onClick={(e) => {changePreviewChapters(allPreviewChapters, e, t('containers.studentWrongQuestionList.__allRanges'));}}>
                        <EyeSolid/>
                        <span>{t('containers.studentWrongQuestionList.__viewQuestions')}</span>
                      </div>
                    </div>
                  }
                  {
                    treeOptions &&
                      <TreeCheck
                        showIcon={true}
                        treeData={treeOptions}
                        checkedList={treeData}
                        onChange={TreeChange}
                        switcherIcon={<ChevronRightOutline/>}
                      />
                  }
                  <div className='footerBtn'>
                    {
                      deviceData.isDesktop &&
                      <Button className={'preStepBtn'} onClick={() => {onStepClick(0);}}>
                        {t('containers.studentWrongQuestionList.__previousStep')}
                      </Button>
                    }
                    <Button
                      className='reportModalBtn'
                      variant={!checkedChapters ? 'ui02' : 'brand2'}
                      disabled={!checkedChapters}
                      onClick={() => nextStep()}
                    >{t('containers.studentWrongQuestionList.__nextStep')}</Button>
                  </div>

                  {/* ---- 試題預覽 ---- */}
                  <Modal
                    className='previewQuesModal'
                    visible={previewQuesModalVisible}
                    zIndex={1001}
                    width={'77%'}
                    onCancel={PreviewQuesModalFalse}
                    title={`${modalTitle} (${previewQuestionData.length})`}
                  >
                    <StylePreviewQuesModalContent>
                      {
                        previewQuestionData && previewQuestionData.length > 0 ?
                          sliceTableData(previewQuestionData, previewPageState.currentPage, previewPageState.limit).map((item, index) => {
                            return (
                              <div className={classNames('perQuesItem')}>
                                <div className='questionContent'>
                                  <div className='questionDetail'>
                                    <div className='title'><span>{t('containers.studentWrongQuestionList.__question')}</span></div>
                                    {
                                      isShowQuestionImg ?
                                        <div className='content'>
                                          <img className='quesImg' src={item.questionImage.content} alt={item.id}/>
                                        </div>
                                        :
                                        renderContent(item.htmlParts.content)
                                    }
                                    <div className='title'>
                                      <span>{t('containers.studentWrongQuestionList.__answer')}</span>
                                    </div>
                                    {renderContent(item.htmlParts.answer)}
                                    {
                                      item.htmlParts.analyze !== '' &&
                                      <>
                                        <div className='title'><span>{t('containers.studentWrongQuestionList.__explanation')}</span></div>
                                        {renderContent(item.htmlParts.analyze)}
                                      </>
                                    }
                                  </div>
                                  <div className='bottomContent'>
                                    <div className='answerBox'>
                                      <div>{item.typeName}</div>
                                      {renderAnswer(item)}
                                    </div>
                                    <Button
                                      className='iGetThatBtn'
                                      onClick={() => clickUnderStandBtn(item)}
                                    >
                                      <ThumbsUpSolid/>{t('containers.studentWrongQuestionList.__IUnderstand')}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            );
                          }) : <div className="emptyMsg">{t('containers.studentWrongQuestionList.__noWrongQuestionsInThisRange')}</div>
                      }
                      <div className="pageBlock">
                        <Pagination
                          total={previewQuestionData?.length || 0}
                          defaultCurrent={1}
                          current={previewPageState.currentPage}
                          onChange={previewPageChange}
                          defaultPageSize={previewPageState.limit}
                        />
                      </div>
                    </StylePreviewQuesModalContent>
                  </Modal>
                </StyledFilter>
              }

              {
                step === 3 &&
                <StyledQuestionsList>
                  <div className='selectAllBox'>
                    <div className='stepTitle'>{t('containers.studentWrongQuestionList.__chooseQuestions')}</div>
                    <div className={allSelected ? 'allSelected' : ''} >
                      <Checkbox checked={allSelected} onClick={toggleAll} indeterminate={partiallySelected}/>
                      {t('containers.studentWrongQuestionList.__selectAllQuestions')}
                    </div>
                    {
                      deviceData.isDesktop &&
                      <div className='selectedNum'>
                        {t('containers.studentWrongQuestionList.__selectedQuestionsCount')}：{selected?.length}/{allQuestionID?.length}
                      </div>
                    }
                  </div>
                  <div className="tableContent">
                    {
                      filterQuestionData && filterQuestionData.length > 0 ?
                        sliceTableData(filterQuestionData, pageState.currentPage, pageState.limit).map((item, index) => {
                          return (
                            <div className={classNames('perQuesItem', isSelected(item.id) ? 'Selected' : '')}>
                              <div className='checkbox'>
                                <div>
                                  <Checkbox
                                    disabled={item.isImport}
                                    checked={isSelected(item.id)}
                                    onClick={(e) => {toggle(item.id); }}
                                  />
                                  {t('containers.studentWrongQuestionList.__selectQuestions')}
                                </div>
                              </div>
                              <div className='questionContent'>
                                <div className='questionDetail'>
                                  <div className='title'><span>{t('containers.studentWrongQuestionList.__question')}</span></div>
                                  {
                                    isShowQuestionImg ?
                                      <div className='content'>
                                        <img className='quesImg' src={item.questionImage.content} alt={item.id}/>
                                      </div>
                                      :
                                      renderContent(item.htmlParts.content)
                                  }
                                  <div className='title'>
                                    <span>{t('containers.studentWrongQuestionList.__answer')}</span>
                                  </div>
                                  {renderContent(item.htmlParts.answer)}
                                  {
                                    item.htmlParts.analyze !== '' &&
                                    <>
                                      <div className='title'><span>{t('containers.studentWrongQuestionList.__explanation')}</span></div>
                                      {renderContent(item.htmlParts.analyze)}
                                    </>
                                  }
                                </div>
                                <div className='bottomContent'>
                                  <div className='answerBox'>
                                    <div>{item.typeName}</div>
                                    {renderAnswer(item)}
                                  </div>
                                  <Button
                                    className='iGetThatBtn'
                                    onClick={() => clickUnderStandBtn(item)}
                                  >
                                    <ThumbsUpSolid/>{t('containers.studentWrongQuestionList.__IUnderstand')}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          );
                        }) : <div className="emptyMsg">{t('containers.studentWrongQuestionList.__noWrongQuestionsInThisRange')}</div>
                    }
                  </div>
                  <div className="pageBlock">
                    <Pagination
                      total={filterQuestionData?.length || 0}
                      defaultCurrent={1}
                      current={pageState.currentPage}
                      onChange={pageChange}
                      defaultPageSize={pageState.limit}
                    />
                  </div>
                  <div className='footerBtn'>
                    {
                      !deviceData.isDesktop ?
                        <div className='selectedNum'>
                          {t('containers.studentWrongQuestionList.__selectedQuestionsCount')}：{selected?.length}/{allQuestionID?.length}
                        </div>
                        :
                        <Button className={'preStepBtn'} onClick={() => {onStepClick(1);}}>
                          {t('containers.studentWrongQuestionList.__previousStep')}
                        </Button>
                    }
                    <Button
                      variant={!checkedSelected ? 'ui02' : 'brand2'}
                      disabled={!checkedSelected}
                      onClick={() => clickCreatePracticeBtn()}
                    >{t('containers.studentWrongQuestionList.__gotoTest')}</Button>
                  </div>
                </StyledQuestionsList>
              }
            </>
            :
            <div>
              <span>{t('containers.studentWrongQuestionList.__noWrongQuestionsCurrently')}！</span>
              <img src='/assets/fixWrongQuestionEmpty.svg' alt='fixWrongQuestionEmpty'/>
            </div>
        }
      </StyledStudentWrongQuestionListContent>
    </StyledStudentWrongQuestionList>
  );
};