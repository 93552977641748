import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
// import PropTypes from 'prop-types';
import {
  Box,
  Table,
  Input,
  sliceTableData,
  Pagination,
  Checkbox as CheckboxType,
} from '@oneclass/ui-components';
import { useBoolean, useSelections } from 'ahooks';
import {
  SearchPlusSolid,
  XmarkOutline,
} from '@onedesign/icon';
import {
  StyledEquationDojoQuestionSelectContent,
} from './EquationDojoQuestionSelectContent.style';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import * as GA from 'utils/googleAnalytics';
import { PAGE_SIZE_OPTIONS } from 'constants/index';
import { useTranslation } from 'react-i18next';

const defaultCountInputValue = {
  answer: '',
  question: '',
  score: {
    perQues: '',
    perAns: '',
    allScore: 0,
  },
};
const { Header, Body, Row, Item } = Table;

export const EquationDojoQuestionSelectContent = ({
  className,
  examPaperInfo,
  prevKeyList,
  getQuestions,
  getSaveData,
  getKeyList,
  renderLoadingContent,
  isLoading,
  setIsLoading,
  isEditCopy,
  reCreateSearchParams,
  editCopyExamPaperData,
  renderFilterQuestionModal,
  isChangeFilter,
  setIsChangeFilter,
  tableData,
  setTableData,
  totalQuesTypeData,
}) => {
  const { t } = useTranslation();
  const { deviceData, deviceDataProviderChange } = useContext(
    deviceProvider.deviceProviderContext
  );
  const [modalImageSrc, setModalImageSrc] = useState(null);
  const [pageState, setPageState] = useState({
    currentPage: 1,
    limit: 10,
  });
  const [
    modalVisible,
    { toggle: setModalVisibleToggle, setFalse: setModalVisibleFalse },
  ] = useBoolean(false);
  const [keyList, setKeyList] = useState(prevKeyList);
  const tableRef = useRef();
  const [questions, setQuestions] = useState([]);

  //把傳過來的examPaperInfo資料從物件轉陣列
  const tableDataArray = useMemo(() => {
    if (!tableData) return {};
    return Object.values(tableData);
  }, [tableData]);

  const goTop = () => {
    const main = document.getElementById('mainLayout');
    main.scrollTop = 0;
  };

  const selectedQuesForEditCopy = (Array) => {
    if (!Array) return;
    let selectQuestions = [];
    let setting = {};
    let newArray = Array;
    Array.forEach((type, i) => {
      let typeName = '';
      const question = type.questionList[0].id;
      Object.values(tableData).some(item => {
        return item.questions.some(que => {
          if (question === que.uid) {
            typeName = que.name;
            return true;
          }
        });
      });
      newArray = {
        ...newArray,
        [i]: {
          ...type,
          typeName: typeName,
        },
      };
    });
    newArray = Object.values(newArray);
    newArray.forEach((item) => {
      let totalAnswer = 0;
      if (!item?.questionList) return;
      const questionArray = item.questionList.map((ques) => {
        totalAnswer += ques?.answerAmount;
        return ques?.id;
      });
      const newArray = selectQuestions.concat(questionArray);
      selectQuestions = newArray;

      if (!item?.typeName | !item?.scoreType) return;
      setting = {
        ...setting,
        [item?.typeName]: {
          scoreType: item?.scoreType,
          score: (item?.scoreType === 'PerAnswer' ? Number(item?.score / totalAnswer) : Number(item?.score / item?.questionList.length)) || 0,
        }
      };
    });
    let nextData = { ...tableData } || {};
    selectQuestions.forEach((id) => {
      Object.entries(nextData).forEach(([key, value]) => {
        const found = value.questions.find((ele) => ele.uid === id);
        if (found) {
          const scoreType =
            setting[found?.name]?.scoreType === 'PerAnswer'
              ? 'perAns'
              : 'perQues';
          const score =
            setting[found?.name]?.score &&
            (setting[found?.name]?.score).toFixed(1);
          if (nextData[found?.name]?.pickUpQuestions) {//已有題組
            nextData[found.name].pickUpQuestions.questionsList.push(found);
            nextData[found.name].inputValue = {
              ...nextData[found.name].inputValue,
              answer:
                nextData[found.name].inputValue.answer +
                found.answerAmount,
              question: nextData[found.name].inputValue.question + 1,
              score: {
                ...nextData[found.name].inputValue.score,
                allScore:
                  scoreType === 'perQues'
                    ? score *
                        (nextData[found.name].inputValue.question +
                          1) &&
                      score *
                        (
                          nextData[found.name].inputValue.question + 1
                        ).toFixed(1)
                    : score *
                        (nextData[found.name].inputValue.answer +
                          found.answerAmount) &&
                      (
                        score *
                        (nextData[found.name].inputValue.answer +
                          found.answerAmount)
                      ).toFixed(1),
                [scoreType]: score,
              },
            };
          } else {
            const quesType = found.name;
            //編輯時因為沒有第2步，沒有全部題組，取編輯前的題組
            const questionGroup = examPaperInfo?.questionGroup || tableData;
            let pickUpQuestions = {
              name: [quesType],
              questionsList: [],
            };
            let newQuestionGroup = {
              [quesType]: {
                ...questionGroup[quesType],
                pickUpQuestions,
                inputValue: defaultCountInputValue,
              },
            };
            newQuestionGroup[quesType].pickUpQuestions.questionsList.push(found);
            newQuestionGroup[quesType].inputValue = {
              ...newQuestionGroup[quesType].inputValue,
              answer: parseInt(found?.answerAmount),
              question: 1,
              score: {
                ...newQuestionGroup[quesType].inputValue.score,
                allScore:
                  score * (scoreType === 'perQues' ? 1 : found?.answerAmount) &&
                  (
                    score * (scoreType === 'perQues' ? 1 : found?.answerAmount)
                  ).toFixed(1),
                [scoreType]: score,
              },
            };
            nextData = {
              ...nextData,
              ...newQuestionGroup,
            };
          }
        }
      });
    });
    setTableData({
      ...nextData,
    });
    setSelected(selectQuestions);
  };

  const handle = (name, maxcount) => (e) => {
    onQuestionCheck(e, name, maxcount);
  };
  const onQuestionCheck = (e, name, maxcount) => {
    if (!tableData) return;
    const value = Number(e?.target?.value);
    const questionCount = value > maxcount ? maxcount : value ;
    let nextData = { ...tableData };
    Object.values(tableData).forEach(item => {
      nextData[item.name].inputValue = {
        ...nextData[item.name].inputValue,
        score: {
          allScore: 0,
          perAns: '',
          perQues: '',
        }
      };
      if (name === item.name) {
        if (questionCount === 0) {
          nextData = {
            ...nextData,
            [name]: {
              code: item.code,
              count: { ...item.count },
              inputValue: {
                ...item.inputValue,
                answer: 0,
                question: 0
              },
              name: item.name,
              questions: [...item.questions]
            }
          };
        } else {
          let max = item.count.questionTotal;
          const questionsListIndex = [];
          const questionsList = [];
          while (questionsListIndex.length < questionCount) {
            let randomNum = Math.floor(Math.random() * max);
            if (questionsListIndex.indexOf(randomNum) === -1) {
              questionsListIndex.push(randomNum);
              questionsList.push({ uid: item.questions[randomNum].uid });
            }
          }
          nextData = {
            ...nextData,
            [name]: {
              ...item,
              inputValue: {
                ...item.inputValue,
                answer: (questionCount * item.count.answer),
                question: questionCount,
              },
              pickUpQuestions: {
                name: [item.name],
                questionsList: questionsList,
              }
            }
          };
        }
      }
    });
    setTableData(nextData);
  };

  const pageChange = (currentPage, pageSize) => {
    setPageState({
      ...pageState,
      currentPage,
      limit: pageSize,
    });
    goTop();
  };
  const showQuestionDetail = (src) => {
    setModalImageSrc(src);
    setModalVisibleToggle();
  };

  const allQuestionID = useMemo(() => {
    if (!questions) return [];
    return questions.map((item) => item.uid);
  }, [questions]);

  const pickUpQuestionID = useMemo(() => {
    if (!tableData) return [];
    let nextData = [];
    Object.values(tableData).forEach(value => {
      if (value?.pickUpQuestions) {
        Object.values(value.pickUpQuestions.questionsList
        ).forEach((item) => {
          nextData = [...nextData, item.uid];}
        );
      }
    });
    return nextData;
  }, [tableData]);

  const { selected, setSelected, isSelected, toggle } = useSelections(
    allQuestionID,
    pickUpQuestionID
  );

  useEffect(() => {
    getSaveData('tableData', tableData);
    const haveDataKey = Object.entries(tableData).filter(([key, value]) => value.pickUpQuestions).map(item => item[0]);
    let nextData = [];
    if (prevKeyList.length < haveDataKey.length) {
      //4->3
      const newKey = haveDataKey.filter((item) => !prevKeyList.includes(item));
      nextData = prevKeyList.concat(newKey);
    } else {
      nextData = prevKeyList.filter((item) => haveDataKey.includes(item));
    }
    const questions = [];
    Object.values(tableData).filter(item => item.questions.map(question => {
      questions.push(question);
    }));
    setQuestions(questions);
    getKeyList(nextData);
    setKeyList(nextData);
  }, [tableData]);

  useEffect(() => {
    if (!questions) return;
    getQuestions(questions);
  }, [questions]);

  useEffect(() => {
    if (isEditCopy) {
      if (!reCreateSearchParams) return;
      //判斷是否為第一次進入頁面（尚未帶入已選題目）
      let firstTime = true;
      Object.entries(tableData).forEach(([key, value]) => {
        if (value.pickUpQuestions) firstTime = false;
      });
      if (firstTime) selectedQuesForEditCopy(editCopyExamPaperData?.questionGroup);
    }
    setIsLoading(false);
  }, [reCreateSearchParams]);

  useEffect(() => {
    if (isChangeFilter) {
      setSelected([]);
      setIsChangeFilter(false);
    }
  }, [isChangeFilter]);

  return isLoading ? (
    renderLoadingContent()
  ) : (
    <StyledEquationDojoQuestionSelectContent
      className={className}
      data-testid="EquationDojoQuestionSelectContent"
    >
      {deviceData.isDesktop ? (
        <>
          <Box mb={5}>
            <div className="pageTitleBox">
              <div>
                <div className="pageTitle">
                  {t('containers.equationDojoQuestionSelectContent.__questionSelection')}
                </div>
              </div>
            </div>
          </Box>
          <Box>
            <div className="tableZone">
              <div className="tableContent">
                <Table ref={tableRef}>
                  <Header>
                    <Row>
                      <Item flex={1.5} className="tableText1">
                        <p>{t('containers.equationDojoQuestionSelectContent.__questionTypeName')}</p>
                      </Item>
                      <Item flex={5} className="tableText2">
                        <p>{t('containers.equationDojoQuestionSelectContent.__textbookExamples')}</p>
                      </Item>
                      <Item flex={0.7} className="tableText3">
                        <p>{t('containers.equationDojoQuestionSelectContent.__selectedQuestionCount')}</p>
                      </Item>
                    </Row>
                  </Header>
                  <Body className="questionTableBody">
                    {tableDataArray && tableDataArray.length > 0 ? (
                      sliceTableData(
                        tableDataArray,
                        pageState.currentPage,
                        pageState.limit
                      ).map((item, index) => {
                        return (
                          <Row key={index}>
                            <Item className="questionItem" flex={1.5}>
                              <div>{item.name}</div>
                            </Item>
                            <Item className="questionItem" flex={5}>
                              <div className="questionContent">
                                {item.questions.length > 0 && (
                                  <img
                                    className="questionImage"
                                    src={item.questions[0].imageUrl}
                                    alt="img"
                                  />
                                )}
                                <div
                                  className="contentDetail"
                                  onClick={() => {
                                    GA.zoomInQuestionSelect(item.name);
                                    showQuestionDetail(
                                      item.questions[0].imageUrl
                                    );
                                  }}
                                >
                                  <SearchPlusSolid />
                                </div>
                              </div>
                            </Item>
                            <Item flex={0.7}>
                              <Input allowClear={false} className="questionInp" value={tableData[item.name]?.inputValue.question || ''} onChange={handle(item.name, item.count.questionTotal)} />
                              <span className='questionTotal'>/{item.questions.length}</span>
                            </Item>
                          </Row>
                        );
                      })
                    ) : (
                      <div className="emptyMsg">
                        {t('containers.equationDojoQuestionSelectContent.__noMatchingItems')}
                      </div>
                    )}
                    <Row>
                      <Item className="totalQuestion"> 
                        {t('containers.equationDojoQuestionSelectContent.__totalQuestion', { questionCount: totalQuesTypeData.questionCount })}
                      </Item>
                    </Row>
                  </Body>
                </Table>
              </div>
            </div>
            <div className="pageBlock">
              <Pagination
                total={tableDataArray?.length || 0}
                defaultCurrent={1}
                current={pageState.currentPage}
                onChange={pageChange}
                defaultPageSize={pageState.limit}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
              />
            </div>
          </Box>
          {modalVisible && (
            <div className="questionImageModal">
              <div className="questionImageModalContent">
                <div
                  className="questionImageModalClose"
                  onClick={() => {
                    setModalVisibleFalse();
                  }}
                >
                  <XmarkOutline />
                </div>
                <div className="questionImageModalContentImage">
                  <img src={modalImageSrc} alt="img" />
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
      //// ------ 手機版 -------
        <div>{t('containers.equationDojoQuestionSelectContent.__noMobileInfiniteQuestions')}</div>
      )}
      {renderFilterQuestionModal()}
    </StyledEquationDojoQuestionSelectContent>
  );
};
