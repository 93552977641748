import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: require('./langs/en.json'),
      },
      vi: {
        translation: require('./langs/vi.json'),
      },
      zh: {
        translation: require('./langs/zh.json'),
      },
    },
    lng: 'vi', // Set the default language
    fallbackLng: 'vi',
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18next;
