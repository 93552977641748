/* eslint-disable react/prop-types */
import React, { useEffect, useState, useContext, useMemo } from 'react';
import {
  Box,
  Table,
  fetchDataMachine,
  FETCH_DATA_EVENT,
  Pagination,
  sliceTableData,
} from '@oneclass/ui-components';
import {
  CopySolid,
  EllipsisVSolid,
  FolderOpenSolid,
  FolderSolid,
  PlusSolid,
  SignInAltSolid,
} from '@onedesign/icon';
import { Button } from '@oneclass/onedesign';
import { useMachine } from '@xstate/react';
import { useBoolean } from 'ahooks';
import { useHistory, useLocation } from 'react-router-dom';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import {
  StyledPaperPackageListContent,
  StyledFilterBar,
  StyledModal,
} from './PaperPackageListContent.style';
import { useLoadingContent } from 'utils/hooks/useLoadingContent';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { getDesignList } from 'api/examPaper';
import { Dropdown, Menu, Tabs, Tooltip } from 'antd';
import { getSubscribeList, subscribePackage, getMemberPermission } from 'api/examPaper';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

const MINE = 'mine';
const SHARED = 'shared';
const CODE_LENGTH = 6;
const CHARS_CAN_INPUT =
  'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

export const PaperPackageListContent = ({ className, tabType }) => {
  const { t } = useTranslation();
  const TABS = [
    { label: t('containers.paperPackageListContent.__myExamPaper'), value: MINE },
    { label: t('containers.paperPackageListContent.__sharedExamPaper'), value: SHARED },
  ];
  let currentKeys = [];
  const history = useHistory();
  const location = useLocation();
  const { deviceData } = useContext(deviceProvider.deviceProviderContext);
  const [pageState, setPageState] = useState({
    currentPage: 1,
    limit: 10,
  });
  const { renderLoadingContent, isLoading, setIsLoading } = useLoadingContent();
  const [
    getPackageModalOpen,
    {
      setTrue: setGetPackageModalOpenTrue,
      setFalse: setGetPackageModalOpenFalse,
    },
  ] = useBoolean(false);
  const [currentTab, setCurrentTab] = useState(TABS[1].value);
  const [redemptionCode, setRedemptionCode] = useState(
    Array(CODE_LENGTH).fill('')
  );
  const [subscribedPackageInfo, setSubscribedPackageInfo] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const closeCodeModal = () => {
    setGetPackageModalOpenFalse();
    setRedemptionCode(Array(CODE_LENGTH).fill(''));
    setSubscribedPackageInfo(null);
  };

  const [stateGetPermission, sendGetPermission] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context) => {
        setIsLoading(true);
        const res = await getMemberPermission();
        const { data, isSuccess, systemCode, message } = res;
        setIsLoading(false);
        return {
          isSuccess,
          systemCode,
          message,
          designShared: data.designShared,
        };
      },
    },
  });
  const { designShared } = stateGetPermission.context.result || {};

  const [stateGetDesignList, sendGetDesignList] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context) => {
        setIsLoading(true);
        const res = await getDesignList();
        const { data, isSuccess, systemCode, message } = res;
        setIsLoading(false);
        return {
          isSuccess,
          systemCode,
          message,
          packages: data.packages,
        };
      },
    },
  });
  const { packages: designList } = stateGetDesignList.context.result || {};

  const [stateGetSubscribeList, sendGetSubscribeList] = useMachine(
    fetchDataMachine,
    {
      services: {
        fetchData: async (_context) => {
          setIsLoading(true);
          const res = await getSubscribeList();
          const { data, isSuccess, systemCode, message } = res;
          setIsLoading(false);
          return {
            isSuccess,
            systemCode,
            message,
            packages: data.packages,
          };
        },
      },
    }
  );
  const { packages: subscribeList } =
    stateGetSubscribeList.context.result || {};

  const [stateSubscribePackage, sendSubscribePackage] = useMachine(
    fetchDataMachine,
    {
      services: {
        fetchData: async (_context, event) => {
          setIsLoading(true);
          try {
            const res = await subscribePackage(event.payload);
            const { isSuccess, systemCode, message, data } = res;
            if (isSuccess) {
              sendGetSubscribeList(FETCH_DATA_EVENT.FETCH);
              setSubscribedPackageInfo(data);
            }
            return {
              isSuccess,
              systemCode,
              message,
            };
          } catch (error) {
            return {
              isSuccess: false,
              message: error.data.message,
            };
          } finally {
            setIsLoading(false);
          }
        },
      },
    }
  );
  const { message: errorMessage } = stateSubscribePackage.context.result || {};

  const list = useMemo(() => {
    switch (currentTab) {
      case MINE:
        return designList || [];
      case SHARED:
        return subscribeList || [];
      default:
        return [];
    }
  }, [currentTab, designShared, designList, subscribeList]);

  useEffect(() => {
    // designShared 可能為 undefined | boolean
    if (designShared === true) {
      sendGetDesignList(FETCH_DATA_EVENT.FETCH);
      sendGetSubscribeList(FETCH_DATA_EVENT.FETCH);
      setCurrentTab(TABS[0].value);
    } else if (designShared === false) {
      sendGetSubscribeList(FETCH_DATA_EVENT.FETCH);
    }
  }, [designShared]);

  const pageChange = (currentPage, pageSize) => {
    setPageState({
      ...pageState,
      currentPage,
      limit: pageSize,
    });
  };

  useEffect(() => {
    if (
      location.pathname === '/examPaperList' &&
      tabType === 'paperPackageList'
    ) {
      sendGetPermission(FETCH_DATA_EVENT.FETCH);
    }
  }, [location.pathname, tabType]);

  // 詳情彈窗開啟時 關閉背景滑動
  useEffect(() => {
    document.body.classList.remove('mobileScrollingStop');
    if (!deviceData.isDesktop) {
      if (getPackageModalOpen) {
        document.body.classList.add('mobileScrollingStop');
      }
    }
  }, [getPackageModalOpen]);

  const handleKeyDown = ({ key }, index) => {
    currentKeys.push(key);
    const inputElements = document.querySelectorAll('.redemptionCodeInput');
    const old = [...redemptionCode];
    const prevInput = inputElements[Math.max(index - 1, 0)];
    const nextInput = inputElements[Math.min(index + 1, CODE_LENGTH - 1)];
    if (
      (currentKeys.includes('Meta') || currentKeys.includes('Control')) &&
      (key === 'v' || key === 'V')
    ) {
      // 貼上
      navigator.clipboard.readText().then((copiedString) => {
        const availableCopiedString = copiedString
          .split('')
          .filter((char) => CHARS_CAN_INPUT.includes(char))
          .join('');
        for (let i = index; i < CODE_LENGTH; i++) {
          old[i] = availableCopiedString[i - index] || '';
        }
        setRedemptionCode(old);
      });
    } else {
      if (CHARS_CAN_INPUT.includes(key)) {
        old[index] = key;
        setRedemptionCode(old);
        if (index !== CODE_LENGTH - 1) {
          nextInput.focus();
        }
      } else if (key === 'ArrowLeft') {
        prevInput.focus();
      } else if (key === 'ArrowRight') {
        nextInput.focus();
      } else if (key === 'Backspace') {
        old[index] = '';
        setRedemptionCode(old);
        prevInput.focus();
      } else if (key === 'Delete') {
        old[index] = '';
        setRedemptionCode(old);
        nextInput.focus();
      }
    }
  };

  const handleKeyUp = ({ key }) => {
    currentKeys = currentKeys.filter((key_) => key_ !== key);
  };

  const handleSubmit = () => {
    const payload = { redemptionCode: redemptionCode.join('') };
    sendSubscribePackage(FETCH_DATA_EVENT.FETCH, { payload });
  };

  return (
    <StyledPaperPackageListContent
      className={className}
      data-testid="PaperPackageListContent"
    >
      <Box mb={4}>
        <StyledFilterBar>
          {/* designShared 可能為 undefined | boolean */}
          {designShared === true && (
            <div className="toggleButtons">
              {TABS.map((tab) => (
                <div
                  className={`button${
                    tab.value === currentTab ? ' selected' : ''
                  }`}
                  onClick={() => setCurrentTab(tab.value)}
                >
                  {tab.label}
                </div>
              ))}
            </div>
          )}
          {currentTab === MINE && (
            <Button
              variant="brand2"
              onClick={() => history.push('/examPaperList/createPaperPackage')}
            >
              <PlusSolid /> {t('containers.paperPackageListContent.__createExamPack')}
            </Button>
          )}
          {(currentTab === SHARED || !designShared) && (
            <Button variant="brand2" onClick={setGetPackageModalOpenTrue}>
              <FolderOpenSolid />{t('containers.paperPackageListContent.__getExamPack')}
            </Button>
          )}
        </StyledFilterBar>
      </Box>
      <Box>
        <Table className="tableZone">
          <Table.Header className="tableHeader">
            {currentTab === MINE && (
              <Table.Row>
                <Table.Item flex="1">{t('containers.paperPackageListContent.__name')}</Table.Item>
                <Table.Item width="89px" flex="none">
                  {t('containers.paperPackageListContent.__volume')}
                </Table.Item>
                <Table.Item width="89px" flex="none">
                  {t('containers.paperPackageListContent.__sharedUsers')}
                </Table.Item>
                <Table.Item width="121px" flex="none">
                  {t('containers.paperPackageListContent.__authorizationCode')}
                </Table.Item>
                <Table.Item width="158px" flex="none" />
              </Table.Row>
            )}
            {(currentTab === SHARED || !designShared) && (
              <Table.Row>
                <Table.Item flex="1">{t('containers.paperPackageListContent.__name')}</Table.Item>
                <Table.Item width="89px" flex="none">
                  {t('containers.paperPackageListContent.__volume')}
                </Table.Item>
                <Table.Item width="160px" flex="none">
                  {t('containers.paperPackageListContent.__updateTime')}
                </Table.Item>
                <Table.Item width="158px" flex="none" />
              </Table.Row>
            )}
          </Table.Header>
          {isLoading ? (
            renderLoadingContent()
          ) : (
            <Table.Body bodyMaxHeight={true}>
              {list && list.length > 0 ? (
                sliceTableData(
                  list,
                  pageState.currentPage,
                  pageState.limit
                ).map((item) => {
                  if (currentTab === MINE) {
                    return (
                      <Table.Row key={item.uid} className="tableRow">
                        <Table.Item flex="1">
                          <FolderSolid style={{ fontSize: '24px' }} />{' '}
                          {item.name}
                        </Table.Item>
                        <Table.Item width="89px" flex="none">
                          {item.paperCount}
                        </Table.Item>
                        <Table.Item width="89px" flex="none">
                          {item.subscriberCount}
                        </Table.Item>
                        <Table.Item width="121px" flex="none">
                          {item.redemptionCode}
                          <Tooltip
                            trigger="click"
                            title={
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  columnGap: '4px',
                                }}
                              >
                                <img src="/assets/checkCircle.svg" alt="" />
                                <span>{t('containers.paperPackageListContent.__copied')}</span>
                              </div>
                            }
                            onVisibleChange={(visible) => {
                              if (visible) {
                                navigator.clipboard.writeText(
                                  item.redemptionCode
                                );
                              }
                            }}
                          >
                            <CopySolid
                              style={{
                                marginLeft: '10px',
                                color: '#8B90A0',
                                fontSize: '24px',
                                cursor: 'pointer',
                              }}
                            />
                          </Tooltip>
                        </Table.Item>
                        <Table.Item width="158px" flex="none">
                          <Button
                            className="outlinedButton"
                            onClick={() =>
                              history.push(`/myPaperDetail/${item.redemptionCode}`)
                            }>
                            <SignInAltSolid
                              style={{ fontSize: '16px' }}
                            />
                            {t('containers.paperPackageListContent.__enter')}
                          </Button>
                          {/* 暫時不做編輯、刪除 */}
                          {/* <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item key="編輯" onClick={() => {}}>
                              編輯
                            </Menu.Item>
                            <Menu.Item key="刪除試卷包" onClick={() => {}}>
                              刪除試卷包
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={['click']}
                      >
                        <EllipsisVSolid
                          style={{
                            marginTop: '4px',
                            marginLeft: '8px',
                            fontSize: '20px',
                            cursor: 'pointer',
                          }}
                        />
                      </Dropdown> */}
                        </Table.Item>
                      </Table.Row>
                    );
                  } else if (currentTab === SHARED || !designShared) {
                    return (
                      <Table.Row key={item.uid} className="tableRow">
                        <Table.Item flex="1">
                          <FolderSolid style={{ fontSize: '24px' }} />{' '}
                          {item.name}{' '}
                          {subscribedPackageInfo?.redemptionCode ===
                            item.redemptionCode && (
                            <div className="newTag">NEW</div>
                          )}
                        </Table.Item>
                        <Table.Item width="89px" flex="none">
                          {item.paperCount}
                        </Table.Item>
                        <Table.Item width="160px" flex="none">
                          {format(
                            new Date(item.updateTime),
                            'yyyy/dd/MM HH:mm'
                          )}
                        </Table.Item>
                        <Table.Item width="158px" flex="none">
                          <Button
                            className="outlinedButton"
                            onClick={() =>
                              history.push(`/sharePaperDetail/${item.redemptionCode}`)
                            }>
                            <SignInAltSolid
                              style={{ fontSize: '16px' }}
                            />
                            {t('containers.paperPackageListContent.__enter')}
                          </Button>
                          {/* 暫時不做刪除 */}
                          {/* <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item key="刪除" onClick={() => {}}>
                              刪除
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={['click']}
                      >
                        <EllipsisVSolid
                          style={{
                            marginTop: '4px',
                            marginLeft: '8px',
                            fontSize: '20px',
                            cursor: 'pointer',
                          }}
                        />
                      </Dropdown> */}
                        </Table.Item>
                      </Table.Row>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <div className="tableEmpty">
                  <div>{t('containers.paperPackageListContent.__noExamPack')}</div>
                </div>
              )}
            </Table.Body>
          )}
        </Table>
        <div className="pageBlock">
          <Pagination
            total={list?.length || 0}
            defaultCurrent={1}
            current={pageState.currentPage}
            onChange={pageChange}
            defaultPageSize={pageState.limit}
          />
        </div>
      </Box>
      <StyledModal
        visible={getPackageModalOpen}
        title={t('containers.paperPackageListContent.__getExamPack')}
        onCancel={closeCodeModal}
        centered={deviceData.isDesktop}
        footer={
          <div className="footerBtn footerBtnBox">
            {subscribedPackageInfo ? (
              <Button variant="brand2" onClick={closeCodeModal}>
                {t('containers.paperPackageListContent.__confirm')}
              </Button>
            ) : (
              <>
                <Button className="cancelBtn" onClick={closeCodeModal}>
                  {t('containers.paperPackageListContent.__cancel')}
                </Button>
                <Button variant="brand2" onClick={handleSubmit}>
                  {t('containers.paperPackageListContent.__createExamPack')}
                </Button>
              </>
            )}
          </div>
        }
      >
        {subscribedPackageInfo ? (
          <div className="modalContent">
            {t('containers.paperPackageListContent.__subscribedPackageInfo', { packageName: subscribedPackageInfo.packageName, paperCount: subscribedPackageInfo.paperCount })}
          </div>
        ) : (
          <>
            <div className="modalContent">
              {t('containers.paperPackageListContent.__enterAuthorizationCode')}
            </div>
            <div className="modalContent--sub">
              {t('containers.paperPackageListContent.__pleaseEnterCode')}
            </div>
            <div className="inputContainer">
              {redemptionCode.map((input, i) => (
                <input
                  className="redemptionCodeInput"
                  key={i}
                  value={input}
                  readOnly
                  onKeyDown={(e) => handleKeyDown(e, i)}
                  onKeyUp={(e) => handleKeyUp(e)}
                  onFocus={(e) => e.target.setSelectionRange(1, 1)}
                />
              ))}
            </div>
            {errorMessage && (
              <div style={{ marginTop: '8px', color: '#F44336' }}>
                {errorMessage}
              </div>
            )}
          </>
        )}
      </StyledModal>
    </StyledPaperPackageListContent>
  );
};
