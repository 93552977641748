import React, { useEffect, useState, useCallback, useMemo, useRef, useContext } from 'react';
import { Box, Input, DragBox, DragRow, Select, Switch, AuthProvider } from '@oneclass/ui-components';
import { RadioGroup, Checkbox, Button, CheckableTag } from '@oneclass/onedesign';
import update from 'immutability-helper';
import { OPTION_ERROR_MSG } from 'constants/index';
import { Radio, Tooltip, Alert } from 'antd';
import {
  SaveSolid,
  HorizontalOneColumnsOutline,
  HorizontalTwoColumnsAOutline,
  HorizontalTwoColumnsBOutline,
  StraightOneColumnsOutline,
  StraightTwoColumnsOutline,
  QuestionCircleSolid
} from '@onedesign/icon';
import PropTypes from 'prop-types';
import { StyledExamPaperSetting } from './ExamPaperSetting.style';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { default as UserProvider } from 'providers/UserProvider.js';
import { formatSpecialCharacters } from 'utils/common';
import { useTranslation } from 'react-i18next';
import { paperAdvancedSettingsMapper } from 'locales/translationMapper';

const wordSettingIconMap = {
  HSS: <HorizontalOneColumnsOutline/>,
  HSD: <HorizontalTwoColumnsBOutline />,
  VHS: <StraightOneColumnsOutline />,
  VHD: <StraightTwoColumnsOutline />,
  HHD: <HorizontalTwoColumnsAOutline/>
};

const advancedPaperAmountOptions = [
  {
    name: '2',
    value: 2,
  },
  {
    name: '3',
    value: 3,
  },
  {
    name: '4',
    value: 4,
  },
  {
    name: '5',
    value: 5,
  },
];
export const ExamPaperSetting = ({
  className,
  sendParams,
  checkedSend,
  rowData,
  globalExamPaperData,
  getSendParams,
  changeCheckedSend,
  onSaveExamPaperInfo,
  paperSizeMap,
  wordSettingMap,
  paperContent,
  analyzeContent,
  advancedSetting,
  onlineAdvancedSetting,
  hasListen
}) => {
  const env = process.env.REACT_APP_NODE_ENV;

  // const { isDesktop } = useDeviceDetect();
  const [dragData, setDragData] = useState(rowData);
  const [currentCheckedInput, setCurrentCheckedInput] = useState('');
  const { useAuthState } = AuthProvider;
  const authState = useAuthState();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const { userData } = useContext(UserProvider.UserProviderContext);
  const { userInfo } = userData;
  const { t } = useTranslation();

  const inputRefs = {
    schoolName: useRef(null),
    teacherSign: useRef(null),
    grade: useRef(null),
    room: useRef(null),
  };
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = dragData[dragIndex];
      setDragData(
        update(dragData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      );
    },
    [dragData],
  );

  const titleName = useMemo(() => {
    return (userInfo?.onePaperIdentity === 'TestCram') ? t('components.examPaperSetting.__titleName') : t('components.examPaperSetting.__schoolName');
  }, [userInfo]);

  const renderRowData = (key) => {
    switch (key) {
      case 'paperName':
        return (
          <div className='dragRowContent'>
            <Checkbox checked={checkedSend.findIndex(item => item === 'paperName') !== -1} disabled={true} className='dragRowCheckBox' />
            <div className='dragRowInnerRow'>
              <div className='label'>{t('components.examPaperSetting.__paperName')}<span className='warningTag'>{t('components.examPaperSetting.__required')}</span></div>
              <Input onBlur={(e) => {onInputBlur('paperName', e);}} placeholder={t('components.examPaperSetting.__paperNamePlaceholder')} message={sendParams?.paperName?.message} value={sendParams?.paperName?.value} onChange={(e) => onInputChange('paperName', e)}/>
            </div>
          </div>
        );
      case 'schoolName':
        return (
          <div className='dragRowContent'>
            <Checkbox checked={checkedSend.findIndex(item => item === 'schoolName') !== -1} className='dragRowCheckBox' onChange={(e) => {onRowChecked('schoolName', e );}} />
            <div className='dragRowInnerRow'>
              <div className='label'>{titleName}</div>
              <Input antRef={inputRefs.schoolName}
                onBlur={(e) => {onInputBlur('schoolName', e);}}
                placeholder={` ${t('components.examPaperSetting.__schoolNamePlaceholder')}${titleName}`}
                disabled={checkedSend.findIndex(item => item === 'schoolName') === -1}
                message={sendParams?.schoolName?.message}
                value={sendParams?.schoolName?.value}
                onChange={(e) => onInputChange('schoolName', e)}/>
            </div>
          </div>
        );
      case 'teacherSign':
        return (
          <div className='dragRowContent'>
            <Checkbox checked={checkedSend.findIndex(item => item === 'teacherSign') !== -1} className='dragRowCheckBox' onChange={(e) => {onRowChecked('teacherSign', e );}} />
            <div className='dragRowInnerRow'>
              <div className='label'>{t('components.examPaperSetting.__teacherSign')}</div>
              <Input antRef={inputRefs.teacherSign} onBlur={(e) => {onInputBlur('teacherSign', e);}} placeholder={t('components.examPaperSetting.__teacherSignPlaceholder')} disabled={checkedSend.findIndex(item => item === 'teacherSign') === -1} message={sendParams?.teacherSign?.message} value={sendParams?.teacherSign?.value} onChange={(e) => onInputChange('teacherSign', e)}/>
            </div>
          </div>
        );
      case 'classInfo':
        return (
          <div className='dragRowContent'>
            <Checkbox checked={checkedSend.findIndex(item => item === 'classInfo') !== -1} className='dragRowCheckBox' onChange={(e) => {onRowChecked('classInfo', e );}} />
            <div className='dragRowInnerRow'>
              <div>{t('components.examPaperSetting.__classInfo')}</div>
              <div className='classInfo'>
                <Input antRef={inputRefs.grade} onBlur={(e) => {onInputBlur('grade', e);}} allowClear={false} disabled={checkedSend.findIndex(item => item === 'classInfo') === -1} message={sendParams?.grade?.message} value={sendParams?.grade?.value} onChange={(e) => {onInputChange('grade', e);}} />{t('components.examPaperSetting.__year')}
                <Input antRef={inputRefs.room} onBlur={(e) => {onInputBlur('room', e);}} allowClear={false} disabled={checkedSend.findIndex(item => item === 'classInfo') === -1} message={sendParams?.room?.message} value={sendParams?.room?.value} onChange={(e) => {onInputChange('room', e);}} />{t('components.examPaperSetting.__class')}
              </div>
            </div>
          </div>
        );
      case 'eduSubject':
        return (
          <div className='dragRowContent'>
            <Checkbox checked={checkedSend.findIndex(item => item === 'eduSubject') !== -1} className='dragRowCheckBox' onChange={(e) => {onRowChecked('eduSubject', e );}} />
            <div className='dragRowInnerRow'>
              <div>{globalExamPaperData?.eduSubjectName.edu}{globalExamPaperData?.eduSubjectName.subject}</div>
            </div>
          </div>
        );
      case 'studentSign':
        return (
          <div className='dragRowContent'>
            <Checkbox checked={checkedSend.findIndex(item => item === 'studentSign') !== -1} className='dragRowCheckBox' onChange={(e) => {onRowChecked('studentSign', e );}}/>
            <div className='dragRowInnerRow'>
              <div>{t('components.examPaperSetting.__studentSign')}</div>
            </div>
          </div>
        );
      default :
        break;
    }
  };

  const onRadioChange = (type, e) => {
    const value = e.target.value;
    getSendParams({ [type]: value });
  };
  const isChecked = (tag) => {
    if (!sendParams?.paperContents) return;
    return sendParams?.paperContents.indexOf(tag) > -1;
  };

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked ? [...sendParams?.paperContents, tag] : sendParams?.paperContents.filter(t => t !== tag);

    getSendParams({
      paperContents: [...nextSelectedTags]
    });
  };

  const onChecked = (type, key, e) => {
    const isChecked = e.target.checked;
    let nextData = [].concat(sendParams[type] || []);
    if (isChecked) {
      nextData = nextData.concat(key);
    } else {
      const eleIndex = nextData.findIndex(item => item === key);
      nextData.splice(eleIndex, 1);
    }
    getSendParams({
      [type]: nextData
    });
  };

  const onSwitchChange = (type, key, val) => {
    let nextData = [].concat(sendParams[type] || []);
    if (val) {
      nextData = nextData.concat(key);
    } else {
      const eleIndex = nextData.findIndex(item => item === key);
      nextData.splice(eleIndex, 1);
    }
    getSendParams({
      [type]: nextData
    });
  };

  const onRowSelectChange = (value) => {
    getSendParams({
      amount: value
    });
  };

  const onInputChange = (key, event) => {
    let value = event.target.value;
    if (key === 'paperName') {
      value = formatSpecialCharacters(value);

      // const newValue = value.replace('\\', '_');
      // if (new RegExp('/').test(value)) {
      //   const newValue = value.replace('/', '');
      //   getSendParams({
      //     [key]: {
      //       value: newValue,
      //       message: '試卷名稱不可使用 ” / ”，建議使用 ” - ”、 ” _ ”'
      //     }
      //   });
      // } else {
      if (value.trim() === '') {
        getSendParams({
          [key]: {
            value: '',
            message: ''
          }
        });
      } else {
        getSendParams({
          [key]: {
            value,
            message: ''
          }
        });
      }
      // }
    } else {
      getSendParams({
        [key]: {
          value,
          message: ''
        }
      });
    }
  };

  const onRowChecked = (key, e) => {
    const isChecked = e.target.checked;
    let nextData = [].concat(checkedSend);
    if (isChecked) {
      nextData = nextData.concat(key);
      setCurrentCheckedInput(key);
      if (key === 'eduSubject') {
        getSendParams({
          [key]: globalExamPaperData?.eduSubjectName.edu + globalExamPaperData?.eduSubjectName.subject
        });
      }
      if (key === 'studentSign') {
        getSendParams({
          [key]: '座號：＿＿＿  姓名：＿＿＿＿＿＿',
        });
      }
    } else {
      const dataIndex = nextData.findIndex(item => item === key);
      nextData.splice(dataIndex, 1);
      if (key === 'classInfo' && sendParams.grade && sendParams.room) {
        getSendParams({
          grade: {
            value: sendParams?.grade.value,
            message: ''
          },
          room: {
            value: sendParams?.room.value,
            message: ''
          },
        });
      } else if (key === 'eduSubject' || key === 'studentSign') {
        getSendParams({
          [key]: ''
        });
      } else if (sendParams[key]) {
        getSendParams({
          [key]: {
            value: sendParams[key].value,
            message: ''
          }
        });
      }
    }
    changeCheckedSend(nextData);
  };
  const onInputBlur = (type, e) => {
    if (!sendParams[type]) return;
    if (!e.target.value) {
      getSendParams({
        [type]: {
          value: sendParams[type].value,
          message: OPTION_ERROR_MSG[type]
        }
      });
    }
    if ((type === 'room' || type === 'grade')) {
      if (checkedSend.findIndex(item => item === 'classInfo') === -1) {
        getSendParams({
          [type]: {
            value: sendParams[type].value,
            message: OPTION_ERROR_MSG[type]
          }
        });
        return;
      }
    } else {
      if (checkedSend.findIndex(item => item === type) === -1) {
        getSendParams({
          [type]: {
            value: sendParams[type].value,
            message: ''
          }
        });
        return;
      }
    }

    if (!sendParams[type].value) {
      if (type === 'schoolName') {
        getSendParams({
          ...sendParams,
          [type]: {
            value: sendParams[type].value,
            message: `${t('components.examPaperSetting.__schoolNamePlaceholder')}${titleName}`
          }
        });
      } else {
        getSendParams({
          ...sendParams,
          [type]: {
            value: sendParams[type].value,
            message: OPTION_ERROR_MSG[type]
          }
        });
      }
    }
  };
  const saveExamPaperDisabled = useMemo(() => {
    const checkArray = ['paperName', 'teacherSign', 'schoolName'];
    const advancedCheckArray = ['amount', 'analyzeContent', 'paperSize', 'wordSetting', 'paperContents'];
    if (!sendParams) return true;
    let result = false;
    Object.entries(sendParams).forEach(([key, value]) => {
      if ((key === 'grade' || key === 'room') && checkedSend.findIndex(item => item === 'classInfo') !== -1) {
        if (!value.value || value.value === '') result = true;
      }
      const needCheck = ((checkArray.findIndex(item => item === key) !== -1 && checkedSend.findIndex(item => item === key) !== -1));
      if (needCheck) {
        if (!value.value || value.value === '') result = true;
      }
      if (advancedCheckArray.findIndex(item => item === key) !== -1) {
        if (!value || value === '' || value.length === 0) result = true;
      }
    });
    return result;
  }, [sendParams, checkedSend]);

  useEffect(() => {
    if (currentCheckedInput === 'classInfo' && checkedSend.some(item => item === 'classInfo')) {
      inputRefs['grade'] && inputRefs['grade']?.current.focus();
      if (!inputRefs['grade']?.current?.state?.value) {
        getSendParams({
          'grade': {
            value: sendParams['grade']?.value,
            message: OPTION_ERROR_MSG['grade']
          }
        });
      }
      if (!inputRefs['room']?.current?.state?.value) {
        getSendParams({
          'room': {
            value: sendParams['room']?.value,
            message: OPTION_ERROR_MSG['room']
          }
        });
      }
    } else if (checkedSend.some(item => item === currentCheckedInput)) {
      inputRefs[currentCheckedInput] && inputRefs[currentCheckedInput]?.current.focus();
      if (!inputRefs[currentCheckedInput]?.current?.state?.value) {
        if (currentCheckedInput === 'studentSign' || currentCheckedInput === 'eduSubject') return;
        if (currentCheckedInput === 'schoolName') {
          getSendParams({
            [currentCheckedInput]: {
              value: sendParams[currentCheckedInput]?.value,
              message: `${t('components.examPaperSetting.__schoolNameMessage')}${titleName}`
            }
          });
        } else {
          getSendParams({
            [currentCheckedInput]: {
              value: sendParams[currentCheckedInput]?.value,
              message: OPTION_ERROR_MSG[currentCheckedInput]
            }
          });
        }
      }
    }
  }, [checkedSend, currentCheckedInput]);

  // useEffect(() => {
  //   setDragData(
  //     sendParams?.outputType === 'Files' ? rowData : ['paperName']
  //   );
  // }, [sendParams?.outputType]);

  const openMutiPaperSetting = (checked) => {
    if ((!advancedSetting || advancedSetting.length) < 0 && (!onlineAdvancedSetting || onlineAdvancedSetting.length < 0)) return;
    let settingAdvance = sendParams?.outputType === 'Files' ? 'advanced' : 'onlineAdvanced';
    let advancedSettingData = sendParams?.outputType === 'Files' ? advancedSetting : onlineAdvancedSetting;
    if (!advancedSettingData) return;

    let nextData = [].concat(sendParams[settingAdvance] || []);
    let mutiSetting = [];
    advancedSettingData.forEach((item, idx) => {
      if (idx >= 2 && sendParams?.outputType === 'Files') {
        mutiSetting = [...mutiSetting, item.code];
      } else if (sendParams?.outputType === 'Online') {
        mutiSetting = [...mutiSetting, item.code];
      }
    });
    if (checked) {
      getSendParams({
        [settingAdvance]: nextData.concat(mutiSetting),
        amount: 2
      });
    } else {
      advancedSettingData.forEach((settingItem, idx) => {
        if (idx >= 2 && sendParams?.outputType === 'Files') {
          const eleIndex = nextData.findIndex(item => item === settingItem.code);
          if (eleIndex !== -1)nextData.splice(eleIndex, 1);
        } else if (sendParams?.outputType === 'Online') {
          const eleIndex = nextData.findIndex(item => item === settingItem.code);
          if (eleIndex !== -1)nextData.splice(eleIndex, 1);
        }
      });
      getSendParams({
        [settingAdvance]: nextData,
        amount: 1
      });
    }
  };

  const openMutiPaperSettingState = useMemo(() => {
    let condi = 0;
    if ((!advancedSetting || advancedSetting.length) < 0 && (!onlineAdvancedSetting || onlineAdvancedSetting.length < 0)) return;
    let settingAdvance = sendParams?.outputType === 'Files' ? 'advanced' : 'onlineAdvanced';
    let advancedSettingData = sendParams?.outputType === 'Files' ? advancedSetting : onlineAdvancedSetting;
    if (!advancedSettingData) return;
    if (!sendParams[settingAdvance]) return;
    const nextSendParams = [].concat(sendParams[settingAdvance] || []);
    advancedSettingData.forEach((settingItem, idx) => {
      if (idx >= 2 && sendParams?.outputType === 'Files') {
        const eleIndex = nextSendParams.findIndex(item => item === settingItem.code);
        condi += eleIndex;
      } else if (sendParams?.outputType === 'Online') {
        const eleIndex = nextSendParams.findIndex(item => item === settingItem.code);
        condi += eleIndex;
      }
    });
    return !(condi === -2);
  },
  [sendParams?.advanced, sendParams?.onlineAdvanced, sendParams?.outputType]);

  useEffect(() => {
    if (sendParams?.outputType === 'Files') {
      setDragData(rowData);
    } else {
      setDragData(['paperName']);
    }
  }, [rowData, sendParams?.outputType]);

  useEffect(() => {
    const stringArray = [];

    const array = dragData && dragData.filter((item) => {
      return checkedSend.indexOf(item) !== -1;
    });

    array && array.forEach((item) => {
      if (item === 'studentSign') {
        stringArray.push(sendParams[item]);
      } else if (item === 'classInfo') {
        stringArray.push(sendParams?.grade?.value + '年' + sendParams?.room?.value + '班');
      } else if (item === 'teacherSign') {
        stringArray.push('命題教師：' + sendParams?.teacherSign?.value);
      } else if (item === 'eduSubject' ) {
        stringArray.push(sendParams?.eduSubject + ' 科考試卷');
      }
      else {
        stringArray.push(sendParams[item]?.value);
      }
    });

    const string = '　　　　' + stringArray.join(' ');

    getSendParams({
      header: string,
      ordering: dragData,
    });
  }, [sendParams?.schoolName, sendParams?.paperName, sendParams?.teacherSign, sendParams?.grade, sendParams?.room, sendParams?.eduSubject, sendParams?.studentSign, checkedSend, dragData]);

  return (
    <StyledExamPaperSetting className={className} data-testid="ExamPaperSetting">
      <Box mb={5}>
        <div className='titleRow'>
          <div className="pageTitle">{t('components.examPaperSetting.__examPaperInformation')}</div>
          {
            sendParams?.outputType === 'Files' &&
              <Button
                disabled={saveExamPaperDisabled}
                type='solid' variant={saveExamPaperDisabled ? 'ui02' : 'default'}
                onClick={onSaveExamPaperInfo}
                className='buttonBackground--white'
              >
                <SaveSolid/>
                {t('components.examPaperSetting.__saveAsCommonSetting')}
                {
                  deviceData.isDesktop ?
                    <Tooltip
                      placement="top"
                      title={<span>{t('components.examPaperSetting.__saveTip')}</span>}
                      zIndex={2999}
                      color='#4e4e5a'
                      trigger={deviceData.isDesktop ? 'hover' : 'click'}
                    >
                      <div className={'saveTip'} >
                        <QuestionCircleSolid/>
                      </div>
                    </Tooltip>
                    : null
                }
              </Button>
          }

        </div>
      </Box>
      <Box mb={44}>
        <DragBox className='examPaper'>
          {
            dragData && dragData.map((item, index) => (
              <DragRow id={item} index={index} key={item} moveCard={moveCard}>
                {renderRowData(item)}
              </DragRow>
            ))
          }
        </DragBox>
      </Box>
      {
        sendParams?.outputType === 'Files' &&
        <>
          <Box mb={4}>
            <div className='titleRow'>
              <div className="pageTitle">{t('components.examPaperSetting.__paperSizeAdjustment')}</div>
              {/* <Button onClick={onSaveAdvancedSetting} type='solid'><ChevronRightOutline/> 儲存為常用設定</Button> */}
            </div>

          </Box>
          <Box mb={10} className={hasListen ? 'row' : ''}>
            {paperSizeMap && paperSizeMap.length > 0 &&
              <Box mb={4}>
                <div className='radioLabel'>{t('components.examPaperSetting.__paperSize')}</div>
                <RadioGroup buttonStyle='solid' optionType='button' options={paperSizeMap} value={sendParams?.paperSize} onChange={(e) => {onRadioChange('paperSize', e);}} />
              </Box>}
            {wordSettingMap && wordSettingMap.length > 0 &&
              <Box mb={4}>
                <div className='radioLabel'>{t('components.examPaperSetting.__layoutMode')}</div>
                <RadioGroup className='iconRadioList' buttonStyle='solid' optionType='button' value={sendParams?.wordSetting} onChange={(e) => {onRadioChange('wordSetting', e);}} >
                  {
                    wordSettingMap.map(item => (
                      <Radio.Button value={item.value}>{wordSettingIconMap[item.value]}{item.label}</Radio.Button>
                    ))
                  }
                </RadioGroup>
              </Box>}
            {paperContent && paperContent.length > 0 && sendParams?.paperContents &&
              <Box>
                <div className='radioLabel'>{t('components.examPaperSetting.__outputPaperType')}</div>
                <div className='mutiCheck'>
                  {
                    env === 'dev' ?
                      paperContent.map(item => (
                        <CheckableTag
                          className='mutiCheckBox'
                          key={item.code}
                          checked={isChecked(item.code)}
                          onChange={checked => handleChange(item.code, checked)}
                        >
                          {item.name}
                          {
                            item.code === 'Read' &&
                            <Tooltip placement="top" title={t('components.examPaperSetting.__readContentNote')} zIndex={100} color={'#4e4e5a'} >
                              <QuestionCircleSolid style={{ marginLeft: '4px' }} />
                            </Tooltip>
                          }
                        </CheckableTag>
                      ))
                      :
                      paperContent.filter((item) => {return item.code !== 'Read';}).map(item => (
                        <CheckableTag
                          className='mutiCheckBox'
                          key={item.code}
                          checked={isChecked(item.code)}
                          onChange={checked => handleChange(item.code, checked)}
                        >
                          {item.name}
                          {
                            item.code === 'Read' &&
                            <Tooltip placement="top" title={t('components.examPaperSetting.__readContentNote')} zIndex={100} color={'#4e4e5a'} >
                              <QuestionCircleSolid style={{ marginLeft: '4px' }} />
                            </Tooltip>
                          }
                        </CheckableTag>
                      ))
                  }
                  {
                    sendParams?.paperContents.length === 0 &&
                    <Alert message={t('components.examPaperSetting.__selectPaperType')} banner={true}></Alert>
                  }
                </div>
                {
                  sendParams?.paperContents && sendParams?.paperContents.findIndex(item => item === 'Analyze') !== -1 &&
                  <div className='azBlock'>
                    <div className='radioLabel'>{t('components.examPaperSetting.__analyzePaperContent')}</div>
                    <div className='checkList'>
                      {
                        analyzeContent && analyzeContent.map(item => (
                          <Checkbox
                            disabled={item.code === 'Answer' || item.code === 'Analyze'}
                            checked={sendParams?.analyzeContent && sendParams?.analyzeContent.findIndex(cond => cond === item.code) !== -1}
                            onChange={(e) => {onChecked('analyzeContent', item.code, e );}}>{item.name}
                          </Checkbox>
                        ))
                      }
                    </div>
                  </div>
                }

              </Box>
            }
          </Box>


        </>
      }
      {
        // 僅有dev 顯示 待功能開發完成在全部顯示
        env === 'dev' &&
        <>
          <Box mb={4}>
            <div className='titleRow'>
              <div className="pageTitle">{t('components.examPaperSetting.__advancedFunction')}</div>
            </div>
          </Box>
          <Box mb={10} className={hasListen ? 'row' : ''}>
            {
              sendParams?.outputType !== 'Files' ?
                <>
                  {
                    advancedSetting && advancedSetting.length > 0 &&
                    advancedSetting.map((item, index) => (
                      index < 2 &&
                      <>
                        <Box mb={16}>
                          <div className='switchBox'>
                            <Switch checked={sendParams?.advanced && sendParams?.advanced.findIndex(cond => cond === item.code) !== -1} onChange={(val) => {onSwitchChange('advanced', item.code, val );}}/><span>{t(paperAdvancedSettingsMapper(item.name))}</span>
                          </div>
                        </Box>
                      </>
                    ))
                  }
                  <Box>
                    <div className='switchBox'>
                      <Switch checked={openMutiPaperSettingState} onChange={(val) => {openMutiPaperSetting(val);}}/><span>{t('components.examPaperSetting.__outputMultipleCopies')}</span>
                    </div>
                  </Box>

                  {
                    openMutiPaperSettingState &&
                    <div className='mutiPaperSettingBox'>
                      <Box mb={16}>
                        <div className='advancedRow'>{t('components.examPaperSetting.__outputNumberOfPapers')}<Select className='advancedSelect' allowClear={false} options={advancedPaperAmountOptions} value={sendParams.amount} onChange={onRowSelectChange} />{t('components.examPaperSetting.__roll')}</div>
                      </Box>
                      {
                        advancedSetting && advancedSetting.length > 0 &&
                        advancedSetting.map((item, index) => (
                          index >= 2 &&
                          <>
                            <Box mb={18}>
                              <div className='switchBox'>
                                <Switch checked={sendParams?.advanced && sendParams?.advanced.findIndex(cond => cond === item.code) !== -1} onChange={(val) => {onSwitchChange('advanced', item.code, val );}}/><span>{ t(paperAdvancedSettingsMapper(item.name))}</span>
                              </div>
                            </Box>
                          </>
                        ))
                      }
                    </div>
                  }
                </>
                :
                <>
                  <Box>
                    <div className='switchBox'>
                      <Switch checked={openMutiPaperSettingState} onChange={(val) => {openMutiPaperSetting(val);}}/><span>{t('components.examPaperSetting.__outputMultipleCopies')}</span>
                    </div>
                  </Box>

                  {
                    openMutiPaperSettingState &&
                    <div className='mutiPaperSettingBox'>
                      {/* <Box mb={16}>
                        <div className='advancedRow'>輸出<Select className='advancedSelect' allowClear={false} options={advancedPaperAmountOptions} value={sendParams.amount} onChange={onRowSelectChange} />卷</div>
                      </Box> */}
                      {
                        onlineAdvancedSetting && onlineAdvancedSetting.length > 0 &&
                        onlineAdvancedSetting.map((item, index) => (
                          <>
                            <Box mb={18}>
                              <div className='switchBox'>
                                <Switch checked={sendParams?.onlineAdvanced && sendParams?.onlineAdvanced.findIndex(cond => cond === item.code) !== -1} onChange={(val) => {onSwitchChange('onlineAdvanced', item.code, val );}}/><span>{item.name}</span>
                              </div>
                            </Box>
                          </>
                        ))
                      }
                    </div>
                  }
                </>
            }
          </Box>
        </>
      }
    </StyledExamPaperSetting>
  );
};


ExamPaperSetting.propTypes = {
  className: PropTypes.string,
  sendParams: PropTypes.objectOf({
    examUID: PropTypes.string,
    paperSize: PropTypes.string,
    wordSetting: PropTypes.string,
    paperContents: PropTypes.array,
    amount: PropTypes.number,
    advanced: PropTypes.array,
    analyzeContent: PropTypes.array,
    schoolName: PropTypes.objectOf({
      value: PropTypes.string,
      message: PropTypes.string
    }),
    paperName: PropTypes.objectOf({
      value: PropTypes.string,
      message: PropTypes.string
    }),
    room: PropTypes.objectOf({
      value: PropTypes.string,
      message: PropTypes.string
    }),
    grade: PropTypes.objectOf({
      value: PropTypes.string,
      message: PropTypes.string
    }),
    teacherSign: PropTypes.objectOf({
      value: PropTypes.string,
      message: PropTypes.string
    }),
  }),
  checkedSend: PropTypes.array, rowData: PropTypes.array,
  globalExamPaperData: PropTypes.object,
  getSendParams: PropTypes.func,
  changeCheckedSend: PropTypes.func,
  onSaveExamPaperInfo: PropTypes.func,
  paperSizeMap: PropTypes.array,
  wordSettingMap: PropTypes.array,
  paperContent: PropTypes.array,
  analyzeContent: PropTypes.array,
  advancedSetting: PropTypes.array,
  onlineAdvancedSetting: PropTypes.string,
  hasListen: PropTypes.bool
};