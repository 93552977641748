import React from 'react';
import PropTypes from 'prop-types';
import { questionTypeMapper } from 'locales/translationMapper';
import { useTranslation } from 'react-i18next';

export const QuestionTypeName = ({ name, isListen }) => {
  const { t } = useTranslation();
  return (
    <>
      {t( questionTypeMapper(name, name ), name)}
      {isListen && <img src='/assets/greyHeadphones.svg' alt='' style={{ marginLeft: '8px' }} />}
    </>
  );
};

QuestionTypeName.propTypes = {
  name: PropTypes.string.isRequired,
  isListen: PropTypes.bool
};
