// input E, J, H -> translation key
const IDENTITY = [
  {
    name: '編輯',
    t_key: '__editor',
  },
];
export const identityMapper = (value) => {
  let identity = IDENTITY.find(e => e.value === value || e.name === value);

  if (!identity) {
    return undefined;
  }
  return `constants.identity.${identity.t_key}`;
};
