import { Modal } from '@oneclass/ui-components';
import { Button } from '@oneclass/onedesign';
import { ButtonBlock, TextBlock } from './RouteLeaveAlert.style';
import { useTranslation } from 'react-i18next';

export default function RouteLeaveAlert({
  show,
  onLeave,
  onStay
}) {
  const { t } = useTranslation();
  return (
    <Modal visible={show}
      width='600px'
      onCancel={onStay}
      title={
        <div> {t('components.routeLeaveAlert.__confirmLeaveTitle')}</div>
      }
      footer={
        <ButtonBlock>
          <Button className='leave' onClick={onLeave}> {t('components.routeLeaveAlert.__leaveButton')}</Button>
          <Button className='think' onClick={onStay}> {t('components.routeLeaveAlert.__stayButton')}</Button>
        </ButtonBlock>
      }>
      <TextBlock>
      {t('components.routeLeaveAlert.__leaveConfirmationText')}
      </TextBlock>
    </Modal>
  );
}
