// input E, J, H -> translation key
const PROPOSITIONAL_APPROACH = [
  {
    name: '電腦命題',
    t_key: '__computerProposition',
  },
  {
    name: '手動命題',
    t_key: '__manualProposition',
  },
  {
    name: '無限命題',
    t_key: '__infiniteProposition',
  }

];

export const propositionalApproachMapper = (value) => {
  let element = PROPOSITIONAL_APPROACH.find(e => e.value === value || e.name === value);

  if (!element) {
    return undefined;
  }
  return `constants.propositionalApproach.${element.t_key}`;
};
