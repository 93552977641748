/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import { Box, TreeCheck, fetchDataMachine, FETCH_DATA_EVENT, FETCH_DATA_STATE, setSelectOptions, sectionToChinese } from '@oneclass/ui-components';
import { Checkbox, RadioGroup } from '@oneclass/onedesign';
import { Row, Col } from 'antd';
import { useMachine } from '@xstate/react';
import { useSelections } from 'ahooks';
import { LANGUAGE_LIST, VERSION_NAME, VN_VOLUME_SYSTEM } from 'constants/index';
import { UiFlexBox } from 'styles/index';
import { StyledMpAreaSelectContent, UiSelectList } from './MpAreaSelectContent.style';
import { postRangeSelection } from 'api/query';
import { CHAPTERS_DONT_SHOW_ORDINAL_NUMBER, ALL_GRADE, HISTORIC_EXAM_ID } from 'constants/index';
import { getCustomUid, removeCustomUid } from 'utils/common';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { convertTWYearToNormalYear } from 'utils/common';

import { publisherMapper } from 'locales/translationMapper';
import { bookNameMapper } from 'locales/translationMapper';
import { sourceMapper } from 'locales/translationMapper';

export const MpAreaSelectContent = ({
  className, resetToggle, examPaperType,
  saveData, getSendAreaParams,
  memberEduSubject,
  getKeyList,
  getSaveData,
  identity,
  renderLoadingContent,
  isLoading,
  setIsLoading,
  isEditCopy,
  isGoBack,
  setIsGoBack,
  setBookList,
  treeCheckedList,
  setTreeCheckedList,
  globalExamPaperData,
  reCreateExam,
}) => {
  const { t } = useTranslation();
  const [sendParams, setSendParams] = useState({
    ...saveData.areaParams,
    year: saveData.areaParams.year,
    books: [],
  });
  const [selectLoading, setSelectLoading] = useState(false);
  const [sendYear, setYear] = useState(saveData?.areaParams?.year);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const [stateGetBook, sendGetBook] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        setSelectLoading(true);
        setIsLoading(true);
        const res = await postRangeSelection(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        const { content } = data;
        setSelectLoading(false);
        if (!isEditCopy) setIsLoading(false);

        // add filter for VN volume system
        Object.keys(content).forEach(key => {
          const value = content[key];

          Object.keys(value).forEach(yearKey => {
            const yearValue = value[yearKey];

            Object.keys(yearValue).forEach(versionKey => {
              const versionValue = yearValue[versionKey];

              Object.keys(versionValue).forEach(bookNameKey => {
                if (!VN_VOLUME_SYSTEM[memberEduSubject.edu].map(se => se.code).includes(bookNameKey) ) {
                  delete versionValue[bookNameKey];
                  // console.log(`delete ${bookNameKey}`);
                }
              });

            });
          });
        });

        if ((JSON.stringify(content) !== '{}')) {
          setBookList(content);
        }

        return {
          isSuccess, systemCode, message,
          bookList: content,
        };
      },
    },
  });

  const {
    bookList = {},
  } = stateGetBook.context.result || {};

  // 自行產生UID 讓畫面渲染時不會有顯示錯誤
  const formatBookList = useMemo(() => {
    if (!bookList) return {};
    if (JSON.stringify(bookList) === '{}') return {};
    const nextData = JSON.parse(JSON.stringify(bookList));
    let num = 1000;
    Object.entries(nextData).forEach(([key_1, value_1]) => { // 課綱
      Object.entries(value_1).forEach(([key_2, value_2]) => { // 學年度
        Object.entries(value_2).forEach(([key_3, value_3]) => { // 版本
          Object.entries(value_3).forEach(([key_4, value_4]) => { // 冊次
            num++;
            nextData[key_1][key_2][key_3][key_4] = {
              ...value_4,
              uid: num,
              chapters: value_4?.chapters ? value_4.chapters.map((chapter) => {
                const setChaptersUid = (chapter) => {
                  num++;
                  if (chapter.data) {
                    return {
                      ...chapter,
                      uid: num,
                      data: chapter.data.map(chapter_ => setChaptersUid(chapter_))
                    };
                  } else if (chapter.Knowledges) {
                    return {
                      ...chapter,
                      uid: num,
                      Knowledges: chapter.Knowledges.map(chapter_ => setChaptersUid(chapter_))
                    };
                  } else {
                    return {
                      ...chapter,
                      uid: num,
                    };
                  }
                };
                return setChaptersUid(chapter);
              }) : []
            };

          });
        });
      });
    });

    return nextData;
  }, [bookList]);

  const sourceOptions = useMemo(() => {
    let options = [];
    const map = {};
    if (!treeCheckedList | treeCheckedList.length === 0) return options;
    if (!sendParams.curriculum | !sendParams.year | !sendParams.version | bookList) return options;
    treeCheckedList
      .forEach((key) => {
        if (typeof key === 'number') return null;
        if (!bookList || JSON.stringify(bookList) === '{}') return null;
        if (!bookList[sendParams.curriculum][sendParams.year][sendParams.version]) return null;
        const booksMap = bookList[sendParams.curriculum][sendParams.year][sendParams.version];
        const chapters = Object.values(booksMap).map(book => book.chapters).flat();
        const newTreeCheckedList = treeCheckedList.filter((item) => typeof item === 'string');

        const sourceArr = [];
        let courseTitle = '';

        const getChapterSource = (item) => {
          if (item?.data) {
            item.data.forEach((chapterItem) => getChapterSource(chapterItem));
          } else if (item.Knowledges) {
            if (item.Knowledges[0].code === courseTitle) {
              sourceArr.push(item.Knowledges[0].sources);
            }
          }
        };

        newTreeCheckedList.map((item) => {
          const getSelectSource = (grade) => {
            const gradeIndex = item.indexOf(grade);
            if (gradeIndex !== -1) {
              courseTitle = item.substring(gradeIndex);
              chapters.forEach((item) => getChapterSource(item));
            }
          };

          const getGradeItem = ALL_GRADE.find((gradeItem) => item.includes(gradeItem));
          getSelectSource(getGradeItem);
        });

        const resultArray = _.uniqBy(_.flatMap(sourceArr), 'code');

        for (const source of resultArray) {
          map[source.code] = source;
        }
      });
    options = Object.values(map);
    const sortArray = (array) => {
      return array.sort((a, b) => {
        return a.code.substring(2, 5) - b.code.substring(2, 5);
      }).sort((a, b) => {
        return a.code.substring(0, 2) - b.code.substring(0, 2);
      });
    };

    options = options.map(item => {
      return {
        ...item,
        name: t(sourceMapper(item.name))
      };
    });

    return setSelectOptions(sortArray(options)) || [];
  }, [treeCheckedList, sendParams.curriculum, sendParams.year, sendParams.version, bookList]);

  useEffect(() => {
    if (!sourceOptions | sourceOptions.length === 0) return null;
    if (!chapterOptions | !bookOptions | !versionOptions | !yearOptions | !curriculumOptions) return null;
    if (!stateGetBook.matches(FETCH_DATA_STATE.DONE)) return null;
    if (!isGoBack) {
      // 預設全選
      setSendParams({
        ...sendParams,
        source: sourceOptions.map(item => item?.value) || [],
      });
      sourceSetSelected(sourceOptions.map(item => item?.value) || []);
    }
  }, [sourceOptions]);

  const setRadioOptions = (type, array) => {
    return array.map((item) => {
      if (type === 'curriculum') {
        return {
          label: t('containers.mpAreaSelectContent.__labelCurriculum', { item }),
          value: item,
        };
      } else if (type === 'year') {
        return {
          // label: item + '學年度',
          label: t('containers.homeAreaSelectContent.__labelYear', { item: convertTWYearToNormalYear(item) }),
          value: item,
        };
      } else if (type === 'version') {
        return {
          label: VERSION_NAME[item],
          value: item,
        };
      } else if (type === 'books') {
        return {
          value: item[0],
          name: item[1].volumnName,
        };
      }
    });
  };

  const curriculumOptions = useMemo(() => {
    if (!bookList || JSON.stringify(bookList) === '{}') return [];
    // const data = (memberEduSubject.edu === 'J' || memberEduSubject.edu === 'H') ? setRadioOptions('curriculum', Object.keys(bookList)).filter(item => item.value !== '99') : setRadioOptions('curriculum', Object.keys(bookList));
    const data = setRadioOptions('curriculum', Object.keys(bookList));
    // 數字越大牌越前面。
    return data.sort((a, b) => {
      return Number(b?.value) - Number(a?.value);
    });
  }, [bookList]);

  const yearOptions = useMemo(() => {
    if ((!bookList || JSON.stringify(bookList) === '{}') || !sendParams.curriculum) return [];
    const data = bookList[sendParams.curriculum] ? setRadioOptions('year', Object.keys(bookList[sendParams.curriculum])).filter(item => item.value !== '109') : [];
    return data.sort((a, b) => {
      return Number(b?.value) - Number(a?.value);
    });
  }, [sendParams.curriculum, bookList]);

  const versionOptions = useMemo(() => {
    if ((!bookList || JSON.stringify(bookList) === '{}') || !sendParams.curriculum || !sendParams.year) return [];
    let data = bookList[sendParams.curriculum][sendParams.year] ? setRadioOptions('version', Object.keys(bookList[sendParams.curriculum][sendParams.year])) : [];
    data = data.map(e => {
      return { ...e, label: t(publisherMapper(e.value)) };
    });
    return data.sort((a, b) => {
      return b?.value.charCodeAt() - a?.value.charCodeAt();
    });
  }, [sendParams.curriculum, sendParams.year, bookList]);

  const bookOptions = useMemo(() => {
    if ((!bookList || JSON.stringify(bookList) === '{}') || !sendParams.curriculum || !sendParams.year || !sendParams.version) return [];

    let data = bookList[sendParams.curriculum][sendParams.year][sendParams.version] ? setRadioOptions('books', Object.entries(bookList[sendParams.curriculum][sendParams.year][sendParams.version])) : [];
    // console.log('bookOptions', data);
    data = data.map(e => {
      return {
        ...e,
        name: t(bookNameMapper(e.value))
      };
    });
    return data;
  }, [sendParams.curriculum, sendParams.year, sendParams.version, bookList]);

  const chapterOptions = useMemo(() => {
    const getTree = (chapter, isFirstHierarchy = false, isHistoricExam = false) => {
      // 遇到特定章節時，不顯示序數
      const hideOrdinalNumber = CHAPTERS_DONT_SHOW_ORDINAL_NUMBER.some(hideChapter => chapter.name.includes(hideChapter));
      let ordinalNumber = '';

      if (!isHistoricExam) {
        if (!hideOrdinalNumber) {
          if (LANGUAGE_LIST.includes(memberEduSubject?.subject)) {
            ordinalNumber = t('containers.mpAreaSelectContent.__sectionLesson', { sectionToChinese: chapter.code });
          } else {
            ordinalNumber = t('containers.mpAreaSelectContent.__sectionChapter', { sectionToChinese: chapter.code });
          }
        }
      }

      return ({
        title: isFirstHierarchy ? `${ordinalNumber}${chapter.name}` : `[${chapter.code}] ${chapter.name}`,
        key: chapter?.uid,
        children: chapter?.Knowledges?.length ? chapter.Knowledges.map(knowledge => ({
          title: knowledge.name,
          key: getCustomUid(chapter, knowledge),
        })) : chapter?.data?.map(chapter_ => getTree(chapter_, false, isHistoricExam))
      });
    };

    if ((!formatBookList || JSON.stringify(formatBookList) === '{}') || !sendParams.curriculum || !sendParams.year || !sendParams.version || !sendParams.books || !memberEduSubject?.subject) return [];
    const nextData = sendParams.books.sort((a, b) => { return a.substring(1, 3) - b.substring(1, 3); }).map((book) => {
      // 是歷屆大考
      const isHistoricExam = book === HISTORIC_EXAM_ID;
      let volume = formatBookList[sendParams.curriculum][sendParams.year][sendParams.version][book]?.volumnName;
      if (volume) {
        volume = t(bookNameMapper(volume), volume);
      }
      return {
        title: volume,
        key: formatBookList[sendParams.curriculum][sendParams.year][sendParams.version][book]?.uid,
        children: formatBookList[sendParams.curriculum][sendParams.year][sendParams.version][book].chapters
          .map((chapter) => getTree(chapter, true, isHistoricExam)),
      };
    });

    return nextData;
  }, [formatBookList, sendParams.curriculum, sendParams.year, sendParams.version, sendParams.books, memberEduSubject?.subject]);

  const defaultOpenArray = useMemo(() => {
    if (!chapterOptions || chapterOptions.length === 0) return [];
    return chapterOptions.map((book) => {
      return book?.key;
    });
  }, [chapterOptions]);

  const getAllTreeData = (data) => {
    if (!data || data.length === 0) return [];
    let nextData = [];
    data.forEach(item => {
      let children = [];
      if ('children' in item) {
        children = getAllTreeData(item.children);
        nextData = [...nextData, ...children];
      }
      nextData = [...nextData, item.key];
    });
    return nextData;
  };

  const treeDataLength = useMemo(() => {
    if (!chapterOptions) return 0;
    return getAllTreeData(chapterOptions).length;
  }, [chapterOptions]);

  const onTreeChange = (data) => {
    setTreeCheckedList(data);
    setIsGoBack(false);
  };

  const onCheckAllChange = e => {
    setTreeCheckedList(e.target.checked ? getAllTreeData(chapterOptions) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const {
    selected: bookSelected,
    allSelected,
    isSelected,
    toggle,
    toggleAll,
    unSelectAll,
    setSelected,
    partiallySelected,
  } = useSelections(bookOptions.map(item => item.value), (reCreateExam ? [] : saveData.areaParams?.books));

  const {
    selected: sourceSelected,
    allSelected: sourceAllSelected,
    isSelected: sourceIsSelected,
    unSelectAll: sourceUnSelectAll,
    setSelected: sourceSetSelected,
    toggle: sourceToggle,
    toggleAll: sourceToggleAll,
    partiallySelected: sourcePartiallySelected,
  } = useSelections(sourceOptions ? sourceOptions.map(item => item.value) : [], saveData.areaParams?.source || []);

  const onRadioChange = (type, e) => {
    if (type === 'curriculum') {
      const curriculum = e.target.value;
      const year = setRadioOptions('year', Object.keys(bookList[curriculum])).filter(item => item.value !== '109')[0].value;
      setSendParams({
        ...sendParams,
        [type]: curriculum,
        year: year,
        version: 'N',
        books: [],
        source: [],
      });
      setSelected([]);
      setTreeCheckedList([]);
      sourceUnSelectAll();
    } else if (type === 'year') {
      const year = e.target.value;
      setSendParams({
        ...sendParams,
        [type]: year,
        version: 'N',
        books: [],
        source: [],
      });
      setSelected([]);
      setTreeCheckedList([]);
      sourceUnSelectAll();
    } else if (type === 'version') {
      setSendParams({
        ...sendParams,
        [type]: e.target.value,
        books: [],
        source: [],
      });
      setSelected([]);
      setTreeCheckedList([]);
      sourceUnSelectAll();
    } else {
      setSendParams({
        ...sendParams,
        [type]: e.target.value
      });
    }
  };

  const onChangeYear = (type, e) => {
    setYear(e.target.value);
  };

  useEffect(() => {
    if (isEditCopy) {
      setIsLoading(true);
    }

    if (!memberEduSubject?.edu || !memberEduSubject?.subject || sendYear === '') return;

    sendGetBook(FETCH_DATA_EVENT.FETCH, {
      payload: {
        edusubject: memberEduSubject?.edu + memberEduSubject?.subject,
        outputStyle: memberEduSubject?.output,
        drawUpStyle: 'CustomPattern',
      }
    });

    setTreeCheckedList([]);
    unSelectAll();
    sourceUnSelectAll();
    if (memberEduSubject?.edu === saveData.education && memberEduSubject?.subject === saveData.subject && sendYear === saveData?.areaParams?.year) return;
    setSendParams({
      curriculum: sendParams.curriculum,
      year: sendYear
    });

    getKeyList([]);
  }, [memberEduSubject.edu, memberEduSubject.subject]);

  useEffect(() => {
    if (stateGetBook.matches(FETCH_DATA_STATE.DONE)) {
      if (sendYear === '' || sendYear === saveData?.areaParams?.year) {
        setSendParams(saveData.areaParams);
        setSelected(saveData.areaParams?.books);
        sourceSetSelected(saveData.areaParams?.source);
        setTreeCheckedList(saveData.areaParams?.treeCheckedList || []);
      }
    }
  }, [stateGetBook.value, sendYear]);

  useEffect(() => {
    setSendParams({
      ...sendParams,
      books: bookSelected
    });
  }, [bookSelected]);

  useEffect(() => {
    setSendParams({
      ...sendParams,
      source: sourceSelected
    });
  }, [sourceSelected]);

  useEffect(() => {
    if (treeCheckedList.length === 0) {
      setIndeterminate(false);
      setCheckAll(false);
    } else {
      setIndeterminate(treeCheckedList.length !== treeDataLength ? true : false);
      setCheckAll(treeCheckedList.length !== treeDataLength ? false : true);
    }
  }, [treeDataLength, treeCheckedList]);

  useEffect(() => {
    if (!sendParams) return;
    getSendAreaParams({
      ...sendParams,
      bookIDs: sendParams?.books ? sendParams.books.map(item => `${sendParams.year}${sendParams.version}-${memberEduSubject.edu}${memberEduSubject.subject}${item}`) : [],
      keys: treeCheckedList
        .filter(item => typeof item !== 'number')
        .map(item => removeCustomUid(item)),
      treeCheckedList
    });
  }, [sendParams, treeCheckedList, memberEduSubject]);

  useEffect(() => {
    if (sendParams.curriculum === saveData.areaParams.curriculum) return;
    if (sendParams.version === saveData.areaParams.version) return;
    if (!sourceOptions) return;
    unSelectAll();
    sourceUnSelectAll();
    setSelected([]);
    sourceSetSelected(sourceOptions.map(item => item.value));
  }, [sendParams.curriculum, sendParams.version]);

  useEffect(() => {
    const nextData = treeCheckedList.filter(item => getAllTreeData(chapterOptions).includes(item));
    setTreeCheckedList(nextData);
  }, [sendParams.books]);

  useEffect(() => {
    getSaveData('bookOptions', bookOptions);
  }, [bookOptions]);

  const mounted = React.useRef(false);
  useEffect(() => {
    if (mounted.current) {
      setSendParams(saveData.areaParams);
      setSelected(saveData.areaParams?.books);
      sourceSetSelected(saveData.areaParams?.source);
      setTreeCheckedList(saveData.areaParams?.treeCheckedList || []);
    } else {
      mounted.current = true;
    }
  }, [resetToggle]);

  // 預設選取 課綱＆年度＆版本 第一個。
  useEffect(() => {
    if (!curriculumOptions || !yearOptions || !versionOptions) return;
    if (sendParams?.curriculum && sendParams?.year && sendParams?.version) return;
    setSendParams({
      ...sendParams,
      curriculum: curriculumOptions[0]?.value,
      year: yearOptions[0]?.value,
      version: versionOptions[0]?.value,
    });
  }, [curriculumOptions, yearOptions, versionOptions, sendParams.curriculum, sendParams.year, sendParams.version]);

  return (
    isLoading ?
      renderLoadingContent()
      :
      <StyledMpAreaSelectContent className={className} data-testid="MpAreaSelectContent">
        <UiFlexBox>
          <Box mb={6}>
            <div className="label">
              {t('containers.mpAreaSelectContent.__curriculum')}
            </div>
            <UiSelectList>
              <RadioGroup buttonStyle='solid' optionType='button' options={curriculumOptions} value={sendParams?.curriculum} onChange={(e) => { onRadioChange('curriculum', e); }} />
            </UiSelectList>
          </Box>
          <Box mb={6}>
            <div className="label">
              {t('containers.mpAreaSelectContent.__year')}
            </div>
            <UiSelectList>
              <RadioGroup buttonStyle='solid' optionType='button' options={yearOptions} value={sendParams?.year} onChange={(e) => { onRadioChange('year', e); onChangeYear('year', e); }} />
            </UiSelectList>
          </Box>
          <Box mb={6}>
            <div className='areaBorder'></div>
          </Box>
          {
            !selectLoading &&
            <>
              {
                versionOptions && versionOptions.length > 0 && identity !== 'SchoolTeacher' &&
                <>
                  <Box mb={6}>
                    <div className="label">
                      {t('containers.mpAreaSelectContent.__version')}
                    </div>
                    <UiSelectList>
                      <RadioGroup buttonStyle='solid' optionType='button' options={versionOptions} value={sendParams?.version} onChange={(e) => { onRadioChange('version', e); }} />
                    </UiSelectList>
                  </Box>
                  <Box mb={6}>
                    <div className='areaBorder'></div>
                  </Box>
                </>
              }

              <Box mb={6}>
                <div className="label">
                  {t('containers.mpAreaSelectContent.__booklet')}
                  {
                    sendParams?.curriculum && sendParams?.year &&
                    <div >
                      <Checkbox
                        checked={allSelected}
                        onClick={toggleAll}
                        indeterminate={partiallySelected}
                      >{t('containers.mpAreaSelectContent.__selectAll')}</Checkbox>
                    </div>
                  }


                </div>
                <UiSelectList>
                  {
                    sendParams?.curriculum && sendParams?.year ?
                      bookOptions.map(item => (
                        <Row className='checkBoxRow' key={item.value}>
                          <Col><Checkbox checked={isSelected(item.value)} onClick={() => toggle(item.value)} >{item.name}</Checkbox></Col>
                        </Row>
                      )) :
                      <div className="stepHint">
                        {t('containers.mpAreaSelectContent.__selectCurriculumAndYear')}
                      </div>
                  }

                </UiSelectList>
              </Box>

              <Box mb={6}>
                <div className='areaBorder'></div>
              </Box>

              <Box mb={6}>
                <div className="label">
                  {t('containers.mpAreaSelectContent.__chapter')}
                  {
                    sendParams?.books && sendParams?.books.length > 0 && chapterOptions.length > 0 &&
                    <div>
                      <Checkbox
                        indeterminate={indeterminate}
                        onChange={onCheckAllChange}
                        checked={checkAll}
                      >{t('containers.mpAreaSelectContent.__selectAll')}</Checkbox>
                    </div>
                  }

                </div>
                <UiSelectList key={defaultOpenArray && defaultOpenArray.length}>
                  {
                    sendParams?.books && sendParams?.books.length > 0 && chapterOptions.length > 0 ?
                      <TreeCheck
                        treeData={chapterOptions}
                        checkedList={treeCheckedList}
                        onChange={onTreeChange}
                        defaultExpandedKeys={defaultOpenArray}
                      /> :
                      <div className="stepHint">
                        {t('containers.mpAreaSelectContent.__selectBookletFirst')}
                      </div>
                  }

                </UiSelectList>
              </Box>

              <Box mb={6}>
                <div className='areaBorder'></div>
              </Box>

              <Box mb={6}>
                <div className="label">
                  {t('containers.mpAreaSelectContent.__source')}
                  {
                    sourceOptions && treeCheckedList && treeCheckedList.length > 0 &&
                    <div >
                      <Checkbox
                        checked={sourceAllSelected}
                        onClick={sourceToggleAll} indeterminate={sourcePartiallySelected}
                      >
                        {t('containers.mpAreaSelectContent.__selectAll')}
                      </Checkbox>
                    </div>
                  }


                </div>
                <UiSelectList>
                  {
                    sourceOptions && treeCheckedList && treeCheckedList.length > 0 ?
                      sourceOptions && sourceOptions.map(item => (
                        <Row className='checkBoxRow' key={item.value}>
                          <Col><Checkbox checked={sourceIsSelected(item.value)} onClick={() => sourceToggle(item.value)} >{item.name}</Checkbox></Col>
                        </Row>

                      )) :
                      <div className="stepHint">
                        {t('containers.mpAreaSelectContent.__selectChapterFirst')}
                      </div>
                  }
                </UiSelectList>
              </Box>
            </>
          }

        </UiFlexBox>
      </StyledMpAreaSelectContent>
  );
};