/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import { Tooltip } from 'antd';
import { Button } from '@oneclass/onedesign';
import {
  CopySolid,
  CheckCircleSolid
} from '@onedesign/icon';

import { StyledTestLinkModalContent } from './TestLinkModalContent.style';
import * as GA from 'utils/googleAnalytics';
import { useTranslation } from 'react-i18next';

export const TestLinkModalContent = ({ className, examLink, modalInfo, shareModalData }) => {
  const linkRef = useRef(null);
  const textRef = useRef(null);
  const { t, i18n } = useTranslation();

  const copyLink = async(type, e) => {
    if (e) {
      const clipboardObj = navigator.clipboard;
      await clipboardObj.writeText(type.current.value);
    }
  };

  const textForCopy = t('components.testLinkModalContent.copyText', { month: shareModalData?.endTime?.month, day: shareModalData?.endTime?.day, time: shareModalData?.endTime?.time, examLink, quizCode: modalInfo.QuizCode, quizCodeTop4: shareModalData?.quizCode?.top4, quizCodeLast4: shareModalData?.quizCode?.last4 });

  return (
    <StyledTestLinkModalContent className={className} data-testid="TestLinkModalContent">
      <div className='testModalContent'>
        <div className='testModalLinkBox'>
          <div className='testModalLinkTitle'>{modalInfo?.QuizName}</div>
          <div className='endTime'>
            <span> {t('components.testLinkModalContent.__testModalTitle')}</span>
            {i18n.language === 'zh' ?
              (
                <div>{`${shareModalData?.endTime?.month}/${shareModalData?.endTime?.day} ${shareModalData?.endTime?.time}`}</div>
              )
              :
              (
                <div>{`${shareModalData?.endTime?.day}/${shareModalData?.endTime?.month} ${shareModalData?.endTime?.time}`}</div>
              )
            }
          </div>
          <div className='testModalLink' id="testModalLink">
            <input disabled className='testModalLinkData' ref={linkRef} value={`${examLink}/quiz/${modalInfo.QuizCode}`} />
            <div className='copyImgBox'>
              <Tooltip
                trigger='click'
                placement="top"
                getPopupContainer={() => document.getElementById('testModalLink') || document.body}
                title={
                  <div className='copyHint'>
                    <div className='copyImg'>
                      <CheckCircleSolid />
                    </div>
                    {t('components.testLinkModalContent.__copyHint')}
                  </div>
                }
                onVisibleChange={(visible) => {
                  GA.shareExamByLink('copy');
                  copyLink(linkRef, visible);
                }}>
                <CopySolid className='copyBtn'/>
              </Tooltip>
            </div>
          </div>
          <ul className='testModalLinkText'>
            <li> {t('components.testLinkModalContent.__examLinkText1')}</li>
            <li> {t('components.testLinkModalContent.__examLinkText2', { examLink, top4: shareModalData?.quizCode?.top4, last4: shareModalData?.quizCode?.last4 })}</li>
          </ul>
        </div>
        <div className='testModalManualBox'>
          <div className='testModalManualTitle'> {t('components.testLinkModalContent.__testModalManualIntro')}</div>
          <textarea disabled style={{ display: 'none' }} ref={textRef} value={textForCopy} />
          <ul className='testModalManualList'>
            <li> {t('components.testLinkModalContent.__testModalManualText1', { month: shareModalData?.endTime?.month, day: shareModalData?.endTime?.day, time: shareModalData?.endTime?.time })}</li>
            <li> {t('components.testLinkModalContent.__testModalManualText2')}<a href={`${examLink}/quiz/${modalInfo.QuizCode}`} target='_blank' rel='noreferrer' onClick={() => GA.shareExamByLink('link')}>{`${examLink}/quiz/${modalInfo.QuizCode}`}</a></li>
            <li> {t('components.testLinkModalContent.__testModalManualText3')}</li>
            <li> {t('components.testLinkModalContent.__testModalManualText4')} <a href={examLink} target='_blank' rel='noreferrer'>{examLink}</a>  {t('components.testLinkModalContent.__examLinkText3', { top4: shareModalData?.quizCode?.top4, last4: shareModalData?.quizCode?.last4 })}</li>
          </ul>
          <div className='testModalManualButton' id='testModalManualList'>
            <Tooltip
              trigger='click'
              placement="top"
              getPopupContainer={() => document.getElementById('testModalManualList') || document.body}
              title={
                <div className='copyHint'>
                  <div className='copyImg'>
                    <CheckCircleSolid />
                  </div>
                  {t('components.testLinkModalContent.__copyHint')}
                </div>
              }
              onVisibleChange={(visible) => {
                GA.copyShareExamManual('link');
                copyLink(textRef, visible);
              }}>
              <Button className='copyTextBtn' type='solid'><CopySolid /> {t('components.testLinkModalContent.__copyTextBtn')}</Button>
            </Tooltip>

          </div>
        </div>
      </div>
    </StyledTestLinkModalContent>
  );
};