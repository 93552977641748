/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect, useContext } from 'react';
import {
  KnowledgeAreaSelectContent,
  KnowledgeQuestionSelectContent,
  KnowledgeQuestionReviewContent,
  KnowledgeLayoutOptionContent,
  HomeQuestionCompleteContent,
} from 'containers';
import {
  StepBox,
  fetchDataMachine,
  FETCH_DATA_EVENT,
  FETCH_DATA_STATE,
  Modal,
  AuthProvider,
  CreateExamPaperParamsProvider,
  MobileStepBox,
  EditCopyExamPaperProvider,
  openNotificationWithIcon,
} from '@oneclass/ui-components';
import { ExamPaperHeader, ReviewPage, QuestionLimitHint, QuestionLimitModal } from 'components';
import { Button } from '@oneclass/onedesign';
import { ExclamationCircleSolid } from '@onedesign/icon';
import { useMachine } from '@xstate/react';
import { putEditExamPaper, postExamCreate, postExamEdit } from 'api/examPaper';
import { postExportPaper } from 'api/exportPaper';
import { formateQuestionList, setCookie, getCookie, formatSelectedKnowledge, autoSettingScore } from 'utils/common';
import { useBoolean } from 'ahooks';
import { useHistory } from 'react-router-dom';
import { StyledKnowledgePropositionPage, StyledKnowledgePropositionContent, UiBtnZone } from './KnowledgePropositionPage.style';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { useLoadingContent } from 'utils/hooks/useLoadingContent';
import { postMemberTypeSetting } from 'api/member';
import { postGetQuestionBucket } from 'api/query';
import { OUTPUT_TYPE_NAME, PROCESS_PARAMS } from 'constants/index';
import { useFilterQuestionModal } from 'utils/hooks/useFilterQuestionModal';
import * as GA from 'utils/googleAnalytics';
import { useTranslation } from 'react-i18next';



const defaultCountInputValue = {
  answer: '',
  question: '',
  score: {
    perQues: '',
    perAns: '',
    allScore: 0,
  },
  difficulty: {
    BEGIN: '',
    INTERMEDIATE: '',
    EXPERT: '',
  }
};
const formateTableData = (data) => {
  if (!data) return null;
  let nextData = {};
  Object.entries(data).forEach(([key, value], index) => {
    nextData = {
      ...nextData,
      [key]: {
        ...value,
        inputValue: defaultCountInputValue
      }
    };
  });
  return nextData;
};
const goTop = () => {
  const main = document.getElementById('mainLayout');
  main.scrollTop = 0;
};
const initSaveData = {
  education: '',
  subject: '',
  areaParams: {},
  quesReviewQuestionRenderList: {},
  createExamPaperParams: {},
  tableData: {},
  examUID: ''
};

export const KnowledgePropositionPage = ({ className, props }) => {
  const { setIsLoadingMark } = props;
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const { editCopyExamPaperData, resetCopyEditPaperParams } = useContext(EditCopyExamPaperProvider.EditCopyExamPaperContext);
  const history = useHistory();
  const { useAuthState } = AuthProvider;
  const authState = useAuthState();
  const { createExamPaperParams: globalExamPaperData } = useContext(CreateExamPaperParamsProvider.createExamPaperContext);
  const [saveData, setSaveData] = useState({ ...initSaveData });
  const [areaParams, setAreaParams] = useState({});
  const [sendParams, setSendParams] = useState({});
  const [resetToggle, setResetToggle] = useState(false);
  const [keyList, setKeyList] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [step, setStep] = useState(1);
  const [checkedSend, setCheckedSend] = useState([]);
  const [jumpStep, setJumpStep] = useState(null);
  const [createExamPaperParams, setCreateExamPaperParams] = useState({});
  const [questions, setQuestions] = useState([]);
  const [tableData, setTableData] = useState({});
  const [areaModalVisible, { toggle: setAreaModalToggle, setFalse: setAreaModalFalse }] = useBoolean(false);
  const { renderLoadingContent, isLoading, setIsLoading } = useLoadingContent();
  const [reCreateExam, setReCreateExam] = useState(false);
  const [isJumpToStep2, setIsJumpToStep2] = useState(false);
  const [emptyTextModalVisible, { toggle: setEmptyTextModalToggle, setFalse: setEmptyTextModalFalse }] = useBoolean(false);
  const [isGoBack, setIsGoBack] = useState(false);
  const [isLanguageSubject, setIsLanguageSubject] = useState(false);
  const [questionCountModalVisible, { toggle: setQuestionCountModalToggle, setFalse: setQuestionCountModalFalse }] = useBoolean(false);
  const [isShowQuestionLimitText, setIsShowQuestionLimitText] = useState(false);
  const [reBuildData, setReBuildData] = useState({});
  const [originalData, setOriginalData] = useState([]); // 所有題型題目。
  const [isAgainDo, setIsAgainDo] = useState(false);
  const [knowledgeList, setKnowledgeList] = useState({});
  const [treeCheckedList, setTreeCheckedList] = useState([]);
  const { t } = useTranslation();

  // 是否是編輯狀態
  const steps = [
    {
      title: t('views.knowledgePropositionPage.__rangeSelection')
    },
    {
      title: t('views.knowledgePropositionPage.__questionSelection')
    },
    {
      title: t('views.knowledgePropositionPage.__previewQuestions')
    },
    {
      title: t('views.knowledgePropositionPage.__layoutConfiguration')
    },
    {
      title: t('views.knowledgePropositionPage.__paperCompletion')
    },
  ];
  const isEditCopy = useMemo(() => {
    if (editCopyExamPaperData && JSON.stringify(editCopyExamPaperData) !== '{}') {
      setIsLoading(true);
      return true;
    } else {
      return false;
    }
  }, [editCopyExamPaperData]);

  // 計算 試卷中各種題型的 總分、總題數、總答數。
  const totalQuesTypeData = useMemo(() => {
    let nextData = {
      score: 0,
      questionCount: 0,
      totalAnswer: 0,
    };
    if (!tableData) return nextData;

    let score = 0;
    let questionCount = 0;
    let totalAnswer = 0;
    Object.entries(tableData).forEach(([key, value]) => {
      score += Number(value.inputValue.score.allScore) || 0;
      questionCount += value.inputValue.question ? parseInt(value.inputValue.question) : 0;
      totalAnswer += Number(value?.inputValue?.answer) || 0;
    });
    nextData = {
      score: Number(Number(score).toFixed(1)) || 0, // 試卷總分。
      questionCount: parseInt(questionCount) || 0, // 總題數。
      totalAnswer: Number(totalAnswer) || 0, // 總答數。
    };
    return nextData;
  }, [tableData]);

  // 出卷類型。
  const processType = useMemo(() => {
    return createExamPaperParams?.outputType || globalExamPaperData.output || null;
  }, [globalExamPaperData.output, createExamPaperParams.outputType]);

  // 題數/答數 限制
  const questionNumLimit = useMemo(() => {
    if (!processType) return 0;
    return PROCESS_PARAMS[processType]?.questionLimit;
  }, [processType]);

  // 要用來判斷的 題數/答數
  const currentQuesNum = useMemo(() => {
    return totalQuesTypeData?.totalAnswer;
  }, [processType, totalQuesTypeData.totalAnswer]);

  // 儲存使用者設定
  const [, sendUserSetting] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_, event) => {
        const res = await postMemberTypeSetting(event.payload);
        const { isSuccess, systemCode, message } = res;
        if (isSuccess) {
          // 成功顯示
        } else {
          openNotificationWithIcon('error', message);
        }
        return {
          isSuccess, systemCode, message
        };
      }
    }
  });

  const getSendParams = (data) => {
    setSendParams(data);
  };

  // 取得試題類型
  const [stateGetQuestionType, sendGetQuestionType] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        setIsLoadingMark(true);
        const res = await postGetQuestionBucket(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        // const { curriculumMap = {}, bookMap = {}, sourceMap = {}, chapterMap = {}, yearMap = {} } = data?.selection || {};
        if (isSuccess) {
          if (JSON.stringify(transformQuestionTypeData(data)) !== '{}') {
            goTop();
          }
        }
        setReBuildData(transformQuestionTypeData(data));
        setOriginalData(data);
        setIsLoadingMark(false);
        // setData(transformQuestionTypeData(data));
        return {
          isSuccess, systemCode, message,
        };
      },
    },
  });

  // const { data } = stateGetQuestionType.context.result || {};

  // 過濾試題 相關 －－－

  // 初始化資料 相關
  const initQuestionTypeData = () => {
    setSaveData({
      ...initSaveData,
      areaParams,
      education: globalExamPaperData?.edu || 'J',
      subject: globalExamPaperData?.subject || 'GE',
      // bookOptions: saveData.bookOptions,
    });
    setCreateExamPaperParams({});
  };

  const {
    renderFilterQuestionModal,
    filterQuestionModalToggle,
    setFilterQuestionModalFalse,
    filterPaperUID,
    filterQuestions,
    isChangeFilter,
    setIsChangeFilter,
    setFilterPaperUID,
    paperFilter,
  } = useFilterQuestionModal(initQuestionTypeData);

  useEffect(() => {
    if (isAgainDo) {
      setFilterPaperUID([]);
      setIsAgainDo(false);
    }
  }, [isAgainDo]);

  const data = useMemo(() => {
    if (!reBuildData | !originalData) return {};
    if (!filterQuestions || filterQuestions.length === 0) return reBuildData;
    let nextData = {
      ...reBuildData
    };
    originalData.forEach((quesType) => {
      quesType.questions.forEach((ques) => {
        if (filterQuestions.indexOf(ques?.uid) !== -1) {
          nextData = {
            ...nextData,
            [quesType?.questionTypeName]: {
              ...nextData[quesType?.questionTypeName],
              count: {
                answer: parseInt(nextData[quesType?.questionTypeName]?.count?.answer) - parseInt(ques?.answerAmount),
                question: parseInt(nextData[quesType?.questionTypeName]?.count?.question) - 1,
                difficulty: {
                  ...nextData[quesType?.questionTypeName].count.difficulty,
                  [ques?.difficulty]: {
                    answer: parseInt(nextData[quesType?.questionTypeName]?.count?.difficulty[ques?.difficulty]?.answer) - parseInt(ques?.answerAmount),
                    question: parseInt(nextData[quesType?.questionTypeName]?.count?.difficulty[ques?.difficulty]?.question) - 1
                  }
                }
              },
              question: nextData[quesType?.questionTypeName]?.question.filter((item) => {
                return filterQuestions.indexOf(item?.uid) === -1;
              })
            }
          };
        }
      });
    });
    return nextData;
  }, [filterQuestions, originalData]);

  // 建立測驗
  const [statePostExamCreate, sendPostExamCreate] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        setIsLoading(true);
        const res = await postExamCreate(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        const { examUID } = data;
        if (isSuccess) {
          doExportExam(examUID);
          setCreateExamPaperParams({
            ...createExamPaperParams,
            examUID: examUID,
          });
        } else {
          openNotificationWithIcon('error', message);
        }
        return {
          isSuccess, systemCode, message,
          data,
        };
      },
    },
  });

  // 編輯試卷
  const [statePostExamEdit, sendPostExamEdit] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        setIsLoading(true);
        const res = await postExamEdit(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) {
          doExportExam(event.payload.examUID);
        } else {
          openNotificationWithIcon('error', message);
        }
        return {
          editIsSuccess: isSuccess, systemCode, message,
          data
        };
      },
    },
  });

  const transformDifficultObject = (array) => {
    if (!array) return {};
    let nextData = {};
    array.forEach((item) => {
      let newData = {
        ...nextData,
        [item.difficulty]: {
          answer: item.answer,
          question: item.question,
        }
      };
      nextData = newData;
    });
    return nextData;
  };

  const transformQuestion = (quesType, quesTypeName, array) => {
    if (!quesType || !quesTypeName || !array) return [];
    return array.map((item) => {
      return {
        ...item,
        quesType, quesTypeName
      };
    });
  };

  const transformQuestionTypeData = (data) => {
    if (!data) return {};
    let nextData = {};
    data.forEach((item) => {
      // if (globalExamPaperData.output === 'Quick') {
      //   if (!new RegExp(/SS|YN/g).test(item.questionType)) return null;
      // }
      let newData = {
        ...nextData,
        [item.questionTypeName]: {
          code: item.questionType,
          count: {
            answer: item.sum.answer,
            question: item.sum.question,
            difficulty: transformDifficultObject(item.diffcultyAggregate)
          },
          name: item.questionTypeName,
          question: transformQuestion(item.questionType, item.questionTypeName, item.questions)
        }
      };
      nextData = newData;
    });
    return nextData;
  };

  const searchParams = useMemo(() => {
    let key = isLanguageSubject ? 'categories' : 'knowledges';
    const isQuick = (isEditCopy ? editCopyExamPaperData?.output : globalExamPaperData?.output) === 'Quick';
    return {
      eduSubject: editCopyExamPaperData?.eduSubject || (globalExamPaperData?.edu + globalExamPaperData?.subject),
      [key]: editCopyExamPaperData?.knowledgeIDs || editCopyExamPaperData?.categoryIDs || areaParams?.keys,
      // sources: areaParams?.source
      curriculum: editCopyExamPaperData?.curriculum || areaParams.curriculum,
      year: editCopyExamPaperData?.year || areaParams.year,
      version: editCopyExamPaperData?.version || areaParams.version,
      isQuick,
    };
  }, [globalExamPaperData.edu, globalExamPaperData.subject, areaParams.keys, areaParams.year, areaParams.version, areaParams.curriculum, globalExamPaperData.output, isLanguageSubject, isEditCopy, editCopyExamPaperData?.output]);

  const [statePutEditExamPaper, sendPutEditExamPaper] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        const res = await putEditExamPaper(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        // if (isSuccess && event.outputType !== 'Files') {goTop();}
        return {
          isSuccess, systemCode, message,
          data
        };
      },
    },
  });

  const [statePostExportPaper, sendPostExportPaper] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        const res = await postExportPaper(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) { goTop(); }
        return {
          isSuccess, systemCode, message,
          exportPaperData: data
        };
      },
    },
  });

  // const { data } = stateGetDrawUpFastCacheQuery.context.result || {};
  const { exportPaperData } = statePostExportPaper.context.result || {};

  const disabled = useMemo(() => {
    let result = false;
    const checkArray = ['paperName', 'teacherSign', 'schoolName'];
    const advancedCheckArray = ['amount', 'paperSize', 'wordSetting', 'paperContents'];
    let pickupQuestion = [];
    switch (step) {
      case 4:
        if (createExamPaperParams?.outputType !== 'Files') {
          const {
            paperName = {}, outputType = '',
            // examType = ''
          } = createExamPaperParams || {};
          const checkParams = {
            paperName: paperName?.value || '',
            outputType,
            // examType
          };
          Object.entries(checkParams).forEach(([key, value]) => {
            if (value === '') result = true;
          });
        } else {
          Object.entries(createExamPaperParams).forEach(([key, value]) => {
            if ((key === 'grade' || key === 'room') && checkedSend.findIndex(item => item === 'classInfo') !== -1) {
              if (value.value === '') result = true;
            }
            const needCheck = ((checkArray.findIndex(item => item === key) !== -1 && checkedSend.findIndex(item => item === key) !== -1));
            if (needCheck) {
              if (value.value === '') result = true;
            }
            if (advancedCheckArray.findIndex(item => item === key) !== -1) {
              if (!value || value === '' || value.length === 0) result = true;
            }
          });
        }

        break;
      case 3:
        Object.entries(tableData).forEach(([key, value]) => {
          if (value?.pickUpQuestions) {
            Object.entries(value?.pickUpQuestions).forEach(([pickKey, pickValue]) => {
              pickupQuestion = pickupQuestion.concat(pickValue);
            });
          }
        });
        if (pickupQuestion.length === 0) return true;
        break;
      case 2:
        if (!totalQuesTypeData?.questionCount || totalQuesTypeData.questionCount === 0) return true;
        break;
      case 1:
        Object.entries(areaParams).forEach(([key, value]) => {
          if (!value || value.length === 0) result = true;
        });
        break;
      default:
        break;
    }
    return result;
  }, [step, areaParams, tableData, createExamPaperParams, totalQuesTypeData]);


  const isChangeData = useMemo(() => {
    let result = false;
    if (step === 1) {
      if (Object.keys(saveData.areaParams).length === 0 || Object.keys(areaParams).length === 0) return false;
      Object.entries(areaParams).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          if (saveData.areaParams[key].length === value.length) {
            if (saveData.areaParams[key].filter(item => !value.includes(item)).length > 0) result = true;
          } else {
            result = true;
          }
        } else {
          if (saveData.areaParams[key] !== value) result = true;
        }
      });
    }
    // else if (step === 2) {
    //   if (Object.keys(saveData.tableData).length === 0 || Object.keys(tableData).length === 0) return false;
    //   let questionList = [];
    //   let saveQuestionList = [];
    //   Object.entries(tableData).forEach(([key, value], index) => {
    //     const quesTypeData = formateQuestionList(value.pickUpQuestions, questions);
    //     if (quesTypeData.length > 0) {
    //       questionList = questionList.concat(quesTypeData);
    //     }
    //   });
    //   Object.entries(saveData.tableData).forEach(([key, value], index) => {
    //     const quesTypeData = formateQuestionList(value.pickUpQuestions, questions);
    //     if (quesTypeData.length > 0) {
    //       saveQuestionList = saveQuestionList.concat(quesTypeData);
    //     }
    //   });
    //   if (questionList.length === saveQuestionList.length) {
    //     if (questionList.filter(item => !saveQuestionList.includes(item)).length > 0) result = true;
    //   } else {
    //     result = true;
    //   }
    // }

    return result;
  }, [areaParams, step, tableData]);

  const getSendAreaParams = (data) => {
    setAreaParams({ ...data });
  };
  const getTableData = (data) => {
    setTableData({ ...data });
  };
  const getKeyList = (data) => {
    setKeyList([...data]);
  };
  const getQuestions = (data) => {
    setQuestions([...data]);
  };
  const getCreateExamPaperParams = (data) => {
    setCreateExamPaperParams(data);
  };
  const getCheckedSend = (data) => {
    setCheckedSend(data);
  };
  const getSaveData = (key, data) => {
    setSaveData({ ...saveData, [key]: data });
  };
  const prevStep = () => {
    setStep(step - 1);
    goTop();
  };

  const onStepClick = (current) => {
    GA.clickStep(steps[current].title);
    setIsGoBack(true);
    setStep(current + 1);
  };

  const resetData = () => {
    setSaveData({ ...initSaveData });
    setTableData({});
    setQuestions([]);
    setCreateExamPaperParams({});
    setKeyList([]);
  };

  const questionGroups = useMemo(() => {
    if (!tableData) return;
    let nextData = keyList.map(key => {
      let questionList = [];
      if (tableData[key]?.pickUpQuestions) {
        Object.values(tableData[key].pickUpQuestions).forEach(questions => {
          questions.forEach(question => {
            questionList = [...questionList, question.uid];
          });
        });
      }
      return ({
        typeCode: tableData[key].code,
        scoreType: tableData[key].inputValue.score.perQues ? 'PerQuestion' : 'PerAnswer',
        score: tableData[key].inputValue.score.perQues ? Number(Number(tableData[key].inputValue.score.perQues).toFixed(1)) || 0 : Number(Number(tableData[key].inputValue.score.perAns).toFixed(1)) || 0,
        questionList
      });
    }).filter(item => item.questionList.length > 0);
    return nextData;
  }, [tableData, keyList]);

  // 組成試卷
  const doExportExam = (examUID) => {
    if (!examUID) return;
    if (sendParams.outputType !== 'Online') {
      let typesetting = {};
      const {
        schoolName = {},
        paperName,
        teacherSign = {},
        grade = {},
        room = {},
        paperSize,
        wordSetting,
        paperContents,
        analyzeContent,
        amount,
        advanced,
        studentSign,
        eduSubject,
        outputType,
        ordering,
        header,
      } = sendParams || {};

      typesetting = {
        schoolName: checkedSend.findIndex(item => item === 'schoolName') !== -1 ? schoolName.value : '',
        paperName: paperName.value,
        teacherSign: checkedSend.findIndex(item => item === 'teacherSign') !== -1 ? teacherSign.value : '',
        grade: checkedSend.findIndex(item => item === 'classInfo') !== -1 ? grade.value : '',
        room: checkedSend.findIndex(item => item === 'classInfo') !== -1 ? room.value : '',
        eduSubject: checkedSend.findIndex(item => item === 'eduSubject') !== -1 ? eduSubject : '',
        studentSign: checkedSend.findIndex(item => item === 'studentSign') !== -1 ? studentSign : '',
        paperSize,
        wordSetting,
        paperContents: paperContents || [],
        analyzeContent: analyzeContent || [],
        amount,
        advanced: advanced || [],
        ordering,
        header,
      };

      // // 儲存常用設定
      // sendUserSetting(FETCH_DATA_EVENT.FETCH, {
      //   payload: typesetting
      // });

      switch (processType) {
        case 'Files':
          // 組成試卷 - 紙本
          sendPostExportPaper(FETCH_DATA_EVENT.FETCH, {
            payload: {
              examUID,
              outputType,
              typesetting,
            }
          });
          break;
        case 'Online':
        case 'Quick':
          // 組成試卷 - 速測
          sendPostExportPaper(FETCH_DATA_EVENT.FETCH, {
            payload: {
              examUID,
              outputType,
              typesetting: {
                paperName: paperName.value,
                paperSize: 'A4',
                wordSetting: (globalExamPaperData?.edu === 'H') ? 'HHD' : 'VHD',
                paperContents: ['Question', 'Analyze'],
                analyzeContent: ['Question', 'Answer', 'Analyze', 'Difficulty', 'Source', 'Knowledge'],
                amount: 1,
                ordering: ['schoolName', 'paperName', 'teacherSign', 'classInfo', 'eduSubject', 'studentSign'],
                header: paperName.value,
              },
            }
          });
          break;
        default:
          break;
      }
    } else {
      // 線上測驗
      // const {
      //   advanced,
      //   outputType,
      // } = sendParams || {};

      // sendPostExportPaper(FETCH_DATA_EVENT.FETCH, {
      //   payload: {
      //     examUID,
      //     outputType,
      //     onlineSetting: {
      //       advanced,
      //     }
      //   }
      // });
    }
  };

  const eduSubjectData = useMemo(() => {
    return isEditCopy ? editCopyExamPaperData : globalExamPaperData;
  }, [isEditCopy, editCopyExamPaperData, globalExamPaperData]);

  const sendStep4Api = () => {
    if (isEditCopy && editCopyExamPaperData?.actionType === 'Edit') {
      sendPostExamEdit(FETCH_DATA_EVENT.FETCH, {
        payload: {
          examUID: editCopyExamPaperData.paperData.uid,
          examName: sendParams.paperName.value,
          outputType: sendParams.outputType,
          drawUp: 'KnowledgePattern',
          // examType: sendParams.examType,
          isPublic: false,
          questionGroups,
        }
      });
    } else {
      const typeName = isLanguageSubject ? 'categories' : 'knowledgeIDs';
      const key = isLanguageSubject ? 'categories' : 'knowledges';

      sendPostExamCreate(FETCH_DATA_EVENT.FETCH, {
        payload: {
          eduSubject: searchParams.eduSubject,
          examName: sendParams.paperName.value,
          searchCondition: {
            eduSubject: searchParams.eduSubject,
            bookIDs: isEditCopy ? reCreateSearchParams.books : searchParams.books,
            [typeName]: isEditCopy ? reCreateSearchParams[key] : searchParams[key],
            sourceIDs: isEditCopy ? reCreateSearchParams.sources : searchParams.sources,
            curriculum: isEditCopy ? reCreateSearchParams.curriculum : searchParams.curriculum,
            year: isEditCopy ? reCreateSearchParams.year : searchParams.year,
            version: isEditCopy ? reCreateSearchParams.version : searchParams.version,
            topicSelection: selectedKnowledge,
          },
          // examType: sendParams.examType,
          outputType: sendParams.outputType,
          drawUp: 'KnowledgePattern',
          questionGroups,
          paperFilter: paperFilter || [],
        }
      });
    }
  };

  const nextStep = (jumpTo = null) => {
    setJumpStep(jumpTo);
    switch (step) {
      case 4:
        sendStep4Api();
        break;
      case 1:
        if (isChangeData) {
          setSaveData({
            ...initSaveData,
            areaParams,
            education: globalExamPaperData?.edu || 'J',
            subject: globalExamPaperData?.subject || 'GE',
            bookOptions: saveData.bookOptions,
          });
          setTableData({});
          // setTotalQuesTypeData({});
          setQuestions([]);
          setCreateExamPaperParams({});
          setKeyList([]);
        } else {
          setSaveData({
            ...saveData,
            areaParams,
            education: globalExamPaperData?.edu || 'J',
            subject: globalExamPaperData?.subject || 'GE',
          });
        }

        sendGetQuestionType(FETCH_DATA_EVENT.FETCH, {
          payload: {
            eduSubject: globalExamPaperData?.edu + globalExamPaperData?.subject,
            [isLanguageSubject ? 'categories' : 'knowledges']: areaParams.keys,
            sources: areaParams.source,
            paperConfig: {
              outputStyle: processType,
              drawUpStyle: 'KnowledgePattern'
            },
          }
        });

        break;

      default:
        if (jumpTo !== null) {
          setStep(jumpTo);
        } else {
          setStep(step + 1);
        }

        goTop();
        break;
    }
  };

  useEffect(() => {
    if (!data) return;
    if (Object.keys(tableData).length > 0) return;
    setIsLoading(true);
    const nextData = formateTableData(data);
    getTableData(nextData);
    setIsJumpToStep2(true);
  }, [data]);

  useEffect(() => {
    if (isEditCopy && isJumpToStep2) {
      // 設置一秒延遲，避免跳轉太快，父層資料尚未傳給子層。
      setTimeout(() => {
        setStep(2);
      }, 1000);
    }
  }, [isEditCopy, isJumpToStep2]);

  useEffect(() => {
    if (stateGetQuestionType.matches(FETCH_DATA_STATE.DONE) && step === 1) {
      if (!data || JSON.stringify(data) === '{}') {
        setEmptyTextModalToggle();
        return null;
      } else {
        if (!isEditCopy) setStep(step + 1);
      }
    }
  }, [stateGetQuestionType.value]);

  useEffect(() => {
    if (saveData.education === '' || saveData.subject === '') return;
    if (globalExamPaperData?.edu === saveData.education && globalExamPaperData?.subject === saveData.subject) return;
    resetData();
    setStep(1);
  }, [globalExamPaperData]);

  useEffect(() => {
    if (Object.keys(tableData).length > 0 && getCookie('createNewPaper') === null) {
      setCookie('createNewPaper', true, 1);
    }
  }, [tableData]);

  // 編輯 複製 相關邏輯 ＝＝＝＝＝＝
  const reCreateSearchParams = useMemo(() => {
    if (!editCopyExamPaperData || JSON.stringify(editCopyExamPaperData) === '{}') return {};
    const {
      eduSubject,
      knowledgeIDs,
      categoryIDs,
      curriculum,
      year,
      version,
      outputType,
      topicSelection,
    } = editCopyExamPaperData;

    const subject = eduSubject.slice(1, 3);
    const isLanguage = (subject === 'PC' || subject === 'EN' || subject === 'EW') ? true : false;
    const isQuick = outputType === 'Quick';
    const typeKey = isLanguage ? 'categories' : 'knowledges';

    return {
      eduSubject,
      curriculum,
      year,
      version,
      isQuick,
      topicSelection,
      [typeKey]: isLanguage ? categoryIDs : knowledgeIDs,
    };
  }, [editCopyExamPaperData]);

  useEffect(() => {
    if (
      JSON.stringify(reCreateSearchParams) === '{}'
      || !editCopyExamPaperData
      || JSON.stringify(editCopyExamPaperData) === '{}'
    ) return;

    const payload = { ...reCreateSearchParams };
    delete payload?.topicSelection;
    sendGetQuestionType(FETCH_DATA_EVENT.FETCH, {
      payload: payload,
    });
  }, [editCopyExamPaperData, reCreateSearchParams]);

  // 編輯 複製 相關邏輯 ＝＝＝＝＝＝

  useEffect(() => {
    Object.entries(globalExamPaperData).forEach(([key, value]) => {
      if ((key === 'edu' || key === 'subject') && value === '') {
        if (!editCopyExamPaperData[key]) {
          history.push('./');
        }
      }
    });
  }, []);

  useEffect(() => {
    if (processType === 'Online') {
      if (statePostExamCreate.matches(FETCH_DATA_STATE.DONE)) {
        setStep(step + 1);
      }
    } else {
      if (statePostExportPaper.matches(FETCH_DATA_STATE.DONE)) {
        setStep(step + 1);
      }
    }
  }, [statePostExamCreate.value, statePostExportPaper]);

  useEffect(() => {
    if (authState.context.userDetails?.userStatus?.identity === 'Student') {
      history.push('/');
    }
  }, []);

  const identity = useMemo(() => {
    return authState?.context?.userDetails?.onePaperIdentity || '';
  }, [authState]);

  const prevStepDisabled = useMemo(() => {
    if (!editCopyExamPaperData?.actionType) return false;
    if ((editCopyExamPaperData?.actionType === 'Edit' || editCopyExamPaperData?.actionType === 'Copy') && step <= 2) return true;
  }, [editCopyExamPaperData?.actionType, step]);

  // 再次出題
  useEffect(() => {
    if (reCreateExam) {
      initAllData();
    }
  }, [reCreateExam]);

  const initAllData = () => {
    setSaveData({ ...initSaveData });
    setResetToggle(false);
    setAreaParams({});
    setSendParams({});
    setQuestions([]);
    setTableData({});
    setKeyList([]);
    setSearchKey('');
    setCreateExamPaperParams({});
    setCheckedSend([]);
    setJumpStep(null);
    setTreeCheckedList([]);
  };

  useEffect(() => {
    const subject = editCopyExamPaperData?.subject || globalExamPaperData?.subject;
    if (subject === 'PC' || subject === 'EN' || subject === 'EW') setIsLanguageSubject(true);
  }, [globalExamPaperData.subject, editCopyExamPaperData.subject]);

  const nextStepClickHandler = () => {
    // 確認是否有超過 出題上限。
    if (currentQuesNum > questionNumLimit && step >= 2) {
      setQuestionCountModalToggle();
      setIsShowQuestionLimitText(true);
    } else {
      if (isChangeData) {
        if (step === 1) {
          setAreaModalToggle();
        }
      } else {
        nextStep();
      }
    }
  };

  // 題數 上限
  // const questionNumLimit = useMemo(() => {
  //   const type = createExamPaperParams?.outputType || globalExamPaperData.output;
  //   let num = 300;
  //   if (type === 'Files') num = 500;
  //   return num;
  // }, [globalExamPaperData.output, createExamPaperParams.outputType]);

  useEffect(() => {
    setIsShowQuestionLimitText(false);
  }, [totalQuesTypeData.questionCount]);

  // 已選取的範圍。
  const selectedKnowledge = useMemo(() => {
    if (!editCopyExamPaperData || JSON.stringify(editCopyExamPaperData) === '{}') {
      // 非編輯
      if (!areaParams?.version || !areaParams?.year || !areaParams?.curriculum) return {};
      if (!knowledgeList || JSON.stringify(knowledgeList) === '{}' || treeCheckedList.length === 0) return {};
      const filterKnowledgeList = { ...knowledgeList[areaParams?.curriculum][areaParams?.year][areaParams?.version] };
      return formatSelectedKnowledge(filterKnowledgeList, treeCheckedList);
    } else {
      // 編輯時。
      const { topicSelection, bookSelection, } = editCopyExamPaperData;
      return topicSelection ? topicSelection : bookSelection;
    }
  }, [knowledgeList, areaParams?.version, areaParams?.year, areaParams?.curriculum, treeCheckedList]);

  useEffect(() => {
    switch (step) {
      case 4:
        // 每次進入版面配置頁面，皆要檢查是否有進行配分，若沒有 則進行自動配分。
        if (totalQuesTypeData?.score === 0) {
          setTableData(autoSettingScore(tableData, keyList, totalQuesTypeData?.totalAnswer));
        }
        break;

      default:
        break;
    }
  }, [step]);

  return (
    <StyledKnowledgePropositionPage className={className} data-testid="KnowledgePropositionPage">
      <ExamPaperHeader pageName={t('views.knowledgePropositionPage.__knowledgeDimensionProposition')} globalExamPaperData={eduSubjectData} step={step} />
      {
        deviceData.isDesktop ?
          <StepBox isEditPaper={editCopyExamPaperData?.actionType} steps={steps} current={step - 1} onChange={onStepClick} stepdisabled={step === 5 ? true : false} type={'KnowledgePattern'}>
            {
              (step === 1 && Object.keys(tableData).length > 0) && !isEditCopy &&
              <div className='warningBar'><ExclamationCircleSolid />{t('views.knowledgePropositionPage.__warningBarMessage')}</div>
            }

            {
              (step === 2 && isShowQuestionLimitText) &&
              <QuestionLimitHint
                currentQuesNum={currentQuesNum}
                processType={processType}
                questionNumLimit={questionNumLimit}
              />
            }

            {/* {
              (step === 2 && saveData?.disabled && saveData?.disabled.length > 0) &&
              <div className='warningBar'>
                <div><ExclamationCircleSolid/>原試卷已選{editCopyQuestionCount}題試題，其中{disabledQuestionCount}題已被停用，將不會再顯示於試卷中：</div>
                <ul>
                  {
                    saveData?.disabled.map(item => (
                      <li>知識向度「{item.name}」 {item.amount} 題</li>
                    ))
                  }
                </ul>
              </div>
            } */}
            <StyledKnowledgePropositionContent notLimitHeight={(step === 1 || step === 2 || step === 3 || step === 4) ? true : false}>
              {
                step === 1 &&

                <KnowledgeAreaSelectContent
                  examPaperType='KnowledgePattern'
                  resetToggle={resetToggle}
                  memberEduSubject={globalExamPaperData}
                  saveData={saveData}
                  getSaveData={getSaveData}
                  getSendAreaParams={getSendAreaParams}
                  getKeyList={getKeyList}
                  getTableData={getTableData}
                  totalQuesTypeData={totalQuesTypeData}
                  identity={identity}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  isEditCopy={isEditCopy}
                  setIsLanguageSubject={setIsLanguageSubject}
                  knowledgeList={knowledgeList}
                  setKnowledgeList={setKnowledgeList}
                  treeCheckedList={treeCheckedList}
                  setTreeCheckedList={setTreeCheckedList}
                  globalExamPaperData={globalExamPaperData}
                />

              }
              {
                step === 2 && JSON.stringify(tableData) !== '{}' &&
                <KnowledgeQuestionSelectContent
                  examPaperInfo={data}
                  tableData={tableData}
                  setTableData={setTableData}
                  searchKey={searchKey}
                  searchParams={searchParams}
                  examPaperTotalScoreData={totalQuesTypeData}
                  prevKeyList={keyList}
                  memberEduSubject={eduSubjectData}
                  saveData={saveData}
                  getSaveData={getSaveData}
                  totalQuesTypeData={totalQuesTypeData}
                  getQuestions={getQuestions}
                  getKeyList={getKeyList}
                  globalExamPaperData={globalExamPaperData}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  isEditCopy={isEditCopy}
                  reCreateSearchParams={reCreateSearchParams}
                  editCopyExamPaperData={editCopyExamPaperData}
                  isLanguageSubject={isLanguageSubject}
                  filterPaperUID={filterPaperUID}
                  renderFilterQuestionModal={renderFilterQuestionModal}
                  filterQuestionModalToggle={filterQuestionModalToggle}
                  setFilterQuestionModalFalse={setFilterQuestionModalFalse}
                  isChangeFilter={isChangeFilter}
                  setIsChangeFilter={setIsChangeFilter}
                  filterQuestions={filterQuestions}
                  selectedKnowledge={selectedKnowledge}
                />
              }
              {/* {
                step === 3 &&
                <KnowledgeQuestionReviewContent
                  examPaperInfo={data}
                  tableData={tableData}
                  setTableData={setTableData}
                  searchKey={searchKey}
                  searchParams={searchParams}
                  examPaperTotalScoreData={totalQuesTypeData}
                  prevKeyList={keyList}
                  questions={questions}
                  saveData={saveData}
                  getSaveData={getSaveData}
                  getKeyList={getKeyList}
                  totalQuesTypeData={totalQuesTypeData}
                  memberEduSubject={eduSubjectData}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  eduSubjectData={eduSubjectData}
                />
              } */}
              {
                step === 3 &&
                <ReviewPage
                  examPaperInfo={data}
                  tableData={tableData}
                  setTableData={setTableData}
                  searchKey={searchKey}
                  searchParams={searchParams}
                  examPaperTotalScoreData={totalQuesTypeData}
                  prevKeyList={keyList}
                  questions={questions}
                  saveData={saveData}
                  getSaveData={getSaveData}
                  getKeyList={getKeyList}
                  totalQuesTypeData={totalQuesTypeData}
                  memberEduSubject={eduSubjectData}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  eduSubjectData={eduSubjectData}
                />
              }
              {
                step === 4 &&
                <KnowledgeLayoutOptionContent
                  searchKey={searchKey}
                  keyList={keyList}
                  examPaperTotalScoreData={totalQuesTypeData}
                  saveData={saveData}
                  globalExamPaperData={globalExamPaperData}
                  eduSubjectData={eduSubjectData}
                  getCreateExamPaperParams={getCreateExamPaperParams}
                  getCheckedSend={getCheckedSend}
                  tableData={tableData}
                  setTableData={setTableData}
                  getSaveData={getSaveData}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  areaParams={areaParams}
                  setOutSideSendParams={getSendParams}
                  totalQuesTypeData={totalQuesTypeData}
                  searchParams={searchParams}
                  isEditCopy={isEditCopy}
                  editCopyExamPaperData={editCopyExamPaperData}
                />
              }
              {
                step === 5 &&
                <HomeQuestionCompleteContent
                  onlineExamUID={createExamPaperParams?.examUID}
                  examUID={createExamPaperParams?.examUID}
                  UID={exportPaperData?.uid}
                  outputType={createExamPaperParams?.outputType}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  sendParams={sendParams}
                  setStep={setStep}
                  setReCreateExam={setReCreateExam}
                  examPaperTotalScoreData={totalQuesTypeData}
                  eduSubjectData={eduSubjectData}
                  setIsAgainDo={setIsAgainDo}
                  step={step}
                />
              }
            </StyledKnowledgePropositionContent>

            <UiBtnZone>
              {
                step !== 1 && step !== 5 &&
                <Button
                  disabled={prevStepDisabled}
                  type={prevStepDisabled ? 'default' : 'solid'}
                  textcolor={prevStepDisabled ? '#D5D7DE' : '#A1A4B1'}
                  variant={prevStepDisabled ? 'ui02' : 'brand1'}
                  size='large'
                  isFull={false}
                  onClick={() => { prevStep(); setIsGoBack(true); }}>
                  {t('views.knowledgePropositionPage.__previousStep')}
                </Button>
              }
              {
                step !== 5 &&
                <Button
                  disabled={disabled}
                  variant={disabled ? 'ui02' : 'brand1'}
                  size='large'
                  isFull={false}
                  onClick={() => { nextStepClickHandler(); }}
                >
                  {
                    step !== 4 ?
                      t('views.knowledgePropositionPage.__nextStep') :
                      t('views.knowledgePropositionPage.__exportExamPaper')
                  }
                </Button>
              }
            </UiBtnZone>
          </StepBox> :
          <MobileStepBox steps={steps} current={step - 1} onChange={onStepClick} stepdisabled={step === 5 ? true : false} type={'KnowledgePattern'}>
            {
              (step === 1 && Object.keys(tableData).length > 0) &&
              <div className='warningBar'><ExclamationCircleSolid />{t('views.knowledgePropositionPage.__changeSelectionWarning')}</div>
            }

            {
              (step === 2 && isShowQuestionLimitText) &&
              <QuestionLimitHint
                currentQuesNum={currentQuesNum}
                processType={processType}
                questionNumLimit={questionNumLimit}
              />
            }

            {/* {
              (step === 2 && saveData?.disabled && saveData?.disabled.length > 0) &&
              <div className='warningBar'>
                <div><ExclamationCircleSolid/>原試卷已選{editCopyQuestionCount}題試題，其中{disabledQuestionCount}題已被停用，將不會再顯示於試卷中：</div>
                <ul>
                  {
                    saveData?.disabled.map(item => (
                      <li>知識向度「{item.name}」 {item.amount} 題</li>
                    ))
                  }
                </ul>
              </div>
            } */}
            <StyledKnowledgePropositionContent notLimitHeight={(step === 1 || step === 2 || step === 3 || step === 4) ? true : false}>
              {
                step === 1 &&

                <KnowledgeAreaSelectContent
                  examPaperType='KnowledgePattern'
                  resetToggle={resetToggle}
                  memberEduSubject={globalExamPaperData}
                  saveData={saveData}
                  getSaveData={getSaveData}
                  getSendAreaParams={getSendAreaParams}
                  getKeyList={getKeyList}
                  totalQuesTypeData={totalQuesTypeData}
                  identity={identity}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  isEditCopy={isEditCopy}
                  setIsLanguageSubject={setIsLanguageSubject}
                  knowledgeList={knowledgeList}
                  setKnowledgeList={setKnowledgeList}
                  treeCheckedList={treeCheckedList}
                  setTreeCheckedList={setTreeCheckedList}
                  globalExamPaperData={globalExamPaperData}
                />

              }
              {
                step === 2 && JSON.stringify(tableData) !== '{}' &&
                <KnowledgeQuestionSelectContent
                  examPaperInfo={data}
                  tableData={tableData}
                  setTableData={setTableData}
                  searchKey={searchKey}
                  searchParams={searchParams}
                  examPaperTotalScoreData={totalQuesTypeData}
                  prevKeyList={keyList}
                  memberEduSubject={eduSubjectData}
                  saveData={saveData}
                  getSaveData={getSaveData}
                  totalQuesTypeData={totalQuesTypeData}
                  getQuestions={getQuestions}
                  getKeyList={getKeyList}
                  globalExamPaperData={globalExamPaperData}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  isEditCopy={isEditCopy}
                  reCreateSearchParams={reCreateSearchParams}
                  editCopyExamPaperData={editCopyExamPaperData}
                  isLanguageSubject={isLanguageSubject}
                  filterPaperUID={filterPaperUID}
                  renderFilterQuestionModal={renderFilterQuestionModal}
                  filterQuestionModalToggle={filterQuestionModalToggle}
                  setFilterQuestionModalFalse={setFilterQuestionModalFalse}
                  isChangeFilter={isChangeFilter}
                  setIsChangeFilter={setIsChangeFilter}
                  filterQuestions={filterQuestions}
                  selectedKnowledge={selectedKnowledge}
                />
              }
              {/* {
                step === 3 &&
                <KnowledgeQuestionReviewContent
                  examPaperInfo={data}
                  tableData={tableData}
                  setTableData={setTableData}
                  searchKey={searchKey}
                  searchParams={searchParams}
                  examPaperTotalScoreData={totalQuesTypeData}
                  prevKeyList={keyList}
                  questions={questions}
                  saveData={saveData}
                  memberEduSubject={eduSubjectData}
                  getSaveData={getSaveData}
                  getKeyList={getKeyList}
                  totalQuesTypeData={totalQuesTypeData}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  eduSubjectData={eduSubjectData}
                />
              } */}
              {
                step === 3 &&
                <ReviewPage
                  examPaperInfo={data}
                  tableData={tableData}
                  setTableData={setTableData}
                  searchKey={searchKey}
                  searchParams={searchParams}
                  examPaperTotalScoreData={totalQuesTypeData}
                  prevKeyList={keyList}
                  questions={questions}
                  saveData={saveData}
                  getSaveData={getSaveData}
                  getKeyList={getKeyList}
                  totalQuesTypeData={totalQuesTypeData}
                  memberEduSubject={eduSubjectData}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  eduSubjectData={eduSubjectData}
                />
              }
              {
                step === 4 &&
                <KnowledgeLayoutOptionContent
                  searchKey={searchKey}
                  keyList={keyList}
                  tableData={tableData}
                  setTableData={setTableData}
                  examPaperTotalScoreData={totalQuesTypeData}
                  saveData={saveData}
                  globalExamPaperData={globalExamPaperData}
                  eduSubjectData={eduSubjectData}
                  getCreateExamPaperParams={getCreateExamPaperParams}
                  getCheckedSend={getCheckedSend}
                  getSaveData={getSaveData}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  areaParams={areaParams}
                  setOutSideSendParams={getSendParams}
                  totalQuesTypeData={totalQuesTypeData}
                  searchParams={searchParams}
                  isEditCopy={isEditCopy}
                  editCopyExamPaperData={editCopyExamPaperData}
                />
              }
              {
                step === 5 &&
                <HomeQuestionCompleteContent
                  onlineExamUID={createExamPaperParams?.examUID}
                  examUID={createExamPaperParams?.examUID}
                  UID={exportPaperData?.uid}
                  outputType={createExamPaperParams?.outputType}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  sendParams={sendParams}
                  setStep={setStep}
                  setReCreateExam={setReCreateExam}
                  examPaperTotalScoreData={totalQuesTypeData}
                  eduSubjectData={eduSubjectData}
                  setIsAgainDo={setIsAgainDo}
                  step={step}
                />
              }
            </StyledKnowledgePropositionContent>

            <UiBtnZone>
              {
                step !== 1 && step !== 5 &&
                <Button
                  className={'prevStepBtn'}
                  disabled={prevStepDisabled}
                  type={prevStepDisabled ? 'default' : 'solid'}
                  textcolor={prevStepDisabled ? '#D5D7DE' : '#A1A4B1'}
                  variant={prevStepDisabled ? 'ui02' : 'brand1'}
                  size='large'
                  isFull={false}
                  onClick={() => { prevStep(); setIsGoBack(true); }}>
                  {t('views.knowledgePropositionPage.__previousStep')}
                </Button>
              }
              {
                step !== 5 &&
                <Button
                  disabled={disabled}
                  variant={disabled ? 'ui02' : 'brand1'}
                  size='large'
                  isFull={false}
                  onClick={() => { nextStepClickHandler(); }}
                >
                  {
                    step !== 4 ?
                      t('views.knowledgePropositionPage.__nextStep') :
                      t('views.knowledgePropositionPage.__exportExamPaper')
                  }
                </Button>
              }
            </UiBtnZone>
          </MobileStepBox>
      }
      <Modal
        visible={areaModalVisible}
        onCancel={setAreaModalFalse}
        className={'mobileModal'}
        title={
          <div>{t('views.knowledgePropositionPage.__confirmChangeArea')}</div>
        }
        footer={
          <div className="footerBtn footerBtnBox">
            <Button variant='ui01' onClick={() => { getSendAreaParams(saveData.areaParams); setAreaModalFalse(); setResetToggle(!resetToggle); }}>{t('views.knowledgePropositionPage.__cancelChange')}</Button>
            <Button variant={'brand1'} onClick={() => { nextStep(); setAreaModalFalse(); setIsGoBack(false); }}>{t('views.knowledgePropositionPage.__confirmChange')}</Button>
          </div>
        }
      >
        <div className="modalContent">
          <div>{t('views.knowledgePropositionPage.__changeAreaAfterWarning')}</div>
          <div>{t('views.knowledgePropositionPage.__confirmChangeAreaMessage')}</div>
          <div>{t('views.knowledgePropositionPage.__useOriginalAreaMessage')}</div>
        </div>
      </Modal>

      <Modal
        visible={emptyTextModalVisible}
        onCancel={setEmptyTextModalFalse}
        className={'mobileModal'}
        id={'emptyTextModal'}
        title={
          <div>{t('views.knowledgePropositionPage.__noAvailableQuestions')}</div>
        }
        footer={
          <div className="footerBtn footerBtnBox">
            <Button
              variant={'brand1'}
              onClick={setEmptyTextModalFalse}
            >
              {t('views.knowledgePropositionPage.__confirm')}
            </Button>
          </div>
        }
      >
        <div>{t('views.knowledgePropositionPage.__noAvailableQuestionsMessage')}</div>
      </Modal>

      <QuestionLimitModal
        visible={questionCountModalVisible}
        onCancel={setQuestionCountModalFalse}
        currentQuesNum={currentQuesNum}
        processType={processType}
        questionNumLimit={questionNumLimit}
      />

    </StyledKnowledgePropositionPage>
  );
};