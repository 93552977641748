import { ChevronLeftSolid } from '@onedesign/icon';
import {
  StyledBackToList,
  StyledContainer,
  StyledCreatePaperPackage,
  StyledFilter,
  StyledInputRow,
  StyledStep,
  StyledSubTitle,
  StyledTitle,
  StyledSelected,
  StyledChoosePaperTitle,
  StyledFirstTitle
} from './CreatePaperPackage.style';
import { useHistory } from 'react-router-dom';
import {
  Button,
  FETCH_DATA_EVENT,
  Input,
  Pagination,
  Select,
  Table,
  fetchDataMachine,
  setSelectOptions,
  sliceTableData,
} from '@oneclass/ui-components';
import { useMachine } from '@xstate/react';
import { useLoadingContent } from 'utils/hooks/useLoadingContent';
import { getQueryExamPaper } from 'api/examPaper';
import { useContext, useEffect, useMemo, useState } from 'react';
import deviceProvider from 'providers/deviceProvider';
import { useBoolean } from 'ahooks';
import { format, getTime } from 'date-fns';
import { Checkbox } from 'antd';
import { createPaperPackage } from 'api/examPaper';
import behaviorProvider from 'providers/behaviorProvider';
import { useTranslation } from 'react-i18next';

export const CreatePaperPackage = () => {
  const history = useHistory();
  const { renderLoadingContent, isLoading, setIsLoading } = useLoadingContent();
  const { behaviorDataProviderChange } = useContext(
    behaviorProvider.behaviorProviderContext
  );
  const { deviceData } = useContext(deviceProvider.deviceProviderContext);
  const [modalInfo, setModalInfo] = useState(null);
  const [
    modalVisible,
    { toggle: setModalVisibleToggle, setFalse: setModalVisibleFalse },
  ] = useBoolean(false);
  const [pageState, setPageState] = useState({
    currentPage: 1,
    limit: 10,
  });
  const [packageName, setPackageName] = useState('');
  const [checkedUids, setCheckedUids] = useState([]);
  const [filterParams, setFilterParams] = useState({ eduSubject: null });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  // useEffect(() => {
  //   setPageState({
  //     currentPage: 1,
  //     limit: 10,
  //   });
  // }, [filterParams]);

  const [stateGetQueryExamPaper, sendGetQueryExamPaper] = useMachine(
    fetchDataMachine,
    {
      services: {
        fetchData: async (_context, event) => {
          setIsLoading(true);
          const res = await getQueryExamPaper();
          const { data, isSuccess, systemCode, message } = res;
          const { examPaper = [], eduSubjectMap = [] } = data || {};
          setIsLoading(false);
          return {
            isSuccess,
            systemCode,
            message,
            examPaper,
            eduSubjectOptions: setSelectOptions(eduSubjectMap) || [],
          };
        },
      },
    }
  );
  const { examPaper = [], eduSubjectOptions } =
    stateGetQueryExamPaper.context.result || {};

  const backToList = () => {
    behaviorDataProviderChange('goToPackageList', true);
    history.push('/examPaperList');
  };

  const [stateCreatePackage, sendCreatePackage] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        setIsSubmitting(true);
        try {
          const res = await createPaperPackage(event.payload);
          const { isSuccess, systemCode, message } = res;
          isSuccess && backToList();
          return {
            isSuccess,
            systemCode,
            message,
          };
        } catch (error) {
          return {
            isSuccess: false,
            message: error.data.message,
          };
        } finally {
          setIsSubmitting(false);
        }
      },
    },
  });
  const { message: errorMessage } = stateCreatePackage.context.result || {};

  const filterData = useMemo(
    () =>
      examPaper.filter(({ attributes }) => {
        if (Object.values(filterParams).every((filter) => !filter)) {
          return true;
        }
        if (filterParams.eduSubject) {
          return (
            `${attributes.education}${attributes.subject}` ===
            filterParams.eduSubject
          );
        }
        return false;
      }),
    [examPaper, filterParams]
  );

  const pageChange = (currentPage, pageSize) => {
    setPageState({
      ...pageState,
      currentPage,
      limit: pageSize,
    });
  };

  const showDetailModal = (data) => {
    if (!data) return;
    setModalInfo(data);
    setModalVisibleToggle();
  };

  const handleCheck = (uid) => {
    setCheckedUids((prev) =>
      prev.includes(uid)
        ? prev.filter((uid_) => uid_ !== uid)
        : prev.concat(uid)
    );
  };

  const handleSubmit = () => {
    const payload = {
      name: packageName,
      sharedPapers: examPaper
        .filter(({ uid }) => checkedUids.includes(uid))
        .map(({ uid, name }) => ({ paperId: uid, name })),
    };
    sendCreatePackage(FETCH_DATA_EVENT.FETCH, {
      payload,
    });
  };

  useEffect(() => {
    sendGetQueryExamPaper(FETCH_DATA_EVENT.FETCH);
  }, []);

  useEffect(() => {
    if (errorMessage) {
      alert(errorMessage);
    }
  }, [errorMessage]);

  // 詳情彈窗開啟時 關閉背景滑動
  useEffect(() => {
    document.body.classList.remove('mobileScrollingStop');
    if (!deviceData.isDesktop) {
      if (modalVisible) {
        document.body.classList.add('mobileScrollingStop');
      }
    }
  }, [modalVisible]);

  return (
    <StyledCreatePaperPackage>
      <StyledBackToList onClick={backToList}>
        <ChevronLeftSolid />
        <span>{t('views.createPaperPackage.__createPackageBackToList')}</span>
      </StyledBackToList>
      <StyledTitle>{t('views.createPaperPackage.__createPackageTitle')}</StyledTitle>
      <StyledContainer>
        <StyledStep>
          <StyledFirstTitle>{t('views.createPaperPackage.__createPackageStep1Title')}</StyledFirstTitle>
          <StyledInputRow>
            <div className="label">{t('views.createPaperPackage.__createPackagePackageNameLabel')}</div>
            <Input
              className="input"
              placeholder={t('views.createPaperPackage.__createPackagePackageNamePlaceholder')}
              value={packageName}
              onChange={(e) => setPackageName(e.target.value)}
            />
          </StyledInputRow>
        </StyledStep>
        <StyledStep>
          <StyledChoosePaperTitle>
            <StyledSubTitle>{t('views.createPaperPackage.__createPackageStep2Title')}</StyledSubTitle>
            <StyledSelected>{t('views.createPaperPackage.__createPackageSelectedPapers', { checkedUidsLength: checkedUids.length })}</StyledSelected>
          </StyledChoosePaperTitle>

          {isLoading ? (
            renderLoadingContent()
          ) : (
            <>
              <StyledFilter>
                <Select
                  placeholder={t('views.createPaperPackage.__createPackageFilterPlaceholder')}
                  options={eduSubjectOptions}
                  value={filterParams?.eduSubject}
                  onChange={(value) => {
                    setFilterParams({ eduSubject: value });
                  }}
                />
              </StyledFilter>
              <Table className="table">
                <Table.Header>
                  <Table.Row isFixedWidth={true} className="tableRow">
                    <Table.Item width={'32px'}>
                      <Checkbox
                        style={{ margin: '0 8px' }}
                        checked={examPaper.length === checkedUids.length}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setCheckedUids(examPaper.map(({ uid }) => uid));
                          } else {
                            setCheckedUids([]);
                          }
                        }}
                        indeterminate={
                          checkedUids.length > 0 &&
                          examPaper.length !== checkedUids.length
                        }
                      />
                    </Table.Item>
                    <Table.Item width={'624px'} className="tableItem">{t('views.createPaperPackage.__createPackageTableHeader2')}
                    </Table.Item>
                    <Table.Item width={'144px'} className="tableItem"> {t('views.createPaperPackage.__createPackageTableHeader3')}
                    </Table.Item>
                    <Table.Item width={'160px'} className="tableItem"> {t('views.createPaperPackage.__createPackageTableHeader4')}
                    </Table.Item>
                  </Table.Row>
                </Table.Header>
                <Table.Body bodyMaxHeight={true}>
                  {filterData &&
                    filterData.length > 0 &&
                    sliceTableData(
                      filterData,
                      pageState.currentPage,
                      pageState.limit
                    ).map((item) => (
                      <Table.Row
                        key={item.uid}
                        isFixedWidth={true}
                        className="tableRow"
                        onClick={() =>
                          !deviceData.isDesktop && showDetailModal(item)
                        }
                      >
                        {deviceData.isDesktop ? (
                          <>
                            <Table.Item width={'32px'}>
                              <Checkbox
                                style={{ margin: '0 8px' }}
                                checked={checkedUids.includes(item.uid)}
                                onChange={() => handleCheck(item.uid)}
                              />
                            </Table.Item>
                            <Table.Item width={'624px'} className="tableItem">
                              {item.name}
                            </Table.Item>
                            <Table.Item width={'144px'} className="tableItem">
                              {item.eduSubjectName}
                            </Table.Item>
                            <Table.Item width={'160px'} className="tableItem">
                              {format(
                                getTime(new Date(item.updateTime)),
                                'yyyy/dd/MM HH:mm'
                              )}
                            </Table.Item>
                          </>
                        ) : (
                          <>
                            <Table.Item>{item.eduSubjectName}</Table.Item>
                            <Table.Item>{item.name}</Table.Item>
                            <Table.Item>
                              {format(
                                getTime(new Date(item.updateTime)),
                                'yyyy/dd/MM HH:mm'
                              )}
                            </Table.Item>
                          </>
                        )}
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
              <Pagination
                total={filterData?.length || 0}
                defaultCurrent={1}
                current={pageState.currentPage}
                onChange={pageChange}
                defaultPageSize={pageState.limit}
              />
            </>
          )}
        </StyledStep>
      </StyledContainer>
      <Button
        className="submitButton"
        variant="brand2"
        onClick={handleSubmit}
        disabled={isSubmitting || !packageName || !checkedUids.length}
      >
        {t('views.createPaperPackage.__createPackageSubmitButton')}
      </Button>
    </StyledCreatePaperPackage>
  );
};
