export const paperAdvancedSettingsMapper = (value) => {
  const element = PAPER_ADVANCED_SETTINGS.find(e => e.name === value);
  if (!element) {
    return undefined;
  }
  return `constants.paperAdvancedSettings.${element.t_key}`;
};

export const PAPER_ADVANCED_SETTINGS = [
  {
    'code': 'Continuous',
    'name': '題號連續',
    't_key': 'continuous',
  },
  {
    'code': 'AddSpace',
    'name': '插入組距',
    't_key': 'addSpace',
  },
  {
    'code': 'ChangeOrder',
    'name': '相同題目，不同題序',
    't_key': 'changeOrder',
  },
  {
    'code': 'ChangeOption',
    'name': '相同題目，選項隨機排列',
    't_key': 'changeOption',
  }
];


/*


{
     code: 'Content',
     name: 'The content of the test question is incorrect',
     t_key: '',
   },
   {
     code: 'Answer',
     name: 'Answer is wrong',
     t_key: '',

   },
   {
     code: 'Range',
     name: 'Outside the scope of the syllabus',
     t_key: '',
   },
   {
     code: 'Suggestion',
     name: 'Test question modification suggestions',
     t_key: '',
   }
];

*/