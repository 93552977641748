/* eslint-disable react/prop-types */
import { Button } from '@oneclass/onedesign';
import { StyledFontsPage } from './FontsPage.style';
import { useTranslation } from 'react-i18next';

export const FontsPage = ({ className }) => {
  const { t } = useTranslation();

  return (
    <StyledFontsPage className={className} data-testid="FontsPage">
      <img src="/assets/downloadFolder.svg" alt="" className='download-icon' />
      <div className='title'>{t('views.fontsPage.__fontInstallationGuideTitle')}</div>
      <div className='description'>
        <p>
        {t('views.fontsPage.__fontDisplayIssue')}
        </p>
        <p className='subtitle'>
        {t('views.fontsPage.__downloadIcon')} 
        </p>
        <Button
          borderRadius="4px"
          variant={'brand1'}
          className="download-button"
        >
          <a href="https://firebasestorage.googleapis.com/v0/b/onepaper-1126a.appspot.com/o/fonts%2F南一題庫產品字型.zip?alt=media">
            <img src="/assets/downloadDisk.svg" alt="" />
            <span>
            {t('views.fontsPage.__downloadFontPackage')} 
            </span>
          </a>
        </Button>
        <p className='subtitle'>
        {t('views.fontsPage.__installInstructions')} 
        </p>
        <ol>
          <li>{t('views.fontsPage.__findDownloadedFile')}</li>
          <li>{t('views.fontsPage.__rightClickExtract')}</li>
          <li>{t('views.fontsPage.__selectFontsFiles')}</li>
          <img src="/assets/fontsPage/install.png" alt="" />
          <li>{t('views.fontsPage.__fontInstallationComplete')}</li>
        </ol>
        <p>{t('views.fontsPage.__installNotInRightClick')}</p>
        <img src="/assets/fontsPage/install2.png" alt="" />
      </div>
    </StyledFontsPage>
  );
};