import React, { useContext, useMemo, useEffect } from 'react';
import { StyledMaintenancePage } from './MaintenancePage.style';
import { getCookie } from 'utils/common';
import { default as firebaseRemoteConfigProvider } from 'providers/firebaseRemoteConfigProvider.js';
import { useHistory } from 'react-router-dom';
import { getHeartbeat } from 'api/test';
import { useMachine } from '@xstate/react';
import { fetchDataMachine, FETCH_DATA_EVENT } from '@oneclass/ui-components';
export const MaintenancePage = ({ className }) => {
  const { remoteConfigData, RemoteConfigDataProviderChange } = useContext(
    firebaseRemoteConfigProvider.firebaseRemoteConfigProviderContext
  );
  const { maintenancePage } = remoteConfigData;
  const pageTitle =
    getCookie('nani_oneclass_serviceRoot') === 'OnePaper'
      ? '南一雲端出題'
      : 'OnePaper';
  const history = useHistory();

  const configData = useMemo(() => {
    if (maintenancePage && !maintenancePage?._value) return;
    return maintenancePage && JSON.parse(maintenancePage?._value || {});
  }, [maintenancePage]);

  // 打後端 測試api 當 心跳包使用，判斷api機台 是否正常
  const [, sendGetHeartbeat] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_, event) => {
        const res = await getHeartbeat();
        if (res) {
          // 若api正常，將重新導向回 登入頁面。
          history.push('/login');
        }
        return {
          res,
        };
      },
    },
  });

  useEffect(() => {
    // 定時打api 確認是否已修復完成。
    const timer = setTimeout(() => {
      sendGetHeartbeat(FETCH_DATA_EVENT.FETCH);
    }, 1000 * 10);
    return () => clearTimeout(timer);
  }, []);

  return (
    <StyledMaintenancePage className={className} data-testid="MaintenancePage">
      <div className="pageTitle">{pageTitle}</div>
      <div className="imgBox">
        <img src={'/assets/MaintenancePage.svg'} alt="MaintenancePageImg"></img>
      </div>
      <div className="hintTitle">
        {configData?.title !== '' && configData?.title}
      </div>
      <div className="text">
        {configData?.text_1 !== '' && configData?.text_1}
      </div>
      <div className="text">
        {configData?.text_2 !== '' && configData?.text_2}
      </div>
      <div className="text">
        {configData?.text_3 !== '' && configData?.text_3}
      </div>
      <div className="text">
        {configData?.text_4 !== '' && configData?.text_4}
      </div>
      <div className="text">
        {configData?.text_5 !== '' && configData?.text_5}
      </div>
    </StyledMaintenancePage>
  );
};
