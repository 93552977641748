import React, { useEffect, useState, useMemo, useContext } from 'react';
import {
  fetchDataMachine,
  FETCH_DATA_EVENT,
  AuthProvider,
  openNotificationWithIcon,
  Tab,
  TabPane,
} from '@oneclass/ui-components';
import { TestListDesktop, TestListMobile, TestLinkModalContent, TestQrCodeModalContent, TestCodeModalContent } from 'components';
import { useMachine } from '@xstate/react';
import { getTime } from 'date-fns';
import { postQuizList, postQuizCSV, deleteQuiz } from 'api/quiz';
import { getCookie, transformTime } from 'utils/common';
import { useLocation } from 'react-router-dom';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { useCreateQuizExamModal } from 'utils/hooks/useCreateQuizExamModal';
import { StyledTestListContent, StyledHintModal, StyledShareModal } from './TestListContent.style';
import { useBoolean } from 'ahooks';
import { useLoadingContent } from 'utils/hooks/useLoadingContent';
import { Button } from '@oneclass/onedesign';
import behaviorProvider from 'providers/behaviorProvider';
import * as GA from 'utils/googleAnalytics';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useTranslation } from 'react-i18next';
import { subjectMapper, educationMapper } from 'locales/translationMapper';
const oneExamUrl = `${process.env.REACT_APP_ONE_EXAM_DOMAIN}/paper/preview`;

export const TestListContent = ({ className, setEduSubjectModalToggle, tabType }) => {
  const { t } = useTranslation();
  const eduOptions = [
    {
      name: t('containers.testListContent.__elementarySchool'),
      value: 'E',
    },
    {
      name: t('containers.testListContent.__juniorHighSchool'),
      value: 'J',
    },
    {
      name: t('containers.testListContent.__highSchool'),
      value: 'H',
    },
  ];
  const env = process.env.REACT_APP_NODE_ENV;
  // const { isDesktop } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const { behaviorData, behaviorDataProviderChange } = useContext(behaviorProvider.behaviorProviderContext);
  const { useAuthState } = AuthProvider;
  const authState = useAuthState();
  const location = useLocation();
  const [filterParams, setFilterParams] = useState({});
  const [pageState, setPageState] = useState({
    currentPage: 1,
    limit: 10,
  });
  const [modalVisible, { toggle: setModalVisibleToggle, setFalse: setModalVisibleFalse }] = useBoolean(false);
  const { renderLoadingContent, isLoading, setIsLoading } = useLoadingContent();
  const [hintModalVisible, { toggle: setHintModalToggle, setFalse: setHintModalFalse }] = useBoolean(false);
  const [deleteQuizUID, setDeleteQuizUID] = useState('');
  const [modalInfo, setModalInfo] = useState(null);
  const [testModalVisible, { toggle: setTestModalVisibleToggle, setFalse: setTestModalVisibleFalse }] = useBoolean(false);
  const [shareTabType, setShareTabType] = useState('testLink');
  const [eduSubjectOptions, setEduSubjectOptions] = useState([]);
  const [quizListData, setQuizListData] = useState([]);

  // 監聽交卷人數
  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_DOMAIN}/OnQuizResult?token=${getCookie('nani_oneclass_onepaper_token')}`)
      .withAutomaticReconnect()
      .configureLogging(LogLevel.None)
      .build();

    if (connection?.start) {
      connection.start()
        .then(() => {
          connection.invoke('SubscribeQuizResult').catch(console.error);
          connection.on('arrivedResult', (quizId) => {
            setQuizListData((prev) =>
              prev.map((quiz) =>
                quiz.QuizUID === quizId
                  ? { ...quiz, attendance: quiz.attendance + 1 }
                  : quiz
              )
            );
          });
        });
    }
    return () => connection?.stop();
  }, []);

  const tabChange = (key) => {
    GA.clickShareExamTab(key);
    setShareTabType(key);
  };
  const [shareModalData, setShareModalData] = useState({
    endTime: {
      month: '',
      day: '',
      time: '',
    },
    quizCode: {
      top4: '',
      last4: '',
    }
  });

  const eduSubjectNameMapper = (value) => {
    const eduStr = value.substr(0, 2);
    const subjectStr = value.substr(2, value.length - 2);
    const eduName = t(educationMapper(eduStr));
    const subjectName = t(subjectMapper(subjectStr));
    return eduName + ' ' + subjectName.toLocaleLowerCase();
  };

  const [statePostQuizList, sendPostQuizList] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        setIsLoading(true);
        const res = await postQuizList(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess && data) {
          let eduSubjectOptions = [];
          setQuizListData(data);
          data.forEach(item => {
            if (eduSubjectOptions.findIndex(data => data.value === `${item.Education}${item.Subject}`) === -1) {
              eduSubjectOptions = eduSubjectOptions.concat({
                name: eduSubjectNameMapper(item.EduSubjectName),
                value: `${item.Education}${item.Subject}`
              });
            }
          });

          setEduSubjectOptions(eduSubjectOptions);

          if (behaviorData?.openShareQuizModal) {
            setModalInfo(data[0]);
            setTestModalVisibleToggle();
            behaviorDataProviderChange('openShareQuizModal', false);
          }
        }
        setIsLoading(false);
        return {
          isSuccess, systemCode, message,
          quizListData: data,
        };
      },
    },
  });

  const [statePostQuizResult, sendPostQuizResult] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        const res = await postQuizCSV(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        // const { yearMap = [], eduSubjectMap = [], typeMap = [], patternMap = [], examPaper = [] } = data || {};
        const { csvUrl } = data;
        if (csvUrl) {
          const link = document.createElement('a');
          link.href = csvUrl;
          link.setAttribute('download', csvUrl);
          document.body.appendChild(link);
          link.click();
        }

        return {
          isSuccess, systemCode, message,
          csvUrl
        };
      },
    },
  });

  const [stateDeleteQuiz, sendDeleteQuiz] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_, event) => {
        const res = await deleteQuiz(event.jwt, event.quizUID);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) {
          openNotificationWithIcon('success', t('containers.testListContent.__successfullyDeletedTest'));
          setHintModalFalse();
          setModalVisibleFalse();
          sendPostQuizList(FETCH_DATA_EVENT.FETCH, {
            payload: {
              jwt: JSON.parse(getCookie('nani_oneclass_login_token')).jwt,
            }
          });
        } else {
          openNotificationWithIcon('error', message);
        }
        return {
          isSuccess, systemCode, message
        };
      },
    },
  });

  const filterData = useMemo(() => {
    if (!quizListData) return [];
    const nextData = quizListData.filter((item) => {
      const checkResult = Object.entries(filterParams).map(([key, value]) => {
        if (value && value.length === 0) return true;
        if (!value) return true;
        if (key === 'eduSubject') {
          const listEduSubject = `${item.Education}${item.Subject}`;
          return listEduSubject === value;
        } else if (key === 'quizName') {
          return item.QuizName.includes(value);
        } else if (key === 'date') {
          if (value[0] === '' || value[1] === '') return true;
          const filterStartTime = new Date(`${value[0]} 00:00:00`);
          const filterEndTime = new Date(`${value[1]} 23:59:59`);
          return (getTime(new Date(item.EndTime)) >= getTime(filterStartTime) && getTime(new Date(item.EndTime)) <= getTime(filterEndTime));
        } else if (key === 'eduGrade') {
          return (item.Education + item.Grade) === value;
        }

      });
      const checkResultSet = new Set(checkResult);
      if (!checkResultSet.has(false)) return item;

    });
    return nextData;
  }, [quizListData, filterParams]);

  const pageChange = (currentPage, pageSize) => {
    setPageState({
      ...pageState,
      currentPage,
      limit: pageSize,
    });
  };

  const setFilterCondition = (value, key) => {
    setFilterParams({
      ...filterParams,
      [key]: value
    });
  };

  const goToExamPage = (examUID) => {
    if (!examUID) return;
    window.open(`${oneExamUrl}/${examUID}`);
  };

  const goToReviewPage = (SchoolYear, QuizCode, QuizUID) => {
    if (!QuizUID) return;
    GA.reviewExam();
    window.open(`${examLink}/quiz/review/${SchoolYear}/${QuizCode}/${QuizUID}`);
  };

  const mobileChangeFilterCondition = (data) => {
    setFilterParams(data);
  };

  const fetchQuizExcel = async (quizId) => {
    GA.exportScore();
    sendPostQuizResult(FETCH_DATA_EVENT.FETCH, {
      payload: {
        quizId
      }
    });
  };

  const examLink = useMemo(() => {
    if (!env) return;
    return `https://oneexam${(env !== 'release' && env !== 'test') ? `-${env}` : ''}.oneclass.vn`;
  }, [env]);

  useEffect(() => {
    if (location.pathname === '/examPaperList' && tabType === 'examList') {
      const oneclubToken = JSON.parse(getCookie('nani_oneclass_login_token'))?.jwt;
      sendPostQuizList(FETCH_DATA_EVENT.FETCH, {
        payload: {
          jwt: oneclubToken,
        }
      });
    }
  }, [location.pathname, tabType]);

  useEffect(() => {
    setPageState({
      currentPage: 1,
      limit: 10,
    });
  }, [filterParams]);

  const closeEditModal = () => {
    setModalVisibleFalse();

    const oneclubToken = JSON.parse(getCookie('nani_oneclass_login_token'))?.jwt;
    sendPostQuizList(FETCH_DATA_EVENT.FETCH, {
      payload: {
        jwt: oneclubToken,
      }
    });
  };

  const { renderCreateQuizExamModal, createQuizExamModalToggle, setCreateQuizExamModalFalse } = useCreateQuizExamModal(closeEditModal, 'create');

  const canEditExam = (examItem) => {
    // const nowTime = new Date().getTime();
    const examEndTime = new Date(new Date(examItem.EndTime).getTime());
    const examStartTime = new Date(new Date(examItem.StartTime).getTime());
    if (examEndTime > examStartTime) {
      return true;
    } else {
      return false;
    }
  };

  const openHintModal = (quizUID) => {
    setHintModalToggle();
    setDeleteQuizUID(quizUID);
  };

  const cancelHintModal = () => {
    setHintModalFalse();
    setCreateQuizExamModalFalse();
  };

  const doneHintModal = () => {
    sendDeleteQuiz(FETCH_DATA_EVENT.FETCH, {
      jwt: JSON.parse(getCookie('nani_oneclass_login_token')).jwt,
      quizUID: deleteQuizUID,
    });
  };

  useEffect(() => {
    if (!modalInfo) return;
    const newTime = transformTime(modalInfo?.EndTime);
    setShareModalData({
      endTime: {
        month: newTime.slice(5, 7),
        day: newTime.slice(8, 10),
        time: newTime.slice(11, 17),
      },
      quizCode: {
        top4: modalInfo?.QuizCode.slice(0, 4),
        last4: modalInfo?.QuizCode.slice(4, 9),
      }
    });
  }, [modalInfo]);

  return (
    <StyledTestListContent className={className} data-testid="TestListContent">
      {
        deviceData.isDesktop ?
          <TestListDesktop
            filterParams={filterParams}
            eduSubjectOptions={eduSubjectOptions}
            filterData={filterData}
            pageState={pageState}
            pageChange={pageChange}
            setFilterCondition={setFilterCondition}
            transformTime={transformTime}
            renderLoadingContent={renderLoadingContent}
            isLoading={isLoading}
            setTestModalVisibleToggle={setTestModalVisibleToggle}
            setModalInfo={setModalInfo}
          />
          :
          <TestListMobile
            examLink={examLink}
            filterParams={filterParams}
            eduSubjectOptions={eduSubjectOptions}
            filterData={filterData}
            pageState={pageState}
            eduOptions={eduOptions}
            authState={authState}
            pageChange={pageChange}
            mobileChangeFilterCondition={mobileChangeFilterCondition}
            goToReviewPage={goToReviewPage}
            setEduSubjectModalToggle={setEduSubjectModalToggle}
            fetchQuizExcel={fetchQuizExcel}
            transformTime={transformTime}
            // eduGradeOptions={eduGradeOptions}
            renderCreateQuizExamModal={renderCreateQuizExamModal}
            createQuizExamModalToggle={createQuizExamModalToggle}
            setCreateQuizExamModalFalse={setCreateQuizExamModalFalse}
            modalVisible={modalVisible}
            setModalVisibleToggle={setModalVisibleToggle}
            setModalVisibleFalse={setModalVisibleFalse}
            canEditExam={canEditExam}
            goToExamPage={goToExamPage}
            renderLoadingContent={renderLoadingContent}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            openHintModal={openHintModal}
            testModalVisible={testModalVisible}
            setTestModalVisibleToggle={setTestModalVisibleToggle}
            modalInfo={modalInfo}
            setModalInfo={setModalInfo}
          />
      }

      {
        modalInfo &&
        <StyledShareModal
          id={'testModal'}
          visible={testModalVisible}
          width={'860px'}
          onCancel={setTestModalVisibleFalse}
          title={deviceData.isDesktop ? '' : modalInfo?.ExamName}
        >
          <Tab activeKey={shareTabType} onChange={tabChange} size='large'>
            <TabPane tab={t('containers.testListContent.__testLink')} key="testLink">
              <TestLinkModalContent examLink={examLink} modalInfo={modalInfo} shareModalData={shareModalData} />
            </TabPane>
            <TabPane tab={t('containers.testListContent.__testCode')} key="testCode">
              <TestCodeModalContent examLink={examLink} modalInfo={modalInfo} shareModalData={shareModalData} />
            </TabPane>
            <TabPane tab="QR code" key="qrCode">
              <TestQrCodeModalContent examLink={examLink} modalInfo={modalInfo} shareModalData={shareModalData} />
            </TabPane>
          </Tab>
        </StyledShareModal>
      }

      <StyledHintModal
        visible={hintModalVisible}
        title={t('containers.testListContent.__confirmDeleteTest')}
        onCancel={setHintModalFalse}
        isMobile={true}
        id={'hintModal'}
        className={'hintModal'}
        zIndex={1099}
        centered={deviceData.isDesktop}
        footer={
          <div className="footerBtn footerBtnBox">
            <Button variant='ui01' onClick={cancelHintModal}>
              {t('containers.testListContent.__cancel')}
            </Button>
            <Button
              // disabled={disableCreateQuizBtn}
              // variant={'delete'}
              className={'confirmBtn'}
              onClick={() => {
                doneHintModal();
                GA.deleteExam();
              }}
              isLoading={isLoading}
            >
              {t('containers.testListContent.__deleteTest')}
            </Button>
          </div>
        }
      >
        <span className='hintModalContent'>
          {t('containers.testListContent.__deleteTestWarning')}
        </span>
      </StyledHintModal>
    </StyledTestListContent>
  );
};