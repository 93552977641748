// eslint-disable-next-line react/prop-types
import React, { useState, useEffect, useMemo, useContext } from 'react';
import {
  Box,
  fetchDataMachine,
  FETCH_DATA_EVENT,
  AuthProvider,
} from '@oneclass/ui-components';
import { Button } from '@oneclass/onedesign';
import { ExternalLinkAltSolid, FolderOpenSolid, PaperPlaneSolid, RocketSolid } from '@onedesign/icon';
import { useMachine } from '@xstate/react';
import { getExamPaperPreview, getExamPaperExportProgress } from 'api/examPaper';
import { useHistory, useLocation } from 'react-router-dom';
import { useInterval } from 'ahooks';
import { blobDownload } from 'utils/common';
import { StyledHomeQuestionCompleteContent } from './HomeQuestionCompleteContent.style';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { useEduSubjectModal } from 'utils/hooks/useEduSubjectModal';
import { default as behaviorProvider } from 'providers/behaviorProvider.js';
import DownloadFailedModal from 'components/DownloadFailedModal';
import { useTranslation } from 'react-i18next';

export const HomeQuestionCompleteContent = ({
  onlineExamUID,
  examUID,
  UID,
  outputType,
  sendParams,
  examPaperTotalScoreData,
  setStep,
  setReCreateExam,
  eduSubjectData,
  setIsAgainDo,
  step,
  setIsLoading,
  isPro,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const { behaviorData, behaviorDataProviderChange } = useContext(behaviorProvider.behaviorProviderContext);
  const location = useLocation();
  const { useAuthState } = AuthProvider;
  const authState = useAuthState();
  const oneExamUrl = `${process.env.REACT_APP_ONE_EXAM_DOMAIN}/paper/preview`;
  // const [stateGetExamPaperPreview, sendGetExamPaperPreview] = useMachine(fetchDataMachine, {
  //   services: {
  //     fetchData: async(_, event) => {
  //       const res = await getExamPaperPreview(event.examUID);
  //       const { data, isSuccess, systemCode, message } = res;
  //       if (isSuccess) window.open(data);

  //       return {
  //         isSuccess, systemCode, message
  //       };
  //     },
  //   },
  // });

  const [stateGetExamPaperExportProgress, sendGetExamPaperExportProgress] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_, event) => {
        const res = await getExamPaperExportProgress({ queueId: event.UID });
        const { isSuccess, systemCode, message, data } = res;
        const {
          status,
          download,
          downloadName
        } = data;
        return {
          isSuccess, systemCode, message,
          status,
          download,
          downloadName
        };
      },
    },
  });

  const {
    status,
    download,
    downloadName
  } = stateGetExamPaperExportProgress.context.result || {};
  const isFailed = status === 'Failed';
  const isFinished = status === 'Finished';

  const goToExamPage = () => {
    if (!onlineExamUID) return;
    window.open(`${oneExamUrl}/${examUID}`);
  };

  const goToExamList = () => {
    history.push('/examPaperList', { completed: true });
  };

  const downloadFile = () => {
    blobDownload(
      download,
      downloadName,
      'GET'
    );
  };
  useInterval(() => {
    if (!UID) return;
    if (outputType !== 'Files') return;
    sendGetExamPaperExportProgress(FETCH_DATA_EVENT.FETCH, {
      UID
    });
  }, 20000);

  useEffect(() => {
    if (!UID) return;
    if (outputType !== 'Files') return;
    sendGetExamPaperExportProgress(FETCH_DATA_EVENT.FETCH, {
      UID
    });
  }, [UID]);

  const jumpToExamList = () => {
    behaviorDataProviderChange('goToExamList', true);
    goToExamList();
  };

  const paperData = useMemo(() => {
    if (!sendParams || !examUID) return {};
    return {
      uid: examUID,
      attributes: {
        questionAmount: examPaperTotalScoreData.questionCount,
        education: eduSubjectData.edu,
      },
      name: sendParams.paperName.value,
    };
  }, [sendParams, examUID]);

  const goBackStep1 = () => {
    setReCreateExam(true);
    setStep(1);
  };

  const { renderEduSubjectModal, setEduSubjectModalToggle, setEduSubjectModalFalse, useEduSubjectModalIsDone } = useEduSubjectModal(authState.context.userDetails?.preference, 'mainLayout', location.pathname, goBackStep1);

  useEffect(() => {
    const activeStep = (location.pathname === '/computerQuestion') ? 6 : 5;
    if (step === activeStep) {
      setIsAgainDo(true);
    }
  }, [step]);

  useEffect(() => {
    setIsLoading(false);
  });

  const goToCreateExam = () => {
    // setCookie('createExam', true, 1);
    behaviorDataProviderChange('createExam', true);
    history.push('/examPaperList');
  };

  return (
    <StyledHomeQuestionCompleteContent data-testid="HomeQuestionCompleteContent" isPro={isPro}>

      {
        outputType === 'Files' ?
          <>
            {
              !isFailed && !isFinished ?
                <>
                  <Box pb={10}>
                    <img src='/assets/paperLoading.svg' alt='examPaper' />
                  </Box>
                  <Box pb={4} className='loadingRow'>
                    <div className="load-wrapp">
                      <div className="load-10">
                        <div className="bar"></div>
                      </div>
                    </div>
                  </Box>
                  <Box pb={8}>
                    <div className='titleRow'>
                      <div className="pageTitle">
                        {t('containers.homeQuestionCompleteContent.__statusInProgress')}
                      </div>
                      {/* <div>完成組卷大約需要 {minutes !== 0 && `${minutes}分`}{seconds !== 0 && `${seconds}秒`}</div> */}
                    </div>

                  </Box>
                  <div className='fixedBottomBox'>
                    <Box pb={4} className='btnBox'>
                      <Button className='again-btn' onClick={() => setEduSubjectModalToggle()}>
                        <PaperPlaneSolid/>
                        {t('containers.homeQuestionCompleteContent.__retryExam')}
                      </Button>
                      <Button className='list-btn' onClick={() => {goToExamList();}}>
                        <FolderOpenSolid/>
                        {t('containers.homeQuestionCompleteContent.__examList')}
                      </Button>
                    </Box>
                  </div>
                  {/* <Box pb={6} className='controlBtnBox'>
                    <Button isLoading={stateGetExamPaperPreview.context.isLoading} type='solid' variant='ui01' onClick={() => {goToExamList();}}><FolderOpenSolid />前往試卷列表</Button>
                  </Box> */}
                  <Box pb={6}>
                    <div className='textRow'>
                      <div className="hint">
                        {t('containers.homeQuestionCompleteContent.__waitingMessage')}
                      </div>
                      <div className="hint">
                        {t('containers.homeQuestionCompleteContent.__leavePageQuestion')}{!deviceData.isDesktop ? <br/> : '，'}{t('containers.homeQuestionCompleteContent.__afterTestPaperCompletedMessage')}
                      </div>
                    </div>
                  </Box>
                </>
                :
                <>
                  <Box pb={10}>
                    <img src={download ? '/assets/paperComplete.svg' : '/assets/paperLoading.svg'} alt='examPaper' />
                  </Box>
                  <Box pb={8}>
                    <div className="pageTitle">
                      {isFailed ? t('containers.homeQuestionCompleteContent.__examFailed') : t('containers.homeQuestionCompleteContent.__examCompleted')}
                    </div>
                  </Box>
                  <div className='fixedBottomBox'>
                    <Box pb={3} className='controlBtnBox'>
                      <Button class='brand-btn' isLoading={stateGetExamPaperExportProgress.context.isLoading} onClick={downloadFile} >
                        <ExternalLinkAltSolid />
                        {t('containers.homeQuestionCompleteContent.__downloadFile')}
                      </Button>
                    </Box>
                    <Box pb={4} className='btnBox'>
                      <Button className='again-btn' onClick={() => setEduSubjectModalToggle()}>
                        <PaperPlaneSolid/>
                        {t('containers.homeQuestionCompleteContent.__retryExam')}
                      </Button>
                      <Button className='list-btn' onClick={() => {goToExamList();}}>
                        <FolderOpenSolid/>
                        {t('containers.homeQuestionCompleteContent.__examList')}
                      </Button>
                    </Box>
                  </div>
                  <Box pb={3}>
                    <div className="hint">
                      {t('containers.homeQuestionCompleteContent.__onlineExamCompletedMessage')}
                      <br/>
                      {t('containers.homeQuestionCompleteContent.__navigateToTestPaper')}
                    </div>
                  </Box>
                  {isFailed && <DownloadFailedModal downloadFailedPaperId={examUID} closeModal={() => history.push('/')} />}
                </>
            }
          </>
          :
          <>
            <Box pb={10}>
              <img src='/assets/paperComplete.svg' alt='examPaper' />
            </Box>
            <Box pb={8}>
              <div className="pageTitle">
                {t('containers.homeQuestionCompleteContent.__examCompleted')}
              </div>
            </Box>
            <div className='fixedBottomBox'>
              <Box pb={3} className='controlBtnBox'>
                {
                  outputType === 'Quick' ?
                    <Button
                      className='brand-btn'
                      onClick={() => goToCreateExam()}
                      // onClick={() => {examUID && createQuizExamModalToggle(paperData, 'create');}}
                    >
                      <RocketSolid />
                      {t('containers.homeQuestionCompleteContent.__createTest')}
                    </Button>
                    :
                    <Button isLoading={!examUID} variant='brand1' onClick={() => {goToExamPage();}}>
                      <ExternalLinkAltSolid />
                      {t('containers.homeQuestionCompleteContent.__viewExam')}
                    </Button>
                }
              </Box>
              <Box pb={4} className='btnBox'>
                <Button className='again-btn' onClick={() => setEduSubjectModalToggle()}>
                  <PaperPlaneSolid/>
                  {t('containers.homeQuestionCompleteContent.__retryExam')}
                </Button>
                <Button className='list-btn' onClick={() => {goToExamList();}}>
                  <FolderOpenSolid/>
                  {t('containers.homeQuestionCompleteContent.__examList')}
                </Button>
              </Box>
            </div>

            <Box pb={3}>
              <div className="hint">
                {t('containers.homeQuestionCompleteContent.__onlineExamCompletedMessage')}
                <br/>
                {t('containers.homeQuestionCompleteContent.__navigateToTestPaper')}
              </div>
            </Box>
          </>
      }
      {
        renderEduSubjectModal(authState.context.userDetails?.onePaperIdentity)
      }
    </StyledHomeQuestionCompleteContent>
  );
};