
import React, { useMemo, useState, useEffect, useContext } from 'react';
import { HomeAreaSelectContent, HomeQuesTypeSelectContent, HomeLayoutOptionContent, HomeQuestionSelectContent, HomeQuestionCompleteContent } from 'containers';
import {
  StepBox,
  fetchDataMachine,
  FETCH_DATA_EVENT,
  FETCH_DATA_STATE,
  Modal,
  AuthProvider,
  CreateExamPaperParamsProvider,
  MobileStepBox,
  EditCopyExamPaperProvider,
  openNotificationWithIcon,
} from '@oneclass/ui-components';
import { ExamPaperHeader, ReviewPage, QuestionLimitHint, QuestionLimitModal } from 'components';
import { Button } from '@oneclass/onedesign';
import { FileAltSolid, ExclamationCircleSolid, FileAltOutline } from '@onedesign/icon';
import { useMachine } from '@xstate/react';
import { formateQuestionList, setCookie, getCookie, formatSelectedBook, autoSettingScore, numberToFirst } from 'utils/common';
import { postExamCreate, postExamEdit } from 'api/examPaper';
import { useBoolean } from 'ahooks';
import { useHistory } from 'react-router-dom';
import { StyledComputerQuestionPage, StyledHomePageContent, UiBtnZone } from './ComputerQuestionPage.style';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { postGetQuestionBucket } from 'api/query';
import { useLoadingContent } from 'utils/hooks/useLoadingContent';
import { postMemberTypeSetting } from 'api/member';
import { PROCESS_PARAMS } from 'constants/index';
import { useFilterQuestionModal } from 'utils/hooks/useFilterQuestionModal';
import { useLeavePageAlert } from 'utils/hooks/useLeavePageAlert';
import { default as UserProvider } from 'providers/UserProvider.js';
import * as GA from 'utils/googleAnalytics';
import SuspendedQuestionsBar from 'components/SuspendedQuestionsBar';
import { routeLeaveContext } from 'providers/routeLeaveProvider.js';
import { useTranslation } from 'react-i18next';
import { questionTypeMapper } from 'locales/translationMapper';



const goTop = () => {
  const main = document.getElementById('mainLayout');
  main.scrollTop = 0;
};

const initSaveData = {
  education: '',
  subject: '',
  areaParams: {},
  quesReviewQuestionRenderList: {},
  createExamPaperParams: {},
  tableData: {},
  bookOptions: [],
  examUID: ''
};
export const ComputerQuestionPage = ({ className, props }) => {
  const { setIsLoadingMark } = props;
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const { editCopyExamPaperData, resetCopyEditPaperParams } = useContext(EditCopyExamPaperProvider.EditCopyExamPaperContext);
  const history = useHistory();
  const { useAuthState } = AuthProvider;
  const authState = useAuthState();
  const { createExamPaperParams: globalExamPaperData } = useContext(CreateExamPaperParamsProvider.createExamPaperContext);
  const [saveData, setSaveData] = useState({ ...initSaveData });
  const [resetToggle, setResetToggle] = useState(false);
  const [areaParams, setAreaParams] = useState({});
  const [sendParams, setSendParams] = useState({});
  const [questions, setQuestions] = useState([]);
  const [tableData, setTableData] = useState({});
  const [keyList, setKeyList] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [createExamPaperParams, setCreateExamPaperParams] = useState({});
  const [checkedSend, setCheckedSend] = useState([]);
  const [step, setStep] = useState(1);
  const [jumpStep, setJumpStep] = useState(null);
  const [areaModalVisible, { toggle: setAreaModalToggle, setFalse: setAreaModalFalse }] = useBoolean(false);
  const [modalVisible, { toggle: setModalToggle, setFalse: setModalFalse }] = useBoolean(false);
  const { renderLoadingContent, isLoading, setIsLoading } = useLoadingContent();
  const [reCreateSearchParams, setReCreateSearchParams] = useState({});
  const [reCreateExam, setReCreateExam] = useState(false);
  const [isJumpToStep5, setIsJumpToStep5] = useState(false);
  const [emptyTextModalVisible, { toggle: setEmptyTextModalToggle, setFalse: setEmptyTextModalFalse }] = useBoolean(false);
  const [isGoBack, setIsGoBack] = useState(false);
  const [questionCountModalVisible, { toggle: setQuestionCountModalToggle, setFalse: setQuestionCountModalFalse }] = useBoolean(false);
  const [isShowQuestionLimitText, setIsShowQuestionLimitText] = useState(false);
  const [reBuildData, setReBuildData] = useState({});
  const [originalData, setOriginalData] = useState([]);
  const [isAgainDo, setIsAgainDo] = useState(false);
  const [bookList, setBookList] = useState({});
  const [treeCheckedList, setTreeCheckedList] = useState([]);
  const { userData } = useContext(UserProvider.UserProviderContext);
  const { userInfo } = userData;
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [submitResponseData, setSubmitResponseData] = useState(null);
  const [isSuspendedQuestionsBarOpen, setIsSuspendedQuestionsBarOpen] = useState(true);
  const [isPageLast, setIsPageLast] = useState(false);
  useLeavePageAlert(isPageLast);
  const { setIsLast } = useContext(routeLeaveContext);
  const { t } = useTranslation();
  const steps = [
    {
      title: t('views.computerQuestionPage.__rangeSelection')
    },
    {
      title: t('views.computerQuestionPage.__questionTypeSelection')
    },
    {
      title: t('views.computerQuestionPage.__questionSelection')
    },
    {
      title: t('views.computerQuestionPage.__previewQuestions')
    },
    {
      title: t('views.computerQuestionPage.__layoutConfiguration')
    },
    {
      title: t('views.computerQuestionPage.__completion')
    },
  ];
  // 計算 試卷中各種題型的 總分、總題數、總答數。
  const totalQuesTypeData = useMemo(() => {
    let nextData = {
      score: 0,
      questionCount: 0,
      totalAnswer: 0,
    };
    if (!tableData) return nextData;

    let score = 0;
    let questionCount = 0;
    let totalAnswer = 0;
    Object.entries(tableData).forEach(([key, value]) => {
      score += Number(value.inputValue.score.allScore) || 0;
      questionCount += value.inputValue.question ? parseInt(value.inputValue.question) : 0;
      totalAnswer += Number(value?.inputValue?.answer) || 0;
    });
    nextData = {
      score: numberToFirst(score) || 0, // 試卷總分。
      questionCount: parseInt(questionCount) || 0, // 總題數。
      totalAnswer: Number(totalAnswer) || 0, // 總答數。
    };
    return nextData;
  }, [tableData]);

  // 出卷類型。
  const processType = useMemo(() => {
    return createExamPaperParams?.outputType || globalExamPaperData.output || null;
  }, [globalExamPaperData.output, createExamPaperParams.outputType]);

  // 題數/答數 限制
  const questionNumLimit = useMemo(() => {
    if (!processType) return 0;
    return PROCESS_PARAMS[processType]?.questionLimit;
  }, [processType]);

  // 要用來判斷的 題數/答數
  const currentQuesNum = useMemo(() => {
    return totalQuesTypeData?.totalAnswer;
  }, [processType, totalQuesTypeData.totalAnswer]);

  // 儲存使用者設定
  const [, sendUserSetting] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_, event) => {
        const res = await postMemberTypeSetting(event.payload);
        const { isSuccess, systemCode, message } = res;
        if (isSuccess) {
          // 成功顯示
        } else {
          openNotificationWithIcon('error', message);
        }
        return {
          isSuccess, systemCode, message
        };
      }
    }
  });

  const getSendParams = (data) => {
    setSendParams(data);
  };

  // 建立測驗
  const [statePostExamCreate, sendPostExamCreate] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        setIsLoading(true);
        const res = await postExamCreate(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        const { examUID } = data;
        if (isSuccess) {
          setCreateExamPaperParams({
            ...createExamPaperParams,
            examUID: examUID,
          });
        } else {
          openNotificationWithIcon('error', message);
        }
        setIsSubmitSuccess(isSuccess);
        setSubmitResponseData(data);
        return {
          createIsSuccess: isSuccess,
          isSuccess, systemCode, message,
          data,
        };
      },
    },
  });

  const [stateGetQuestionType, sendGetQuestionType] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        setIsLoadingMark(true);
        const res = await postGetQuestionBucket(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) {
          if (JSON.stringify(transformQuestionTypeData(data)) !== '{}') {
            goTop();
          }
        }
        const translatedData = data?.map(e => {
          return {
            ...e,
            questionTypeName: t(questionTypeMapper(e.questionTypeName))
          };
        });
        setReBuildData(transformQuestionTypeData(translatedData));
        setOriginalData(translatedData);
        setIsLoadingMark(false);
        return {
          isSuccess, systemCode, message,
        };
      },
    },
  });

  // 初始化資料 相關
  const initQuestionTypeData = () => {
    setSaveData({
      ...initSaveData,
      areaParams,
      education: globalExamPaperData?.edu || 'J',
      subject: globalExamPaperData?.subject || 'GE',
      // bookOptions: saveData.bookOptions,
    });
    setTableData({});
    // setTotalQuesTypeData({});
    setQuestions([]);
    setCreateExamPaperParams({});
    setKeyList([]);
  };

  const {
    renderFilterQuestionModal,
    filterQuestionModalToggle,
    setFilterQuestionModalFalse,
    filterPaperUID,
    filterQuestions,
    isChangeFilter,
    setIsChangeFilter,
    setFilterPaperUID,
    paperFilter
  } = useFilterQuestionModal(initQuestionTypeData);

  useEffect(() => {
    if (isAgainDo) {
      setFilterPaperUID([]);
      setIsAgainDo(false);
    }
  }, [isAgainDo]);

  const data = useMemo(() => {
    if (!reBuildData | !originalData) return {};
    if (!filterQuestions || filterQuestions.length === 0) return reBuildData;
    let nextData = {
      ...reBuildData
    };
    originalData.forEach((quesType) => {
      quesType.questions.forEach((ques) => {
        if (filterQuestions.indexOf(ques?.uid) !== -1) {
          nextData = {
            ...nextData,
            [quesType?.questionTypeName]: {
              ...nextData[quesType?.questionTypeName],
              count: {
                answer: parseInt(nextData[quesType?.questionTypeName]?.count?.answer) - parseInt(ques?.answerAmount),
                question: parseInt(nextData[quesType?.questionTypeName]?.count?.question) - 1,
                difficulty: {
                  ...nextData[quesType?.questionTypeName].count.difficulty,
                  [ques?.difficulty]: {
                    answer: parseInt(nextData[quesType?.questionTypeName]?.count?.difficulty[ques?.difficulty]?.answer) - parseInt(ques?.answerAmount),
                    question: parseInt(nextData[quesType?.questionTypeName]?.count?.difficulty[ques?.difficulty]?.question) - 1
                  }
                }
              },
              question: nextData[quesType?.questionTypeName]?.question.filter((item) => {
                return filterQuestions.indexOf(item?.uid) === -1;
              }),
            }
          };
        }
      });
    });
    return nextData;
  }, [filterQuestions, originalData]);

  const transformDifficultObject = (array) => {
    if (!array) return {};
    let nextData = {};
    array.forEach((item) => {
      let newData = {
        ...nextData,
        [item.difficulty]: {
          answer: item.answer,
          question: item.question,
        }
      };
      nextData = newData;
    });
    return nextData;
  };

  const transformQuestion = (quesType, quesTypeName, array) => {
    if (!quesType || !quesTypeName || !array) return [];
    return array.map((item) => {
      return {
        ...item,
        quesType, quesTypeName
      };
    });
  };

  const transformQuestionTypeData = (data) => {
    if (!data) return {};
    let nextData = {};
    data.forEach((item) => {
      let newData = {
        ...nextData,
        [item.questionTypeName]: {
          code: item.questionType,
          count: {
            answer: item.sum.answer,
            question: item.sum.question,
            difficulty: transformDifficultObject(item.diffcultyAggregate)
          },
          name: item.questionTypeName,
          question: transformQuestion(item.questionType, item.questionTypeName, item.questions),
          isListen: item.isListen
        }
      };
      nextData = newData;
    });
    return nextData;
  };

  const [statePostExamEdit, sendPostExamEdit] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        setIsLoading(true);
        const res = await postExamEdit(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) {
          setCreateExamPaperParams({
            ...createExamPaperParams,
            examUID: event.payload.examUID,
          });
        } else {
          openNotificationWithIcon('error', message);
        }
        setIsSubmitSuccess(isSuccess);
        setSubmitResponseData(data);
        return {
          editIsSuccess: isSuccess, systemCode, message,
          data
        };
      },
    },
  });

  const disabled = useMemo(() => {
    let result = false;
    const checkArray = ['paperName', 'teacherSign', 'schoolName'];
    const advancedCheckArray = ['amount', 'paperSize', 'wordSetting', 'paperContents'];
    let pickupQuestion = [];
    switch (step) {
      case 5:
        if (createExamPaperParams?.outputType !== 'Files') {
          const {
            paperName = {}, outputType = '',
            //  examType = ''
          } = createExamPaperParams || {};
          const checkParams = {
            paperName: paperName?.value || '',
            outputType,
            // examType
          };
          Object.entries(checkParams).forEach(([key, value]) => {
            if (value === '') result = true;
          });
        } else {
          Object.entries(createExamPaperParams).forEach(([key, value]) => {
            if ((key === 'grade' || key === 'room') && checkedSend.findIndex(item => item === 'classInfo') !== -1) {
              if (value.value === '') result = true;
            }
            const needCheck = ((checkArray.findIndex(item => item === key) !== -1 && checkedSend.findIndex(item => item === key) !== -1));
            if (needCheck) {
              if (value.value === '') result = true;
            }

            if (advancedCheckArray.findIndex(item => item === key) !== -1) {
              if (!value || value === '' || value.length === 0) result = true;
            }
          });
        }
        break;

      case 3:
        Object.entries(tableData).forEach(([key, value]) => {
          if (value?.pickUpQuestions) {
            Object.entries(value?.pickUpQuestions).forEach(([pickKey, pickValue]) => {
              pickupQuestion = pickupQuestion.concat(pickValue);
            });
          }
        });
        if (pickupQuestion.length === 0) return true;
        break;

      case 2:
        if (!totalQuesTypeData?.questionCount || totalQuesTypeData.questionCount === 0) return true;
        break;

      case 1:
        Object.entries(areaParams).forEach(([key, value]) => {
          if (!value || value.length === 0) result = true;
        });
        break;

      default:
        break;
    }
    return result;
  }, [step, areaParams, tableData, createExamPaperParams, totalQuesTypeData, checkedSend]);


  const isChangeData = useMemo(() => {
    let result = false;
    if (step === 1) {
      if (Object.keys(saveData.areaParams).length === 0 || Object.keys(areaParams).length === 0) return false;
      Object.entries(areaParams).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          if (saveData.areaParams[key].length === value.length) {
            if (saveData.areaParams[key].filter(item => !value.includes(item)).length > 0) result = true;
          } else {
            result = true;
          }
        } else {
          if (saveData.areaParams[key] !== value) result = true;
        }
      });
    } else if (step === 2) {
      if (Object.keys(saveData.tableData).length === 0 || Object.keys(tableData).length === 0) return false;
      let questionList = [];
      let saveQuestionList = [];
      Object.entries(tableData).forEach(([key, value], index) => {
        const quesTypeData = formateQuestionList(value.pickUpQuestions, questions);
        if (quesTypeData.length > 0) {
          questionList = questionList.concat(quesTypeData);
        }
      });
      Object.entries(saveData.tableData).forEach(([key, value], index) => {
        const quesTypeData = formateQuestionList(value.pickUpQuestions, questions);
        if (quesTypeData.length > 0) {
          saveQuestionList = saveQuestionList.concat(quesTypeData);
        }
      });
      if (questionList.length === saveQuestionList.length) {
        if (questionList.filter(item => !saveQuestionList.includes(item)).length > 0) result = true;
      } else {
        result = true;
      }
    }

    return result;
  }, [areaParams, step, tableData, saveData.areaParams]);

  const getSendAreaParams = (data) => {
    setAreaParams({ ...data });
  };
  const getTableData = (data) => {
    setTableData({ ...data });
  };
  const getKeyList = (data) => {
    setKeyList([...data]);
  };
  const getQuestions = (data) => {
    setQuestions([...data]);
  };

  const getCreateExamPaperParams = (data) => {
    setCreateExamPaperParams(data);
  };
  const getCheckedSend = (data) => {
    setCheckedSend(data);
  };
  const getSaveData = (key, data) => {
    setSaveData({ ...saveData, [key]: data });
  };

  const prevStep = () => {
    setStep(step - 1);
    goTop();
  };

  const onStepClick = (current) => {
    GA.clickStep(steps[current].title);
    setIsGoBack(true);
    setStep(current + 1);
  };

  const resetData = () => {
    setSaveData({ ...initSaveData });
    setTableData({});
    setQuestions([]);
    setCreateExamPaperParams({});
    setKeyList([]);
  };

  const nextStep = (jumpTo = null) => {
    setJumpStep(jumpTo);

    switch (step) {
      case 5: {
        const {
          schoolName = {},
          paperName,
          teacherSign = {},
          grade = {},
          room = {},
          paperSize,
          wordSetting,
          paperContents,
          analyzeContent,
          amount,
          advanced,
          studentSign,
          eduSubject,
          ordering,
          header,
          engAudioSetting
        } = sendParams || {};
        const hasListen = Object.values(tableData)
          .filter(quesType => quesType.pickUpQuestions)
          .some(quesType => quesType.isListen);
        let setting = {};
        switch (processType) {
          case 'Files':
            // 組成試卷 - 紙本
            setting = {
              schoolName: checkedSend.findIndex(item => item === 'schoolName') !== -1 ? schoolName.value : '',
              paperName: paperName.value,
              teacherSign: checkedSend.findIndex(item => item === 'teacherSign') !== -1 ? teacherSign.value : '',
              grade: checkedSend.findIndex(item => item === 'classInfo') !== -1 ? grade.value : '',
              room: checkedSend.findIndex(item => item === 'classInfo') !== -1 ? room.value : '',
              eduSubject: checkedSend.findIndex(item => item === 'eduSubject') !== -1 ? eduSubject : '',
              studentSign: checkedSend.findIndex(item => item === 'studentSign') !== -1 ? studentSign : '',
              paperSize,
              wordSetting,
              paperContents: paperContents || [],
              analyzeContent: analyzeContent || [],
              amount,
              advanced: advanced || [],
              ordering,
              header,
            };
            break;
          case 'Online':
            setting = {
              paperName: paperName.value,
              paperSize: 'A4',
              wordSetting: ['CH', 'CN'].includes(globalExamPaperData?.subject) ? 'HSD' : 'VHD',
              paperContents: ['Question', 'Answer'],
              analyzeContent: ['Question', 'Answer', 'Analyze', 'Difficulty', 'Source', 'Knowledge'],
              amount: 1,
              ordering: ['schoolName', 'paperName', 'teacherSign', 'classInfo', 'eduSubject', 'studentSign'],
              header: paperName.value,
            };
            break;
          case 'Quick':
            setting = {
              paperName: paperName.value,
              paperSize: 'A4',
              wordSetting: (globalExamPaperData?.edu === 'H') ? 'HHD' : 'VHD',
              paperContents: ['Question', 'Analyze'],
              analyzeContent: ['Question', 'Answer', 'Analyze', 'Difficulty', 'Source', 'Knowledge'],
              amount: 1,
              ordering: ['schoolName', 'paperName', 'teacherSign', 'classInfo', 'eduSubject', 'studentSign'],
              header: paperName.value,
            };
            break;
          default:
            break;
        }
        // 英聽設定
        let engAudioSettingPayload = {};
        if (hasListen) {
          const { audioTitle, outputAudioFormat, outputAudioRate, recitation } = engAudioSetting;
          const { schoolLocation, schoolYear, semester, grade, examCategory } = audioTitle;
          const { schoolId, schoolName } = schoolLocation || {};

          engAudioSettingPayload = {
            outputAudioFormat,
            outputAudioRate,
            recitation
          };

          switch (processType) {
            case 'Files':
              engAudioSettingPayload.audioTitle = {
                schoolYear,
                semester,
                grade,
                examCategory,
                schoolLocation: (schoolId && schoolName) ? schoolLocation : null
              };
              break;
            case 'Online':
            case 'Quick':
              engAudioSettingPayload = engAudioSetting;
              break;
            default:
              break;
          }
        }
        if (isEditCopy && editCopyExamPaperData?.actionType === 'Edit') {
          sendPostExamEdit(FETCH_DATA_EVENT.FETCH, {
            payload: {
              examUID: editCopyExamPaperData.paperData.uid,
              examName: sendParams.paperName.value,
              outputType: sendParams.outputType,
              drawUp: 'FastPattern',
              // examType: sendParams.examType,
              isPublic: false,
              questionGroups,
              setting,
              ...(hasListen ? { engAudioSetting: engAudioSettingPayload } : {})
            }
          });
        } else {
          sendPostExamCreate(FETCH_DATA_EVENT.FETCH, {
            payload: {
              eduSubject: searchParams.eduSubject,
              examName: sendParams.paperName.value,
              searchCondition: {
                bookIDs: isEditCopy ? reCreateSearchParams.bookIDs : searchParams.books,
                knowledgeIDs: isEditCopy ? reCreateSearchParams.knowledges : searchParams.knowledges,
                sourceIDs: isEditCopy ? reCreateSearchParams.sources : searchParams.sources,
                curriculum: isEditCopy ? reCreateSearchParams.curriculum : searchParams.curriculum,
                year: isEditCopy ? reCreateSearchParams.year : searchParams.year,
                version: isEditCopy ? reCreateSearchParams.version : searchParams.version,
                bookSelection: selectedBooks,
              },
              // examType: sendParams.examType,
              outputType: sendParams.outputType,
              drawUp: 'FastPattern',
              questionGroups,
              paperFilter: paperFilter || [],
              setting,
              ...(hasListen ? { engAudioSetting: engAudioSettingPayload } : {})
            }
          });
        }
        break;
      }

      case 2:
        getSaveData('tableData', tableData);
        setModalFalse();
        setStep(3);
        setIsShowQuestionLimitText(false);
        goTop();
        break;

      case 1:
        if (isChangeData) {
          initQuestionTypeData();
        } else {
          setSaveData({
            ...saveData,
            areaParams,
            education: globalExamPaperData?.edu || 'J',
            subject: globalExamPaperData?.subject || 'GE',
          });
        }
        sendGetQuestionType(FETCH_DATA_EVENT.FETCH, {
          payload: {
            eduSubject: globalExamPaperData?.edu + globalExamPaperData?.subject,
            knowledges: areaParams.keys,
            sources: areaParams.source,
            paperConfig: {
              outputStyle: processType,
              drawUpStyle: 'FastPattern'
            },
          }
        });
        break;
      default:
        if (jumpTo !== null) {
          setStep(jumpTo);
        } else {
          setStep(step + 1);
        }
        goTop();
        break;
    }
  };

  const searchParams = useMemo(() => {
    return {
      eduSubject: editCopyExamPaperData?.eduSubject || (globalExamPaperData?.edu + globalExamPaperData?.subject),
      paperConfig: {
        outputStyle: processType,
        drawUpStyle: 'FastPattern'
      },
      books: editCopyExamPaperData?.bookIDs || areaParams?.bookIDs,
      knowledges: editCopyExamPaperData?.knowledgeIDs || areaParams?.keys,
      sources: editCopyExamPaperData?.sourceIDs || areaParams?.source,
      curriculum: editCopyExamPaperData?.curriculum || areaParams.curriculum,
      year: editCopyExamPaperData?.year || areaParams.year,
      version: editCopyExamPaperData?.version || areaParams.version,
    };
  }, [globalExamPaperData.edu,
    globalExamPaperData.subject,
    areaParams.bookIDs,
    areaParams.keys,
    areaParams.source,
    areaParams.year,
    areaParams.version,
    areaParams.curriculum,
    globalExamPaperData.output,
    editCopyExamPaperData]);

  useEffect(() => {
    if (stateGetQuestionType.matches(FETCH_DATA_STATE.DONE)) {
      if (!data || JSON.stringify(data) === '{}') {
        setEmptyTextModalToggle();
        return null;
      } else {
        if (!isEditCopy) setStep(step + 1);
      }
    }
  }, [stateGetQuestionType.value]);

  useEffect(() => {
    if ((statePostExamCreate.matches(FETCH_DATA_STATE.DONE) || statePostExamEdit.matches(FETCH_DATA_STATE.DONE)) && isSubmitSuccess) {
      setStep(step + 1);
    }
  }, [statePostExamCreate, statePostExamEdit, isSubmitSuccess]);

  useEffect(() => {
    if (authState.context.userDetails?.userStatus.identity === 'Student') {
      history.push('/');
    }

  }, []);
  useEffect(() => {
    if (Object.keys(tableData).length > 0 && getCookie('createNewPaper') === null) {
      setCookie('createNewPaper', true, 1);
    }
  }, [tableData]);

  useEffect(() => {
    if (saveData.education === '' || saveData.subject === '') return;
    if (globalExamPaperData?.edu === saveData.education && globalExamPaperData?.subject === saveData.subject) return;
    resetData();
    setStep(1);
  }, [globalExamPaperData]);

  const isEditCopy = useMemo(() => {
    if (editCopyExamPaperData && JSON.stringify(editCopyExamPaperData) !== '{}') {
      setIsLoading(true);
      return true;
    } else {
      return false;
    }
  }, [editCopyExamPaperData]);

  const eduSubjectData = useMemo(() => {
    return isEditCopy ? editCopyExamPaperData : globalExamPaperData;
  }, [isEditCopy, editCopyExamPaperData, globalExamPaperData]);

  useEffect(() => {
    Object.entries(globalExamPaperData).forEach(([key, value]) => {
      if ((key === 'edu' || key === 'subject') && value === '') {
        if (!editCopyExamPaperData[key]) {
          history.push('./');
        }
      }
    });

    if (!editCopyExamPaperData || JSON.stringify(editCopyExamPaperData) === '{}') return;
    //   const { edu, subject, tableData, bookOptions, actionType, examUID, disabled } = editCopyExamPaperData;

    const {
      bookIDs,
      eduSubject,
      knowledgeIDs,
      sourceIDs,
      output,
      curriculum,
      year,
      version,
    } = editCopyExamPaperData;

    // 取得 題目類型
    setSendParams({ bookIDs, knowledges: knowledgeIDs, sourceIDs, eduSubject });
    sendGetQuestionType(FETCH_DATA_EVENT.FETCH, {
      payload: {
        eduSubject,
        knowledges: knowledgeIDs,
        sources: sourceIDs,
        paperConfig: {
          outputStyle: processType,
          drawUpStyle: 'FastPattern'
        },
      }
    });

    setReCreateSearchParams({
      eduSubject,
      paperConfig: {
        outputStyle: processType,
        drawUpStyle: 'FastPattern'
      },
      bookIDs,
      knowledges: knowledgeIDs,
      sources: sourceIDs,
      curriculum,
      year,
      version,
    });

  }, []);

  const identity = useMemo(() => {
    return authState?.context?.userDetails?.onePaperIdentity || '';
  }, [authState]);

  const prevStepDisabled = useMemo(() => {
    if (!editCopyExamPaperData?.actionType) return false;
    if ((editCopyExamPaperData?.actionType === 'Edit' || editCopyExamPaperData?.actionType === 'Copy') && step <= 3) return true;
  }, [editCopyExamPaperData?.actionType, step]);

  const questionGroups = useMemo(() => {
    if (!tableData || JSON.stringify(tableData) === '{}') return;
    let nextData = keyList.map(key => {
      let questionList = [];
      if (JSON.stringify(saveData?.quesReviewQuestionRenderList) !== '{}') {
        saveData?.quesReviewQuestionRenderList[key] && saveData?.quesReviewQuestionRenderList[key].forEach((ques) => {
          questionList.push(ques?.uid);
        });
      } else {
        if (tableData[key]?.pickUpQuestions) {
          Object.values(tableData[key]?.pickUpQuestions).forEach(questions => {
            questions.forEach(question => {
              questionList = [...questionList, question?.uid];
            });
          });
        }
      }
      return ({
        typeCode: tableData[key]?.code,
        scoreType: tableData[key]?.inputValue?.score?.perQues ? 'PerQuestion' : 'PerAnswer',
        score: tableData[key]?.inputValue?.score?.perQues ? Number(Number(tableData[key]?.inputValue?.score?.perQues).toFixed(1)) || 0 : Number(Number(tableData[key]?.inputValue?.score?.perAns).toFixed(1)) || 0,
        questionList
      });
    }).filter(item => item?.questionList?.length > 0);

    return nextData;
  }, [tableData, keyList]);

  const defaultCountInputValue = {
    answer: '',
    question: '',
    score: {
      perQues: '',
      perAns: '',
      allScore: 0,
    },
    difficulty: {
      BEGIN: '',
      INTERMEDIATE: '',
      EXPERT: '',
    }
  };

  const formateTableData = (data) => {
    if (!data) return null;
    let nextData = {};
    Object.entries(data).forEach(([key, value], index) => {
      nextData = {
        ...nextData,
        [key]: {
          ...value,
          inputValue: defaultCountInputValue
        }
      };
    });
    return nextData;
  };

  useEffect(() => {
    if (!isEditCopy) return;
    if (!data) return;
    if (Object.keys(tableData).length > 0) return;
    const nextData = formateTableData(data);
    getTableData(nextData);
  }, [data, isEditCopy]);

  useEffect(() => {
    if (isEditCopy && JSON.stringify(tableData) !== '{}') {
      if (step < 3) setStep(3);
    }
  }, [isEditCopy, tableData]);

  useEffect(() => {
    if (reCreateExam) {
      initAllData();
    }
  }, [reCreateExam]);

  const initAllData = () => {
    setSaveData({ ...initSaveData });
    setResetToggle(false);
    setAreaParams({});
    setSendParams({});
    setQuestions([]);
    setTableData({});
    setKeyList([]);
    setSearchKey('');
    setCreateExamPaperParams({});
    setCheckedSend([]);
    setJumpStep(null);
  };

  const jumpToStep5 = (boolean) => {
    setIsJumpToStep5(true);
    if (boolean) {
      setJumpStep(3);
    } else {
      setStep(3);
    }
  };

  const nextStepClickHandler = () => {
    // 確認是否有超過 出題上限。
    if (currentQuesNum > questionNumLimit && step >= 2) {
      setQuestionCountModalToggle();
      setIsShowQuestionLimitText(true);
    } else {
      if (isChangeData) {
        if (step === 1) {
          setAreaModalToggle();
        }
        if (step === 2) {
          setModalToggle();
        }
      } else {
        nextStep();
      }
    }
  };

  const toFinalStepClickHandler = () => {
    // 確認是否有超過 出題上限。
    if (currentQuesNum > questionNumLimit) {
      setQuestionCountModalToggle();
      setIsShowQuestionLimitText(true);
    } else {
      setModalFalse();
      goTop();
      if (step === 3) {
        setStep(5);
      } else {
        jumpToStep5(false);
      }
    }
  };

  // 題數/答數 上限
  // const questionNumLimit = useMemo(() => {
  //   const type = createExamPaperParams?.outputType || globalExamPaperData.output;
  //   let num = 300;
  //   if (type === 'Files') num = 500;
  //   return num;
  // }, [globalExamPaperData.output, createExamPaperParams.outputType]);

  useEffect(() => {
    setIsShowQuestionLimitText(false);
  }, [totalQuesTypeData.questionCount]);

  // 已選取的範圍。
  const selectedBooks = useMemo(() => {
    if (!editCopyExamPaperData || JSON.stringify(editCopyExamPaperData) === '{}') {
      // 非編輯
      if (!areaParams?.version || !areaParams?.year || !areaParams?.curriculum) return {};
      if (!bookList || JSON.stringify(bookList) === '{}' || treeCheckedList.length === 0) return {};
      const filterBookList = { ...bookList[areaParams?.curriculum][areaParams?.year][areaParams?.version] };
      return formatSelectedBook(filterBookList, treeCheckedList);
    } else {
      // 編輯時。
      const { topicSelection, bookSelection, } = editCopyExamPaperData;
      return topicSelection ? topicSelection : bookSelection;
    }
  }, [bookList, areaParams?.version, areaParams?.year, areaParams?.curriculum, treeCheckedList]);

  useEffect(() => {
    setIsPageLast(step === 6);
    setIsLast(step === 6);
    switch (step) {
      case 5:
        // 每次進入版面配置頁面，皆要檢查是否有進行配分，若沒有 則進行自動配分。
        if (totalQuesTypeData?.score === 0) {
          setTableData(autoSettingScore(tableData, keyList, totalQuesTypeData?.totalAnswer));
        }
        break;

      default:
        break;
    }
  }, [step]);

  return (
    <StyledComputerQuestionPage className={className} data-testid="ComputerQuestionPage">
      <ExamPaperHeader pageName={t('views.computerQuestionPage.__examPaperHeader')} globalExamPaperData={eduSubjectData} step={step} />
      {
        deviceData.isDesktop ?
          <StepBox isEditPaper={editCopyExamPaperData?.actionType} steps={steps} current={step - 1} onChange={onStepClick} stepdisabled={step === 6 ? true : false} type={'FastPattern'}>
            {
              (step === 1 && Object.keys(tableData).length > 0) && !isEditCopy &&
              <div className='warningBar'><ExclamationCircleSolid />{t('views.computerQuestionPage.__warningBar1')}</div>
            }

            {
              (step === 2 && questions.length > 0) && !isEditCopy &&
              <div className='warningBar'><ExclamationCircleSolid />{t('views.computerQuestionPage.__warningBar2')}</div>
            }

            {
              ((step === 2 || step === 3) && isShowQuestionLimitText) &&
              <QuestionLimitHint
                currentQuesNum={currentQuesNum}
                processType={processType}
                questionNumLimit={questionNumLimit}
              />
              // <div className='errorBar'><ExclamationCircleSolid/>每卷題數上限為{questionNumLimit}題，已選{totalQuesTypeData.questionCount}題，請減少題數。</div>
            }
            {step === 3 && editCopyExamPaperData && editCopyExamPaperData?.error?.total > 0 && isSuspendedQuestionsBarOpen && (
              <SuspendedQuestionsBar
                currentTotal={totalQuesTypeData.questionCount}
                error={editCopyExamPaperData.error}
                onClose={() => setIsSuspendedQuestionsBarOpen(false)}
              />
            )}
            {
              step === 1 &&
              <StyledHomePageContent>
                <HomeAreaSelectContent
                  examPaperType="FastPattern"
                  resetToggle={resetToggle}
                  saveData={saveData}
                  areaParams={areaParams}
                  getSendAreaParams={getSendAreaParams}
                  globalExamPaperData={globalExamPaperData}
                  memberEduSubject={eduSubjectData}
                  getKeyList={getKeyList}
                  setTableData={setTableData}
                  totalQuesTypeData={totalQuesTypeData}
                  getSaveData={getSaveData}
                  identity={identity}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  isEditCopy={isEditCopy}
                  isGoBack={isGoBack}
                  setIsGoBack={setIsGoBack}
                  setBookList={setBookList}
                  treeCheckedList={treeCheckedList}
                  setTreeCheckedList={setTreeCheckedList}
                  reCreateExam={reCreateExam}
                />
              </StyledHomePageContent>
            }
            {
              step === 2 &&
              <StyledHomePageContent>
                <HomeQuesTypeSelectContent
                  resetToggle={resetToggle}
                  tableData={tableData}
                  setTableData={setTableData}
                  keyList={keyList}
                  examPaperInfo={data}
                  getKeyList={getKeyList}
                  totalQuesTypeData={totalQuesTypeData}
                  globalExamPaperData={globalExamPaperData}
                  eduSubjectData={eduSubjectData}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  filterPaperUID={filterPaperUID}
                  renderFilterQuestionModal={renderFilterQuestionModal}
                  filterQuestionModalToggle={filterQuestionModalToggle}
                  setFilterQuestionModalFalse={setFilterQuestionModalFalse}
                  isChangeFilter={isChangeFilter}
                  setIsChangeFilter={setIsChangeFilter}
                />
              </StyledHomePageContent>
            }
            {
              step === 3 && JSON.stringify(tableData) !== '{}' &&
              <StyledHomePageContent>
                <HomeQuestionSelectContent
                  examPaperInfo={data}
                  searchKey={searchKey}
                  searchParams={searchParams}
                  examPaperTotalScoreData={totalQuesTypeData}
                  prevKeyList={keyList}
                  saveData={saveData}
                  eduSubjectData={eduSubjectData}
                  getSaveData={getSaveData}
                  getKeyList={getKeyList}
                  getTableData={getTableData}
                  totalQuesTypeData={totalQuesTypeData}
                  getQuestions={getQuestions}
                  globalExamPaperData={globalExamPaperData}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  reCreateSearchParams={reCreateSearchParams}
                  isEditCopy={isEditCopy}
                  editCopyExamPaperData={editCopyExamPaperData}
                  isJumpToStep5={isJumpToStep5}
                  setIsJumpToStep5={setIsJumpToStep5}
                  setStep={setStep}
                  filterQuestions={filterQuestions}
                  selectedBooks={selectedBooks}
                  tableData={tableData}
                  setTableData={setTableData}
                />
              </StyledHomePageContent>
            }
            {
              step === 4 &&
              <StyledHomePageContent>
                <ReviewPage
                  examPaperInfo={data}
                  searchKey={searchKey}
                  searchParams={searchParams}
                  examPaperTotalScoreData={totalQuesTypeData}
                  prevKeyList={keyList}
                  questions={questions}
                  saveData={saveData}
                  memberEduSubject={eduSubjectData}
                  getSaveData={getSaveData}
                  getKeyList={getKeyList}
                  getTableData={getTableData}
                  totalQuesTypeData={totalQuesTypeData}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  eduSubjectData={eduSubjectData}
                  tableData={tableData}
                  setTableData={setTableData}
                />
              </StyledHomePageContent>
            }
            {
              step === 5 &&
              <StyledHomePageContent>
                <HomeLayoutOptionContent
                  examPaperInfo={data}
                  searchKey={searchKey}
                  searchParams={searchParams}
                  keyList={keyList}
                  examPaperData={tableData}
                  examPaperTotalScoreData={totalQuesTypeData}
                  saveData={saveData}
                  globalExamPaperData={globalExamPaperData}
                  eduSubjectData={eduSubjectData}
                  getCreateExamPaperParams={getCreateExamPaperParams}
                  getCheckedSend={getCheckedSend}
                  getSaveData={getSaveData}
                  onePaperIdentity={userInfo?.onePaperIdentity}
                  step={step}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  areaParams={areaParams}
                  setOutSideSendParams={getSendParams}
                  isEditCopy={isEditCopy}
                  editCopyExamPaperData={editCopyExamPaperData}
                  tableData={tableData}
                  setTableData={setTableData}
                  totalQuesTypeData={totalQuesTypeData}
                />
              </StyledHomePageContent>
            }
            {
              step === 6 &&
              <StyledHomePageContent>
                <HomeQuestionCompleteContent
                  onlineExamUID={createExamPaperParams?.examUID}
                  examUID={createExamPaperParams?.examUID}
                  UID={submitResponseData?.exportId}
                  outputType={processType}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  globalExamPaperData={globalExamPaperData}
                  examPaperTotalScoreData={totalQuesTypeData}
                  sendParams={sendParams}
                  setStep={setStep}
                  setReCreateExam={setReCreateExam}
                  eduSubjectData={eduSubjectData}
                  setIsAgainDo={setIsAgainDo}
                  step={step}
                  isPro={userInfo?.isPro}
                >
                </HomeQuestionCompleteContent>
              </StyledHomePageContent>
            }
            <UiBtnZone>
              {
                !isLoading &&
                <>
                  {
                    step !== 1 && step !== 6 &&
                    <Button
                      disabled={prevStepDisabled}
                      type={prevStepDisabled ? 'default' : 'solid'}
                      textcolor={prevStepDisabled ? '#D5D7DE' : '#A1A4B1'}
                      variant={prevStepDisabled ? 'ui02' : 'brand1'}
                      size='large'
                      isFull={false}
                      onClick={() => { prevStep(); setIsGoBack(true); }}>
                      {t('views.computerQuestionPage.__uiBtnZone')}
                    </Button>
                  }
                  {
                    step !== 6 &&
                    <Button
                      disabled={disabled}
                      variant={disabled ? 'ui02' : 'brand1'}
                      size='large'
                      isFull={false}
                      onClick={() => { nextStepClickHandler(); setIsGoBack(false); }}
                    >
                      {
                        step !== 5 ?
                          t('views.computerQuestionPage.__nextStep') :
                          t('views.computerQuestionPage.__outputExamPaper')
                      }
                    </Button>
                  }

                  {
                    (step !== 1 && step !== 4 && step !== 5 && step !== 6) &&
                    <Button
                      type="solid"
                      textcolor={disabled ? '#A1A4B1' : userInfo?.isPro ? '#121232' : '#EC7963'}
                      disabled={disabled}
                      variant={disabled ? 'ui02' : 'brand1'}
                      size='large'
                      isFull={false}
                      onClick={() => { toFinalStepClickHandler(); setIsGoBack(false); }}
                    >
                      <FileAltOutline color={disabled ? '#A1A4B1' : userInfo?.isPro ? '#121232' : '#EC7963'} />{t('views.computerQuestionPage.__directOutputExam')}
                    </Button>
                  }
                </>
              }
            </UiBtnZone>
          </StepBox>
          :
          // ----------------------------- 手機版本 --------------------------------------------------
          // ----------------------------- 手機版本 --------------------------------------------------
          // ----------------------------- 手機版本 --------------------------------------------------
          <MobileStepBox isEditPaper={editCopyExamPaperData?.actionType} steps={steps} current={step - 1} onChange={onStepClick} stepdisabled={step === 6 ? true : false} type={'FastPattern'}>
            {
              (step === 1 && Object.keys(tableData).length > 0) && !isEditCopy &&
              <div className='warningBar'><ExclamationCircleSolid />{t('views.computerQuestionPage.__warningChangeSelection')}</div>
            }

            {
              (step === 2 && questions.length > 0) && !isEditCopy &&
              <div className='warningBar'><ExclamationCircleSolid />{t('views.computerQuestionPage.__warningChangeSelection2')}</div>
            }

            {
              ((step === 2 || step === 3) && isShowQuestionLimitText) &&
              <QuestionLimitHint
                currentQuesNum={currentQuesNum}
                processType={processType}
                questionNumLimit={questionNumLimit}
              />
              // <div className='errorBar'><ExclamationCircleSolid/>每卷題數上限為{questionNumLimit}題，已選{totalQuesTypeData.questionCount}題，請減少題數。</div>
            }

            {/* {
              (step === 2 && isShowQuestionLimitText) &&
              <div className='errorBar'><ExclamationCircleSolid/>每卷題數上限為{questionNumLimit}題，已選{totalQuesTypeData.questionCount}題，請減少題數。</div>
            } */}
            {step === 3 && editCopyExamPaperData && editCopyExamPaperData?.error?.total > 0 && isSuspendedQuestionsBarOpen && (
              <SuspendedQuestionsBar
                currentTotal={totalQuesTypeData.questionCount}
                error={editCopyExamPaperData.error}
                onClose={() => setIsSuspendedQuestionsBarOpen(false)}
              />
            )}
            {
              step === 1 &&
              <StyledHomePageContent>
                <HomeAreaSelectContent
                  examPaperType="FastPattern"
                  resetToggle={resetToggle}
                  saveData={saveData}
                  areaParams={areaParams}
                  getSendAreaParams={getSendAreaParams}
                  globalExamPaperData={globalExamPaperData}
                  memberEduSubject={eduSubjectData}
                  getKeyList={getKeyList}
                  setTableData={setTableData}
                  totalQuesTypeData={totalQuesTypeData}
                  getSaveData={getSaveData}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  isEditCopy={isEditCopy}
                  identity={identity}
                  isGoBack={isGoBack}
                  setIsGoBack={setIsGoBack}
                  setBookList={setBookList}
                  treeCheckedList={treeCheckedList}
                  setTreeCheckedList={setTreeCheckedList}
                  reCreateExam={reCreateExam}
                />
              </StyledHomePageContent>
            }
            {
              step === 2 &&
              <StyledHomePageContent>
                <HomeQuesTypeSelectContent
                  resetToggle={resetToggle}
                  keyList={keyList}
                  examPaperInfo={data}
                  getKeyList={getKeyList}
                  getTableData={getTableData}
                  totalQuesTypeData={totalQuesTypeData}
                  globalExamPaperData={globalExamPaperData}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  filterPaperUID={filterPaperUID}
                  renderFilterQuestionModal={renderFilterQuestionModal}
                  filterQuestionModalToggle={filterQuestionModalToggle}
                  setFilterQuestionModalFalse={setFilterQuestionModalFalse}
                  tableData={tableData}
                  setTableData={setTableData}
                />
              </StyledHomePageContent>
            }
            {
              step === 3 && JSON.stringify(tableData) !== '{}' &&
              <StyledHomePageContent>
                <HomeQuestionSelectContent
                  examPaperInfo={data}
                  searchKey={searchKey}
                  searchParams={searchParams}
                  examPaperTotalScoreData={totalQuesTypeData}
                  prevKeyList={keyList}
                  saveData={saveData}
                  globalExamPaperData={globalExamPaperData}
                  eduSubjectData={eduSubjectData}
                  getSaveData={getSaveData}
                  getKeyList={getKeyList}
                  getTableData={getTableData}
                  totalQuesTypeData={totalQuesTypeData}
                  getQuestions={getQuestions}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  reCreateSearchParams={reCreateSearchParams}
                  isEditCopy={isEditCopy}
                  editCopyExamPaperData={editCopyExamPaperData}
                  isJumpToStep5={isJumpToStep5}
                  setIsJumpToStep5={setIsJumpToStep5}
                  setStep={setStep}
                  filterQuestions={filterQuestions}
                  selectedBooks={selectedBooks}
                  tableData={tableData}
                  setTableData={setTableData}
                />
              </StyledHomePageContent>
            }
            {/* {
              step === 4 &&
              <StyledHomePageContent>
                <HomeQuestionReviewPage
                  examPaperInfo={data}
                  searchKey={searchKey}
                  searchParams={searchParams}
                  examPaperTotalScoreData={totalQuesTypeData}
                  prevKeyList={keyList}
                  questions={questions}
                  saveData={saveData}
                  getSaveData={getSaveData}
                  getKeyList={getKeyList}
                  getTableData={getTableData}
                  totalQuesTypeData={totalQuesTypeData}
                  memberEduSubject={eduSubjectData}
                  eduSubjectData={eduSubjectData}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  tableData={tableData}
                  setTableData={setTableData}
                />
              </StyledHomePageContent>
            } */}
            {
              step === 4 &&
              <StyledHomePageContent>
                <ReviewPage
                  examPaperInfo={data}
                  searchKey={searchKey}
                  searchParams={searchParams}
                  examPaperTotalScoreData={totalQuesTypeData}
                  prevKeyList={keyList}
                  questions={questions}
                  saveData={saveData}
                  memberEduSubject={eduSubjectData}
                  getSaveData={getSaveData}
                  getKeyList={getKeyList}
                  getTableData={getTableData}
                  totalQuesTypeData={totalQuesTypeData}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  eduSubjectData={eduSubjectData}
                  tableData={tableData}
                  setTableData={setTableData}
                />
              </StyledHomePageContent>
            }
            {
              step === 5 &&
              <StyledHomePageContent>
                <HomeLayoutOptionContent
                  searchKey={searchKey}
                  searchParams={searchParams}
                  keyList={keyList}
                  examPaperData={tableData}
                  examPaperTotalScoreData={totalQuesTypeData}
                  saveData={saveData}
                  globalExamPaperData={globalExamPaperData}
                  eduSubjectData={eduSubjectData}
                  getCreateExamPaperParams={getCreateExamPaperParams}
                  getCheckedSend={getCheckedSend}
                  getSaveData={getSaveData}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  areaParams={areaParams}
                  setOutSideSendParams={getSendParams}
                  isEditCopy={isEditCopy}
                  editCopyExamPaperData={editCopyExamPaperData}
                  tableData={tableData}
                  setTableData={setTableData}
                  totalQuesTypeData={totalQuesTypeData}
                />
              </StyledHomePageContent>
            }
            {
              step === 6 &&
              <StyledHomePageContent>
                <HomeQuestionCompleteContent
                  onlineExamUID={createExamPaperParams?.examUID}
                  examUID={createExamPaperParams?.examUID}
                  UID={submitResponseData?.exportId}
                  outputType={processType}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  globalExamPaperData={globalExamPaperData}
                  sendParams={sendParams}
                  setStep={setStep}
                  setReCreateExam={setReCreateExam}
                  examPaperTotalScoreData={totalQuesTypeData}
                  eduSubjectData={eduSubjectData}
                  setIsAgainDo={setIsAgainDo}
                  step={step}
                  isPro={userInfo?.isPro}
                >

                </HomeQuestionCompleteContent>
              </StyledHomePageContent>
            }
            <UiBtnZone>
              {
                !isLoading &&
                <>
                  {
                    step !== 1 && step !== 6 &&
                    <Button
                      className={'prevStepBtn'}
                      disabled={prevStepDisabled}
                      type={prevStepDisabled ? 'default' : 'solid'}
                      textcolor={prevStepDisabled ? '#D5D7DE' : '#A1A4B1'}
                      variant={prevStepDisabled ? 'ui02' : 'brand1'}
                      size='large'
                      isFull={false}
                      onClick={() => { prevStep(); setIsGoBack(true); }}>
                      {t('views.computerQuestionPage.__prevStepBtn')}
                    </Button>
                  }
                  {
                    (step !== 1 && step !== 4 && step !== 5 && step !== 6) &&
                    <Button
                      type="solid"
                      textcolor={disabled ? '#A1A4B1' : userInfo?.isPro ? '#121232' : '#EC7963'}
                      className={disabled ? 'disabledText' : 'goToSteps5Btn'}
                      disabled={disabled}
                      variant={disabled ? 'ui02' : 'brand1'}
                      size='large'
                      isFull={false}
                      onClick={() => { toFinalStepClickHandler(); setIsGoBack(false); }}
                    >
                      <FileAltSolid color={disabled ? '#A1A4B1' : userInfo?.isPro ? '#121232' : '#EC7963'} />{t('views.computerQuestionPage.__goToSteps5Btn')}
                    </Button>
                  }
                  {
                    step !== 6 &&
                    <Button
                      disabled={disabled}
                      variant={disabled ? 'ui02' : 'brand1'}
                      size='large'
                      isFull={false}
                      onClick={() => { nextStepClickHandler(); setIsGoBack(false); }}
                    >
                      {
                        step !== 5 ?
                          t('views.computerQuestionPage.__nextStepBtn') :
                          t('views.computerQuestionPage.__outputExamPaperBtn')

                      }
                    </Button>
                  }
                </>
              }
            </UiBtnZone>
          </MobileStepBox>
      }
      <Modal
        visible={areaModalVisible}
        onCancel={setAreaModalFalse}
        className={'mobileModal'}
        title={
          <div>{t('views.computerQuestionPage.__changeAreaConfirmation')}</div>
        }
        footer={
          <div className="footerBtn footerBtnBox">
            <Button variant='ui01' onClick={() => { getSendAreaParams(saveData.areaParams); setAreaModalFalse(); setResetToggle(!resetToggle); }}>{t('views.computerQuestionPage.__cancelChange')}</Button>
            <Button variant={'brand1'} onClick={() => { nextStep(); setAreaModalFalse(); setIsGoBack(false); }}>{t('views.computerQuestionPage.__confirmChange')}</Button>
          </div>
        }
      >
        <div className="modalContent">
          <div>{t('views.computerQuestionPage.__areaChangeMessage1')}</div>
          <div>{t('views.computerQuestionPage.__areaChangeMessage2')}</div>
          <div>{t('views.computerQuestionPage.__areaChangeMessage3')}</div>
        </div>
      </Modal>

      <Modal
        visible={modalVisible}
        onCancel={setModalFalse}
        className={'mobileModal'}
        title={
          <div>{t('views.computerQuestionPage.__changeTypeAndNumberConfirmation')}</div>
        }
        footer={
          <div className="footerBtn footerBtnBox">
            <Button variant='ui01' onClick={() => { setModalFalse(); getTableData(saveData.tableData); setResetToggle(!resetToggle); }}>{t('views.computerQuestionPage.__cancelChange')}</Button>
            <Button variant={'brand1'} onClick={() => { nextStep(); }}>{t('views.computerQuestionPage.__confirmChange')}</Button>
          </div>
        }
      >
        <div className="modalContent">
          <div>{t('views.computerQuestionPage.__typeNumberChangeMessage')}</div>
          <div>{t('views.computerQuestionPage.__typeNumberChangeMessage2')}</div>
          <div>{t('views.computerQuestionPage.__typeNumberChangeMessage3')}</div>
        </div>
      </Modal>

      <Modal
        visible={emptyTextModalVisible}
        onCancel={setEmptyTextModalFalse}
        className={'mobileModal'}
        id={'emptyTextModal'}
        title={
          <div>{t('views.computerQuestionPage.__emptyTextModalTitle')}</div>
        }
        footer={
          <div className="footerBtn footerBtnBox">
            <Button
              variant={'brand1'}
              onClick={setEmptyTextModalFalse}
            >
              {t('views.computerQuestionPage.__emptyTextModalButton')}
            </Button>
          </div>
        }
      >
        <div>{t('views.computerQuestionPage.__emptyTextModalContent')}</div>
      </Modal>

      <QuestionLimitModal
        visible={questionCountModalVisible}
        onCancel={setQuestionCountModalFalse}
        currentQuesNum={currentQuesNum}
        processType={processType}
        questionNumLimit={questionNumLimit}
      />

    </StyledComputerQuestionPage>
  );
};