import React from 'react';
import { StyledDifferentTag } from './DifferentTag.style';
import { useTranslation } from 'react-i18next';

export const DifferentTag = ({ className, variant = 'easy', mr }) => {
  const { t } = useTranslation();
  return (
    <StyledDifferentTag className={className} data-testid="DifferentTag" variant={variant} mr={mr}>
      {t(`constants.question.difficulty.${variant}`)}
    </StyledDifferentTag>
  );
};