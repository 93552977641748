import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider, SearchExamPaperProvider, CreateExamPaperParamsProvider, EditCopyExamPaperProvider } from '@oneclass/ui-components';
import { ConfigProvider } from 'antd';
import vi from 'antd/lib/locale/vi_VN';
import en from 'antd/lib/locale/en_US';
import zh from 'antd/lib/locale/zh_TW';
import 'antd/dist/antd.css';
import './index.css';
import ReactGA from 'react-ga4';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { default as firebaseRemoteConfigProvider } from 'providers/firebaseRemoteConfigProvider.js';
import { default as behaviorProvider } from 'providers/behaviorProvider.js';
import { default as UserProvider } from 'providers/UserProvider.js';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { TranslationProvider } from './providers/TranslationProvider';
import { useTranslation } from 'react-i18next';

// Google Analytics
const env = process.env.REACT_APP_NODE_ENV;
let GAID = 'G-T2D9MRXE1W'; // dev & uat
if (env === 'release') GAID = 'G-NQW46VECJM'; // release
ReactGA.initialize(GAID);
ReactGA.send('pageview');

if (process.env.REACT_APP_NODE_ENV === 'release' || process.env.REACT_APP_NODE_ENV === 'uat') {
  Sentry.init({
    dsn: 'https://58c012fbabf44d8eaacf9161a6d27231@o1420729.ingest.sentry.io/4504460948471808',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_NODE_ENV
  });
}

const root = document.getElementById('root');
const Main = () => {
  const { i18n } = useTranslation();
  const languages = { en, vi, zh };
  const [language, setLanguage] = useState(languages[i18n.language]);
  useEffect(() => {
    setLanguage(languages[i18n.language]);
  }, [i18n.language]);
  return (
    <BrowserRouter>
      <AuthProvider.Provider
        apiBaseUrl={process.env.REACT_APP_DOMAIN}
        env={process.env.REACT_APP_NODE_ENV}
      >
        {/* <ThemeProvider isPro={false}> */}
        <ConfigProvider locale={language}>
          <UserProvider.Provider>
            <deviceProvider.Provider>
              <firebaseRemoteConfigProvider.Provider>
                <behaviorProvider.Provider>
                  <TranslationProvider>
                    <CreateExamPaperParamsProvider.Provider>
                      <EditCopyExamPaperProvider.Provider>
                        <SearchExamPaperProvider.Provider>
                          <App />
                        </SearchExamPaperProvider.Provider>
                      </EditCopyExamPaperProvider.Provider>
                    </CreateExamPaperParamsProvider.Provider>
                  </TranslationProvider>
                </behaviorProvider.Provider>
              </firebaseRemoteConfigProvider.Provider>
            </deviceProvider.Provider>
          </UserProvider.Provider>
        </ConfigProvider>
        {/* </ThemeProvider> */}
      </AuthProvider.Provider>
    </BrowserRouter>
  );
};

ReactDOM.render(<Main />, root);

reportWebVitals();
