import { useEffect, useContext } from 'react';
import { useBitrix } from 'utils/hooks/useBitrix';
import AppRoutes from './routes';
import { getCookie } from 'utils/common';
import { default as UserProvider } from 'providers/UserProvider.js';

const cookieLoginName = 'nani_oneclass_login_token';

const App = () => {
  const { insert, hideButton } = useBitrix();
  const { userData } = useContext(UserProvider.UserProviderContext);
  const isPro = userData?.userInfo?.isPro;

  useEffect(() => {
    // insert(); // temporary hide the bitrix
    let time = setInterval(() => {
      const isShow = document.getElementsByClassName('b24-widget-button-wrapper')?.[0];

      const handleHideButton = () => {
        clearInterval(time);
        hideButton();
      };
      if (isShow && (!getCookie(cookieLoginName) || isPro) ) {
        handleHideButton();
      }
    }, 100);
    return () => clearInterval(time);
  }, [isPro]);

  return <AppRoutes />;
};
export default App;
