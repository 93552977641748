import React, { useEffect } from 'react';
import { StyledQuestionLimitHint } from './QuestionLimitHint.style';
import { ExclamationCircleSolid } from '@onedesign/icon';
import { PROCESS_PARAMS } from 'constants/index';
import { useTranslation } from 'react-i18next';

export const QuestionLimitHint = ({ className, currentQuesNum, processType, questionNumLimit }) => {
	const { t } = useTranslation();
  return (
    <StyledQuestionLimitHint className={className} data-testid="QuestionLimitHint">
      <ExclamationCircleSolid/>
      {t('components.reviewPage.__questionLimitHint', {quesLimitText: PROCESS_PARAMS[processType]?.quesLimitText,questionNumLimit, currentQuesNum})}
    </StyledQuestionLimitHint>
  );
};
