// BACK-END TRANSLATION
// THERE IS SOME VALUE ARE COMING FROM BACKEND

// how to get all possible value
// just god know

// BOOK NAME = VOLUME
export const questionTypeMapper = (value) => {
  const element = QUESTION_TYPES.find((e) => e.value === value || e.name === value);
  if (!element) {
    window.console.warn('Can not find element for backend translation', { value });
    return '';
  }
  return `commonTranslation.questionType.${element.t_key}`;
};

export const groupQuestionTypeMapper = (value) => {
  const element = GROUP_QUESTION_TYPES.find((e) => e.code === value || e.name === value);
  if (!element) {
    window.console.warn('Can not find element for backend translation', { value });
    return '';
  }
  return `commonTranslation.groupQuestionType.${element.t_key}`;
};

const GROUP_QUESTION_TYPES = [
  {
    code: '1',
    name: '(　　)1.題目……',
    t_key: 'itemWithNumberInParentheses',
  },
  {
    code: '2',
    name: '(　　)(　　)1.題目……',
    t_key: 'itemWithTwoNumbersInParentheses',
  },
  {
    code: '3',
    name: '1.題目……',
    t_key: 'itemWithNumberOnly',
  },
  {
    code: '4',
    name: '1.題目+空白區塊',
    t_key: 'itemWithNumberAndBlankSpace',
  },
  {
    code: '5',
    name: '1.____________',
    t_key: 'itemWithUnderline',
  },
  {
    code: '6',
    name: '____________1.',
    t_key: 'itemWithUnderlineAtEnd',
  },
  {
    code: '7',
    name: '1.題目+一行空行',
    t_key: 'itemWithNumberAndLineBreak',
  },
  {
    code: 'FL',
    name: '填充題',
    t_key: 'fillingQuestion',
  },
  {
    code: 'GR',
    name: '題組題',
    t_key: 'groupQuestion',
  },
  {
    code: 'MT',
    name: '多選題',
    t_key: 'multipleChoiceQuestion',
  },
  {
    code: 'MX',
    name: '混合題',
    t_key: 'mixedQuestion',
  },
  {
    code: 'SS',
    name: '單一選擇題',
    t_key: 'singleSelectQuestion',
  },
  {
    code: 'WR',
    name: '非選題',
    t_key: 'nonSelectQuestion',
  },
  {
    code: 'YN',
    name: '是非題',
    t_key: 'yesNoQuestion',
  },
];

const QUESTION_TYPES = [
  {
    name: '題組題',
    t_key: 'questionSet',
    code: 'FL001',
    groupCode: 'FL',
  },
  {
    name: '科學閱讀',
    t_key: 'scientificReading',
    code: 'FL002',
    groupCode: 'FL',
  },
  {
    name: '實驗題組',
    t_key: 'experimentalQuestionSet',
    code: 'FL003',
    groupCode: 'FL',
  },
  {
    name: '克漏字填空',
    t_key: 'clozePassage',
    code: 'FL004',
    groupCode: 'FL',
  },
  {
    name: '看圖填填看',
    t_key: 'pictureFillIn',
    code: 'FL005',
    groupCode: 'FL',
  },
  {
    name: '看圖填寫',
    t_key: 'fillInByLookingAtPictures',
    code: 'FL006',
    groupCode: 'FL',
  },
  {
    name: '填充題',
    t_key: 'fillInTheBlank',
    code: 'FL007',
    groupCode: 'FL',
  },
  {
    name: '填單位量詞',
    t_key: 'fillInUnitsAndQuantifiers',
    code: 'FL008',
    groupCode: 'FL',
  },
  {
    name: '填填看',
    t_key: 'fillInAndCheck',
    code: 'FL009',
    groupCode: 'FL',
  },
  {
    name: '填標點符號',
    t_key: 'fillInPunctuationMarks',
    code: 'FL010',
    groupCode: 'FL',
  },
  {
    name: '填單位量詞(筆述)',
    t_key: 'fillInUnitsAndQuantifiersDescription',
    code: 'FL011',
    groupCode: 'FL',
  },
  {
    name: '選詞填寫(筆述)',
    t_key: 'chooseWordsToFillInDescription',
    code: 'FL012',
    groupCode: 'FL',
  },
  {
    name: '填代號',
    t_key: 'fillInCodes',
    code: 'FL013',
    groupCode: 'FL',
  },
  {
    name: '填入適合的字或詞(填入適合的字)',
    t_key: 'fillInTheAppropriateCharactersOrWordsCharacters',
    code: 'FL014',
    groupCode: 'FL',
  },
  {
    name: '填入適合的字或詞(填入適合的詞)',
    t_key: 'fillInTheAppropriateCharactersOrWordsWords',
    code: 'FL015',
    groupCode: 'FL',
  },
  {
    name: '將語詞填入正確的解釋中，請填代號',
    t_key: 'fillWordsIntoTheCorrectExplanation',
    code: 'FL016',
    groupCode: 'FL',
  },
  {
    name: '依據提示填寫答案(寫出相同的部分)',
    t_key: 'fillInTheAnswerBasedOnThePromptWriteTheSamePart',
    code: 'FL017',
    groupCode: 'FL',
  },
  {
    name: '依據提示填寫答案(寫相反詞)',
    t_key: 'fillInTheAnswerBasedOnThePromptWriteTheOppositeWord',
    code: 'FL018',
    groupCode: 'FL',
  },
  {
    name: '依據提示填寫答案(寫筆畫數)',
    t_key: 'fillInTheAnswerBasedOnThePromptWriteTheStrokeCount',
    code: 'FL019',
    groupCode: 'FL',
  },
  {
    name: '看圖填字',
    t_key: 'pictureFillInCharacters',
    code: 'FL020',
    groupCode: 'FL',
  },
  {
    name: '成語填空',
    t_key: 'idiomFillIn',
    code: 'FL021',
    groupCode: 'FL',
  },
  {
    name: '填充題(單字中翻英)',
    t_key: 'fillInTheBlankTranslateFromChineseToEnglish',
    code: 'FL022',
    groupCode: 'FL',
  },
  {
    name: '填充題(看圖寫單字)',
    t_key: 'fillInTheBlankWriteWordsBasedOnPictures',
    code: 'FL023',
    groupCode: 'FL',
  },
  {
    name: '填充題(寫出名詞的複數形)',
    t_key: 'fillInTheBlankWriteThePluralFormOfNouns',
    code: 'FL024',
    groupCode: 'FL',
  },
  {
    name: '填充題(寫出動詞的過去式)',
    t_key: 'fillInTheBlankWriteThePastTenseOfVerbs',
    code: 'FL025',
    groupCode: 'FL',
  },
  {
    name: '填充題(文法)',
    t_key: 'fillInTheBlankGrammar',
    code: 'FL026',
    groupCode: 'FL',
  },
  {
    name: '填充題(單字)',
    t_key: 'fillInTheBlankWords',
    code: 'FL027',
    groupCode: 'FL',
  },
  {
    name: '填充題(寫出動詞的過去分詞)',
    t_key: 'fillInTheBlankWriteThePastParticipleOfVerbs',
    code: 'FL028',
    groupCode: 'FL',
  },
  {
    name: '翻譯填空',
    t_key: 'translationFillIn',
    code: 'FL029',
    groupCode: 'FL',
  },
  {
    name: '根據提示寫答案(根據提示寫答案)',
    t_key: 'writeAnswersBasedOnPrompts',
    code: 'FL030',
    groupCode: 'FL',
  },
  {
    name: '根據提示寫答案(寫同部首的字)',
    t_key: 'writeAnswersBasedOnPromptsWriteCharactersWithTheSameRadical',
    code: 'FL031',
    groupCode: 'FL',
  },
  {
    name: '生字成語(改錯)(停用)',
    t_key: 'newWordsAndIdiomsErrorCorrectionDisabled',
    code: 'FL032',
    groupCode: 'FL',
  },
  {
    name: '字彙填空(認識字彙)',
    t_key: 'vocabularyFillInKnowVocabulary',
    code: 'FL033',
    groupCode: 'FL',
  },
  {
    name: '字彙填空(應用字彙)',
    t_key: 'vocabularyFillInApplyVocabulary',
    code: 'FL034',
    groupCode: 'FL',
  },
  {
    name: '字彙填空',
    t_key: 'vocabularyFillIn',
    code: 'FL035',
    groupCode: 'FL',
  },
  {
    name: '選字詞填寫',
    t_key: 'chooseWordsToFillIn2',
    code: 'FL036',
    groupCode: 'FL',
  },
  {
    name: '選字詞填寫，請填代號',
    t_key: 'chooseWordsToFillInPleaseFillInCodes',
    code: 'FL037',
    groupCode: 'FL',
  },
  {
    name: '文意字彙填空',
    t_key: 'contextualVocabularyFillIn',
    code: 'FL038',
    groupCode: 'FL',
  },
  {
    name: '整段式選擇(克漏字)',
    t_key: 'wholeParagraphSelectionCloze',
    code: 'FL039',
    groupCode: 'FL',
  },
  {
    name: '發音題-寫下聽到母音字母',
    t_key: 'pronunciationQuestionWriteDownHeardVowelLetters',
    code: 'FL040',
    groupCode: 'FL',
  },
  {
    name: '發音題-寫出聽到的字首字母',
    t_key: 'pronunciationQuestionWriteDownHeardInitialLetters',
    code: 'FL041',
    groupCode: 'FL',
  },
  {
    name: '選詞填寫(填充)',
    t_key: 'chooseWordsToFillInFill',
    code: 'FL042',
    groupCode: 'FL',
  },
  {
    name: '選詞填寫',
    t_key: 'chooseWordsToFillIn',
    code: 'GR001',
    groupCode: 'GR',
  },
  {
    name: '克漏字選擇',
    t_key: 'clozeSelection',
    code: 'GR002',
    groupCode: 'GR',
  },
  {
    name: '數學基測題 (停用)',
    t_key: 'mathBasicsTestQuestions (Discontinued)',
    code: 'GR003',
    groupCode: 'GR',
  },
  {
    name: '閱讀測驗',
    t_key: 'readingTest',
    code: 'GR004',
    groupCode: 'GR',
  },
  {
    name: '題組',
    t_key: 'questionGroup',
    code: 'GR005',
    groupCode: 'GR',
  },
  {
    name: '字詞義測驗(題組)',
    t_key: 'wordMeaningTestQuestionGroup',
    code: 'GR006',
    groupCode: 'GR',
  },
  {
    name: '成語閱讀測驗',
    t_key: 'idiomReadingTest',
    code: 'GR007',
    groupCode: 'GR',
  },
  {
    name: '修辭練習(題組題)',
    t_key: 'rhetoricalPracticeQuestionSet',
    code: 'GR008',
    groupCode: 'GR',
  },
  {
    name: '成語運用(題組題)',
    t_key: 'idiomApplicationQuestionSet',
    code: 'GR009',
    groupCode: 'GR',
  },
  {
    name: '科學閱讀(題組)',
    t_key: 'scientificReadingQuestionSet',
    code: 'GR010',
    groupCode: 'GR',
  },
  {
    name: '多重選擇題',
    t_key: 'multipleChoiceQuestions',
    code: 'MT001',
    groupCode: 'MT',
  },
  {
    name: '多選題(2)',
    t_key: 'multipleChoice(2)',
    code: 'MT002',
    groupCode: 'MT',
  },
  {
    name: '題組(混合)',
    t_key: 'questionGroupMixed',
    code: 'MX001',
    groupCode: 'MX',
  },
  {
    name: '閱讀測驗(混合)',
    t_key: 'readingTestMixed',
    code: 'MX002',
    groupCode: 'MX',
  },
  {
    name: '混合題組',
    t_key: 'mixedQuestionGroup',
    code: 'MX003',
    groupCode: 'MX',
  },
  {
    name: '科學閱讀(混合)',
    t_key: 'scientificReadingMixed',
    code: 'MX004',
    groupCode: 'MX',
  },
  {
    name: '選出正確的答案',
    t_key: 'selectTheCorrectAnswer',
    code: 'SS001',
    groupCode: 'SS',
  },
  {
    name: '選出正確的讀音',
    t_key: 'selectTheCorrectPronunciation',
    code: 'SS002',
    groupCode: 'SS',
  },
  {
    name: '選出適當的答案',
    t_key: 'selectTheAppropriateAnswer',
    code: 'SS003',
    groupCode: 'SS',
  },
  {
    name: '選擇題',
    t_key: 'multipleChoiceQuestion',
    code: 'SS004',
    groupCode: 'SS',
  },
  {
    name: '選擇題(字音字形)',
    t_key: 'multipleChoiceCharacterPronunciationAndForm',
    code: 'SS005',
    groupCode: 'SS',
  },
  {
    name: '選擇題(字詞義及運用)',
    t_key: 'multipleChoiceWordMeaningAndUsage',
    code: 'SS006',
    groupCode: 'SS',
  },
  {
    name: '選擇題(文意理解)',
    t_key: 'multipleChoiceComprehension',
    code: 'SS007',
    groupCode: 'SS',
  },
  {
    name: '選擇題(標點符號)',
    t_key: 'multipleChoicePunctuationMarks',
    code: 'SS008',
    groupCode: 'SS',
  },
  {
    name: '選擇題(基本修辭)',
    t_key: 'multipleChoiceBasicRhetoric',
    code: 'SS009',
    groupCode: 'SS',
  },
  {
    name: '選擇題(其他)',
    t_key: 'multipleChoiceQuestionOther',
    code: 'SS010',
    groupCode: 'SS',
  },
  {
    name: '選擇題(生字延伸成語)',
    t_key: 'multipleChoiceQuestionIdiomsExtendedFromNewWords',
    code: 'SS011',
    groupCode: 'SS',
  },
  {
    name: '選擇題(統整活動)',
    t_key: 'multipleChoiceQuestionIntegratedActivities',
    code: 'SS012',
    groupCode: 'SS',
  },
  {
    name: '文法',
    t_key: 'grammar',
    code: 'SS013',
    groupCode: 'SS',
  },
  {
    name: '字詞義測驗',
    t_key: 'vocabularyMeaningTest',
    code: 'SS014',
    groupCode: 'SS',
  },
  {
    name: '字彙選擇',
    t_key: 'vocabularySelection',
    code: 'SS015',
    groupCode: 'SS',
  },
  {
    name: '修辭練習',
    t_key: 'rhetoricalPractice',
    code: 'SS016',
    groupCode: 'SS',
  },
  {
    name: '單選題',
    t_key: 'singleChoiceQuestion',
    code: 'SS017',
    groupCode: 'SS',
  },
  {
    name: '對話',
    t_key: 'dialogue',
    code: 'SS018',
    groupCode: 'SS',
  },
  {
    name: '課文文意測驗',
    t_key: 'textualMeaningTest',
    code: 'SS019',
    groupCode: 'SS',
  },
  {
    name: '字詞義測驗(選擇)',
    t_key: 'vocabularyMeaningTestMultipleChoice',
    code: 'SS020',
    groupCode: 'SS',
  },
  {
    name: '課文文意測驗(選擇)',
    t_key: 'textualMeaningTestMultipleChoice',
    code: 'SS021',
    groupCode: 'SS',
  },
  {
    name: '生字成語(選擇)',
    t_key: 'newWordsAndIdiomsMultipleChoice',
    code: 'SS022',
    groupCode: 'SS',
  },
  {
    name: '選出適當的語詞',
    t_key: 'chooseAppropriateWords',
    code: 'SS023',
    groupCode: 'SS',
  },
  {
    name: '成語運用(選擇題)',
    t_key: 'idiomApplicationMultipleChoiceQuestion',
    code: 'SS024',
    groupCode: 'SS',
  },
  {
    name: '高層次思考題',
    t_key: 'higherOrderThinkingQuestions',
    code: 'SS025',
    groupCode: 'SS',
  },
  {
    name: '字彙選擇(認識字彙)',
    t_key: 'vocabularySelectionUnderstandingVocabulary',
    code: 'SS026',
    groupCode: 'SS',
  },
  {
    name: '字彙選擇(應用字彙)',
    t_key: 'vocabularySelectionApplyingVocabulary',
    code: 'SS027',
    groupCode: 'SS',
  },
  {
    name: '填單位量詞(選擇)',
    t_key: 'fillInUnitsAndQuantifiersMultipleChoice',
    code: 'SS028',
    groupCode: 'SS',
  },
  {
    name: '選詞填寫(選擇)',
    t_key: 'chooseWordsToFillInMultipleChoice',
    code: 'SS029',
    groupCode: 'SS',
  },
  {
    name: '計算選擇題',
    t_key: 'calculationMultipleChoiceQuestion',
    code: 'SS030',
    groupCode: 'SS',
  },
  {
    name: '閱讀理解',
    t_key: 'readingComprehension',
    code: 'SS031',
    groupCode: 'SS',
  },
  {
    name: '統整活動',
    t_key: 'integratedActivities',
    code: 'SS032',
    groupCode: 'SS',
  },
  {
    name: '發音題-根據聽到的發音，選出對應的字母',
    t_key: 'pronunciationQuestion-SelectLettersBasedOnHeardPronunciation',
    code: 'SS033',
    groupCode: 'SS',
  },
  {
    name: '發音題-選出聽到的字首字母',
    t_key: 'pronunciationQuestion-SelectHeardInitialLetters',
    code: 'SS034',
    groupCode: 'SS',
  },
  {
    name: '發音題-寫出聽到的字首和字尾字母',
    t_key: 'pronunciationQuestion-WriteHeardInitialAndFinalLetters',
    code: 'SS035',
    groupCode: 'SS',
  },
  {
    name: '發音題-根據聽到的發音，選出母音發音不同的字',
    t_key: 'pronunciationQuestion-SelectWordsWithDifferentVowelPronunciationsBasedOnHeardPronunciation',
    code: 'SS036',
    groupCode: 'SS',
  },
  {
    name: '發音題-圈出聽到的字尾',
    t_key: 'pronunciationQuestion-CircleHeardWordEndings',
    code: 'SS037',
    groupCode: 'SS',
  },
  {
    name: '發音題-根據聽到的發音，選出子音發音不同的字',
    t_key: 'pronunciationQuestion-SelectWordsWithDifferentConsonantPronunciationsBasedOnHeardPronunciation',
    code: 'SS038',
    groupCode: 'SS',
  },
  {
    name: '發音題-選出聽到的單字',
    t_key: 'pronunciationQuestion-SelectHeardWords',
    code: 'SS039',
    groupCode: 'SS',
  },
  {
    name: '發音題-選出聽到母音字母',
    t_key: 'pronunciationQuestion-SelectHeardVowelLetters',
    code: 'SS040',
    groupCode: 'SS',
  },
  {
    name: '單字選擇-選出聽到的單字',
    t_key: 'wordSelection-SelectHeardWords',
    code: 'SS041',
    groupCode: 'SS',
  },
  {
    name: '辨識圖片單字-根據聽到的單字，選出正確的圖片代碼',
    t_key: 'identifyPictureWords-SelectCorrectImageCodeBasedOnHeardWords',
    code: 'SS042',
    groupCode: 'SS',
  },
  {
    name: '辨識句意-根據聽到的內容，選出正確的圖片代碼',
    t_key: 'identifySentenceMeaning-SelectCorrectImageCodeBasedOnHeardContent',
    code: 'SS043',
    groupCode: 'SS',
  },
  {
    name: '基本問答-根據聽到的內容，選出合適的回應',
    t_key: 'basicQuestionsAndAnswers-SelectAppropriateResponseBasedOnHeardContent',
    code: 'SS044',
    groupCode: 'SS',
  },
  {
    name: '言談理解-根據聽到的對話內容與問題，選出正確的答案',
    t_key: 'conversationComprehension-SelectCorrectAnswerBasedOnHeardDialogueAndQuestions',
    code: 'SS045',
    groupCode: 'SS',
  },
  {
    name: '數學基測題',
    t_key: 'mathematicsBasicTestQuestions',
    code: 'SS046',
    groupCode: 'SS',
  },
  {
    name: '選詞',
    t_key: 'wordSelection',
    code: 'WR001',
    groupCode: 'WR',
  },
  {
    name: '綜合測驗',
    t_key: 'comprehensiveTest',
    code: 'WR002',
    groupCode: 'WR',
  },
  {
    name: '閱讀理解題',
    t_key: 'readingComprehensionQuestions',
    code: 'WR003',
    groupCode: 'WR',
  },
  {
    name: '勾選題',
    t_key: 'checkboxQuestion',
    code: 'WR004',
    groupCode: 'WR',
  },
  {
    name: '連連看',
    t_key: 'connectMatching',
    code: 'WR005',
    groupCode: 'WR',
  },
  {
    name: '圖表題',
    t_key: 'chartQuestion',
    code: 'WR006',
    groupCode: 'WR',
  },
  {
    name: '對的打V',
    t_key: 'trueOrFalse',
    code: 'WR007',
    groupCode: 'WR',
  },
  {
    name: '量量看',
    t_key: 'quantitativeComparison',
    code: 'WR008',
    groupCode: 'WR',
  },
  {
    name: '寫寫看',
    t_key: 'writeAndCheck',
    code: 'WR009',
    groupCode: 'WR',
  },
  {
    name: '改錯字(句子)',
    t_key: 'correctMistakesSentences',
    code: 'WR010',
    groupCode: 'WR',
  },
  {
    name: '改錯字(短文)',
    t_key: 'correctMistakesParagraphs',
    code: 'WR011',
    groupCode: 'WR',
  },
  {
    name: '改錯題',
    t_key: 'errorCorrectionQuestion',
    code: 'WR012',
    groupCode: 'WR',
  },
  {
    name: '多音字',
    t_key: 'polyphone',
    code: 'WR013',
    groupCode: 'WR',
  },
  {
    name: '國語習作',
    t_key: 'mandarinExercise',
    code: 'WR014',
    groupCode: 'WR',
  },
  {
    name: '教用課本數學園地',
    t_key: 'mathematicsInTextbooks',
    code: 'WR015',
    groupCode: 'WR',
  },
  {
    name: '教用課本補充題',
    t_key: 'supplementaryQuestionsInTextbooks',
    code: 'WR016',
    groupCode: 'WR',
  },
  {
    name: '活用題',
    t_key: 'applicationQuestions',
    code: 'WR017',
    groupCode: 'WR',
  },
  {
    name: '句子重組',
    t_key: 'sentenceReordering2',
    code: 'WR018',
    groupCode: 'WR',
  },
  {
    name: '回答問題',
    t_key: 'answerQuestions',
    code: 'WR019',
    groupCode: 'WR',
  },
  {
    name: '依課文內容回答問題',
    t_key: 'answerQuestionsBasedOnText',
    code: 'WR020',
    groupCode: 'WR',
  },
  {
    name: '注釋',
    t_key: 'annotations',
    code: 'WR021',
    groupCode: 'WR',
  },
  {
    name: '看圖做做看',
    t_key: 'lookAtPicturesAndDo',
    code: 'WR022',
    groupCode: 'WR',
  },
  {
    name: '做做看',
    t_key: 'doAndCheck',
    code: 'WR023',
    groupCode: 'WR',
  },
  {
    name: '問答題',
    t_key: 'questionAndAnswer',
    code: 'WR024',
    groupCode: 'WR',
  },
  {
    name: '簡答題',
    t_key: 'shortAnswer',
    code: 'WR025',
    groupCode: 'WR',
  },
  {
    name: '翻譯',
    t_key: 'translation',
    code: 'WR026',
    groupCode: 'WR',
  },
  {
    name: '依課文內容回答問題(筆述)',
    t_key: 'answerQuestionsBasedOnTextDescription',
    code: 'WR027',
    groupCode: 'WR',
  },
  {
    name: '語譯',
    t_key: 'languageTranslation',
    code: 'WR028',
    groupCode: 'WR',
  },
  {
    name: '看圖回答問題',
    t_key: 'answerQuestionsBasedOnPictures2',
    code: 'WR029',
    groupCode: 'WR',
  },
  {
    name: '排出正確的順序',
    t_key: 'arrangeInCorrectOrder',
    code: 'WR030',
    groupCode: 'WR',
  },
  {
    name: '根據提示填寫答案',
    t_key: 'fillInAnswerBasedOnHints',
    code: 'WR031',
    groupCode: 'WR',
  },
  {
    name: '翻譯成白話文',
    t_key: 'translateIntoPlainLanguage',
    code: 'WR032',
    groupCode: 'WR',
  },
  {
    name: '多餘條件布題',
    t_key: 'superfluousConditionQuestion',
    code: 'WR033',
    groupCode: 'WR',
  },
  {
    name: '情境修改數據不改',
    t_key: 'contextualModifyDataWithoutChanging',
    code: 'WR034',
    groupCode: 'WR',
  },
  {
    name: '教用課本資優題',
    t_key: 'textbookGiftedQuestions',
    code: 'WR035',
    groupCode: 'WR',
  },
  {
    name: '結合時事情境題',
    t_key: 'integrateCurrentEventsScenarioQuestions',
    code: 'WR036',
    groupCode: 'WR',
  },
  {
    name: 'PIRLS題型',
    t_key: 'PIRLSTypeQuestions',
    code: 'WR037',
    groupCode: 'WR',
  },
  {
    name: '數學國際題',
    t_key: 'mathInternationalQuestions',
    code: 'WR038',
    groupCode: 'WR',
  },
  {
    name: '數學歷屆考題',
    t_key: 'mathPastExamQuestions',
    code: 'WR039',
    groupCode: 'WR',
  },
  {
    name: '看圖回答問題',
    t_key: 'answerQuestionsBasedOnPictures',
    code: 'WR040',
    groupCode: 'WR',
  },
  {
    name: '引導式翻譯',
    t_key: 'guidedTranslation',
    code: 'WR041',
    groupCode: 'WR',
  },
  {
    name: '整句式翻譯',
    t_key: 'wholeSentenceTranslation',
    code: 'WR042',
    groupCode: 'WR',
  },
  {
    name: '整句式翻譯(課文原句)',
    t_key: 'wholeSentenceTranslationFromTextOriginalSentence',
    code: 'WR043',
    groupCode: 'WR',
  },
  {
    name: '計算題',
    t_key: 'calculationQuestion',
    code: 'WR044',
    groupCode: 'WR',
  },
  {
    name: '算算看',
    t_key: 'calculateAndCheck',
    code: 'WR045',
    groupCode: 'WR',
  },
  {
    name: '應用題',
    t_key: 'applicationQuestion',
    code: 'WR046',
    groupCode: 'WR',
  },
  {
    name: '寫出直式算算看',
    t_key: 'writeVerticalCalculationAndCheck',
    code: 'WR047',
    groupCode: 'WR',
  },
  {
    name: '句子加長',
    t_key: 'lengthenSentences',
    code: 'WR048',
    groupCode: 'WR',
  },
  {
    name: '照樣造句',
    t_key: 'makeSentencesLikewise',
    code: 'WR049',
    groupCode: 'WR',
  },
  {
    name: '照樣寫短語',
    t_key: 'writePhrasesLikewise',
    code: 'WR050',
    groupCode: 'WR',
  },
  {
    name: '語詞接龍',
    t_key: 'wordChain',
    code: 'WR051',
    groupCode: 'WR',
  },
  {
    name: '國語看圖寫作',
    t_key: 'mandarinPictureComposition',
    code: 'WR052',
    groupCode: 'WR',
  },
  {
    name: '寫出注音並造詞(單字)',
    t_key: 'writePhoneticAnnotationAndCreateWordsSingleWord',
    code: 'WR053',
    groupCode: 'WR',
  },
  {
    name: '寫出注音並造詞(形近字題組)',
    t_key: 'writePhoneticAnnotationAndCreateWordsHomophonicCharacterGroup',
    code: 'WR054',
    groupCode: 'WR',
  },
  {
    name: '寫出多音字並造詞',
    t_key: 'writePolyphoneAndCreateWords',
    code: 'WR055',
    groupCode: 'WR',
  },
  {
    name: '造句(語詞)',
    t_key: 'makeSentencesWord',
    code: 'WR056',
    groupCode: 'WR',
  },
  {
    name: '造句(句型)',
    t_key: 'makeSentencesSentenceStructure',
    code: 'WR057',
    groupCode: 'WR',
  },
  {
    name: '生字成語(造句)',
    t_key: 'newWordsAndIdiomsMakeSentences',
    code: 'WR058',
    groupCode: 'WR',
  },
  {
    name: '造詞',
    t_key: 'wordCreation',
    code: 'WR059',
    groupCode: 'WR',
  },
  {
    name: '接寫句子',
    t_key: 'continueWritingSentences',
    code: 'WR060',
    groupCode: 'WR',
  },
  {
    name: '語詞擴充成句子',
    t_key: 'vocabularyExpansionIntoSentences',
    code: 'WR061',
    groupCode: 'WR',
  },
  {
    name: '換句話說',
    t_key: 'expressInOtherWords',
    code: 'WR062',
    groupCode: 'WR',
  },
  {
    name: '辨別破音字並造詞',
    t_key: 'identifyBrokenToneCharactersAndCreateWords',
    code: 'WR063',
    groupCode: 'WR',
  },
  {
    name: '斷句',
    t_key: 'segmentSentences',
    code: 'WR064',
    groupCode: 'WR',
  },
  {
    name: '主題式題型(主題寫作題型)',
    t_key: 'themeBasedQuestionTypeThemeWriting',
    code: 'WR065',
    groupCode: 'WR',
  },
  {
    name: '把對的圈起來',
    t_key: 'circleTheCorrectOnes',
    code: 'WR066',
    groupCode: 'WR',
  },
  {
    name: '配合題',
    t_key: 'matchingQuestion',
    code: 'WR067',
    groupCode: 'WR',
  },
  {
    name: '將正確的解釋連起來',
    t_key: 'connectTheCorrectExplanations',
    code: 'WR068',
    groupCode: 'WR',
  },
  {
    name: '國語主題成語',
    t_key: 'mandarinThemeIdioms',
    code: 'WR069',
    groupCode: 'WR',
  },
  {
    name: '檢測題',
    t_key: 'testQuestion',
    code: 'WR070',
    groupCode: 'WR',
  },
  {
    name: '生字成語(選詞填寫)',
    t_key: 'newWordsAndIdiomsSelectWordFillIn',
    code: 'WR071',
    groupCode: 'WR',
  },
  {
    name: '語文天地',
    t_key: 'languageWorld',
    code: 'WR072',
    groupCode: 'WR',
  },
  {
    name: '圈出不同類的語詞',
    t_key: 'circleDifferentTypesOfWords',
    code: 'WR073',
    groupCode: 'WR',
  },
  {
    name: '圈出多餘的字',
    t_key: 'circleTheExtraWords',
    code: 'WR074',
    groupCode: 'WR',
  },
  {
    name: '把對的字圈起來',
    t_key: 'circleTheCorrectWords',
    code: 'WR075',
    groupCode: 'WR',
  },
  {
    name: '把對的音圈起來',
    t_key: 'circleTheCorrectSounds',
    code: 'WR076',
    groupCode: 'WR',
  },
  {
    name: '把對的語詞圈起來',
    t_key: 'circleTheCorrectTerms2',
    code: 'WR077',
    groupCode: 'WR',
  },
  {
    name: '圈圈看',
    t_key: 'circleAndCheck',
    code: 'WR078',
    groupCode: 'WR',
  },
  {
    name: '圈出正確的注音',
    t_key: 'circleTheCorrectPhoneticAnnotation',
    code: 'WR079',
    groupCode: 'WR',
  },
  {
    name: '圈出正確的字',
    t_key: 'circleTheCorrectCharacters',
    code: 'WR080',
    groupCode: 'WR',
  },
  {
    name: '圈出正確的語詞',
    t_key: 'circleTheCorrectTerms',
    code: 'WR081',
    groupCode: 'WR',
  },
  {
    name: '畫掉多餘的字',
    t_key: 'crossOutTheExtraWords',
    code: 'WR082',
    groupCode: 'WR',
  },
  {
    name: '畫掉多餘的字詞',
    t_key: 'crossOutTheExtraWordsAndTerms',
    code: 'WR083',
    groupCode: 'WR',
  },
  {
    name: '畫畫看',
    t_key: 'drawAndCheck',
    code: 'WR084',
    groupCode: 'WR',
  },
  {
    name: '塗塗看',
    t_key: 'colorAndCheck',
    code: 'WR085',
    groupCode: 'WR',
  },
  {
    name: '畫畫看，做做看',
    t_key: 'drawAndCheckAndDo',
    code: 'WR086',
    groupCode: 'WR',
  },
  {
    name: '比比看',
    t_key: 'compareAndCheck',
    code: 'WR087',
    groupCode: 'WR',
  },
  {
    name: '填入＞、＜或＝',
    t_key: 'fillInGreaterThanLessThanOrEqual',
    code: 'WR088',
    groupCode: 'WR',
  },
  {
    name: '句子重組',
    t_key: 'sentenceReordering',
    code: 'WR089',
    groupCode: 'WR',
  },
  {
    name: '改錯',
    t_key: 'errorCorrection',
    code: 'WR090',
    groupCode: 'WR',
  },
  {
    name: '改錯字',
    t_key: 'correctMistakesInWords',
    code: 'WR091',
    groupCode: 'WR',
  },
  {
    name: '注音與國字',
    t_key: 'phoneticAnnotationAndChineseCharacters',
    code: 'WR092',
    groupCode: 'WR',
  },
  {
    name: '造句',
    t_key: 'makeSentences',
    code: 'WR093',
    groupCode: 'WR',
  },
  {
    name: '寫出注音並造詞',
    t_key: 'writePhoneticAnnotationAndCreateWords',
    code: 'WR094',
    groupCode: 'WR',
  },
  {
    name: '寫出部首並造詞',
    t_key: 'writeRadicalsAndCreateWords',
    code: 'WR095',
    groupCode: 'WR',
  },
  {
    name: '寫國字或注音(句子式)',
    t_key: 'writeChineseCharactersOrPhoneticAnnotationSentenceStyle',
    code: 'WR096',
    groupCode: 'WR',
  },
  {
    name: '寫國字或注音(語詞式)',
    t_key: 'writeChineseCharactersOrPhoneticAnnotationPhraseStyle',
    code: 'WR097',
    groupCode: 'WR',
  },
  {
    name: '寫國字或注音(短文式)',
    t_key: 'writeChineseCharactersOrPhoneticAnnotationParagraphStyle',
    code: 'WR098',
    groupCode: 'WR',
  },
  {
    name: '寫出不同的讀音',
    t_key: 'writeDifferentPronunciations',
    code: 'WR099',
    groupCode: 'WR',
  },
  {
    name: '寫出相同處並造詞',
    t_key: 'writeSimilaritiesAndCreateWords',
    code: 'WR100',
    groupCode: 'WR',
  },
  {
    name: '寫出不同處並造詞',
    t_key: 'writeDifferencesAndCreateWords',
    code: 'WR101',
    groupCode: 'WR',
  },
  {
    name: '句型練習',
    t_key: 'sentencePatternPractice',
    code: 'WR102',
    groupCode: 'WR',
  },
  {
    name: '寫出不同的讀音並造詞',
    t_key: 'writeDifferentPronunciationsAndCreateWords',
    code: 'WR103',
    groupCode: 'WR',
  },
  {
    name: '寫出注音、部首並造詞',
    t_key: 'writePhoneticAnnotationRadicalsAndCreateWords',
    code: 'WR104',
    groupCode: 'WR',
  },
  {
    name: '文字加減後寫出新字並造詞',
    t_key: 'writeNewWordsAfterAddingOrRemovingCharacters2',
    code: 'WR105',
    groupCode: 'WR',
  },
  {
    name: '拼音練習',
    t_key: 'pinyinPractice',
    code: 'WR106',
    groupCode: 'WR',
  },
  {
    name: '寫出筆畫數並造詞',
    t_key: 'writeStrokeCountsAndCreateWords',
    code: 'WR107',
    groupCode: 'WR',
  },
  {
    name: '文字加減後寫出新字、注音並造詞',
    t_key: 'writeNewWordsAfterAddingOrRemovingCharactersAndPhoneticAnnotation',
    code: 'WR108',
    groupCode: 'WR',
  },
  {
    name: '文字加減後寫出新字',
    t_key: 'writeNewWordsAfterAddingOrRemovingCharacters',
    code: 'WR109',
    groupCode: 'WR',
  },
  {
    name: '聲調練習',
    t_key: 'tonePractice',
    code: 'WR110',
    groupCode: 'WR',
  },
  {
    name: '非選題',
    t_key: 'nonMultipleChoiceQuestions',
    code: 'WR111',
    groupCode: 'WR',
  },
  {
    name: '依提示作答',
    t_key: 'answerAccordingToPrompts',
    code: 'WR112',
    groupCode: 'WR',
  },
  {
    name: '引導式翻譯(停用)',
    t_key: 'guidedTranslation(Discontinued)',
    code: 'WR113',
    groupCode: 'WR',
  },
  {
    name: '文意選填',
    t_key: 'contextualFill-in-the-Blanks',
    code: 'WR114',
    groupCode: 'WR',
  },
  {
    name: '申論題',
    t_key: 'essayQuestions',
    code: 'WR115',
    groupCode: 'WR',
  },
  {
    name: '作文',
    t_key: 'composition',
    code: 'WR116',
    groupCode: 'WR',
  },
  {
    name: '重組',
    t_key: 'rearrange',
    code: 'WR117',
    groupCode: 'WR',
  },
  {
    name: '情境配合題',
    t_key: 'contextualCoordinatedQuestions',
    code: 'WR118',
    groupCode: 'WR',
  },
  {
    name: '習作題型',
    t_key: 'exerciseQuestionTypes',
    code: 'WR119',
    groupCode: 'WR',
  },
  {
    name: '短文填寫',
    t_key: 'fill-in-the-Blanks(ShortPassage)',
    code: 'WR120',
    groupCode: 'WR',
  },
  {
    name: '篇章結構',
    t_key: 'textualStructure',
    code: 'WR121',
    groupCode: 'WR',
  },
  {
    name: '課本題型',
    t_key: 'textbookQuestionTypes',
    code: 'WR122',
    groupCode: 'WR',
  },
  {
    name: '整段式選擇',
    t_key: 'wholeParagraphSelection',
    code: 'WR123',
    groupCode: 'WR',
  },
  {
    name: '聽力',
    t_key: 'listening',
    code: 'WR124',
    groupCode: 'WR',
  },
  {
    name: '國語EZ題',
    t_key: 'MandarinEZQuestions',
    code: 'WR125',
    groupCode: 'WR',
  },
  {
    name: '數學資優題',
    t_key: 'mathTalentedQuestions',
    code: 'WR126',
    groupCode: 'WR',
  },
  {
    name: '整句式翻譯',
    t_key: 'sentenceStyleTranslation',
    code: 'WR127',
    groupCode: 'WR',
  },
  {
    name: '單字例句填空',
    t_key: 'vocabularySentenceCloze',
    code: 'WR128',
    groupCode: 'WR',
  },
  {
    name: '生字成語',
    t_key: 'newWordsAndIdioms',
    code: 'WR129',
    groupCode: 'WR',
  },
  {
    name: '主題修辭進階',
    t_key: 'advancedThemeRhetoric',
    code: 'WR130',
    groupCode: 'WR',
  },
  {
    name: '主題詞語進階',
    t_key: 'advancedThemeWords',
    code: 'WR131',
    groupCode: 'WR',
  },
  {
    name: 'PISA試題',
    t_key: 'PISAQuestions',
    code: 'WR132',
    groupCode: 'WR',
  },
  {
    name: '混合題',
    t_key: 'mixedQuestions',
    code: 'WR133',
    groupCode: 'WR',
  },
  {
    name: '發音題-寫出聽到的子音字母',
    t_key: 'pronunciationQuestions-WriteTheHeardConsonants',
    code: 'WR134',
    groupCode: 'WR',
  },
  {
    name: '發音題-圈出聽到的單字',
    t_key: 'pronunciationQuestions-CircleTheHeardWords',
    code: 'WR135',
    groupCode: 'WR',
  },
  {
    name: '發音題-圈出聽到的字母組合',
    t_key: 'pronunciationQuestions-CircleTheHeardLetterCombinations',
    code: 'WR136',
    groupCode: 'WR',
  },
  {
    name: '寫國字或注音(括號式)',
    t_key: 'writeChineseCharactersOrPhoneticAnnotation(BracketStyle)',
    code: 'WR137',
    groupCode: 'WR',
  },
  {
    name: '是的打ˇ．不是的打╳',
    t_key: 'YesForˇNoFor╳',
    code: 'WR138',
    groupCode: 'WR',
  },
  {
    name: '相似的畫○．不同的打╳',
    t_key: 'DrawCircleForSimilarityCrossForDifference',
    code: 'WR139',
    groupCode: 'WR',
  },
  {
    name: '對的畫○．不對的打╳',
    t_key: 'DrawCircleForCorrectCrossForIncorrect',
    code: 'WR140',
    groupCode: 'WR',
  },
  {
    name: '意思相近的畫O，不是打X',
    t_key: 'DrawOForSimilarMeaningXForNot',
    code: 'WR141',
    groupCode: 'WR',
  },
  {
    name: '填填看(圖片)',
    t_key: 'fillInTheBlanksWithPictures2',
    code: 'WR142',
    groupCode: 'WR',
  },
  {
    name: '題組(圖片)',
    t_key: 'fillInTheBlanksNonMultipleChoice',
    code: 'WR143',
    groupCode: 'WR',
  },
  {
    name: '看圖填填看(圖片)',
    t_key: 'trueOrFalseQuestionsNonMultipleChoice',
    code: 'WR144',
    groupCode: 'WR',
  },
  {
    name: '填充題(圖片)',
    t_key: 'questionSetsNonMultipleChoice',
    code: 'WR145',
    groupCode: 'WR',
  },
  {
    name: '填充題(非選)',
    t_key: 'newWordsAndIdiomsErrorCorrection',
    code: 'WR146',
    groupCode: 'WR',
  },
  {
    name: '是非題(非選)',
    t_key: 'readingComprehensionTestWithPictures',
    code: 'WR147',
    groupCode: 'WR',
  },
  {
    name: '題組(非選)',
    t_key: 'vocabularyMeaningTestNonMultipleChoice',
    code: 'WR148',
    groupCode: 'WR',
  },
  {
    name: '生字成語(改錯)',
    t_key: 'readingComprehensionTestNonMultipleChoice',
    code: 'WR149',
    groupCode: 'WR',
  },
  {
    name: '閱讀測驗(圖片)',
    t_key: 'YesForˇNoFor╳(Discontinued)',
    code: 'WR150',
    groupCode: 'WR',
  },
  {
    name: '字詞義測驗(非選)',
    t_key: 'trueOrFalseQuestions',
    code: 'WR151',
    groupCode: 'WR',
  },
  {
    name: '閱讀測驗(非選)',
    t_key: 'DrawCircleForSimilarityCrossForDifference(Discontinued)',
    code: 'WR152',
    groupCode: 'WR',
  },
  {
    name: '是的打ˇ．不是的打╳(停用)',
    t_key: 'DrawCircleForCorrectCrossForIncorrect(Discontinued)',
    code: 'YN001',
    groupCode: 'YN',
  },
  {
    name: '是非題',
    t_key: 'DrawOForSimilarMeaningXForNot(Discontinued)',
    code: 'YN002',
    groupCode: 'YN',
  },
  {
    name: '相似的畫○．不同的打╳(停用)',
    t_key: 'pronunciationQuestions-DrawCircleForSamePronunciationCrossForDifferent',
    code: 'YN003',
    groupCode: 'YN',
  },
  {
    name: '對的畫○．不對的打╳(停用)',
    t_key: 'fillInTheBlanksWithPictures',
    code: 'YN004',
    groupCode: 'YN',
  },
  {
    name: '意思相近的畫O，不是打X(停用)',
    t_key: 'questionSetsWithPictures',
    code: 'YN005',
    groupCode: 'YN',
  },
  {
    name: '發音題-畫線部分發音相同畫「○」，不同則畫「×」',
    t_key: 'lookAtPicturesAndFillInTheBlanks',
    code: 'YN006',
    groupCode: 'YN',
  },
];
