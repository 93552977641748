import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useLeavePageAlert = (isLast) => {
  const { t } = useTranslation();
  useEffect(() => {

    const beforeunload = (event) => {
      event.preventDefault();
      if (!isLast) {
        event.returnValue = t('hooks.useLeavePageAlert.__confirmLeavePage');
      }
    };

    window.addEventListener('beforeunload', beforeunload);

    return () => {
      window.removeEventListener('beforeunload', beforeunload);
    };

  }, [isLast]);
};
