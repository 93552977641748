/* eslint-disable react/prop-types */
import { useState, useMemo, useEffect, useContext } from 'react';
import { Tab, TabPane, Modal, Table, FETCH_DATA_EVENT, fetchDataMachine, Chart } from '@oneclass/ui-components';
import { useMachine } from '@xstate/react';
import { useBoolean } from 'ahooks';
import { postQuestionAnalysis } from 'api/drawUpFast';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { ChevronRightOutline, ChartPieSolid } from '@onedesign/icon';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { useTranslation } from 'react-i18next';
import { difficultyMapper } from 'locales/translationMapper';
import { bookNameMapper } from 'locales/translationMapper';
import { questionTypeMapper } from 'locales/translationMapper';
const { Header, Body, Row, Item } = Table;

const StyleQuestionAnalysisBtn = styled.div`
  background-color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  align-items:center;
  border: 1px solid #D5D7DE;
  border-radius: 4px;
  padding: 4px 16px;
`;

const StyledPieChartTable = styled.div`
    width: 60%;
    height: 378px;
    .pieChartTableContent{
      >div:last-child{
        padding: 6px 20px 6px 9px !important;
      }
    }
    @media (max-width: 1024px) {
      width: 100% !important;
      height: calc(calc(var(--vh, 1vh) * 100)- 356px) !important;
    }
`;

const StyledTabPaneContent = styled.div`
    display: flex;
    @media (max-width: 1024px) {
      flex-direction: column !important;
    }
`;

const StyledModal = styled(Modal)`
  position: absolute;
  .ant-tabs-tab + .ant-tabs-tab{
        margin: 0 0 0 10px !important;
    }
    .pieChartBox{
        width: 40%;
        height: 378px;
    }
    .barChartBox{
        width: 100%;
    }
    .StyledPieChartTable{
        width: 55%;
        height: 378px;
        overflow: auto;
    }
    .knowledgeChartBox{
        width: 100%;
        height: 340px;
        overflow: auto;
        @media (max-width: 1024px) {
          height: 100% !important;
        }
    }
    .colorSquare{
        width: 16px;
        height: 16px;
    }
    .ant-tabs-top > .ant-tabs-nav{
        margin: 0 0 32px 0;
    }
    .knowledgeControlBox{
        width: 100%;
        display: flex;
        background-color:rgba(250, 250, 252, 1) ;
        border-bottom:1px solid rgba(228, 231, 236, 1);
        >div{
            padding: 8px 16px;
            color:rgba(69, 75, 92, 1);
        }
        >div:nth-child(1){
            text-align:right;
            width: 35%;
        }
        >div:nth-child(2){
            text-align:left;
            width: 65%;
        }
    }
    .toolTipIcon{
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: bold;
		color: #fff;
		width: 15px;
		height: 15px;
		cursor: pointer;
		border-radius: 50%;
		background-color: #8B90A0;
		margin-left: 10px;
	}

  .mobileCloseBtn{
    display: none;

    @media (max-width: 1024px) {
      display: block;
      position: absolute;
      top: 24px;
      left: 20px;
      font-size: 16px;
      transform: rotate(180deg);
    }
  }
  @media (max-width: 1024px) {
    .ant-modal-content{
      background-color: #fff !important;
			width: 100vw;
			height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
			.ant-modal-close{
        left: 0 !important;
        right: unset !important;
      }
		}
    .pieChartBox{
        width: 100% !important;
        height: 250px !important;
    }
    .ant-tabs-top > .ant-tabs-nav{
      margin: 0 0 !important;
    }
    .triangleZone{
      display: block !important;
    }
    .anticon-close{
      display: none;
    }
    .pieChartTableTitle{
      >div{
        padding: 6px 9px !important;
      }
    }
    .pieChartTableContent{
      >div{
        padding: 6px 9px !important;
      }
    }
    .ant-modal-body{
      background-color: #fff;
      padding: 12px 24px !important;
    }
  }
`;
export const useQuestionAnalysisModal = (pickUpQuestionID, searchParams) => {
  // const { isDesktop } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const [difficultyData, setDifficultyData] = useState(null);
  const [bookData, setBookData] = useState(null);
  const [lessonData, setLessonData] = useState(null);
  const [knowledgeData, setKnowledgeData] = useState(null);
  const [quesTypeData, setQuesTypeData] = useState(null);
  const { t } = useTranslation();
  // ---- 試題分佈 相關 ----
  // 彈窗顯示控制
  const [questionAnalysisModalVisible, { toggle: setQuestionAnalysisModalToggle, setFalse: setQuestionAnalysisModalFalse }] = useBoolean(false);

  const doShowAnalysisModal = () => {
    setQuestionAnalysisModalToggle();
    tabChange('DIFFICULTY');
  };

  // 當所選題目有更動時 重打api取得資料
  useEffect(() => {
    if (questionAnalysisModalVisible && pickUpQuestionID.length > 0) {
      sendPostQuestionAnalysis(FETCH_DATA_EVENT.FETCH, {
        payload: {
          eduSubject: searchParams.eduSubject,
          curriculum: searchParams.curriculum,
          year: searchParams.year,
          version: searchParams.version,
          questions: pickUpQuestionID
        }
      });
    }
  }, [questionAnalysisModalVisible, pickUpQuestionID]);

  // 試題分佈 API
  const [postQuestionAnalysisState, sendPostQuestionAnalysis] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        const res = await postQuestionAnalysis(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        const { chart } = data;
        // const { BOOK, DIFFICULTY, KNOWLEDGE, LESSON, QUES_TYPE } = chart;
        let { book, difficulty, knowledge, lesson, ques_type } = chart;
        difficulty = {
          ...difficulty,
          item: difficulty.item.map(e => {
            return {
              ...e,
              name: t(difficultyMapper(e.code), e.name)
            };
          })
        };

        book = {
          ...book,
          item: book.item.map(e => {
            return {
              ...e,
              name: t(bookNameMapper(e.name), e.name)
            };
          })
        };

        ques_type = {
          ...ques_type,
          item: ques_type.item.map(e => {
            return {
              ...e,
              name: t(questionTypeMapper(e.name), e.name)
            };
          })
        };

        setPieChartSwitchData(transformToPieChartData(difficulty));
        setDifficultyData(transformToPieChartData(difficulty));
        setBookData(transformToPieChartData(book));
        setLessonData(transformToPieChartData(lesson));
        setKnowledgeData(transformToPieChartData(knowledge));
        setQuesTypeData(transformToPieChartData(ques_type));
        return {
          isSuccess, systemCode, message,
          difficultyData: transformToPieChartData(difficulty),
          bookData: transformToPieChartData(book),
          lessonData: transformToPieChartData(lesson),
          knowledgeData: transformToPieChartData(knowledge),
          quesTypeData: transformToPieChartData(ques_type),
        };
      },
    },
  });

  // const { difficultyData, bookData, lessonData, knowledgeData, quesTypeData } = postQuestionAnalysisState.context.result || {};

  // 試題分佈 控制頁面切換
  const [questionAnalysisType, setQuestionAnalysisType] = useState('DIFFICULTY');


  const tabChange = (key) => {
    setQuestionAnalysisType(key);
    if (key === 'DIFFICULTY') {
      setPieChartSwitchData(difficultyData);
    } else {
      if (key === 'BOOK') {
        setPieChartSwitchData(bookData);
      }
      if (key === 'LESSON') {
        setPieChartSwitchData(lessonData);
      }
      if (key === 'QUES_TYPE') {
        setPieChartSwitchData(quesTypeData);
      }
    }
  };

  // 整理資料為 圓餅圖所需
  const transformToPieChartData = (array) => {
    let newArray = [];
    if (array.item) {
      if (array.code === 'difficulty') {
        newArray = array.item.map((item) => {
          if (item.code === 'EXPERT') {
            item.itemStyle = { color: 'rgba(244, 175, 161, 1)' };
          } else if (item.code === 'INTERMEDIATE') {
            item.itemStyle = { color: 'rgba(247, 201, 193, 1)' };
          } else if (item.code === 'BEGIN') {
            item.itemStyle = { color: 'rgba(251, 228, 224, 1)' };
          }
          return { ...item, value: item.count };
        });
        newArray.reverse();
      } else if (array.code === 'knowledge') {
        const xAxisData = [];
        const yAxisData = [];
        array.item.forEach((item, index) => {
          xAxisData.push(item.count);
          yAxisData.push(item.name);
        });
        newArray = { xAxisData, yAxisData, originData: array.item };
      } else {
        const colorArray = getPieChartColor(array.item);
        const sortArray = array.item.sort((a, b) => {
          return b.count - a.count;
        });
        newArray = sortArray.map((item, index) => {
          return { ...item, value: item.count, itemStyle: { color: colorArray[index] } };
        });
      }
      return newArray;
    }
  };

  // 圓餅圖 對應個類別 切換顯示之內容
  const [pieChartSwitchData, setPieChartSwitchData] = useState([]);

  //  依照ui/ux設計之七種顏色 循環降低20％透明度
  const getPieChartColor = ((dataArray) => {
    let colorArray = [];
    if (dataArray) {
      const totalColorNum = dataArray?.length;
      const colorLoopNum = totalColorNum && Math.ceil(totalColorNum / 35);
      let num = totalColorNum;
      for (let i = 1 ; i <= colorLoopNum ; i++) {
        let opacity = 1;
        for (let n = 1 ; n <= 5 ; n++ ) {
          colorArray.push(`rgba(228, 146, 115, ${opacity})`, `rgba(168, 208, 219, ${opacity})`, `rgba(76, 153, 165, ${opacity})`, `rgba(43, 69, 112, ${opacity})`, `rgba(113, 128, 172, ${opacity})`, `rgba(244, 221, 205, ${opacity})`, `rgba(163, 122, 116, ${opacity})`);
          num -= 7;
          opacity -= 0.2;
          if (num <= 0 ) break;
        }
      }
      return colorArray;
    }
  });

  // 知識向度 顯示文字 長度判斷 過長轉換成... 顯示
  const transformKnowledgeNameArray = (array) => {
    // 推算 能顯示文字之寬度
    const knowledgeTextBoxWidth = document.body.clientWidth * 0.19027778;
    if (array) {
      return array.map((String) => {
        let useWidth = 0;
        let filterKnowledgeName = '';
        const chineseString = new RegExp('[\u4E00-\u9FA5]+');

        const stringLength = String.length;
        for (let i = 0 ; i <= stringLength - 1 ; i++ ) {
          if (knowledgeTextBoxWidth - 20 > useWidth) {
            if (chineseString.test(String.charAt(i))) {
              useWidth += 14;
            } else {
              // useWidth += 9.3515;
              useWidth += 7.38888889;
            }
            filterKnowledgeName += String.charAt(i);
          } else {
            filterKnowledgeName += '...';
            break;
          }
        }
        return filterKnowledgeName;
      });
    }
  };

  // hover 判斷目前顯示的 index
  let showIndex = 0;

  // tooltip hover 文字過長轉換
  const transformTooltipText = (string) => {
    let useWidth = 0;
    let filterKnowledgeName = '';
    const chineseString = new RegExp('[\u4E00-\u9FA5]+');

    let checkFirstLine = false;
    let checkSecLine = false;
    const stringLength = string.length;
    for (let i = 0 ; i <= stringLength - 1 ; i++ ) {
      if (444 > useWidth) {
        if (checkFirstLine === false && useWidth > 148) {
          checkFirstLine = true;
          filterKnowledgeName += '<br>';
        }
        if (checkFirstLine === false && useWidth > 296) {
          checkSecLine = true;
          filterKnowledgeName += '<br>';
        }
        if (chineseString.test(string.charAt(i))) {
          useWidth += 14;
        } else {
          useWidth += 7.38888889;
        }
        filterKnowledgeName += string.charAt(i);
      } else {
        filterKnowledgeName += '...';
        break;
      }
    }
    return filterKnowledgeName;
  };

  // ------ 試題分析 table 排序 相關 ----------

  // 難易度 排序
  const [difficultySortParams, setDifficultySortParams] = useState({
    key: '',
    sort: 'asc',
  });
  const changeDifficultySortParams = (key) => {
    const prevSortDetail = difficultySortParams;
    if (prevSortDetail.key === key) {
      setDifficultySortParams({
        key,
        sort: prevSortDetail.sort === 'asc' ? 'desc' : 'asc'
      });
    } else {
      setDifficultySortParams({
        key,
        sort: 'asc'
      });
    }
  };

  const showDifficultySortData = useMemo(() => {
    if (difficultySortParams.key === '') return difficultyData;
    let sortData = [];
    if (difficultyData) {
      const nextData = [...difficultyData];
      if ( difficultySortParams.key === 'percent') {
        sortData = nextData.sort((a, b) => difficultySortParams.sort === 'asc' ? a.percent - b.percent : b.percent - a.percent );
      } else {
        sortData = nextData.sort((a, b) => a[difficultySortParams.key].localeCompare(b[difficultySortParams.key]) * (difficultySortParams.sort === 'asc' ? 1 : -1));
      }
    }
    return sortData;
  }, [difficultySortParams, difficultyData]);

  // 冊次 排序
  const [bookSortParams, setBookSortParams] = useState({
    key: '',
    sort: 'asc',
  });
  const changeBookSortParams = (key) => {
    const prevSortDetail = bookSortParams;
    if (prevSortDetail.key === key) {
      setBookSortParams({
        key,
        sort: prevSortDetail.sort === 'asc' ? 'desc' : 'asc'
      });
    } else {
      setBookSortParams({
        key,
        sort: 'asc'
      });
    }
  };

  const showBookSortData = useMemo(() => {
    if (bookSortParams.key === '') return bookData;
    let sortData = [];
    if (bookData) {
      const nextData = [...bookData];
      if ( bookSortParams.key === 'percent') {
        sortData = nextData.sort((a, b) => bookSortParams.sort === 'asc' ? a.percent - b.percent : b.percent - a.percent );
      } else {
        sortData = nextData.sort((a, b) => a[bookSortParams.key].localeCompare(b[bookSortParams.key]) * (bookSortParams.sort === 'asc' ? 1 : -1));
      }
    }
    return sortData;
  }, [bookSortParams, bookData]);

  // 課次 排序
  const [lessonSortParams, setLessonSortParams] = useState({
    key: '',
    sort: 'asc',
  });
  const changeLessonSortParams = (key) => {
    const prevSortDetail = lessonSortParams;
    if (prevSortDetail.key === key) {
      setLessonSortParams({
        key,
        sort: prevSortDetail.sort === 'asc' ? 'desc' : 'asc'
      });
    } else {
      setLessonSortParams({
        key,
        sort: 'asc'
      });
    }
  };

  const showLessonSortData = useMemo(() => {
    if (lessonSortParams.key === '') return lessonData;
    let sortData = [];
    if (lessonData) {
      const nextData = [...lessonData];
      if ( lessonSortParams.key === 'percent') {
        sortData = nextData.sort((a, b) => lessonSortParams.sort === 'asc' ? a.percent - b.percent : b.percent - a.percent );
      } else {
        sortData = nextData.sort((a, b) => a[lessonSortParams.key].localeCompare(b[lessonSortParams.key]) * (lessonSortParams.sort === 'asc' ? 1 : -1));
      }
    }
    return sortData;
  }, [lessonSortParams, lessonData]);

  // 題型 排序
  const [quesTypeSortParams, setQuesTypeSortParams] = useState({
    key: '',
    sort: 'asc',
  });
  const changeQuesTypeSortParams = (key) => {
    const prevSortDetail = quesTypeSortParams;
    if (prevSortDetail.key === key) {
      setQuesTypeSortParams({
        key,
        sort: prevSortDetail.sort === 'asc' ? 'desc' : 'asc'
      });
    } else {
      setQuesTypeSortParams({
        key,
        sort: 'asc'
      });
    }
  };

  const showQuesTypeSortData = useMemo(() => {
    if (quesTypeSortParams.key === '') return quesTypeData;
    let sortData = [];
    if (quesTypeData) {
      const nextData = [...quesTypeData];
      if ( quesTypeSortParams.key === 'percent') {
        sortData = nextData.sort((a, b) => quesTypeSortParams.sort === 'asc' ? a.percent - b.percent : b.percent - a.percent );
      } else {
        sortData = nextData.sort((a, b) => a[quesTypeSortParams.key].localeCompare(b[quesTypeSortParams.key]) * (quesTypeSortParams.sort === 'asc' ? 1 : -1));
      }
    }
    return sortData;
  }, [quesTypeSortParams, quesTypeData]);

  // 知識向度 排序
  const [knowledgeSortParams, setKnowledgeSortParams] = useState({
    key: '',
    sort: 'asc',
  });
  const changeKnowledgeSortParams = (key) => {
    const prevSortDetail = knowledgeSortParams;
    if (prevSortDetail.key === key) {
      setKnowledgeSortParams({
        key,
        sort: prevSortDetail.sort === 'asc' ? 'desc' : 'asc'
      });
    } else {
      setKnowledgeSortParams({
        key,
        sort: 'asc'
      });
    }
  };

  const showKnowledgeSortData = useMemo(() => {
    if (knowledgeSortParams.key === '') return knowledgeData;
    let sortData = [];
    const xAxisData = [];
    const yAxisData = [];
    if (knowledgeData?.originData) {
      if ( knowledgeSortParams.key === 'count') {
        sortData = knowledgeData?.originData.sort((a, b) => knowledgeSortParams.sort === 'asc' ? b.count - a.count : a.count - b.count );
      } else {
        sortData = knowledgeData?.originData.sort((a, b) => a[knowledgeSortParams.key].localeCompare(b[knowledgeSortParams.key]) * (knowledgeSortParams.sort === 'asc' ? -1 : 1));
      }
      if (sortData.length > 0) {
        sortData.forEach((item, index) => {
          xAxisData.push(item.count);
          yAxisData.push(item.name);
        });
      }
    }
    const sortDataArray = { xAxisData, yAxisData };
    return sortDataArray;
  }, [knowledgeSortParams, knowledgeData]);

  // 圓餅圖 相關參數設置
  const pieChartOptions = {
    // 標題
    title: {
      text: t('hooks.useQuestionAnalysisModal.__averageDifficulty'),
      subtext: t('hooks.useQuestionAnalysisModal.__difficulty'),
      show: false,
      x: 'center', // center / left / right / {number} px
      y: '45%', // top / bottom / center / {number} px
      // 主標題樣式
      textStyle: {
        fontSize: 14,
        fontWeight: 'bolder',
        color: 'rgba(139, 144, 160, 1)',
      },
      // 副標題樣式
      subtextStyle: {
        fontSize: 24,
        fontWeight: 'bolder',
        // color: 'blue',
      }
    },
    // 提示框 (hover 到圓餅圖上時顯示的)
    tooltip: {
      trigger: 'item', // 觸發類型 默認 數據觸發 item / axis
      // formatter: '<span style="color:rgba(69, 75, 92, 1)">{b}</span> <br/><span style="color:rgba(139, 144, 160, 1)">{c} 題 ({d}%)</span>', // {a} 指series.name {b}指series.data的name {c} series.data的value {d}指這一部分佔總數的百分比
      formatter: function( params ) {
        showIndex = params.dataIndex;
        return `<span style="color:rgba(69, 75, 92, 1)">${transformTooltipText(params.name)}</span><br/><span style="color:rgba(139, 144, 160, 1)">${params.value} 題  (${pieChartSwitchData[showIndex].percent} %)</span>`;
      }, // {a} 指series.name {b}指series.data的name {c} series.data的value {d}指這一部分佔總數的百分比
      textStyle: {
        fontSize: '16px',
        // color: '#000',
      },
    },
    // 圖例
    legend: {
      top: '5%',
      left: '70%',
      orient: 'vertical',
      show: false,
    },
    // 圓餅圖 值
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: questionAnalysisType === 'DIFFICULTY' ? ['50%', '70%'] : '70%',  //  內圈寬度, 外圈寬度, 若只有一個值（不是陣列） 會變成純圓餅 無空心 (ex: '50%')
        avoidLabelOverlap: false,
        center: ['50%', '50%'],   // 位置 水平, 垂直
        label: {
          position: 'outer', // 圓餅內 還是 外 outer , inner
          //  formatter: '{a} {b} : {c}個 ({d}%)', // {a} 指series.name {b}指series.data的name {c} series.data的value {d}指這一部分佔總數的百分比
          formatter: function( params ) {
            return params.percent;
          }, // {a} 指series.name {b}指series.data的name {c} series.data的value {d}指這一部分佔總數的百分比
          show: false,
        },
        emphasis: { // hover 值域 文字顯示
          label: {
            show: false,
            fontSize: '40',
            fontWeight: 'bold'
          },
          itemStyle: { // 圓餅樣式
            shadowBlur: 5,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.1)',
            borderColor: '#fff',
          },
          scale: false,
        },
        labelLine: { // 值域那條指向線
          show: false
        },
        // 圓餅圖 使用之資料
        data: pieChartSwitchData,
      }
    ],
    // 圓餅圖 使用之顏色  默認有自定義的
    // color: questionAnalysisType === 'DIFFICULTY' ? '' : colorArray,
    // backgroundColor: 'pink' // 整個圖形的背景顏色
  };

  // 柱狀圖 相關參數設定
  const barChartOptions = {
    grid: {
      top: '0%',
      left: '35%',
      bottom: '3%',
    },
    xAxis: {
      type: 'value',
      show: false,
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      }
    },
    yAxis: {
      type: 'category',
      data: transformKnowledgeNameArray(showKnowledgeSortData?.yAxisData),
      show: true,
      axisLabel: {
        show: true,
        color: 'rgba(47, 54, 73, 1)',
        fontSize: 14,
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgba(228, 231, 236, 1)',
          width: 1,
        }
      }
    },
    series: [
      {
        data: showKnowledgeSortData?.xAxisData,
        type: 'bar',
        label: {
          show: true,
          position: 'insideLeft',
          color: 'rgba(76, 153, 165, 1)',
          distance: 10, // 距離元素的距離
        },
        itemStyle: {
          color: 'rgba(238, 245, 246, 1)', // 柱子顏色
        },
        emphasis: {
          itemStyle: {
            color: 'rgba(238, 245, 246, 1)',
          }
        },
        barWidth: '55%',
      }
    ]
  };

  const renderQuestionAnalysisModal = () => {
    return (
      <>
        {
          deviceData.isDesktop ?
            <StyleQuestionAnalysisBtn onClick={() => {doShowAnalysisModal();} }>
              <ChartPieSolid/>{t('hooks.useQuestionAnalysisModal.__questionDistribution')}
            </StyleQuestionAnalysisBtn>
            :
            <div className='chartPieSolidIcon' onClick={() => {doShowAnalysisModal();} }>
              <img src={'/assets/chartPieSolid.svg'} alt={'chartPieSolid'}></img>
            </div>
        }
        <StyledModal
          visible={questionAnalysisModalVisible}
          zIndex={1001}
          onCancel={setQuestionAnalysisModalFalse}
          title={t('hooks.useQuestionAnalysisModal.__viewQuestionDistribution')}
          width="60%"
        >
          <Tab defaultActiveKey="DIFFICULTY" onChange={tabChange}>
            <ChevronRightOutline className={'mobileCloseBtn'}/>
            <TabPane tab={t('hooks.useQuestionAnalysisModal.__accordingToDifficulty')} key="DIFFICULTY">
              <StyledTabPaneContent>
                <div className='pieChartBox'>
                  <Chart width={'100%'} height={'100%'} options={pieChartOptions} key={difficultyData}/>
                </div>
                <StyledPieChartTable>
                  <Table>
                    <Header>
                      <Row className="pieChartTableTitle" issticky={true} key={pieChartSwitchData}>
                        <Item flex={deviceData.isDesktop ? 0.8 : 0.5}></Item>
                        <Item flex={deviceData.isDesktop ? 5.2 : 4.6}>{t('hooks.useQuestionAnalysisModal.__difficultyLevel')}</Item>
                        <Item flex={deviceData.isDesktop ? 1.2 : 1.3} textalign={'flex-end'}>{t('hooks.useQuestionAnalysisModal.__value')}</Item>
                        <Item flex={deviceData.isDesktop ? 1.8 : 2.6} textalign={'flex-end'} onClick={() => {changeDifficultySortParams('percent');}} sortDetail={difficultySortParams} activeKey={'percent'}>{t('hooks.useQuestionAnalysisModal.__percentage')}</Item>
                      </Row>
                    </Header>
                    <Body>
                      {
                        showDifficultySortData &&
                        showDifficultySortData.map((item, index) => {
                          return (
                            <Row key={item.code} className="pieChartTableContent">
                              <Item flex={deviceData.isDesktop ? 0.8 : 0.5}><div className='colorSquare' style={{ backgroundColor: `${item.itemStyle.color}` }}></div></Item>
                              <Item flex={deviceData.isDesktop ? 5.2 : 4.6}>{item.name}</Item>
                              <Item flex={deviceData.isDesktop ? 1.2 : 1.3} textalign={'flex-end'}>{item.value}</Item>
                              <Item flex={deviceData.isDesktop ? 1.8 : 2.6} textalign={'flex-end'}>{item.percent} %</Item>
                            </Row>
                          );
                        })
                      }
                    </Body>
                  </Table>
                </StyledPieChartTable>
              </StyledTabPaneContent>
            </TabPane>
            <TabPane tab={t('hooks.useQuestionAnalysisModal.__questionType')} key="QUES_TYPE">
              <StyledTabPaneContent>
                <div className='pieChartBox'>
                  <Chart width={'100%'} height={'100%'} options={pieChartOptions} key={quesTypeData}/>
                </div>
                <StyledPieChartTable>
                  <Table>
                    <Header>
                      <Row className="pieChartTableTitle" issticky={true} key={pieChartSwitchData}>
                        <Item flex={deviceData.isDesktop ? 0.8 : 0.5}></Item>
                        <Item flex={deviceData.isDesktop ? 5.2 : 4.6} onClick={() => {changeQuesTypeSortParams('name');}} sortDetail={quesTypeSortParams} activeKey={'name'}>{t('hooks.useQuestionAnalysisModal.__answerMethod')}</Item>
                        <Item flex={deviceData.isDesktop ? 1.2 : 1.3} textalign={'flex-end'}>{t('hooks.useQuestionAnalysisModal.__value')}</Item>
                        <Item flex={deviceData.isDesktop ? 1.8 : 2.6} textalign={'flex-end'} onClick={() => {changeQuesTypeSortParams('percent');}} sortDetail={quesTypeSortParams} activeKey={'percent'}>{t('hooks.useQuestionAnalysisModal.__percentage')}</Item>
                      </Row>
                    </Header>
                    <Body>
                      {
                        showQuesTypeSortData &&
                        showQuesTypeSortData.map((item, index) => {
                          return (
                            <Row key={item.code} className="pieChartTableContent">
                              <Item flex={deviceData.isDesktop ? 0.8 : 0.5}><div className='colorSquare' style={{ backgroundColor: `${item.itemStyle.color}` }}></div></Item>
                              <Item flex={deviceData.isDesktop ? 5.2 : 4.6}>{item.name}</Item>
                              <Item flex={deviceData.isDesktop ? 1.2 : 1.3} textalign={'flex-end'}>{item.value}</Item>
                              <Item flex={deviceData.isDesktop ? 1.8 : 2.6} textalign={'flex-end'}>{item.percent} %</Item>
                            </Row>
                          );
                        })
                      }
                    </Body>
                  </Table>
                </StyledPieChartTable>
              </StyledTabPaneContent>
            </TabPane>
            <TabPane tab={t('hooks.useQuestionAnalysisModal.__knowledgeDimension')} key="KNOWLEDGE">
              <Table>
                <Header>
                  <Row>
                    <Item flex={3.4} textalign={'flex-end'} onClick={() => {changeKnowledgeSortParams('name');}} sortDetail={knowledgeSortParams} activeKey={'name'}>{t('hooks.useQuestionAnalysisModal.__knowledgeDimension')}</Item>
                    <Item flex={6.6} onClick={() => {changeKnowledgeSortParams('count');}} sortDetail={knowledgeSortParams} activeKey={'count'}>
                      {t('hooks.useQuestionAnalysisModal.__value')}
                      <Tooltip placement="top" title={t('hooks.useQuestionAnalysisModal.__subQuestionsCountNotice')}>
                        <div className="toolTipIcon">?</div>
                      </Tooltip>
                    </Item>
                  </Row>
                </Header>
                <Body bodyMaxHeight={true}>
                  <div className='knowledgeChartBox'>
                    <div className='barChartBox' style={{ height: knowledgeData?.yAxisData?.length * 44 + 'px' }}>
                      <Chart width={'100%'} height={'100%'} options={barChartOptions} key={showKnowledgeSortData}/>
                    </div>
                  </div>
                </Body>
              </Table>
            </TabPane>
            <TabPane tab={t('hooks.useQuestionAnalysisModal.__lesson')} key="LESSON">
              <StyledTabPaneContent>
                <div className='pieChartBox'>
                  <Chart width={'100%'} height={'100%'} options={pieChartOptions} key={lessonData}/>
                </div>
                <StyledPieChartTable>
                  <Table>
                    <Header>
                      <Row className="pieChartTableTitle" issticky={true} key={pieChartSwitchData}>
                        <Item flex={deviceData.isDesktop ? 0.8 : 0.5}></Item>
                        <Item flex={deviceData.isDesktop ? 5.2 : 4.6} onClick={() => {changeLessonSortParams('name');}} sortDetail={lessonSortParams} activeKey={'name'}>{t('hooks.useQuestionAnalysisModal.__scope')}</Item>
                        <Item flex={deviceData.isDesktop ? 1.2 : 1.3} textalign={'flex-end'}>{t('hooks.useQuestionAnalysisModal.__value')}</Item>
                        <Item flex={deviceData.isDesktop ? 1.8 : 2.6} textalign={'flex-end'} onClick={() => {changeLessonSortParams('percent');}} sortDetail={lessonSortParams} activeKey={'percent'}>{t('hooks.useQuestionAnalysisModal.__percentage')}</Item>
                      </Row>
                    </Header>
                    <Body>
                      {
                        showLessonSortData &&
                        showLessonSortData.map((item, index) => {
                          return (
                            <Row key={item.code} className="pieChartTableContent">
                              <Item flex={deviceData.isDesktop ? 0.8 : 0.5}><div className='colorSquare' style={{ backgroundColor: `${item.itemStyle.color}` }}></div></Item>
                              <Item flex={deviceData.isDesktop ? 5.2 : 4.6}>{item.name}</Item>
                              <Item flex={deviceData.isDesktop ? 1.2 : 1.3} textalign={'flex-end'}>{item.value}</Item>
                              <Item flex={deviceData.isDesktop ? 1.8 : 2.6} textalign={'flex-end'}>{item.percent} %</Item>
                            </Row>
                          );
                        })
                      }
                    </Body>
                  </Table>
                </StyledPieChartTable>
              </StyledTabPaneContent>
            </TabPane>
            <TabPane tab={t('hooks.useQuestionAnalysisModal.__book')} key="BOOK">
              <StyledTabPaneContent>
                <div className='pieChartBox'>
                  <Chart width={'100%'} height={'100%'} options={pieChartOptions} key={bookData}/>
                </div>
                <StyledPieChartTable>
                  <Table>
                    <Header>
                      <Row className="pieChartTableTitle" issticky={true}>
                        <Item flex={deviceData.isDesktop ? 0.8 : 0.5}></Item>
                        <Item flex={deviceData.isDesktop ? 5.2 : 4.6} onClick={() => {changeBookSortParams('name');}} sortDetail={bookSortParams} activeKey={'name'}>{t('hooks.useQuestionAnalysisModal.__scope')}</Item>
                        <Item flex={deviceData.isDesktop ? 1.2 : 1.3} textalign={'flex-end'}>{t('hooks.useQuestionAnalysisModal.__value')}</Item>
                        <Item flex={deviceData.isDesktop ? 1.8 : 2.6} textalign={'flex-end'} onClick={() => {changeBookSortParams('percent');}} sortDetail={bookSortParams} activeKey={'percent'}>{t('hooks.useQuestionAnalysisModal.__percentage')}</Item>
                      </Row>
                    </Header>
                    <Body>
                      {
                        showBookSortData &&
                        showBookSortData.map((item, index) => {
                          return (
                            <Row key={item.code} className="pieChartTableContent">
                              <Item flex={deviceData.isDesktop ? 0.8 : 0.5}><div className='colorSquare' style={{ backgroundColor: `${item.itemStyle.color}` }}></div></Item>
                              <Item flex={deviceData.isDesktop ? 5.2 : 4.6}>{item.name}</Item>
                              <Item flex={deviceData.isDesktop ? 1.2 : 1.3} textalign={'flex-end'}>{item.value}</Item>
                              <Item flex={deviceData.isDesktop ? 1.8 : 2.6} textalign={'flex-end'}>{item.percent} %</Item>
                            </Row>
                          );
                        })
                      }
                    </Body>
                  </Table>
                </StyledPieChartTable>
              </StyledTabPaneContent>
            </TabPane>
          </Tab>
        </StyledModal>
      </>

    );
  };
  return { renderQuestionAnalysisModal, doShowAnalysisModal, setQuestionAnalysisModalFalse };
};
