export const OUTPUT_TYPE_TRANS_KEY = [
  {
    code: 'Online',
    name: '線上測驗',
    t_key: 'online'
  },
  {
    code: 'Files',
    name: '紙本卷類',
    t_key: 'files'
  },
  {
    code: 'Quick',
    name: '速測',
    t_key: 'quick'
  },
  {
    code: 'Series',
    name: '叢書成卷',
    t_key: 'series'
  }
];

export const quizOutputTypeMapper = (value) => {
  const element = OUTPUT_TYPE_TRANS_KEY.find(e => e.code === value);
  if (!element) {
    return undefined;
  }
  return `constants.quizOutputTypes.${element.t_key}`;
};
