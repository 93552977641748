import { Modal } from '@oneclass/ui-components';
import { Button } from '@oneclass/onedesign';
import { ButtonBlock, TextBlock } from './LogoutAler.style';
import { useTranslation } from 'react-i18next';

export default function LogoutAlert({
  show,
  onLeave,
  onStay
}) {
  const { t } = useTranslation();
  return (
    <Modal visible={show}
      onCancel={onStay}
      title={
        <div>{t('components.logoutAlert.__confirmLogout')}</div>
      }
      footer={
        <ButtonBlock>
          <Button className='leave' onClick={onStay}>{t('components.logoutAlert.__cancel')}</Button>
          <Button className='think' onClick={onLeave}>{t('components.logoutAlert.__logoutConfirm')}</Button>
        </ButtonBlock>
      }>
      <TextBlock>
        {t('components.logoutAlert.__logoutWarning')}
      </TextBlock>
    </Modal>
  );
}
