import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { Button } from '@oneclass/onedesign';
import { CopySolid } from '@onedesign/icon';
import { StyledHintModal } from 'containers/ExamPaperListContent/ExamPaperListContent.style';
import deviceProvider from 'providers/deviceProvider';
import { useTranslation } from 'react-i18next';

export const DownloadFailedModal = ({ downloadFailedPaperId, closeModal }) => {
  const { deviceData } = useContext(deviceProvider.deviceProviderContext);
  const { t } = useTranslation();
  return <StyledHintModal
    visible={downloadFailedPaperId}
    title= {t('components.downloadFailedModal.__failedToCreatePaperVolume')}
    className='downloadFailed'
    onCancel={closeModal}
    centered={deviceData.isDesktop}
    footer={
      <Button
        variant='brand2'
        onClick={closeModal}
        className='submit'
      >
     {t('components.downloadFailedModal.__learn')}
      </Button>
    }
  >
    <div> {t('components.downloadFailedModal.__paperInVolumeIssue')}</div>
    <div className='paperId'>
      <span> {t('components.downloadFailedModal.__paperId')} {downloadFailedPaperId}</span>
      <Tooltip
        trigger='click'
        title={
          <div style={{ display: 'flex', alignItems: 'center', columnGap: '4px' }}>
            <img src='/assets/checkCircle.svg' alt='' />
            <span> {t('components.downloadFailedModal.__copySuccess')}</span>
          </div>
        }
        onVisibleChange={(visible) => {
          if (visible) {
            navigator.clipboard.writeText(downloadFailedPaperId);
          }
        }}
      >
        <div style={{ height: '24px', cursor: 'pointer' }}><CopySolid color='#A1A4B1' fontSize='24px'/></div>
      </Tooltip>
    </div>
    <div className='hint'> {t('components.downloadFailedModal.__getPaperAgain')}</div>
  </StyledHintModal>;
};

DownloadFailedModal.propTypes = {
  downloadFailedPaperId: PropTypes.string,
  closeModal: PropTypes.func,
};
