import React, { useEffect, useState, useContext } from 'react';
import { ExamPaperListContent, TestListContent } from 'containers';
import {
  AuthProvider,
  Tab,
  TabPane
} from '@oneclass/ui-components';
import { useLocation } from 'react-router-dom';
import { useEduSubjectModal } from 'utils/hooks/useEduSubjectModal';
import { StyledExamPaperListPage } from './ExamPaperListPage.style';
import { Announcement } from 'components';
import { default as behaviorProvider } from 'providers/behaviorProvider.js';
import { default as UserProvider } from 'providers/UserProvider.js';
import PaperPackageListContent from 'containers/PaperPackageListContent';
import { useTranslation } from 'react-i18next';

export const ExamPaperListPage = ({ className }) => {
  const [tabType, setTabType] = useState('paperList');
  const tabChange = (key) => {
    setTabType(key);
  };
  const { useAuthState } = AuthProvider;
  const authState = useAuthState();
  const location = useLocation();
  const { renderEduSubjectModal, setEduSubjectModalToggle, setEduSubjectModalFalse } = useEduSubjectModal(authState.context.userDetails?.preference, 'mainLayout', location.pathname);
  const { behaviorData, behaviorDataProviderChange } = useContext(behaviorProvider.behaviorProviderContext);
  // const isTutoring = authState.context.userDetails.userStatus?.organization?.type === 'Tutoring';
  const [isShowHint, setIsShowHint] = useState(true);
  const { userData } = useContext(UserProvider.UserProviderContext);
  const { userInfo } = userData;
  const { t } = useTranslation();

  useEffect(() => {
    if (behaviorData?.goToExamList) {
      tabChange('examList');
      behaviorDataProviderChange('goToExamList', false);
    }
  }, [behaviorData?.goToExamList]);

  useEffect(() => {
    if (behaviorData?.goToPackageList) {
      tabChange('paperPackageList');
      behaviorDataProviderChange('goToPackageList', false);
    }
  }, [behaviorData?.goToPackageList]);

  return (
    <StyledExamPaperListPage className={className} data-testid="ExamPaperListPage">
      {
        userInfo?.onePaperIdentity === 'TestCram' ?
          <>
            {
              isShowHint &&
                <Announcement className={'Announcement'} setIsShowHint={setIsShowHint}/>
            }
            <ExamPaperListContent className='Tutoring' setEduSubjectModalToggle={setEduSubjectModalToggle} tabType={tabType} tabChange={tabChange} />
          </>
          :
          <Tab activeKey={tabType} onChange={tabChange} size='large' destroyInactiveTabPane>
            <TabPane tab={t('views.examPaperListPage.__examPaperList')} key="paperList">
              <ExamPaperListContent setEduSubjectModalToggle={setEduSubjectModalToggle} tabType={tabType} tabChange={tabChange} />
            </TabPane>
            {
              userInfo?.userType !== 'Premium' &&
                <TabPane tab={t('views.examPaperListPage.__examList')} key="examList">
                  <TestListContent setEduSubjectModalToggle={setEduSubjectModalToggle} tabType={tabType} />
                </TabPane>
            }
            {
              userInfo?.userType === 'Premium' &&
                <TabPane tab={t('views.examPaperListPage.__paperPackageList')} key="paperPackageList">
                  <PaperPackageListContent setEduSubjectModalToggle={setEduSubjectModalToggle} tabType={tabType} tabChange={tabChange} />
                </TabPane>
            }
          </Tab>
      }
      {renderEduSubjectModal(authState.context.userDetails?.onePaperIdentity)}
    </StyledExamPaperListPage>
  );
};