/* eslint-disable react/prop-types */
import { useState, useMemo, useEffect, useContext } from 'react';
import { Box, Modal, FETCH_DATA_EVENT, fetchDataMachine, setRadioOptions, AuthProvider, CreateExamPaperParamsProvider, EditCopyExamPaperProvider } from '@oneclass/ui-components';
import { Button, RadioGroup } from '@oneclass/onedesign';
import { Radio, Tooltip } from 'antd';
import { useMachine } from '@xstate/react';
import { useBoolean } from 'ahooks';
import { getMemberTypeSetting, postMemberPreference } from 'api/member';
import styled from 'styled-components';
import { LaptopSolid, HandPointUpSolid, ChessKnightSolid, ShapesSolid, QuestionCircleSolid } from '@onedesign/icon';
import { useHistory, useLocation } from 'react-router-dom';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { getCookie, getLocalStorage, setLocalStorage } from 'utils/common';
import { default as UserProvider } from 'providers/UserProvider.js';
import * as GA from 'utils/googleAnalytics';
import { useBitrix } from 'utils/hooks/useBitrix';
import { educationMapper, quizOutputTypeMapper } from 'locales/translationMapper';
import { useTranslation } from 'react-i18next';
import { subjectMapper } from 'locales/translationMapper';
const StyledModal = styled(Modal)`
  .examTypeBox{
    position:relative;
    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .quizTip{
    position: absolute;
    top: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:${({ theme }) => theme.colors.text.text_09};
    cursor: pointer;

    @media (max-width: 1024px) {
      position: relative;
      left: unset !important;
      top: unset;
    }
  }
  .modalTitle{
    font-size: 24px;
  }
  .footerBtn{
		display: flex;
		&> *:first-child{
			margin-right: 8px;
		}
	}
  .modalContent{
		color:${({ theme }) => theme.colors.text.text_06};

		.title{
			font-size: 20px;
			color:${({ theme }) => theme.colors.text.black};
		}
		.subTitle{
			color:${({ theme }) => theme.colors.text.text_09};
		}
	}
  .ant-radio-group.radioList{
		width: 100%;
		.ant-radio-button-wrapper{
			width: 18.4%;
			margin-bottom: 8px;
      border-color:${({ theme }) => theme.colors.text.text_06};
			&:not(:first-child){
				margin-left:2%;
			}
			&:nth-child(5n+1){
				margin-left: 0;
			}
		}
	}
	.questionTypeRadio{
		width: 100%;
		
		.ant-radio-button-wrapper{
			height: initial;
			width: 31%;
			margin-bottom: 8px;
      border-color:${({ theme }) => theme.colors.text.text_06};
			padding: 32px 28px;
      border-radius: 8px;
      box-sizing: border-box;
      border-left-width: 1.02px;
			&:not(:first-child){
				margin-left:2%;
			}
			&:nth-child(3n+1){
				margin-left: 0;
			}
			/* &:last-child{
				border-left-width: 1.02px;
			} */
		}
    .new{
      position: absolute;
      top: 12px;
      right:11px;
      pointer-events: none;
      background-color: #FEF2F0;
      border-radius: 4px;
      p{
        margin: 4px 8px;
        font-size: 11px;
        color: #EC7963;
        font-weight: 700;
        line-height: 16px;
      }
    }
		.radioContent{
			text-align: center;
			.radioImg{
				background-color: ${({ theme }) => theme.colors.ui.ui_01};
				border-radius: 50%;
				padding: 20px;
				display: inline-flex;
				font-size: 40px;
				color:${({ theme }) => theme.colors.brand.brand01};
				margin-bottom: 20px;
				svg{
					margin-right: 0;
				}
			}
			.radioTitle{
				font-size: 16px;
				margin-bottom: 8px;
				color:${({ theme }) => theme.colors.text.black};
			}
			.radioText{
				color:${({ theme }) => theme.colors.text.text_09};
        line-height: initial;
        text-align: left;
			}
		}
		
		.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled){
            &:hover{
                color:${({ theme }) => theme.colors.brand.brand02};
                border-color: ${({ theme }) => theme.colors.brand.brand02};
				border-left-color: ${({ theme }) => theme.colors.brand.brand02};
				border-width: 2px;
				.radioTitle{
					color:${({ theme }) => theme.colors.text.black};
				}
				.radioText{
					color:${({ theme }) => theme.colors.text.text_09};
				}
            }
			&::before{
                display: none;
            }
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
            /* background-color: ${({ theme }) => theme.colors.brand.brand02}; */
            border-color: ${({ theme }) => theme.colors.brand.brand02};
			color:${({ theme }) => theme.colors.ui.white};
			border-width: 2px;
            &::before{
                display: none;
            }
            &:hover{
                /* color:${({ theme }) => theme.colors.ui.white};
                border-color: ${({ theme }) => theme.colors.brand.brand02}; */
				.radioTitle{
					color:${({ theme }) => theme.colors.text.black};
				}
				.radioText{
					color:${({ theme }) => theme.colors.text.text_09};
				}
            }
        } 
	}

  /* 手機版 畫面調整 (425px 開始變化) */
  @media (max-width: 1024px) { 
    .ant-modal{
      /* top: 3vh; */
      top: 0px;
      left: 0;
      bottom: 0;
      max-width:100%;
      margin:0 0;
      padding: 0 0;
    }
    .ant-modal-content{
      overflow: hidden;
      border-radius:12px 12px 0 0;
      height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
    }
    .ant-modal-body{
      padding: 16px 24px 16px !important;
      height: calc(calc(var(--vh, 1vh) * 100) - 64px - 90px) !important;
      overflow-y: auto;
    }
    .ant-modal-title{
      justify-content: center;
    }
    .ant-modal-header{
      padding: 21px 40px 21px;
    }
    .ant-radio-group.radioList{
      width: 100%;
      .ant-radio-button-wrapper{
        width: calc((92%)/3) !important;
        margin-bottom: 4%;
        padding: 8px 9px;
        margin-left: 0 !important;
        margin-right: 2.66% !important;
      }
      .longerRadio{
        width: calc((92%)/2) !important;
      }
    }
    .ant-radio-group.ant-radio-group-outline.questionTypeRadio{
      >label{
        width: 100%;
        height: 331px;
        margin-left:0;
        margin-bottom:4%;
        display: flex;
        justify-content:center;
        align-items:center;
      }
    }
    .ant-modal-close-x{
      display: flex;
      justify-content:center;
      align-items:center;
      width: 50px;
      height:63px;
    }
    .ant-modal-footer{
      background-color: #fff;
      box-shadow: 0px -1px 4px rgba(0,0,0,0.1);
      z-index: 1010;
      position: sticky;
      bottom: 0;
      padding: 10px 34px 40px;
      >div{
        width: 100%;
        >div{
          width: 100%;
          margin-right:0px !important;
          >div{
            margin-right:0px !important;
            width: 100%;
          }
        }
      }
    }
  } 
`;
export const useEduSubjectModal = (defaultOptions, usedPage, pathname, doSomeThing) => {
  const { hideButton, showButton } = useBitrix();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const { createExamPaperParams, createExamPaperParamsChange } = useContext(CreateExamPaperParamsProvider.createExamPaperContext);
  const { editCopyExamPaperData, resetCopyEditPaperParams } = useContext(EditCopyExamPaperProvider.EditCopyExamPaperContext);
  const { useAuthState } = AuthProvider;
  const { userData } = useContext(UserProvider.UserProviderContext);
  const { userInfo, eduSubjects, outputTypes, drawUpTypes } = userData;
  const history = useHistory();
  const authState = useAuthState();
  const location = useLocation();
  const [useEduSubjectModalIsDone, setUseEduSubjectModalIsDone] = useState(false);
  const [sendParams, setSendParams] = useState({});
  const [modalVisible, { toggle: setEduSubjectModalToggle, setFalse: setEduSubjectModalFalse }] = useBoolean(false);
  const { onePaperIdentity } = userInfo;
  const { t } = useTranslation();
  const studentArray = ['LinkCramStudent', 'LiveStudent'];
  const schoolTeacherArray = ['SchoolTeacher', 'LinkSchoolTeacher'];


  // const setRadioOptionsWithTranslation = (items) => {
  //   setRadioOptions();
  // };

  useEffect(() => {
    if (modalVisible) {
      hideButton();
    } else {
      showButton();
    }
  }, [modalVisible]);

  // 是否為 學生身份。
  const isStudent = useMemo(() => {
    return studentArray.indexOf(onePaperIdentity) !== -1;
  }, [onePaperIdentity, studentArray]);

  // const [, sendPostMemberPreference] = useMachine(fetchDataMachine, {
  //   services: {
  //     fetchData: async(_, event) => {
  //       const res = await postMemberPreference(event.payload);
  //       const { isSuccess, systemCode, message } = res;

  //       return {
  //         isSuccess, systemCode, message,
  //       };
  //     }
  //   }
  // });

  // 因學制科目 更換api提供，改變了取得的方式 及 處理格式上的差異
  const eduSubjectMap = useMemo(() => {
    if (eduSubjects && JSON.stringify(eduSubjects) !== '{}' ) {
      let newObject = {};
      Object.entries(eduSubjects).forEach(([key, value]) => {
        newObject = {
          ...newObject,
          [key]: value.map((item) => {
            return {
              code: item.code.slice(1),
              name: item.name.slice(2),
            };
          })
        };
      });
      return newObject;
    } else {
      return {};
    }
  }, [eduSubjects]);

  const [state, send] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_, event) => {
        const res = await getMemberTypeSetting(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        const {
          uid = '',
          isTeacher = false,
          identityName = '',
          account = '',
          name = '',
          organizationName = '',
          paperSizeMap = [],
          wordSettingMap = [],
          paperContent = [],
          typesetting = {},
          analyzeContent = []
        } = data || {};
        return {
          isSuccess, systemCode, message,
          uid,
          isTeacher,
          identityName,
          account,
          name,
          organizationName,
          paperSizeMap: setRadioOptions(paperSizeMap) || [],
          wordSettingMap: setRadioOptions(wordSettingMap) || [],
          paperContent,
          typesetting,
          analyzeContent
        };
      }
    }
  });

  const { isTeacher } = state.context.result || {};
  const disabled = useMemo(() => {
    let result = false;
    const num = isStudent ? 3 : 4;
    if (Object.keys(sendParams).length < num) result = true;
    Object.entries(sendParams).forEach(([key, value]) => {
      if (isStudent) {
        if (key === 'output') return;
      }
      if (value === '') result = true;
    });
    return result;
  }, [sendParams]);

  const subjectOptions = useMemo(() => {
    if (!sendParams?.edu || !eduSubjectMap) return [];
    if (!eduSubjectMap[sendParams.edu]) return [];
    let subjects = eduSubjectMap[sendParams.edu].map(e => {
      return {
        code: e.code,
        name: t(subjectMapper(e.code))
      };
    });
    return setRadioOptions(subjects);

  }, [sendParams.edu, eduSubjectMap]);

  const outputMap = useMemo(() => {

    const transOutputType = outputTypes.map(e => {
      return {
        code: e.code,
        name: t(quizOutputTypeMapper(e.code))
      };
    });
    if (transOutputType && transOutputType.length > 0 ) {
      return setRadioOptions(transOutputType);
    } else {
      return [];
    }
  }, [outputTypes]);

  const goToCreateExamPaper = async() => {
    resetCopyEditPaperParams();
    createExamPaperParamsChange(
      sendParams.edu,
      sendParams.subject,
      sendParams?.output,
      {
        edu: eduMap.find(item => item.value === sendParams.edu).label,
        subject: subjectOptions.find(item => item.value === sendParams.subject).label,
      }
    );

    // 按下完成時，紀錄使用者 選取的內容，下次打開時 預先帶入。
    setLocalStorage('OnePaper_Behavior', {
      edu: sendParams?.edu,
      subject: sendParams?.subject,
      output: sendParams?.output,
      mode: sendParams?.questionType,
    });

    // await sendPostMemberPreference(FETCH_DATA_EVENT.FETCH, {
    //   payload: {
    //     education: sendParams.edu,
    //     subject: sendParams.subject
    //   }
    // });

    setEduSubjectModalFalse();
    if (location.pathname === '/' + sendParams.questionType) {
      doSomeThing && doSomeThing();
    } else {
      history.push(`/${sendParams.questionType}`);
    }
  };

  const onRadioChange = (type, e) => {
    if (type === 'edu') {
      setSendParams({
        ...sendParams,
        [type]: e.target.value,
        subject: '',
      });
    } else {
      setSendParams({
        ...sendParams,
        [type]: e.target.value
      });
    }
  };
  const modalCancel = () => {
    setEduSubjectModalFalse();

    if (createExamPaperParams.edu !== '' && createExamPaperParams.subject !== '' ) {
      setSendParams({
        ...sendParams,
        edu: createExamPaperParams.edu,
        subject: createExamPaperParams.subject
      });
    } else {
      setSendParams({
        ...sendParams,
        edu: '',
        subject: ''
      });
    }
  };
  useEffect(() => {
    if (usedPage === 'mainLayout'
        && pathname !== '/examPaperList'
        && pathname !== '/studentExamPaperList'
        && pathname !== '/memberCenter'
        && pathname !== '/SearchPaperListPage'
        && pathname !== '/computerQuestion'
        && pathname !== '/manualProposition'
        && pathname !== '/knowledgeProposition'
        && pathname !== '/equationDojo'
        && pathname !== '/studentIntelligentProposition') return;
    if ((authState.value === 'loggedIn' || authState.value === 'settingEduSubject')) {
      const oneClubToken = JSON.parse(getCookie('nani_oneclass_login_token'))?.jwt;
      send(FETCH_DATA_EVENT.FETCH, {
        payload: {
          jwtToken: oneClubToken,
        }
      });
      if (createExamPaperParams) {
        if (createExamPaperParams.edu !== '' && createExamPaperParams.subject !== '' ) {
          setSendParams({
            ...sendParams,
            edu: createExamPaperParams.edu,
            subject: createExamPaperParams.subject,
            output: createExamPaperParams.output,
          });
        } else {
          setSendParams({
            ...sendParams,
            edu: '',
            subject: '',
            output: '',
          });
        }
      }
    }
  }, [authState.value]);

  useEffect(() => {
    document.body.classList.remove('mobileScrollingStop');
    if (!deviceData.isDesktop) {
      if (modalVisible) {
        document.body.classList.add('mobileScrollingStop');
      }
    }
  }, [modalVisible]);

  // useEffect(() => {
  //   if (isTeacher) {
  //     setSendParams({
  //       ...sendParams,
  //       output: 'Files'
  //     });
  //   }
  // }, [isTeacher, outputMap]);

  const eduMap = useMemo(() => {
    if (!eduSubjects) return [];
    let eduMapArray = Object.entries(eduSubjects).map(([key, value]) => {
      return {
        name: (value[0].name).substring(0, 2),
        code: key,
      };
    });

    const transEduMapArray = eduMapArray.map(e => {
      return {
        name: t(educationMapper(e.code), e.name),
        code: e.code
      };
    });

    return setRadioOptions(transEduMapArray) || [];
  }, [eduSubjectMap]);


  // 後端若有傳 最後使用科目 將會預先帶入
  // useEffect(() => {
  //   if (modalVisible === false) return null;
  //   if (authState && eduMap && JSON.stringify(eduSubjectMap) !== '{}') {
  //     if (userInfo?.preference?.education && userInfo?.preference?.subject) {
  //       setSendParams({
  //         ...sendParams,
  //         edu: userInfo.preference.education,
  //         subject: userInfo.preference.subject,
  //       });
  //     } else {
  //       setSendParams({
  //         ...sendParams,
  //         edu: eduMap[0].value,
  //         subject: eduSubjectMap[eduMap[0].value][0].code,
  //       });
  //     }
  //   }
  // }, [authState, stateGetMemberEduSubject.value, eduMap, modalVisible]);

  useEffect(() => {
    if ((sendParams.edu === 'E' && sendParams.subject === 'CH') || (sendParams.edu === 'E' && sendParams.subject === 'PE')) {
      setSendParams({
        ...sendParams,
        questionType: '',
      });
    }
  }, [sendParams.edu, sendParams.subject]);

  // 每當 彈窗打開時，判斷是否有儲存操作記錄，有的話帶入。
  useEffect(() => {
    if (modalVisible) {
      const behavior = getLocalStorage('OnePaper_Behavior') || {};

      // FIXME: 只給立即體驗使用
      if (defaultOptions) {
        behavior.edu = defaultOptions?.edu;
        behavior.subject = defaultOptions?.subject;
        behavior.output = defaultOptions?.output;
        behavior.mode = defaultOptions?.mode;
      }

      if (Object.keys(behavior).length) {
        setSendParams({
          ...sendParams,
          edu: behavior?.edu,
          subject: behavior?.subject,
          output: isStudent ? 'SelfLearning' : behavior?.output,
          questionType: behavior?.mode,
        });
      } else {
        setSendParams({
          ...sendParams,
          edu: eduMap[0]?.value || 'E',
          subject: eduSubjectMap[eduMap[0].value][0].code,
          output: isTeacher ? 'Files' : isStudent ? 'SelfLearning' : '',
        });
      }
    }
  }, [modalVisible]);

  const renderEduSubjectModal = (identity) => {

    const checkIdentityForOutputMap = (outputMap) => {
      if (identity === 'TestCram') {
        return outputMap.filter((item) => {
          return item.value === 'Files';
        });
      } else if (schoolTeacherArray.indexOf(identity) !== -1) {
        return outputMap.filter((item) => {
          return item.value === 'Files' || item.value === 'Quick';
        });
      } else {
        return outputMap;
      }
    };

    return (
      <StyledModal
        visible={modalVisible}
        width={deviceData.isDesktop ? '868px' : '100%'}
        onCancel={modalCancel}
        zIndex={2000}
        id={'useEduSubjectModal'}
        title={
          <div className='modalTitle'>{t('hooks.useEduSubjectModal.__examModalTitle')}</div>
        }
        footer={
          <div className="footerBtn">
            <Button disabled={disabled} variant={disabled ? 'ui02' : 'brand1'} onClick={() => {goToCreateExamPaper(); }}>{t('hooks.useEduSubjectModal.__startButton')}</Button>
          </div>
        }
      >
        <div className="modalContent" id={'modalContent'}>
          <Box mt={2} mb={4}>
            <div className='title'>{t('hooks.useEduSubjectModal.__educationSubject')}</div>
          </Box>
          <Box mb={2}>
            <div className='subTitle'>{t('hooks.useEduSubjectModal.__educationSystem')}</div>
          </Box>
          <Box mb={6}>
            <RadioGroup className='radioList' buttonStyle='solid' optionType='button' options={eduMap} value={sendParams?.edu} onChange={(e) => {onRadioChange('edu', e);}} />
          </Box>
          {
            subjectOptions && subjectOptions.length > 0 &&
            <>
              <Box mb={2}>
                <div className='subTitle'>{t('hooks.useEduSubjectModal.__subject')}</div>
              </Box>
              <Box mb={48}>
                <RadioGroup className='radioList' buttonStyle='solid' optionType='button' value={sendParams?.subject} onChange={(e) => {onRadioChange('subject', e);}}>
                  {
                    subjectOptions && subjectOptions.filter((item) => {
                      // OPP-1758 國小英文暫時隱藏
                      // const isEEN = sendParams?.edu === 'E' && item.value === 'EN';
                      return true;
                    }).map(item => (
                      <Radio.Button key={item.value} className={item.label.length > 5 ? 'longerRadio' : ''} value={item.value}>{item.label}</Radio.Button>
                    ))
                  }
                </RadioGroup>
              </Box>
            </>
          }
          {
            studentArray.indexOf(identity) === -1 ?
              <>
                <Box mb={4}>
                  <div className='title'>{t('hooks.useEduSubjectModal.__examType')}</div>
                </Box>
                <Box mb={48} className={'examTypeBox'}>
                  <RadioGroup className='radioList' buttonStyle='solid' optionType='button' value={sendParams?.output} onChange={(e) => {onRadioChange('output', e);}}>
                    {
                      outputMap && checkIdentityForOutputMap(outputMap).map(item => {
                        return <Radio.Button key={item.value} value={item.value}>{item.label}</Radio.Button>;
                      })
                    }
                  </RadioGroup>
                  {
                    (identity !== 'TestCram' && identity !== 'LinkCramStudent' && identity !== 'LinkCramTeacher' && identity !== 'LiveStudent' && userInfo?.userType !== 'Premium') ?
                      <Tooltip
                        placement="top"
                        title={<span>{t('hooks.useEduSubjectModal.__examTypeExplanation1')}<br/>{t('hooks.useEduSubjectModal.__examTypeExplanation2')}</span>}
                        zIndex={2999}
                        color='#4e4e5a'
                        trigger={deviceData.isDesktop ? 'hover' : 'click'}
                      >
                        <div
                          className={'quizTip'}
                          style={{ left: checkIdentityForOutputMap(outputMap).findIndex(item => item.value === 'Quick') * 161 }}
                        >
                          <QuestionCircleSolid/>{t('hooks.useEduSubjectModal.__whatIsTestCram')}
                        </div>
                      </Tooltip>
                      : null
                  }
                </Box>
              </> : null
          }

          <Box mb={4}>
            <div className='title'>{t('hooks.useEduSubjectModal.__questionType')}</div>
          </Box>
          <Box>
            <RadioGroup
              className='questionTypeRadio'
              value={sendParams?.questionType}
              onChange={(e) => {
                GA.chooseQuestionType(e.target.value);
                onRadioChange('questionType', e);
              }}>
              {
                isStudent ?
                  <>
                    <Radio.Button value='studentIntelligentProposition'>
                      <div className='radioContent'>
                        <div className='radioImg'><ShapesSolid /></div>
                        <div className='radioTitle'>{t('hooks.useEduSubjectModal.__intelligentProposition')}</div>
                        <div className='radioText'>{t('hooks.useEduSubjectModal.__intelligentPropositionExplanation')}</div>
                      </div>
                    </Radio.Button>
                  </> :
                  <>
                    <Radio.Button value='computerQuestion'>
                      <div className='radioContent'>
                        <div className='radioImg'><LaptopSolid /></div>
                        <div className='radioTitle'>{t('hooks.useEduSubjectModal.__computerProposition')}</div>
                        <div className='radioText'>{t('hooks.useEduSubjectModal.__computerPropositionExplanation')}</div>
                      </div>
                    </Radio.Button>
                    <Radio.Button value='manualProposition'>
                      <div className='radioContent'>
                        <div className='radioImg'><HandPointUpSolid /></div>
                        <div className='radioTitle'>{t('hooks.useEduSubjectModal.__manualProposition')}</div>
                        <div className='radioText'>{t('hooks.useEduSubjectModal.__manualPropositionExplanation')}</div>
                      </div>
                    </Radio.Button>
                    {/*     知識點命題 => 國小 - 國語、健體 沒有支援    */}
                    {/* {
                      (!(sendParams.edu === 'E' && sendParams.subject === 'CH') && !(sendParams.edu === 'E' && sendParams.subject === 'PE'))
                      && schoolTeacherArray.indexOf(identity) === -1 &&
                      <Radio.Button value='knowledgeProposition'>
                        <div className='radioContent'>
                          <div className='radioImg'><ChessKnightSolid/></div>
                          <div className='radioTitle'>知識向度命題</div>
                          <div className='radioText'>以知識主題選題出卷。協助針對學生的學習弱項進行加強。</div>
                        </div>
                      </Radio.Button>
                    } */}
                    {/* 無限命題只有國中數學才有 */}
                    {deviceData.isDesktop && userInfo.userType === 'General' &&
                      (sendParams.edu === 'J' && sendParams.subject === 'MA'
                      && sendParams.output === 'Files') &&
                       <Radio.Button value='equationDojo'>
                         <div className='new'><p>{t('hooks.useEduSubjectModal.__new')}</p></div>
                         <div className='radioContent'>
                           <div className='radioImg'><img src="/assets/equationDojo.png" alt="equationDojo" /></div>
                           <div className='radioTitle'>{t('hooks.useEduSubjectModal.__infiniteProposition')}</div>
                           <div className='radioText'>{t('hooks.useEduSubjectModal.__infinitePropositionExplanation')}</div>
                         </div>
                       </Radio.Button>
                    }
                  </>
              }
            </RadioGroup>
          </Box>
        </div>
      </StyledModal>
    );
  };
  return { renderEduSubjectModal, setEduSubjectModalToggle, setEduSubjectModalFalse, useEduSubjectModalIsDone };
};
