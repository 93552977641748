/* eslint-disable react/prop-types */
import { ExclamationCircleSolid, XmarkOutline } from '@onedesign/icon';
import { StyledSuspendedQuestionsBar } from './SuspendedQuestionsBar.style';
import { useTranslation } from 'react-i18next';

export const SuspendedQuestionsBar = ({ className, currentTotal, error, onClose }) => {
  const { missingKnowledges, total, undefinedCount } = error;
  const originalTotal = currentTotal + total;
  const { t } = useTranslation();

  return (
    <StyledSuspendedQuestionsBar className={className} data-testid="SuspendedQuestionsBar">
      <ExclamationCircleSolid />
      <div className="banner__text">
        <div>
          {t('components.suspendedQuestionsBar.__originalTotalLabel',{originalTotal,total})}
        </div>
        {missingKnowledges.map((knowledge) => (
          <div key={knowledge.name}>
            {t('components.suspendedQuestionsBar.__suspendedLabel',{knowledgeName: knowledge.name,knowledgeCount:knowledge.count})}
          </div>
        ))}
        {undefinedCount > 0 && <div> {t('components.suspendedQuestionsBar.__messageLabel', {undefinedCount})}</div>}
      </div>
      <div className="banner__close" onClick={onClose}>
        <XmarkOutline />
      </div>
    </StyledSuspendedQuestionsBar>
  );
};