/* eslint-disable react/prop-types */
import { knowledgeArrayToString } from 'utils/common';
import { StyledQuestionDetail } from './QuestionDetail.style';
import { ChevronDownOutline, ChevronUpOutline } from '@onedesign/icon';
import { useTranslation } from 'react-i18next';
import { sourceMapper } from 'locales/translationMapper/sourceMapper';

const QuestionDetail = ({ question, isOpened, onToggle }) => {
  const source = question?.metaData?.source?.name;
  const knowledge = knowledgeArrayToString(question?.metaData?.knowledges || []);
  const learnContent = question?.metaData?.learnContent?.name;
  const { t } = useTranslation();

  return <StyledQuestionDetail>
    {isOpened && (
      <div className='questionDetail'>
        {source && (
          <div className='questionDetail__item'>
            <div className='questionDetail__key'>{t('components.questionDetail.__source')}</div>
            &nbsp;
            <div className='questionDetail__value'>{source}</div>
          </div>
        )}
        {knowledge && (
          <div className='questionDetail__item'>
            <div className='questionDetail__key'>{t('components.questionDetail.__knowledgeDimension')}</div>
            &nbsp;
            <div className='questionDetail__value'>{knowledge}</div>
          </div>
        )}
        {learnContent && (
          <div className='questionDetail__item'>
            <div className='questionDetail__key'>{t('components.questionDetail.__learningContent')}</div>
            &nbsp;
            <div className='questionDetail__value'>{learnContent}</div>
          </div>
        )}
      </div>
    )}
    <div className='toggleButton' onClick={onToggle}>
      {isOpened
        ? <><ChevronUpOutline />{t('components.questionDetail.__collapseQuestionInfo')}</>
        : <><ChevronDownOutline />{t('components.questionDetail.__expandQuestionInfo')}</>
      }
    </div>
  </StyledQuestionDetail>;
};

export default QuestionDetail;