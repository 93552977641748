/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  Pagination,
  Modal,
  sliceTableData,
} from '@oneclass/ui-components';
import { Button, RadioGroup } from '@oneclass/onedesign';
import { EyeSolid, SlidersHSolid, ChevronRightOutline, LinkSolid, DownloadSolid, CopySolid, TrashAltOutline, ChalkboardOutline, PenSolid } from '@onedesign/icon';
import { useBoolean } from 'ahooks';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { StyledTestListMobile } from './TestListMobile.style';
import { v4 as uuidv4 } from 'uuid';
import * as GA from 'utils/googleAnalytics';
import { useTranslation } from 'react-i18next';

export const TestListMobile = ({
  className,
  filterParams,
  eduSubjectOptions,
  typeOptions,
  patternOptions,
  filterData,
  pageState,
  authState,
  pageChange,
  mobileChangeFilterCondition,
  goToReviewPage,
  setEduSubjectModalToggle,
  fetchQuizExcel,
  examLink,
  transformTime,
  eduGradeOptions,
  renderCreateQuizExamModal,
  createQuizExamModalToggle,
  setCreateQuizExamModalFalse,
  modalVisible,
  setModalVisibleToggle,
  setModalVisibleFalse,
  goToExamPage,
  canEditExam,
  renderLoadingContent,
  isLoading,
  setIsLoading,
  openHintModal,
  testModalVisible,
  setTestModalVisibleToggle,
  modalInfo,
  setModalInfo,
}) => {
  const { t } = useTranslation();
  const [filterParamsMobile, setFilterParamsMobile] = useState({});
  const [eduSubjectMobile, setEduSubjectMobile] = useState({
    edu: '',
    subject: '',
    userEdu: '',
    grade: '',
  });
  const [timeArrayMobile, setTimeArrayMobile] = useState({
    startTime: '',
    endTime: '',
  });
  const [filterIsChanged, setFilterIsChanged] = useState(false);
  // 手機版相關

  const [filterDrawerVisible, { toggle: filterDrawerToggle, setFalse: filterDrawerFalse }] = useBoolean(false);
  const showMobileFilter = () => {
    filterDrawerToggle();
    setFilterIsChanged(false);
    setEduSubjectMobile({
      edu: filterParams?.eduSubject ? filterParams?.eduSubject.slice(0, 1) : '',
      subject: filterParams?.eduSubject ? filterParams?.eduSubject.slice(1, 3) : '',
      userEdu: filterParams?.eduGrade ? filterParams?.eduGrade.slice(0, 1) : '',
      grade: filterParams?.eduGrade ? filterParams?.eduGrade.slice(1, 4) : '',
    });
    setFilterParamsMobile(filterParams);
  };

  const showDetailModal = (data) => {
    if (!data) return;
    setModalInfo(data);
    setModalVisibleToggle();
  };

  const doneChangeFilter = () => {
    mobileChangeFilterCondition(filterParamsMobile);
    filterDrawerFalse();
    for (const type in filterParamsMobile) {
      GA.setExamListFilter(type);
    }
  };

  const showShareModal = (data) => {
    if (!data) return;
    setModalInfo(data);
    setTestModalVisibleToggle();
  };

  const clearAllFilter = () => {
    if (Object.keys(filterParamsMobile).length !== 0 || eduSubjectMobile.edu || eduSubjectMobile.userEdu || timeArrayMobile.startTime || timeArrayMobile.endTime) {
      setFilterParamsMobile({});
      setEduSubjectMobile({
        edu: '',
        subject: '',
        userEdu: '',
        grade: '',
      });
      setTimeArrayMobile({
        startTime: '',
        endTime: '',
      });
      setFilterIsChanged(true);
    }
  };

  const changeEduSubjectMobile = (val, type) => {
    if (type === 'edu') {
      setEduSubjectMobile({
        ...eduSubjectMobile,
        edu: val,
        subject: '',
      });
    } else if (type === 'userEdu') {
      setEduSubjectMobile({
        ...eduSubjectMobile,
        userEdu: val,
        grade: '',
      });
    }
    else {
      setEduSubjectMobile({
        ...eduSubjectMobile,
        [type]: val,
      });
    }
    setFilterIsChanged(true);
  };

  const checkChangeFilterBtn = useMemo(() => {
    if (filterIsChanged) {
      const checkedEduSubject = () => {
        if (eduSubjectMobile.edu) {
          if (eduSubjectMobile.subject) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      };

      const checkedEduGrade = () => {
        if (eduSubjectMobile.userEdu) {
          if (eduSubjectMobile.grade) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      };

      const checkedTimeArray = () => {
        if (timeArrayMobile.startTime !== '' || timeArrayMobile.endTime !== '') {
          if (timeArrayMobile.startTime !== '' && timeArrayMobile.endTime !== '') {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      };
      if (checkedEduSubject() && checkedTimeArray() && checkedEduGrade()) {
        return true;
      } else {

        return false;
      }
    } else {
      return false;
    }
  }, [eduSubjectMobile, filterIsChanged, timeArrayMobile]);

  // 重新組成 學制 / 科目 列表
  const eduOptions = useMemo(() => {
    let eduArray = [];
    let checkArray = [];
    if (eduSubjectOptions) {
      eduSubjectOptions.forEach((item) => {
        if (checkArray.indexOf(item.value.slice(0, 1)) === -1) {
          let order = 0;
          if (item.value.slice(0, 1) === 'J') order = 1;
          if (item.value.slice(0, 1) === 'H') order = 2;
          eduArray.push({
            label: item.name.slice(0, 2),
            value: item.value.slice(0, 1),
            order
          });
          checkArray.push(item.value.slice(0, 1));
        }
      });
    }
    return eduArray.sort((a, b) => {
      return a.order - b.order;
    });
  }, [eduSubjectOptions]);

  const subjectOptions = useMemo(() => {
    let subjectArray = {};
    if (eduSubjectOptions) {
      eduSubjectOptions.forEach((item) => {
        if (subjectArray[item.value.slice(0, 1)]) {
          let next = { ...subjectArray };
          next[item.value.slice(0, 1)].push({
            label: item.name.slice(2, 10),
            value: item.value.slice(1, 3)
          });
          subjectArray = next;
        } else {
          let next = { ...subjectArray };
          next[item.value.slice(0, 1)] = [{
            label: item.name.slice(2, 10),
            value: item.value.slice(1, 3)
          }];
          subjectArray = next;
        }
      });
    }
    return subjectArray;
  }, [eduSubjectOptions]);

  const userEduOptions = useMemo(() => {
    let eduArray = [];
    let checkArray = [];
    if (eduGradeOptions) {
      eduGradeOptions.forEach((item) => {
        if (checkArray.indexOf(item?.value.slice(0, 1)) === -1) {
          let order = 0;
          if (item?.value.slice(0, 1) === 'J') order = 1;
          if (item?.value.slice(0, 1) === 'H') order = 2;
          eduArray.push({
            label: item?.name.slice(0, 2),
            value: item?.value.slice(0, 1),
            order
          });
          checkArray.push(item?.value.slice(0, 1));
        }
      });
    }
    return eduArray.sort((a, b) => {
      return a.order - b.order;
    });
  }, [eduGradeOptions]);

  const gradeOptions = useMemo(() => {
    let subjectArray = {};
    if (eduGradeOptions) {
      eduGradeOptions.forEach((item) => {
        if (subjectArray[item.value.slice(0, 1)]) {
          let next = { ...subjectArray };
          next[item.value.slice(0, 1)].push({
            label: item.name.slice(2, 10),
            value: item.value.slice(1, 4)
          });
          subjectArray = next;
        } else {
          let next = { ...subjectArray };
          next[item.value.slice(0, 1)] = [{
            label: item.name.slice(2, 10),
            value: item.value.slice(1, 4)
          }];
          subjectArray = next;
        }
      });
    }
    return subjectArray;
  }, [eduGradeOptions]);

  const canOperateExamPaper = useMemo(() => {
    if (!authState.context.userDetails?.userStatus) return;
    const { identity } = authState.context.userDetails?.userStatus;
    return identity === 'Admin' ||
            identity === 'Editor' ||
            identity === 'Teacher';
  }, [authState.context.userDetails]);

  // 詳情彈窗開啟時 關閉背景滑動
  useEffect(() => {
    document.body.classList.remove('mobileScrollingStop');

    if (modalVisible || filterDrawerVisible || testModalVisible) {
      document.body.classList.add('mobileScrollingStop');
    }

  }, [modalVisible, filterDrawerVisible, testModalVisible]);

  useEffect(() => {
    if (eduSubjectMobile.edu && eduSubjectMobile.subject) {
      setFilterParamsMobile({
        ...filterParamsMobile,
        eduSubject: eduSubjectMobile.edu + eduSubjectMobile.subject,
      });
    }
    if (eduSubjectMobile.userEdu && eduSubjectMobile.grade) {
      setFilterParamsMobile({
        ...filterParamsMobile,
        eduGrade: eduSubjectMobile.userEdu + eduSubjectMobile.grade,
      });
    }
  }, [eduSubjectMobile]);

  const eduGradeName = useMemo(() => {
    if (!modalInfo) return '';
    let eduName = '';
    let gradeName = '';
    switch (modalInfo.Education) {
      case 'E':
        eduName = t('components.testListMobile.__elementarySchool');
        break;
      case 'J':
        eduName = t('components.testListMobile.__juniorHighSchool');
        break;
      case 'H':
        eduName = t('components.testListMobile.__highSchool');
        break;
      default:
        break;
    }
    switch (modalInfo.Grade) {
      case 'G01':
        gradeName = t('components.testListMobile.__grade1');
        break;
      case 'G02':
        gradeName = t('components.testListMobile.__grade2');
        break;
      case 'G03':
        gradeName = t('components.testListMobile.__grade3');
        break;
      case 'G04':
        gradeName = t('components.testListMobile.__grade4');
        break;
      case 'G05':
        gradeName = t('components.testListMobile.__grade5');
        break;
      case 'G06':
        gradeName = t('components.testListMobile.__grade6');
        break;
      default:
        break;
    }
    return eduName + ' ' + gradeName;
  }, [modalInfo]);

  const selectTime = (id) => {
    const time = document.querySelector(`#${id}`).value;
    setTimeArrayMobile({
      ...timeArrayMobile,
      [id]: time,
    });
    setFilterIsChanged(true);
  };

  useEffect(() => {
    if (!filterParams.date) return;
    setTimeArrayMobile({
      startTime: filterParams.date[0],
      endTime: filterParams.date[1],
    });
  }, [filterParams]);

  useEffect(() => {
    if (timeArrayMobile.startTime && timeArrayMobile.endTime) {
      setFilterParamsMobile({
        ...filterParamsMobile,
        date: [timeArrayMobile.startTime, timeArrayMobile.endTime],
      });
    }
  }, [timeArrayMobile]);

  const checkedFilterBtnActive = useMemo(() => {
    let res = false;
    if (filterParams.eduSubject) res = true;
    if (filterParams.eduGrade) res = true;
    if (filterParams.date && filterParams.date[0] !== '' && filterParams.date[1] !== '') res = true;
    return res;
  }, [filterParams]);

  return (
    <StyledTestListMobile className={className} data-testid="TestListMobile">
      <div className='filterBtnBox'>
        <div className={checkedFilterBtnActive ? 'active' : '' }>
          <SlidersHSolid onClick={showMobileFilter}/>
        </div>
      </div>
      {
        isLoading
          ?
          renderLoadingContent()
          :
          <Box>
            <Table className="tableZone">
              <Table.Body bodyMaxHeight={true}>
                {
                  filterData && filterData.length > 0 ? sliceTableData(filterData, pageState.currentPage, pageState.limit).map(item => (
                    <Table.Row key={uuidv4()} isFixedWidth={true} className='tableRow' onClick={() => showDetailModal(item)}>
                      {
                        <>

                          <Table.Item>{item.EduSubjectName}</Table.Item>
                          <Table.Item className='quizName'>{item.QuizName}</Table.Item>
                          <Table.Item>{t('components.testListMobile.__quizEndTime')} {transformTime(item.EndTime)}</Table.Item>
                          <span className='itemImg'><ChevronRightOutline /></span>

                        </>
                      }
                    </Table.Row>
                  )) :
                    <div className="tableEmpty">
                      <div>
                        {t('components.testListMobile.__loadingMessage')}
                      </div>
                      {
                        authState?.context?.userDetails &&
                        <>
                          <Button borderRadius='24px' variant={'brand1'} className="makeATextPaperBtn" onClick={() => setEduSubjectModalToggle()}>
                            {t('components.testListMobile.__makeATextPaperBtn')}
                          </Button>
                        </>
                      }
                    </div>
                }
              </Table.Body>
            </Table>
            <div className="pageBlock">
              <Pagination
                total={filterData?.length || 0}
                defaultCurrent={1}
                current={pageState.currentPage}
                onChange={pageChange}
                defaultPageSize={pageState.limit}
              />
            </div>
          </Box>
      }

      {
        modalInfo &&
        <Modal
          id={'detailModal'}
          visible={modalVisible}
          width={'100%'}
          onCancel={setModalVisibleFalse}
          title= {t('components.testListMobile.__quizDetails')}
        >
          <div className="modalContent">
            <div className="modalRow">
              <Box mb={4}>
                <div className={classNames('modalInnerTitle', 'editBox', canEditExam(modalInfo) ? '' : 'disabled')}>
                  <span>{t('components.testListMobile.__quizSetting')}</span>
                  {
                    canEditExam(modalInfo)
                      ?
                      <PenSolid onClick={() => {createQuizExamModalToggle(modalInfo, 'edit');}}/>
                      :
                      <Tooltip target={'click'} placement="top" title={t('components.testListMobile.__quizAlreadyStarted')} zIndex={1003} color='#4e4e5a'>
                        <PenSolid/>
                      </Tooltip>
                  }
                </div>
                <div className="modalInnerContent">
                  <div className="modalInfoRow">
                    <div>{t('components.testListMobile.__quizName')}</div>
                    <span>{modalInfo.QuizName}</span>
                  </div>
                  <div className="modalInfoRow">
                    <div>{t('components.testListMobile.__quizStartTime')}</div>
                    <span>{transformTime(modalInfo.StartTime)}</span>
                  </div>
                  <div className="modalInfoRow">
                    <div>{t('components.testListMobile.__quizEndTimeModal')}</div>
                    <span>{transformTime(modalInfo.EndTime)}</span>
                  </div>
                  <div className="modalInfoRow">
                    <div>{t('components.testListMobile.__quizDuration')}</div>
                    <span>{modalInfo.Duration}</span>
                  </div>
                  <div className="modalInfoRow">
                    <div>{t('components.testListMobile.__gradeLevel')}</div>
                    <span>{eduGradeName}</span>
                  </div>
                </div>
              </Box>
              <Box>
                <div className="modalInnerTitle">{t('components.testListMobile.__examData')}</div>
                <div className="modalInnerContent">
                  <div className="modalInfoRow">
                    <div>{t('components.testListMobile.__examName')}</div>
                    <span>{modalInfo.ExamName}</span>
                  </div>
                  <div className="modalInfoRow">
                    <div>{t('components.testListMobile.__eduSubject')}</div>
                    <span>{modalInfo.EduSubjectName}</span>
                  </div>
                </div>
              </Box>
              <Box>
                <div className="modalInnerTitle">{t('components.testListMobile.__quizResults')}</div>
                <div className="modalInnerContent">
                  <div className="modalInfoRow">
                    <div>{t('components.testListMobile.__quizResults')}</div>
                    <span>{modalInfo.attendance}</span>
                  </div>
                </div>
              </Box>
            </div>

            <div className='blockDiv'></div>

            <div className="modalRow">
              <div className="modalInnerContent">
                <Box className="buttonZone" mb={52}>
                  <Button
                    disabled={modalInfo.attendance !== 0 ? false : true}
                    variant={modalInfo.attendance !== 0 ? 'brand2' : 'ui02'}
                    onClick={() => {modalInfo.attendance !== 0 && fetchQuizExcel(modalInfo.QuizUID);}}
                  >
                    <Tooltip trigger={modalInfo.attendance === 0 ? 'click' : ''} placement="top" title={'尚無人交卷'} zIndex={1003} color='#4e4e5a'>
                      <DownloadSolid />{t('components.testListMobile.__exportGrades')}
                    </Tooltip>
                  </Button>

                  {
                    modalInfo.attendance === 0 && <div>{t('components.testListMobile.__exportGradesPending')}</div>
                  }

                  <div className='controlBox'>
                    <Button
                      type={'solid'}
                      variant={'ui01'}
                      onClick={() => {showShareModal(modalInfo);}}
                    >
                      <LinkSolid /> {t('components.testListMobile.__shareQuiz')}
                    </Button>

                    <Button
                      variant={'ui01'}
                      type={'solid'}
                      onClick={() => {goToExamPage(modalInfo.ExamUID);}}
                    >
                      <EyeSolid /> {t('components.testListMobile.__previewExam')}
                    </Button>

                    <Button
                      disabled={modalInfo.attendance === 0}
                      type={'solid'}
                      variant={modalInfo.attendance !== 0 ? 'ui01' : 'ui02'}
                      onClick={() => {goToReviewPage(modalInfo.SchoolYear, modalInfo.QuizCode, modalInfo.QuizUID);}}
                    >
                      <Tooltip trigger={modalInfo.attendance === 0 ? 'click' : ''} placement="top" title={'尚無人交卷'} zIndex={1003} color='#4e4e5a'>
                        <ChalkboardOutline /> {t('components.testListMobile.__reviewQuiz')}
                      </Tooltip>
                    </Button>
                  </div>

                  <div className='copyEditBtnBox'>
                    {/* <Button
                      disabled={!canOperateExamPaper}
                      variant={canOperateExamPaper ? 'brand1' : 'ui02'}
                      onClick={() => {}}
                    >
                      <CopySolid /> 複製測驗
                    </Button> */}

                    <Button
                      variant={'brand2'}
                      onClick={() => openHintModal(modalInfo?.QuizUID)}
                    >
                      <TrashAltOutline /> {t('components.testListMobile.__deleteQuiz')}
                    </Button>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </Modal>
      }

      {/* 篩選彈窗 */}
      <Modal
        id={'filterDrawer'}
        visible={filterDrawerVisible}
        zIndex={1001}
        onCancel={filterDrawerFalse}
        width='100%'
        title={
          <>
            <span>{t('components.testListMobile.__filter')}</span><div className={classNames('clearAllFilterBtn', (Object.keys(filterParamsMobile).length !== 0 || eduSubjectMobile.edu || timeArrayMobile.startTime !== '' || timeArrayMobile.endTime !== '') ? 'active' : '' )} onClick={clearAllFilter}> {t('components.testListMobile.__clearAllFilterBtn')}</div>
          </>
        }
        footer={
          <>
            <Button
              className='reportModalBtn'
              variant={!checkChangeFilterBtn ? 'ui02' : 'brand2'}
              disabled={!checkChangeFilterBtn}
              onClick={doneChangeFilter}
            >{t('components.testListMobile.__useFilterBtn')}</Button>
          </>
        }
      >
        <div className='filterDrawerContent'>
          <div className='title'>{t('components.testListMobile.__eduSubject')}</div>
          <div>
            <div className='subTitle'>{t('components.testListMobile.__educationalSystem')}</div>
            <RadioGroup
              key={uuidv4()}
              name={'eduOptions'}
              className='radioList' buttonStyle='solid'
              optionType='button'
              options={eduOptions}
              value={eduSubjectMobile.edu}
              onChange={(e) => {changeEduSubjectMobile(e.target.value, 'edu');}}
            />
          </div>
          {
            eduSubjectMobile.edu &&
            <div>
              <div className='subTitle'>{t('components.testListMobile.__subject')}科目</div>
              <RadioGroup
                key={uuidv4()}
                name={'subjectOptions'}
                className='radioList' buttonStyle='solid'
                optionType='button'
                options={subjectOptions[eduSubjectMobile.edu]}
                value={eduSubjectMobile.subject}
                onChange={(e) => {changeEduSubjectMobile(e.target.value, 'subject');}}
              />
            </div>
          }
        </div>

        <div className='filterDrawerContent'>
          <div className='title'>{t('components.testListMobile.__quizEndDate')}</div>
          <div className='timeBox'>
            <div>
              <div>{t('components.testListMobile.__startDate')}</div>
              <input
                className='timePicker'
                type='date'
                id='startTime'
                value={timeArrayMobile.startTime}
                onChange={() => selectTime('startTime')}
                max={timeArrayMobile.endTime ? timeArrayMobile.endTime : null}
                required
              />
            </div>
            <div>
              <div>{t('components.testListMobile.__endDate')}結束時間</div>
              <input
                className='timePicker'
                type={'date'}
                id='endTime'
                value={timeArrayMobile.endTime}
                onChange={() => selectTime('endTime')}
                min={timeArrayMobile.startTime ? timeArrayMobile.startTime : null}
                required
              />
            </div>
          </div>
        </div>

        {/* <div className='filterDrawerContent'>
          <div className='title'>受測學制年級</div>
          <div>
            <div className='subTitle'>學制</div>
            <RadioGroup
              key={uuidv4()}
              name={'userEduOptions'}
              className='radioList' buttonStyle='solid'
              optionType='button'
              options={userEduOptions}
              value={eduSubjectMobile.userEdu}
              onChange={(e) => {changeEduSubjectMobile(e.target.value, 'userEdu');}}
            />
          </div>
          {
            eduSubjectMobile.userEdu &&
            <div>
              <div className='subTitle'>年級</div>
              <RadioGroup
                key={uuidv4()}
                name={'gradeOptions'}
                className='radioList' buttonStyle='solid'
                optionType='button'
                options={gradeOptions[eduSubjectMobile.userEdu]}
                value={eduSubjectMobile.grade}
                onChange={(e) => {changeEduSubjectMobile(e.target.value, 'grade');}}
              />
            </div>
          }
        </div> */}
      </Modal>


      {/* {
        modalInfo &&
        <Modal
          id={'testModal'}
          visible={testModalVisible}
          width={'860px'}
          onCancel={setTestModalVisibleFalse}
          title={modalInfo?.ExamName || ''}
        >
          <Tab activeKey={tabType} onChange={tabChange} size='large'>
            <TabPane tab="測驗碼" key="testCode">
              <TestCodeModalContent examLink={examLink} modalInfo={modalInfo} shareModalData={shareModalData}/>
            </TabPane>
            <TabPane tab="測驗連結" key="testLink">
              <TestLinkModalContent examLink={examLink} modalInfo={modalInfo} shareModalData={shareModalData}/>
            </TabPane>
            <TabPane tab="QR code" key="qrCode">
              <TestQrCodeModalContent examLink={examLink} modalInfo={modalInfo} shareModalData={shareModalData}/>
            </TabPane>
          </Tab>
        </Modal>
      } */}

      {
        renderCreateQuizExamModal()
      }

    </StyledTestListMobile>
  );
};


TestListMobile.propTypes = {
  className: PropTypes.string,
  filterParams: PropTypes.object,
  eduSubjectOptions: PropTypes.array,
  typeOptions: PropTypes.array,
  patternOptions: PropTypes.array,
  filterData: PropTypes.array,
  pageState: PropTypes.object,
  authState: PropTypes.object,
  pageChange: PropTypes.func,
  mobileChangeFilterCondition: PropTypes.func,
  goToReviewPage: PropTypes.func,
  setEduSubjectModalToggle: PropTypes.func
};