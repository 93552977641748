import { useState, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ChevronLeftSolid } from '@onedesign/icon';
import { useMachine } from '@xstate/react';
import {
  Button,
  FETCH_DATA_EVENT,
  Input,
  Select,
  Table,
  fetchDataMachine,
  setSelectOptions,
  sliceTableData,
  Pagination,
} from '@oneclass/ui-components';
import { Button as AntButton } from '@oneclass/onedesign';
import { Checkbox } from 'antd';
import { useLoadingContent } from 'utils/hooks/useLoadingContent';
import {
  getQueryExamPaper,
  savePaperPackage,
  getPaperPackageInfo
} from 'api/examPaper';
import { format, getTime } from 'date-fns';
import {
  StyledBackToList,
  StyledContainer,
  StyledCreatePaperPackage,
  StyledFilter,
  StyledInputRow,
  StyledStep,
  StyledSubTitle,
  StyledSelected,
  StyledTitle,
  StyledFirstTitle,
  StyledFooterButtonGroup,
  StyledChoosePaperTitle,
} from './EditPaperPackagePage.style';
import { useTranslation } from 'react-i18next';

export const EditPaperPackagePage = () => {
  const history = useHistory();
  const { redemptionCode } = useParams();
  const [packageName, setPackageName] = useState('');
  const [pageState, setPageState] = useState({
    currentPage: 1,
    limit: 10,
  });
  const [filterParams, setFilterParams] = useState({ eduSubject: null });
  const [checkedUids, setCheckedUids] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { renderLoadingContent, isLoading, setIsLoading } = useLoadingContent();
  const { prevPage } = history.location.state;
  const { t } = useTranslation();

  const backToList = () => {
    history.push(`/${prevPage}/${redemptionCode}`);
  };

  const [stateGetPackageInfo, sendGetPackageInfo] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        setIsLoading(true);
        const res = await getQueryExamPaper();
        const { data, isSuccess, systemCode, message } = res;
        const { examPaper = [], eduSubjectMap = [] } = data || {};
        setIsLoading(false);

        return {
          isSuccess,
          systemCode,
          message,
          examPaper,
          eduSubjectOptions: setSelectOptions(eduSubjectMap) || [],
        };
      }
    }
  });

  const { examPaper = [], eduSubjectOptions } = stateGetPackageInfo.context.result || {};

  const [stateSavePackage, sendSavePackage] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        setIsSubmitting(true);
        try {
          const res = await savePaperPackage(event.payload);
          const { isSuccess } = res;
          isSuccess && backToList();
        } catch (error) {
          return {
            isSuccess: false,
            message: error.data.message,
          };
        } finally {
          setIsSubmitting(false);
        }
      },
    },
  });

  const { message: errorMessage } = stateSavePackage.context.result || {};

  const [statePaperPackageInfo, sendPaperPackageInfo] = useMachine(
    fetchDataMachine, {
      services: {
        fetchData: async(_context, event) => {
          setIsLoading(true);
          const res = await getPaperPackageInfo(event.payload);
          const { isSuccess, systemCode, message, data } = res;
          setPackageName(data?.packageName);
          setIsLoading(false);

          return {
            isSuccess, systemCode, message, packageInfo: data
          };
        }
      }
    }
  );

  const { papers } = statePaperPackageInfo.context.result?.packageInfo || {};

  useEffect(() => {
    if (papers) {
      setCheckedUids(
        ...checkedUids,
        papers.map((item) => {
          return item.paperId;
        })
      );
    }
  }, [papers]);

  const filterData = useMemo(
    () =>
      examPaper.filter(({ attributes }) => {
        if (Object.values(filterParams).every((filter) => !filter)) {
          return true;
        }
        if (filterParams.eduSubject) {
          return (
            `${attributes.education}${attributes.subject}` ===
            filterParams.eduSubject
          );
        }
        return false;
      }),
    [examPaper, filterParams]
  );

  const handleCheck = (uid) => {
    setCheckedUids((prev) =>
      prev.includes(uid)
        ? prev.filter((uid_) => uid_ !== uid)
        : prev.concat(uid)
    );
  };

  const pageChange = (currentPage, pageSize) => {
    setPageState({
      ...pageState,
      currentPage,
      limit: pageSize,
    });
  };

  const handleSubmit = () => {
    const payload = {
      redemptionCode: redemptionCode,
      packageName: packageName,
      sharedPapers: examPaper
        .filter(({ uid }) => checkedUids.includes(uid))
        .map(({ uid, name }) => ({ paperId: uid, name })),
    };

    sendSavePackage(FETCH_DATA_EVENT.FETCH, {
      payload,
    });
  };

  useEffect(() => {
    const payload = { redemptionCode: redemptionCode };
    sendGetPackageInfo(FETCH_DATA_EVENT.FETCH);
    sendPaperPackageInfo(FETCH_DATA_EVENT.FETCH, { payload });
  }, []);

  useEffect(() => {
    if (errorMessage) {
      alert(errorMessage);
    }
  }, [errorMessage]);

  return (
    <StyledCreatePaperPackage>
      <StyledBackToList onClick={backToList}>
        <ChevronLeftSolid />
        <span>{t('views.editPaperPackagePage.__backToList')}</span>
      </StyledBackToList>
      <StyledTitle>{t('views.editPaperPackagePage.__editPackageTitle')}</StyledTitle>
      <StyledContainer>
        <StyledStep>
          <StyledFirstTitle>{t('views.editPaperPackagePage.__packageSettingStepTitle')}</StyledFirstTitle>
          <StyledInputRow>
            <div className="label">{t('views.editPaperPackagePage.__packageNameLabel')}</div>
            <Input
              className="input"
              placeholder={t('views.editPaperPackagePage.__packageNamePlaceholder')}
              value={packageName}
              onChange={(e) => setPackageName(e.target.value)}
            />
          </StyledInputRow>
        </StyledStep>
        <StyledStep>
          <StyledChoosePaperTitle>
            <StyledSubTitle>{t('views.editPaperPackagePage.__selectPapersStepTitle')}</StyledSubTitle>
            <StyledSelected>{t('views.editPaperPackagePage.__selectedPapers', { checkedUidsLength: checkedUids.length })}</StyledSelected>
          </StyledChoosePaperTitle>

          {isLoading ? (
            renderLoadingContent()
          ) : (
            <>
              <StyledFilter>
                <Select
                  placeholder={t('views.editPaperPackagePage.__eduSubjectPlaceholder')}
                  options={eduSubjectOptions}
                  value={filterParams?.eduSubject}
                  onChange={(value) => {
                    setFilterParams({ eduSubject: value });
                  }}
                />
              </StyledFilter>
              <Table className="table">
                <Table.Header>
                  <Table.Row isFixedWidth={true} className="tableRow">
                    <Table.Item width={'32px'}>
                      <Checkbox
                        style={{ margin: '0 8px' }}
                        checked={examPaper.length === checkedUids.length}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setCheckedUids(examPaper.map(({ uid }) => uid));
                          } else {
                            setCheckedUids([]);
                          }
                        }}
                        indeterminate={
                          checkedUids.length > 0 &&
                          examPaper.length !== checkedUids.length
                        }
                      />
                    </Table.Item>
                    <Table.Item width={'624px'} className="tableItem"> {t('views.editPaperPackagePage.__paperName')}
                    </Table.Item>
                    <Table.Item width={'144px'} className="tableItem">  {t('views.editPaperPackagePage.__eduSubject')}
                    </Table.Item>
                    <Table.Item width={'160px'} className="tableItem">  {t('views.editPaperPackagePage.__creationTime')}
                    </Table.Item>
                  </Table.Row>
                </Table.Header>
                <Table.Body bodyMaxHeight={true}>
                  {filterData &&
                    filterData.length > 0 &&
                    sliceTableData(
                      filterData,
                      pageState.currentPage,
                      pageState.limit
                    ).map((item) => (
                      <Table.Row
                        key={item.uid}
                        isFixedWidth={true}
                        className="tableRow"
                      >
                        <>
                          <Table.Item width={'32px'}>
                            <Checkbox
                              style={{ margin: '0 8px' }}
                              checked={checkedUids.includes(item.uid)}
                              onChange={() => handleCheck(item.uid)}
                            />
                          </Table.Item>
                          <Table.Item width={'624px'} className="tableItem">
                            {item.name}
                          </Table.Item>
                          <Table.Item width={'144px'} className="tableItem">
                            {item.eduSubjectName}
                          </Table.Item>
                          <Table.Item width={'160px'} className="tableItem">
                            {format(
                              getTime(new Date(item.updateTime)),
                              'yyyy/dd/MM HH:mm'
                            )}
                          </Table.Item>
                        </>

                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
              <Pagination
                total={filterData?.length || 0}
                defaultCurrent={1}
                current={pageState.currentPage}
                onChange={pageChange}
                defaultPageSize={pageState.limit}
              />
            </>
          )}
        </StyledStep>
      </StyledContainer>
      <StyledFooterButtonGroup>
        <AntButton
          className="btn outlined"
          onClick={() => backToList()}
        >
          {t('views.editPaperPackagePage.__cancelButton')}
        </AntButton>
        <Button
          className="submitButton"
          variant="brand2"
          onClick={handleSubmit}
          disabled={isSubmitting || !packageName || !checkedUids.length}
        >
          {t('views.editPaperPackagePage.__saveButton')}
        </Button>
      </StyledFooterButtonGroup>

    </StyledCreatePaperPackage>
  );
};