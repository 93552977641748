import React from 'react';
import { StyledQuestionLimitModal } from './QuestionLimitModal.style';
import { PROCESS_PARAMS, OUTPUT_TYPE_NAME } from 'constants/index';
import { Button } from '@oneclass/onedesign';
import { useTranslation } from 'react-i18next';
import { Modal } from '@oneclass/ui-components';

export const QuestionLimitModal = ({ className, visible, onCancel, currentQuesNum, processType, questionNumLimit }) => {
  const questionLimitText = PROCESS_PARAMS[processType]?.quesLimitText;
  const { t } = useTranslation();
  return (
    <StyledQuestionLimitModal>
      <Modal
        className={`${className} questionCountModal mobileModal`}
        data-testid="QuestionLimitModal"
        visible={visible}
        onCancel={onCancel}
        title={
          <div> {t('components.questionLimitModal.__questionLimitModalTitle')}</div>
        }
        footer={
          <div className="footerBtn footerBtnBox">
            <Button variant={'brand1'} onClick={() => { onCancel();}}> {t('components.questionLimitModal.__modalConfirm')}</Button>
          </div>
        }
      >
        <div className="modalContent">
          <div>
            {t('components.questionLimitModal.__outputTypeLimitText', { outputTypeName: OUTPUT_TYPE_NAME[processType], questionLimitText, questionNumLimit, currentQuesNum })}
          </div>
        </div>
      </Modal>
    </StyledQuestionLimitModal>
  );
};