import React, { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useRedoModal } from 'utils/hooks/useRedoModal';
import { default as UserProvider } from 'providers/UserProvider.js';
import {
  StyledTestHeader,
  StyleLogoBox,
  StyleLogoName,
  StyledLinkZone,
  StyledLink,
  StyledTestHeaderLogo,
  StyleUserName,
  StyledSlash
} from './OneTestHeader.style';
import { useTranslation } from 'react-i18next';

export const OneTestHeader = ({ className, logout, authStateValue, env, history, openEduSubjectModalToggle }) => {
  const { t } = useTranslation();
  const { userData } = useContext(UserProvider.UserProviderContext);
  const { userInfo } = userData;
  const { renderRedoModal, goToHome, reDo } = useRedoModal(userInfo?.onePaperIdentity);
  const location = useLocation();
  const goToOneTestEntrance = () => {
    window.location.href = `https://onetest${env !== 'release' ? `-${env}` : ''}.oneclass.vn/entrance`;
  };
  const goTo = (pathName) => {
    history.push(pathName);
  };

  const isCreatePaper = () => {
    if (location.pathname !== '/examPaperList' && location.pathname !== '/memberCenter') return true;
    return false;
  };

  const isCreateQuestionProcess = useMemo(() => {
    if (location.pathname === '/computerQuestion' || location.pathname === '/manualProposition' || location.pathname === '/knowledgeProposition') return true;
    return false;
  }, [location.pathname]);

  const linkData = [
    {
      pathname: 'showModal',
      linkText: t('containers.oneTestHeader.__startExamPaper'),
      click: openEduSubjectModalToggle
    },
    {
      pathname: '/examPaperList',
      linkText: t('containers.oneTestHeader.__examPaperList')
    },
    {
      pathname: '/memberCenter',
      linkText: t('containers.oneTestHeader.__commonSettings')
    },
  ];

  return (
    <>
      <StyledTestHeader>
        <StyleLogoBox>
          <div className='logoBox' onClick={() => {goToOneTestEntrance();}}>
            <StyledTestHeaderLogo/>
            <StyleUserName>{userData.name}<div className='triangle' /></StyleUserName>
          </div>
          <StyledSlash></StyledSlash>
          <StyleLogoName>{t('containers.oneTestHeader.__questionBankSystem')}</StyleLogoName>

        </StyleLogoBox>
        <StyledLinkZone>
          {
            linkData.map((item) => (
              item.pathname === 'showModal' ?
                <StyledLink
                  key={item.pathname}
                  active={isCreateQuestionProcess}
                  onClick={() => isCreateQuestionProcess ? null : item.click()}
                >
                  {item.linkText}
                </StyledLink>
                :
                <StyledLink
                  key={item.pathname}
                  active={location.pathname === item.pathname}
                  onClick={() => {
                    isCreatePaper() ? goToHome(item.pathname, item.linkText) : goTo(item.pathname);
                  }}>{item.linkText}
                </StyledLink>
            ))
          }
        </StyledLinkZone>
      </StyledTestHeader>
      {renderRedoModal()}
    </>
  );
};

OneTestHeader.propTypes = {
  className: PropTypes.string,
  logout: PropTypes.func,
  userData: PropTypes.object
};