/* eslint-disable react/prop-types */
import React, { useRef, useContext } from 'react';
import QRCode from 'qrcode.react';
import { StyledTestQrCodeModalContent } from './TestQrCodeModalContent.style';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { useTranslation } from 'react-i18next';

export const TestQrCodeModalContent = ({ className, examLink, modalInfo, shareModalData }) => {
  const linkRef = useRef(null);
  // const { isDesktop } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const { t, i18n } = useTranslation();

  return (
    <StyledTestQrCodeModalContent className={className} data-testid="TestQrCodeModalContent">
      <div className='testModalContent'>
        <div className='testModalLinkBox'>
          <div className='testModalLinkTitle'>{modalInfo?.QuizName}</div>
          <div className='endTime'>
            <span>{t('components.testQrCodeModalContent.__endTime')}</span>
            {i18n.language === 'zh' ?
              (<div>{`${shareModalData?.endTime?.month}/${shareModalData?.endTime?.day} ${shareModalData?.endTime?.time}`}</div>)
              :
              (<div>{`${shareModalData?.endTime?.day}/${shareModalData?.endTime?.month} ${shareModalData?.endTime?.time}`}</div>)
            }
          </div>
          <div className='testModalLink' id="testModalLink">
            <div>
              <div className='qrCodeBox'>
                <QRCode
                  id="bill_qr_code_url"
                  value={`${examLink}/quiz/${modalInfo.QuizCode}`} //value引數為生成二維碼的連結 我這裡是由後端返回
                  size={deviceData.isDesktop ? 200 : 144} //二維碼的寬高尺寸
                  fgColor="#000000"  //二維碼的顏色
                />
              </div>
            </div>
            <div>
              <input disabled className='testModalLinkData' ref={linkRef} value={`${examLink}/quiz/${modalInfo.QuizCode}`} />
            </div>
          </div>
        </div>

        <div className='testModalManualBox borderLine'>
          {t('components.testQrCodeModalContent.__startQuizInstructions')}
        </div>

        <div className='testModalLinkBox'>
          <div className='titleText'>{t('components.testQrCodeModalContent.__viewQuizResults')}</div>
          <div className='testModalLink' id="testModalLink">
            <div>
              <div className='qrCodeBox'>
                <QRCode
                  id="bill_qr_code_url"
                  value={examLink} //value引數為生成二維碼的連結 我這裡是由後端返回
                  size={deviceData.isDesktop ? 200 : 144} //二維碼的寬高尺寸
                  fgColor="#000000"  //二維碼的顏色
                />
              </div>
            </div>
            <div>
              <input disabled className='testModalLinkData' ref={linkRef} value={examLink} />
            </div>
          </div>
        </div>

        <div className='testModalManualBox'>
          {t('components.testQrCodeModalContent.__testModalManualBox', { top4: shareModalData.quizCode.top4, last4: shareModalData.quizCode.last4 })}
        </div>

      </div>
    </StyledTestQrCodeModalContent>
  );
};