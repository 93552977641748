const PAPER_CONTENT_TYPE = [
  {
    value: 'Question',
    name: '題目卷',
    t_key: 'question',
  },
  {
    value: 'Answer',
    name: '答案卷',
    t_key: 'answer',
  },
  {
    value: 'Analyze',
    name: '解析卷',
    t_key: 'analyze',
  },
  {
    value: 'Read',
    name: '閱卷',
    t_key: 'read',
  },
  {
    value: 'AnswerPaper',
    name: '作答紙',
    t_key: 'answerPaper',
  }
];

export const paperContentTypeMapper = (value) => {
  let layout = PAPER_CONTENT_TYPE.find(e => e.value === value || e.name === value);

  if (!layout) {
    return undefined;
  }
  return `constants.paperContentType.${layout.t_key}`;
};
