const ANALYZE_CONTENT = [
  {
    value: 'Question',
    name: '題目',
    t_key: '__question'
  },
  {
    value: 'Answer',
    name: '答案',
    t_key: '__answer'
  },
  {
    value: 'Answer',
    name: '解析',
    t_key: '__answer'
  },
  {
    value: 'Difficulty',
    name: '難易度',
    t_key: '__difficulty'
  },
  {
    value: 'Source',
    name: '出處',
    t_key: '__source'
  },
  {
    value: 'Knowledge',
    name: '知識向度',
    t_key: '__knowledge'
  },
  {
    value: 'Cognitive',
    name: '認知向度',
    t_key: '__cognitive'
  }
];

export const analyzeContentMapper = (value) => {
  let element = ANALYZE_CONTENT.find(e => e.value === value || e.name === value);

  if (!element) {
    return undefined;
  }
  return `constants.analyzeContent.${element.t_key}`;
};
