/* eslint-disable react/prop-types */
import { useState, useMemo, useEffect, useContext } from 'react';
import { Modal, FETCH_DATA_EVENT, fetchDataMachine, setRadioOptions, AuthProvider, openNotificationWithIcon, Drawer } from '@oneclass/ui-components';
import { Button, RadioGroup } from '@oneclass/onedesign';
import { Input, Radio } from 'antd';
import { useMachine } from '@xstate/react';
import { useBoolean } from 'ahooks';
import { postCreateQuiz, patchQuizEdit } from 'api/quiz';
import styled from 'styled-components';
import { ExclamationCircleSolid } from '@onedesign/icon';
import { useHistory } from 'react-router-dom';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { getCookie, setCookie, formatSpecialCharacters } from 'utils/common';
import { getMemberEduSubject } from 'api/drawUpFast';
import { v4 as uuidv4 } from 'uuid';
import { default as behaviorProvider } from 'providers/behaviorProvider.js';
import * as GA from 'utils/googleAnalytics';
import { SESSION_STORAGE_KEY } from 'constants/index';
import { useTranslation } from 'react-i18next';
const StyledModal = styled(Modal)`

  .blockLine{
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_03};
    margin: 24px 0px;
  }

  .radioBox{
    padding: 5px 0;
    .DrawerRow{
      margin-bottom:24px;
      .title{
        margin-bottom: 8px;
        color: ${({ theme }) => theme.colors.text.text_06};
      }
      .ant-radio-group-solid .ant-radio-button-wrapper {
        margin-left: unset !important;
        margin-right: 8px;
        margin-bottom: 16px;
        padding: 8px 30px !important;
        @media (max-width: 1024px) {
          width: 88px;
          height: 40px;
          padding: 8px 12px !important;
        }
      }
    }
  }
  .loadingMask{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    opacity: 0.3;
    z-index: 3999;
  }
  .itemRow{
      display: flex;
      align-items: flex-start;
      margin-bottom: 16px;

      .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
        color: ${({ theme }) => theme.colors.ui.white};
        background-color: ${({ theme }) => theme.colors.brand.brand02};
        border-color: ${({ theme }) => theme.colors.brand.brand02};
      }

      .radioBox{
        display: flex;
        flex-direction: column;
        >label:first-child{
          margin-bottom: 16px;
        }
        .ant-radio-inner{
          border-color: ${({ theme }) => theme.colors.brand.brand01};
        }

        .ant-radio-inner::after{
          background-color: ${({ theme }) => theme.colors.brand.brand01};
        }
      }

      >div:first-child{
          width: 90px;
          text-align: right;
          margin-right: 24px;
          padding: 5px 0;
          flex-shrink: 0;
          color: ${({ theme }) => theme.colors.text.text_06};
      }
      input{
          height: 34px;
      }
      .timePicker{
          width: 215px;
      }
      .testTime{
          display: flex;
          align-items: center;
          >input{
              width: 88px;
              margin-right: 8px;
          }
      }
      .eduSubjectBox{
          display: flex;
          align-items: center;
          >div:first-child{
            margin-right: 8px;
          }
      }
      .errorText{
          display: none;
          align-items: center;
          color: ${({ theme }) => theme.colors.support.error};
          margin-top: 8px;
      }
      .errorText_2{
          display: none;
          align-items: center;
          color: ${({ theme }) => theme.colors.support.error};
          margin-top: 8px;
      }
    }
    .itemRow.active{
      .errorText{
          display: flex;
      }
      .testTime{
          >input{
              border: 1px solid ${({ theme }) => theme.colors.support.error};
          }
      }
      .examNameBox{
          border: 1px solid ${({ theme }) => theme.colors.support.error};
      }
      .timePicker{
          border: 1px solid ${({ theme }) => theme.colors.support.error};
      }
    }

    .DrawerRow{
      .errorText{
        display: none;
        align-items: center;
        color: ${({ theme }) => theme.colors.support.error} !important;
        margin-top: 8px;
        svg{
          color: ${({ theme }) => theme.colors.support.error} !important;
        }
      }
    }
    .DrawerRow.active{
      .errorText{
        display: flex;
      }
    }
    .itemRow.active_2{
      .errorText_2{
          display: flex;
      }
    }
  .examNameBox{
      width: 380px;
  }
  .ant-modal-body{
      padding: 0px 24px 24px;
  }
  .duration{
      border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_03};
  }

  /* 手機版 畫面調整 (425px 開始變化) */
  @media (max-width: 1024px) { 
    .examNameBox{
        width: 215px;
    }
    .footerBtn{
        width: 100%;
        margin-right: unset !important;
    }
    .footerBtnBox > div{
        margin-right: unset !important;
        width: 100%;
    }
    .modalContent{
        display: flex;
        flex-direction: column;
    }
    .eduGradeBox{
        align-items: center;
        @media (max-width: 1024px) {
          align-items: flex-start;

        }
    }
    .eduSubjectBox{
        width: 215px;
        justify-content: space-between;
        svg{
            color: ${({ theme }) => theme.colors.ui.ui_04};
            margin-right: unset;
            font-size: 16px;
        }
    }

    // Modal
    .ant-modal{
        position: absolute;
        bottom: 0;
        max-width:100%;
        padding: 0 0;
        margin: 0 0;
    }
    .ant-modal-content{
        position: absolute;
        bottom: 0;
        border-radius: 12px 12px 0 0;
        width: 100vw;
        height: 97vh;
        height: calc(var(--vh, 1vh) * 97);
        .ant-modal-close{
            right: 0;
        }
    }
    .ant-modal-header{
        display: flex;
        justify-content:center;
        align-items:center;
        padding: 21px 40px 21px;
    }
    .ant-modal-close-x{
        display: flex;
        justify-content:center;
        align-items:center;
        width: 50px;
        height:63px;
    }
    .ant-modal-body{
        padding: 16px 24px 16px;
        background-color: #fff;
        height: calc(calc(var(--vh, 1vh) * 97) - 64px - 84px) !important;
        overflow: auto;
    }
    .ant-modal-footer{
        padding: 4px 24px 40px;
    }
  } 
`;

const StyledHintModal = styled(Modal)`
  .ant-modal{
    width: 600px !important;
    height: 222px !important;
  }
  .hintModalContent{
    color: ${({ theme }) => theme.colors.text.text_06};
  }
  @media (max-width: 1024px) {
    .ant-modal{
      width: unset !important;
      height: unset !important;
      position: absolute;
      bottom: 0;
      max-width:100%;
      padding: 0 0;
      margin: 0 0;
    }
    .ant-modal-content{
        position: absolute;
        bottom: 0;
        border-radius: 12px 12px 0 0;
        width: 100vw;
        height: auto !important;
        .ant-modal-close{
            right: 0;
        }
    }
    .ant-modal-header{
        display: flex;
        justify-content:center;
        align-items:center;
        padding: 21px 40px 21px;
    }
    .ant-modal-close-x{
        display: flex;
        justify-content:center;
        align-items:center;
        width: 50px;
        height:63px;
    }
    .ant-modal-body{
        padding: 16px 24px 16px;
        background-color: #fff;
        height: auto !important;
        overflow: auto;
    }
    .footerBtn{
      width: 100%;
    }
    .footerBtnBox{
      width: 100%;
      flex-direction: column;
      >div{
        width: 100%;
        margin-right: unset !important;
        &:nth-child(1){
          order: 2;
        }
        &:nth-child(2){
          order: 1;
          margin-bottom: 8px;
        }
      }
    }
  }
`;

export const useCreateQuizExamModal = (doSomeThing) => {
  // const { isDesktop } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const { behaviorData, behaviorDataProviderChange } = useContext(behaviorProvider.behaviorProviderContext);
  const { useAuthState } = AuthProvider;
  const history = useHistory();
  const authState = useAuthState();
  const [createQuizExamModalVisible, { toggle: setCreateQuizExamModalToggle, setFalse: setCreateQuizExamModalFalse }] = useBoolean(false);
  const [eduGradeDrawerVisible, { toggle: eduGradeDrawerToggle, setFalse: eduGradeDrawerFalse }] = useBoolean(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState('');
  const [quizUID, setQuizUID] = useState('');
  const [jwtToken, setJwtToken] = useState('');
  const [originalData, setOriginalData] = useState({});
  const [hintModalVisible, { toggle: setHintModalToggle, setFalse: setHintModalFalse }] = useBoolean(false);
  const [isChange, setIsChange] = useState(false);
  const { t } = useTranslation();
  // 取得 學制 科目等資料
  const [stateGetMemberEduSubject, sendGetMemberEduSubject] = useMachine(fetchDataMachine, { //Todo
    services: {
      fetchData: async(_context, event) => {
        const res = await getMemberEduSubject(event.year);
        const { data, isSuccess, systemCode, message } = res;
        const { eduMap = [], eduGrade = {} } = data || {};
        if (isSuccess) setIsLoading(false);
        return {
          isSuccess, systemCode, message,
          eduMap,
          eduGradeMap: eduGrade,
        };
      },
    },
  });
  const { eduMap, eduGradeMap } = stateGetMemberEduSubject.context.result || {};

  // useEffect(() => {
  //   if ((eduMap && eduMap.length !== 0) && (eduGradeMap && JSON.stringify(eduGradeMap) !== '{}')) {
  //     setSendParams({
  //       ...sendParams,
  //       educationCode: eduMap[0].code,
  //       // gradeCode: eduGradeMap[eduMap[0].code][0].code
  //     });
  //   }
  // }, [eduMap, eduGradeMap]);

  // 取得現在時間
  const [nowTime, setNowTime] = useState('');

  useEffect(() => {
    sendGetMemberEduSubject(FETCH_DATA_EVENT.FETCH);
    setNowTime(new Date(+new Date() + 8 * 3600 * 1000).toISOString().substring(0, 16)); // +8小時 因為時差
    const oneClubToken = JSON.parse(getCookie('nani_oneclass_login_token')).jwt;
    setJwtToken(oneClubToken);
  }, []);

  const createQuizExamModalToggle = (item, mode) => {
    if (!item || !mode) return;
    setMode(mode);

    if (mode === 'create') {
      const nowTime = new Date();
      const year = nowTime.getFullYear();
      const month = nowTime.getMonth() + 1;
      const date = nowTime.getDate();
      setSendParams({
        ...sendParams,
        examID: item.uid,
        startTime: new Date(+new Date() + 8 * 3600 * 1000).toISOString().substring(0, 16),
        endTime: new Date(+new Date() + 32 * 3600 * 1000).toISOString().substring(0, 16),
        duration: item.attributes.questionAmount * 2,
        quizName: `${year}${(month >= 10) ? month : ('0' + month)}${(date >= 10) ? date : ('0' + date)}${item.name}`,
        // educationCode: item?.attributes?.education,
        isAutoCheck: true,
      });
    } else if (mode === 'edit') {
      setQuizUID(item?.QuizUID);
      setOriginalData(item);
      setSendParams({
        ...sendParams,
        examID: item?.ExamUID,
        startTime: new Date(+new Date(item.StartTime) + 8 * 3600 * 1000).toISOString().substring(0, 16),
        endTime: new Date(+new Date(item.EndTime) + 8 * 3600 * 1000).toISOString().substring(0, 16),
        duration: item?.Duration,
        quizName: item?.QuizName,
        // educationCode: item?.Education,
        // gradeCode: item?.Grade,
        isAutoCheck: item?.IsAutoCheck,
      });
    }

    setCreateQuizExamModalToggle();
  };

  const [sendParams, setSendParams] = useState({
    quizName: '',
    startTime: '',
    endTime: '',
    examID: '',
    duration: null,
    isAutoCheck: true,
    // educationCode: '',
    // gradeCode: '',
  });

  const onSendParamsChange = (type, val) => {
    setIsChange(true);
    if (type === 'duration') {
      let newVal = '';
      let replaceVal = val.replace(/[^\d]/g, '');
      if (replaceVal !== '') {
        newVal = parseInt(replaceVal);
      } else {
        newVal = '';
      }
      setSendParams({
        ...sendParams,
        [type]: newVal,
      });
    } else if (type === 'quizName') {
      const quizName = document.querySelector('#quizName');
      // if (new RegExp('/').test(val)) {
      //   quizName.classList.add('active_2');
      //   const newValue = val.replace('/', '');
      //   setSendParams({
      //     ...sendParams,
      //     [type]: newValue,
      //   });
      // } else {

      val = formatSpecialCharacters(val);

      quizName.classList.remove('active_2');
      if ( val.trim() === '') {
        setSendParams({
          ...sendParams,
          [type]: '',
        });
      } else {
        setSendParams({
          ...sendParams,
          [type]: val,
        });
      }
      // }
    }
    // else if (type === 'educationCode') {
    //   setSendParams({
    //     ...sendParams,
    //     [type]: val,
    //     gradeCode: '', //eduGradeMap[val][0].code
    //   });
    // }
    else {
      setSendParams({
        ...sendParams,
        [type]: val,
      });
    }
  };

  const selectTime = (id) => {
    setIsChange(true);
    const time = document.querySelector(`#${id}`).value;
    onSendParamsChange(`${id}`, time);
  };

  const doCreateQuiz = () => {
    const sendTime = new Date().getTime();
    const startTime = new Date(sendParams.startTime).getTime();
    const endTime = new Date(sendParams.endTime).getTime();

    const startTime_second = new Date(sendParams.startTime).getTime();
    const endTime_second = new Date(sendParams.endTime).getTime();
    const diffTime_second = endTime_second - startTime_second;
    const mins = diffTime_second / (1000 * 60);

    if (sendParams.quizName.trim() === '') {
      const quizName = document.querySelector('#quizName');
      quizName.classList.add('active');
      setSendParams({
        ...sendParams,
        quizName: sendParams.quizName.trim(),
      });
      return;
    } else {
      const quizName = document.querySelector('#quizName');
      quizName.classList.remove('active');
      setSendParams({
        ...sendParams,
        quizName: sendParams.quizName.trim(),
      });
    }

    // if (startTime < sendTime) {
    //   const startTime = document.querySelector('#startTimeBox');
    //   startTime.classList.add('active');
    //   setNowTime(new Date().toISOString().substring(0, 16));
    //   return;
    // } else {
    //   const startTime = document.querySelector('#startTimeBox');
    //   startTime.classList.remove('active');
    // }

    if (endTime <= startTime) {
      const endTime = document.querySelector('#endTimeBox');
      endTime.classList.add('active');
      return;
    } else {
      const endTime = document.querySelector('#endTimeBox');
      endTime.classList.remove('active');
    }

    if (mins < sendParams.duration) {
      const duration = document.querySelector('#duration');
      duration.classList.add('active');
      return;
    } else {
      const duration = document.querySelector('#duration');
      duration.classList.remove('active');
    }

    // if (!sendParams.gradeCode) {
    //   const gradeCode = document.querySelector('#gradeCode');
    //   gradeCode.classList.add('active');
    //   return;
    // } else {
    //   const gradeCode = document.querySelector('#gradeCode');
    //   gradeCode.classList.remove('active');
    // }

    if (mode === 'create') {
      sendPostCreateQuiz(FETCH_DATA_EVENT.FETCH, {
        payload: {
          ...sendParams,
          startTime: new Date(new Date(sendParams.startTime)).toISOString().substring(0, 16),
          endTime: new Date(new Date(sendParams.endTime)).toISOString().substring(0, 16),
          jwtToken,
        }
      });
    } else {
      // 判斷 編輯時 測驗是否已經開始
      // if (sendTime > new Date(originalData.StartTime).getTime()) {
      //   openNotificationWithIcon('error', '測驗已開始，無法編輯');
      // } else {
      GA.editExam();
      sendPatchQuizEdit(FETCH_DATA_EVENT.FETCH, {
        payload: {
          quizName: sendParams?.quizName,
          duration: sendParams?.duration,
          // education: sendParams?.educationCode,
          // grade: sendParams?.gradeCode,
          isAutoCheck: sendParams?.isAutoCheck,
          startTime: new Date(new Date(sendParams.startTime)).toISOString().substring(0, 16),
          endTime: new Date(new Date(sendParams.endTime)).toISOString().substring(0, 16),
        },
        jwt: jwtToken,
        quizUID,
      });
      // }
    }
  };

  const disableCreateQuizBtn = useMemo(() => {
    const checkData = sendParams.quizName && sendParams.startTime && sendParams.endTime && sendParams.duration;
    if (mode === 'create') {
      if (checkData) {
        return false;
      } else {
        return true;
      }
    } else {
      if (!isChange) {
        return true;
      } else {
        if (checkData) {
          return false;
        } else {
          return true;
        }
      }
    }
  }, [sendParams]);

  const [, sendPostCreateQuiz] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_, event) => {
        setIsLoading(true);
        const res = await postCreateQuiz(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) {
          openNotificationWithIcon('success',t('hooks.useCreateQuizExamModal.__createQuizSuccess'));
          setCreateQuizExamModalFalse();
          setSendParams({
            ...sendParams,
            quizName: '',
            startTime: '',
            endTime: '',
            examID: '',
            duration: null,
            isAutoCheck: true,
            // educationCode: '',
            // gradeCode: '',
          });
          behaviorDataProviderChange('openShareQuizModal', true);
          doSomeThing && doSomeThing();
          sessionStorage.setItem(SESSION_STORAGE_KEY.OnePaper_NewTest, data.quizUID);
        } else {
          openNotificationWithIcon('error', data);
        }
        setIsLoading(false);
        return {
          isSuccess, systemCode, message,
        };
      }
    }
  });

  const [, sendPatchQuizEdit] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_, event) => {
        setIsLoading(true);
        const res = await patchQuizEdit(event.payload, event.jwt, event.quizUID);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) {
          openNotificationWithIcon('success', t('hooks.useCreateQuizExamModal.__editQuizSuccess'));
          setCreateQuizExamModalFalse();
          setIsLoading(false);
          setSendParams({
            ...sendParams,
            quizName: '',
            startTime: '',
            endTime: '',
            examID: '',
            duration: null,
            isAutoCheck: true,
            // educationCode: '',
            // gradeCode: '',
          });
          setQuizUID('');
          doSomeThing && doSomeThing();
        } else {
          openNotificationWithIcon('error', data);
          setIsLoading(false);
        }
        return {
          isSuccess, systemCode, message,
        };
      }
    }
  });

  // 轉換 學制科目 Title 顯示
  // const transformEduSubjectName = useMemo(() => {
  //   if (!eduMap && !eduGradeMap) return '';
  //   if (sendParams.educationCode && sendParams.gradeCode) {
  //     let eduName = '';
  //     let gradeName = '';
  //     eduMap.forEach((item) => {
  //       if (item.code === sendParams.educationCode) eduName = item.name;
  //     });
  //     eduGradeMap[sendParams.educationCode].forEach((item) => {
  //       if (item.code === sendParams.gradeCode) gradeName = item.name;
  //     });
  //     return eduName + gradeName;
  //   }
  // }, [sendParams.educationCode, sendParams.gradeCode]);

  // 關閉 背景滑動
  //   useEffect(() => {
  //     document.body.classList.remove('mobileScrollingStop');
  //     if (!isDesktop()) {
  //       if (createQuizExamModalVisible | eduGradeDrawerVisible) {
  //         document.body.classList.add('mobileScrollingStop');
  //       }
  //     }
  //   }, [createQuizExamModalVisible, eduGradeDrawerVisible]);

  const checkHintModalShow = () => {
    if (mode === 'create') {
      setCreateQuizExamModalFalse();
    } else {
      if (isChange) {
        setHintModalToggle();
      } else {
        setCreateQuizExamModalFalse();
      }
    }
  };

  useEffect(() => {
    if (!createQuizExamModalVisible) setIsChange(false);
  }, [createQuizExamModalVisible]);

  const doneHintModal = () => {
    doCreateQuiz();
    setHintModalFalse();
  };

  const cancelHintModal = () => {
    setHintModalFalse();
    setCreateQuizExamModalFalse();
  };

  // 判斷 測驗是否已經開始，若已開始 則只能針對 特定欄位進行編輯。
  const isCanEdit = useMemo(() => {
    if (mode === 'create') return true;
    return new Date(sendParams?.startTime) > new Date();
  }, [sendParams?.startTime, mode]);

  const renderCreateQuizExamModal = () => {
    return (
      <>
        <StyledModal
          visible={createQuizExamModalVisible}
          width={deviceData.isDesktop ? '576px' : '100%'}
          onCancel={!isLoading ? () => {checkHintModalShow();} : null}
          title={mode === 'create' ? t('hooks.useCreateQuizExamModal.__createQuiz'): t('hooks.useCreateQuizExamModal.__editQuiz')}
          zIndex={1010}
          footer={
            <div className="footerBtn footerBtnBox">
              {
                deviceData.isDesktop &&
                <Button variant='ui01' onClick={!isLoading ? () => {checkHintModalShow();} : null}>{t('hooks.useCreateQuizExamModal.__cancel')}</Button>
              }
              <Button
                disabled={disableCreateQuizBtn}
                variant={(disableCreateQuizBtn ? 'ui02' : 'brand2') }
                onClick={doCreateQuiz}
                isLoading={isLoading}
              >
                {
                  mode === 'create' ?t('hooks.useCreateQuizExamModal.__create')  : t('hooks.useCreateQuizExamModal.__save')
                }
              </Button>
            </div>
          }
        >
          <div id={'quizName'} className='itemRow'>
            <div>{t('hooks.useCreateQuizExamModal.__quizName')}</div>
            <div>
              <Input className='examNameBox'
                onChange={(e) => onSendParamsChange('quizName', e.target.value)}
                value={sendParams.quizName}
                // disabled={!isCanEdit}
              />
              <div className='errorText'>
                <ExclamationCircleSolid/>
                <span>{t('hooks.useCreateQuizExamModal.__enterQuizName')}</span>
              </div>
              <div className='errorText_2'>
                <ExclamationCircleSolid/>
                <span>{t('hooks.useCreateQuizExamModal.__quizNameFormat')}</span>
              </div>
            </div>
          </div>
          <div id={'startTimeBox'} className='itemRow'>
            <div>{t('hooks.useCreateQuizExamModal.__startTime')}</div>
            <div>
              <input
                className='timePicker'
                type='datetime-local'
                id='startTime'
                value={sendParams.startTime}
                onChange={() => selectTime('startTime')}
                // min={deviceData.isDesktop ? nowTime : null}
                // max={deviceData.isDesktop ? sendParams.endTime : null}
                required
                // disabled={!isCanEdit}
              />
              <div className='errorText'>
                <ExclamationCircleSolid/>
                <span>{t('hooks.useCreateQuizExamModal.__startTimeAfterCurrent')}</span>
              </div>
            </div>
          </div>
          <div id={'endTimeBox'} className='itemRow'>
            <div>{t('hooks.useCreateQuizExamModal.__endTime')}</div>
            <div>
              <input
                className='timePicker'
                type={'datetime-local'}
                id='endTime'
                value={sendParams.endTime}
                onChange={() => selectTime('endTime')}
                // min={deviceData.isDesktop ? (sendParams.startTime ? sendParams.startTime : nowTime) : null}
                // disabled={!isCanEdit || (sendParams.startTime ? false : true)}
                required
              />
              <div className='errorText'>
                <ExclamationCircleSolid/>
                <span>{t('hooks.useCreateQuizExamModal.__endTimeAfterStart')}</span>
              </div>
            </div>
          </div>
          <div id={'duration'} className='itemRow'>
            <div>{t('hooks.useCreateQuizExamModal.__duration')}</div>
            <div >
              <div className='testTime'>
                <Input
                  onChange={(e) => onSendParamsChange('duration', e.target.value)}
                  value={sendParams.duration}
                  // disabled={!isCanEdit}
                />
                <span>{t('hooks.useCreateQuizExamModal.__minutes')}</span>
              </div>
              <div className='errorText'>
                <ExclamationCircleSolid/>
                <span>{t('hooks.useCreateQuizExamModal.__durationLessThanPeriod')}</span>
              </div>
            </div>
          </div>

          <div className='itemRow'>
            <div>{t('hooks.useCreateQuizExamModal.__announceScoreSetting')}</div>
            <div >
              <Radio.Group
                className='radioBox'
                name="radioGroup"
                defaultValue={sendParams?.isAutoCheck}
                onChange={(e) => onSendParamsChange('isAutoCheck', e.target.value)}
              >
                <Radio value={true}>{t('hooks.useCreateQuizExamModal.__announceScoreAtSubmission')}</Radio>
                <Radio value={false}>{t('hooks.useCreateQuizExamModal.__announceScoreAtEnd')}</Radio>
              </Radio.Group>
            </div>
          </div>

          <div className='blockLine'></div>

          {/* <div className='itemRow eduGradeBox'>
            <div>受測對象年級</div>
            <div className='eduSubjectBox'>
              {
                eduMap && eduGradeMap && sendParams.educationCode &&
                    <>
                      <div className='radioBox'>
                        <div className='DrawerRow'>
                          <div className='title'>學制</div>
                          <RadioGroup
                            key={uuidv4()}
                            name={'eduRadioOptions'}
                            className='radioList' buttonStyle='solid'
                            optionType='button'
                            options={setRadioOptions(eduMap)}
                            value={sendParams.educationCode}
                            onChange={(e) => {onSendParamsChange('educationCode', e.target.value);}}
                            // disabled={!isCanEdit}
                          />
                        </div>
                        <div className='DrawerRow' id={'gradeCode'}>
                          <div className='title'>年級</div>
                          <RadioGroup
                            key={uuidv4()}
                            name={'gradeRadioOptions'}
                            className='radioList' buttonStyle='solid'
                            optionType='button'
                            options={setRadioOptions(eduGradeMap[sendParams.educationCode])}
                            value={sendParams.gradeCode}
                            onChange={(e) => {onSendParamsChange('gradeCode', e.target.value);}}
                            // disabled={!isCanEdit}
                          />
                          <div className='errorText'>
                            <ExclamationCircleSolid/>
                            <span>請選擇年級</span>
                          </div>
                        </div>
                      </div>
                    </>
              }
            </div>
          </div> */}
          {
            isLoading ? <div className={'loadingMask'}></div> : null
          }
        </StyledModal>

        <StyledHintModal
          visible={hintModalVisible}
          title={t('hooks.useCreateQuizExamModal.__saveChanges')}
          onCancel={setHintModalFalse}
          isMobile={true}
          id={'hintModal'}
          className={'hintModal'}
          zIndex={1099}
          centered={deviceData.isDesktop}
          footer={
            <div className="footerBtn footerBtnBox">
              <Button variant='ui01' onClick={cancelHintModal}>{t('hooks.useCreateQuizExamModal.__abandonChanges')}</Button>
              <Button
                // disabled={disableCreateQuizBtn}
                variant={'brand2'}
                onClick={doneHintModal}
                isLoading={isLoading}
              >
                {t('hooks.useCreateQuizExamModal.__saveChangesBtn')}
              </Button>
            </div>
          }
        >
          <span className='hintModalContent'>{t('hooks.useCreateQuizExamModal.__afterSaveWarning')}</span>
        </StyledHintModal>
      </>
    );
  };
  return { renderCreateQuizExamModal, createQuizExamModalToggle, setCreateQuizExamModalFalse, createQuizExamModalVisible };
};
