/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  Select,
  Pagination,
  sliceTableData,
  Input,
  PickListDropdown,
} from '@oneclass/ui-components';
import { Button } from '@oneclass/onedesign';
import { StyledTestListDesktop, StyledFilterBar } from './TestListDesktop.style';
import { v4 as uuidv4 } from 'uuid';
import * as GA from 'utils/googleAnalytics';
import { useHistory } from 'react-router-dom';
import { SESSION_STORAGE_KEY } from 'constants/index';
import StatusLabel from 'components/StatusLabel';
import { useTranslation } from 'react-i18next';
import { subjectMapper, educationMapper } from 'locales/translationMapper';
import { format } from 'date-fns';

export const TestListDesktop = ({
  className,
  filterParams,
  eduSubjectOptions,
  filterData,
  pageState,
  pageChange,
  setFilterCondition,
  transformTime,
  renderLoadingContent,
  isLoading,
  setTestModalVisibleToggle,
  setModalInfo,
}) => {
  const history = useHistory();
  const newestTest = sessionStorage.getItem(SESSION_STORAGE_KEY.OnePaper_NewTest);
  const { t } = useTranslation();

  const showShareModal = (data) => {
    if (!data) return;
    setModalInfo(data);
    setTestModalVisibleToggle();
  };

  const eduSubjectNameMapper = (value) => {
    const eduStr = value.substr(0, 2);
    const subjectStr = value.substr(2, value.length - 2);
    const eduName = t(educationMapper(eduStr));
    const subjectName = t(subjectMapper(subjectStr));
    return eduName + ' ' + subjectName.toLocaleLowerCase();
  };

  return (
    <StyledTestListDesktop className={className} data-testid="TestListDesktop">
      <Box mb={4}>
        <StyledFilterBar>
          <Input.Search
            placeholder= {t('components.testListDesktop.__searchPlaceholder')}
            allowClear={false}
            onSearch={(v) => {
              setFilterCondition(v, 'quizName');
              GA.setExamListFilter('quizName');
            }}
            maxLength={30}
          />
          <Select
            placeholder= {t('components.testListDesktop.__eduSubjectPlaceholder')}
            allowClear={filterParams?.eduSubject ? true : false}
            options={eduSubjectOptions}
            value={filterParams.eduSubject || null}
            onChange={(val) => {
              setFilterCondition(val, 'eduSubject');
              GA.setExamListFilter('eduSubject');
            }}
          />
          <PickListDropdown.DatePickListDropdown
            targetStr= {t('components.testListDesktop.__quiz')}
            defaultTitle= {t('components.testListDesktop.__examEndDateTitle')}
            defaultValue={null}
            onChange={(data) => {
              setFilterCondition(data, 'date');
              GA.setExamListFilter('date');
            }}
          />
        </StyledFilterBar>
      </Box>
      <Box>
        <Table className="tableZone">
          <Table.Header className='tableHeader'>
            <Table.Row>
              <Table.Item width='266px' flex='none'> {t('components.testListDesktop.__tableHeaderQuizName')}</Table.Item>
              <Table.Item width='90px' flex='none'> {t('components.testListDesktop.__tableHeaderEduSubject')}</Table.Item>
              <Table.Item width='144px' flex='none'>{t('components.testListDesktop.__tableHeaderStartTime')}</Table.Item>
              <Table.Item width='144px' flex='none'> {t('components.testListDesktop.__tableHeaderEndTime')}</Table.Item>
              <Table.Item width='90px' flex='none'> {t('components.testListDesktop.__tableHeaderStatus')}</Table.Item>
              <Table.Item width='88px' flex='none'> {t('components.testListDesktop.__tableHeaderSubmissionCount')}</Table.Item>
              <Table.Item></Table.Item>
            </Table.Row>
          </Table.Header>
          {
            isLoading
              ?
              renderLoadingContent()
              :
              <Table.Body bodyMaxHeight={true}>
                {
                  filterData && filterData.length > 0 ? sliceTableData(filterData, pageState.currentPage, pageState.limit).map(item => (
                    <Table.Row key={uuidv4()} className='tableRow'>
                      <Table.Item width='266px' flex='none'>
                        <div>
                          {item.QuizUID === newestTest && <StatusLabel isNew={true} />}
                          {item.QuizName}
                        </div>
                      </Table.Item>
                      <Table.Item width='90px' flex='none'>{ eduSubjectNameMapper(item.EduSubjectName)}</Table.Item>
                      <Table.Item width='144px' flex='none'>{ format(new Date(item.StartTime), 'dd/MM/yyyy HH:mm') }</Table.Item>
                      <Table.Item width='144px' flex='none'>{format(new Date(item.EndTime), 'dd/MM/yyyy HH:mm')}</Table.Item>
                      <Table.Item width='90px' flex='none'>
                        <StatusLabel startTime={item.StartTime} endTime={item.EndTime} />
                      </Table.Item>
                      <Table.Item width='88px' flex='none' textalign={'center'}>{item.attendance}</Table.Item>
                      <Table.Item className='actions'>
                        <Button
                          className="tableBtn outlined"
                          onClick={() => showShareModal(item)}
                        >
                          {t('components.testListDesktop.__quizLink')}
                        </Button>
                        <Button
                          className="tableBtn"
                          variant='brand2'
                          onClick={() => history.push(`/testDetail/${item.QuizUID}`)}
                        >
                          {t('components.testListDesktop.__testDetails')}
                        </Button>
                      </Table.Item>
                    </Table.Row>
                  )) :
                    <div className="tableEmpty">
                      <div>
                        {t('components.testListDesktop.__tableEmptyMessage1')}
                      </div>
                      <div> {t('components.testListDesktop.__tableEmptyMessage2')}
                      </div>
                    </div>
                }
              </Table.Body>
          }
        </Table>
        <div className="pageBlock">
          <Pagination
            total={filterData?.length || 0}
            defaultCurrent={1}
            current={pageState.currentPage}
            onChange={pageChange}
            defaultPageSize={pageState.limit}
          />
        </div>
      </Box>
    </StyledTestListDesktop>
  );
};

TestListDesktop.propTypes = {
  className: PropTypes.string,
  filterParams: PropTypes.object,
  eduSubjectOptions: PropTypes.array,
  typeOptions: PropTypes.array,
  patternOptions: PropTypes.array,
  filterData: PropTypes.array,
  pageState: PropTypes.object,
  pageChange: PropTypes.func,
  setFilterCondition: PropTypes.func,
};