/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo, useContext } from 'react';
import { getExportPaperSetting } from 'api/exportPaper';
import { postMemberTypeSetting, getMemberTypeSetting } from 'api/member';
import { useMachine } from '@xstate/react';
import { Box, Table, setRadioOptions, fetchDataMachine, FETCH_DATA_EVENT, FETCH_DATA_STATE, openNotificationWithIcon, Drawer, Input } from '@oneclass/ui-components';
import { arrayUnique } from 'utils/common';
import { ExamPaperSetting } from 'components';
import PropTypes from 'prop-types';
import { StyledHomeLayoutOptionContent } from './HomeLayoutOptionContent.style';
import { useQuestionAnalysisModal } from 'utils/hooks/useQuestionAnalysisModal';
import { useBoolean } from 'ahooks';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { default as UserProvider } from 'providers/UserProvider.js';
import QuestionTypeName from 'components/QuestionTypeName';
import LayoutSettingPaper from 'components/LayoutSettingPaper';
import EnglishListenSettings from 'components/EnglishListenSettings';
import { LISTEN_DEFAULT_SETTINGS, } from 'constants/index';
import { useTranslation } from 'react-i18next';
import { convertTWYearToNormalYear } from 'utils/common';

// eslint-disable-next-line react/prop-types
export const HomeLayoutOptionContent = ({
  className,
  searchKey,
  keyList,
  examPaperData,
  examPaperTotalScoreData,
  saveData,
  globalExamPaperData,
  getCreateExamPaperParams,
  getSaveData,
  getCheckedSend,
  searchParams,
  eduSubjectData,
  areaParams,
  setOutSideSendParams,
  renderLoadingContent,
  isLoading,
  setIsLoading,
  isEditCopy,
  editCopyExamPaperData,
  tableData,
  setTableData,
  totalQuesTypeData,
}) => {
  const { t } = useTranslation();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const rowData = ['schoolName', 'paperName', 'teacherSign', 'classInfo', 'eduSubject', 'studentSign'];
  const { userData } = useContext(UserProvider.UserProviderContext);
  const { userInfo } = userData;
  const [sendParams, setSendParams] = useState({
    paperName: {
      value: '',
      message: '',
    },
    // examType: '',
    outputType: globalExamPaperData?.output,
    amount: 2,
    engAudioSetting: LISTEN_DEFAULT_SETTINGS
  });

  const defaultCountInputValue = {
    answer: '',
    question: '',
    score: {
      perQues: '',
      perAns: '',
      allScore: 0,
    },
    difficulty: {
      BEGIN: '',
      INTERMEDIATE: '',
      EXPERT: '',
    }
  };

  useEffect(() => {
    setOutSideSendParams(sendParams);
  }, [sendParams]);

  //導入 試題分析 Hook
  const pickUpQuestionID = useMemo(() => {
    if (!tableData) return [];
    let nextData = [];
    Object.entries(tableData).forEach(([key, value]) => {
      if (value?.pickUpQuestions) {
        Object.entries(value.pickUpQuestions).forEach(([quesKey, quesValue]) => {
          quesValue.forEach(item => {
            nextData = [...nextData, item.uid];
          });
        });
      }

    });
    return nextData;
  }, [tableData]);

  const { renderQuestionAnalysisModal, doShowAnalysisModal } = useQuestionAnalysisModal(pickUpQuestionID, searchParams);

  const [checkedSend, setCheckedSend] = useState(['paperName']);

  // 取得 使用者 出卷輸出設定
  const [stateExportPaperRelated, sendExportPaperRelated] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (ctx, evt) => {
        setIsLoading(true);
        const res = await getExportPaperSetting(evt?.payload);
        const { data, isSuccess, systemCode, message } = res;
        if (!isSuccess) openNotificationWithIcon('error', message);
        const { settings } = data;

        const languageSubject = ['CH', 'PC', 'CN'];

        if (languageSubject.indexOf(eduSubjectData.subject) === -1) {
          settings.Files.wordSettingMap = settings?.Files?.wordSettingMap.filter((item) => { return (item?.code !== 'HSS' && item?.code !== 'HSD'); });
        }

        setIsLoading(false);

        const nowTypesetting = isEditCopy
          ? editCopyExamPaperData?.typeSetting?.ordering
            ? editCopyExamPaperData?.typeSetting
            : { ...editCopyExamPaperData?.typeSetting, ordering: rowData }
          : settings?.Files?.typesetting?.ordering
            ? settings?.Files?.typesetting
            : { ...settings?.Files?.typesetting, ordering: rowData };

        return {
          isSuccess, systemCode, message,
          paperSizeMap: setRadioOptions(settings?.Files?.paperSizeMap) || [],
          wordSettingMap: setRadioOptions(settings?.Files?.wordSettingMap) || [],
          paperContent: settings?.Files?.paperContent,
          typesetting: nowTypesetting,
          advancedSetting: settings?.Files?.advancedSetting,
          analyzeContent: settings?.Files?.analyzeContent,
          onlineAdvanced: settings?.Online?.advanced,
          onlineAdvancedSetting: settings?.Online?.advancedSetting,
        };
      }
    }
  });
  const {
    paperSizeMap,
    wordSettingMap,
    paperContent,
    advancedSetting,
    typesetting,
    analyzeContent,
    onlineAdvanced,
    onlineAdvancedSetting,
  } = stateExportPaperRelated.context.result || {};

  const [state, send] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async () => {
        const res = await getMemberTypeSetting();
        const { data, isSuccess, systemCode, message } = res;
        const {
          uid = '',
          isTeacher = false,
          identityName = '',
          account = '',
          name = '',
          organizationName = '',
          paperSizeMap = [],
          wordSettingMap = [],
          paperContent = [],
          typesetting = {},
          analyzeContent = []
        } = data || {};
        return {
          isSuccess, systemCode, message,
          uid,
          isTeacher,
          identityName,
          account,
          name,
          organizationName,
          paperSizeMap: setRadioOptions(paperSizeMap) || [],
          wordSettingMap: setRadioOptions(wordSettingMap) || [],
          paperContent,
          typesetting,
          analyzeContent
        };
      }
    }
  });
  const { isTeacher } = state.context.result || {};
  const [, sendUserSetting] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_, event) => {
        const res = await postMemberTypeSetting(event.payload);
        const { isSuccess, systemCode, message } = res;
        if (isSuccess) {
          openNotificationWithIcon('success', t('containers.homeLayoutOptionContent.__saveSuccess'));
        } else {
          openNotificationWithIcon('error', message);
        }
        return {
          isSuccess, systemCode, message
        };
      }
    }
  });

  const onSaveExamPaperInfo = () => {
    let nextData = {};

    const {
      schoolName = {},
      paperName,
      teacherSign = {},
      grade = {},
      room = {},
      paperSize,
      wordSetting,
      paperContents,
      analyzeContent,
      amount,
      advanced,
      studentSign,
      eduSubject,
      ordering,
      header,
    } = sendParams || {};

    nextData = {
      schoolName: checkedSend.findIndex(item => item === 'schoolName') !== -1 ? schoolName.value : '',
      paperName: paperName.value,
      teacherSign: checkedSend.findIndex(item => item === 'teacherSign') !== -1 ? teacherSign.value : '',
      grade: checkedSend.findIndex(item => item === 'classInfo') !== -1 ? grade.value : '',
      room: checkedSend.findIndex(item => item === 'classInfo') !== -1 ? room.value : '',
      eduSubject: checkedSend.findIndex(item => item === 'eduSubject') !== -1 ? eduSubject : '',
      studentSign: checkedSend.findIndex(item => item === 'studentSign') !== -1 ? studentSign : '',
      paperSize,
      wordSetting,
      paperContents: paperContents || [],
      analyzeContent: analyzeContent || [],
      amount,
      advanced: advanced || [],
      ordering,
      header,
    };

    sendUserSetting(FETCH_DATA_EVENT.FETCH, {
      payload: nextData
    });

  };

  const getSendParams = (data) => {
    setSendParams({ ...sendParams, ...data });
  };

  const changeCheckedSend = (data) => {
    setCheckedSend(data);
  };

  ///// ------------------------------

  const questionGroup = useMemo(() => {
    if (!examPaperData) return;
    const nextData = keyList.map(key => {
      let questionList = [];
      if (examPaperData[key]?.pickUpQuestions) {
        Object.values(examPaperData[key].pickUpQuestions).forEach(questions => {
          questions.forEach(question => {
            questionList = [...questionList, question.uid];
          });
        });
      }
      return ({
        typeCode: examPaperData[key].code,
        scoreType: examPaperData[key].inputValue.score.perQues ? 'PerQuestion' : 'PerAnswer',
        score: examPaperData[key].inputValue.score.perQues ? Number(examPaperData[key].inputValue.score.perQues) || 0 : Number(examPaperData[key].inputValue.score.perAns) || 0,
        questionList
      });
    }).filter(item => item.questionList.length > 0);
    return nextData;
  }, [examPaperData]);

  const hasListen = useMemo(() => {
    return Object.values(tableData)
      .filter((quesType) => quesType?.pickUpQuestions)
      .some((quesType) => quesType.isListen);
  }, [tableData]);

  useEffect(() => {
    if (!saveData?.createExamPaperParams.examUID) {
      if (saveData?.actionType) {
        sendExportPaperRelated(FETCH_DATA_EVENT.FETCH, {
          payload: {
            examUID: saveData.rootExamUID
          }
        });
      } else {
        sendExportPaperRelated(FETCH_DATA_EVENT.FETCH);
      }
    } else {
      sendExportPaperRelated(FETCH_DATA_EVENT.FETCH, {
        payload: {
          examUID: saveData.rootExamUID
        }
      });
    }
  }, []);

  const paperDefaultName = useMemo(() => {
    if (!areaParams || !eduSubjectData) return '';
    // return `${convertTWYearToNormalYear(areaParams.year)} ${eduSubjectData.eduSubjectName.edu + ' ' + eduSubjectData.eduSubjectName.subject}`;
    return t('containers.homeLayoutOptionContent.__academicYear', { year: convertTWYearToNormalYear(areaParams.year), eduSubject: eduSubjectData.eduSubjectName.edu + ' ' + eduSubjectData.eduSubjectName.subject });
  }, [areaParams, eduSubjectData]);

  useEffect(() => {
    getCreateExamPaperParams(sendParams);
    getSaveData('createExamPaperParams', sendParams);
  }, [sendParams]);


  useEffect(() => {
    getCheckedSend(checkedSend);
  }, [checkedSend]);

  useEffect(() => {
    if (saveData?.createExamPaperParams) {
      setSendParams({ ...saveData?.createExamPaperParams });
    }
  }, []);

  useEffect(() => {
    if (!stateExportPaperRelated.matches(FETCH_DATA_STATE.DONE)) return;
    if (!typesetting) return;
    const {
      room = '',
      grade = '',
    } = typesetting || {};
    let nextCheckSend = [];
    rowData.forEach(item => {
      if ((item === 'classInfo') && room && grade) {
        nextCheckSend = [...nextCheckSend, item];
      } else if (item === 'paperName') {
        nextCheckSend = [...nextCheckSend, item];
      } else {
        if (typesetting[item]) {
          nextCheckSend = [...nextCheckSend, item];
        }
      }
    });
    setCheckedSend(nextCheckSend);
  }, [typesetting, stateExportPaperRelated.value]);

  useEffect(() => {
    const {
      paperSize,
      //wordSetting,
      paperContents,
      // paperName = '',
      amount = 1,
      advanced = [],
      schoolName = '',
      room = '',
      grade = '',
      teacherSign = '',
      analyzeContent = [],
      ordering = [],
      header = '',
    } = typesetting || {};


    setSendParams({
      ...sendParams,
      // examUID: data.examUID,
      paperSize: paperSize || (paperSizeMap && paperSizeMap[0].value),
      wordSetting: wordSetting || (wordSettingMap && wordSettingMap[0].value),
      paperContents: paperContents?.length > 0 ? paperContents : paperContent && paperContent.filter(item => item.code === 'Question' || item.code === 'Analyze').map(item => item.code),
      paperName: {
        value: paperDefaultName, //newPaperName,
        message: '',
      },
      schoolName: {
        value: schoolName,
        message: '',
      },
      grade: {
        value: grade,
        message: '',
      },
      room: {
        value: room,
        message: '',
      },
      teacherSign: {
        value: teacherSign,
        message: '',
      },
      outputType: sendParams?.outputType || 'Files',
      // examType: sendParams.examType || 'General',
      amount,
      advanced,
      analyzeContent: arrayUnique(['Question', 'Answer', 'Analyze'].concat(analyzeContent)),
      studentSign: `${t('containers.homeLayoutOptionContent.__seatNumber')}：＿＿＿  ${t('containers.homeLayoutOptionContent.__name')}：＿＿＿＿＿＿`,
      eduSubject: `${globalExamPaperData?.eduSubjectName.edu}${globalExamPaperData?.eduSubjectName.subject}`,
      onlineAdvanced,
      ordering,
      header,
    });
  }, [typesetting, globalExamPaperData, onlineAdvanced]);

  const scoreInputChange = (quesType, inputKey, e) => {
    if (!new RegExp(/^(\d{0,3})(\.\d{0,1})?$/g).test(e.target.value)) return;
    const value = e.target.value;
    let allScore = 0;
    if (inputKey === 'perQues') {
      allScore = tableData[quesType].inputValue.question ? (value * tableData[quesType].inputValue.question).toFixed(1) : 0;
    } else {
      allScore = tableData[quesType].inputValue.answer ? (value * tableData[quesType].inputValue.answer).toFixed(1) : 0;
    }
    let nextScore = {
      ...defaultCountInputValue.score,
      [inputKey]: value,
      allScore
    };
    let nextData = {};
    nextData = {
      [quesType]: {
        ...tableData[quesType],
        inputValue: {
          ...tableData[quesType].inputValue,
          score: {
            ...nextScore
          }
        }
      }
    };
    setTableData({
      ...tableData,
      ...nextData
    });
  };

  // ---- 手機版 相關 ----

  // 題數與配分
  const [setupDrawerVisible, { toggle: setupDrawerToggle, setFalse: setupDrawerFalse }] = useBoolean(false);

  // 動態新增 題型與配分Btn 到 header 上面
  const addSetupBtnClickEvent = () => {
    const setupBtn = document.getElementById('setupBtn');
    if (setupBtn) {
      setupBtn.addEventListener('click', function () {
        setupDrawerToggle();
      });
    }
  };

  useEffect(() => {
    addSetupBtnClickEvent();
  }, []);

  // 關閉 背景滑動
  useEffect(() => {
    document.body.classList.remove('mobileScrollingStop');
    if (!deviceData.isDesktop) {
      if (setupDrawerVisible) {
        document.body.classList.add('mobileScrollingStop');
      }
    }
  }, [setupDrawerVisible]);

  // 若為 編輯複製 將原本設定 帶入
  useEffect(() => {
    if (!isEditCopy) return;

  }, [isEditCopy]);

  // 依照學制科目 預先帶入對應的排版方式
  const wordSetting = useMemo(() => {
    if (!eduSubjectData) return;
    const edu = eduSubjectData.edu;
    const subject = eduSubjectData.subject;
    switch (edu) {
      case 'E':
        if (subject === 'CH') {
          return 'HSD';
        } else {
          return 'VHD';
        }
      case 'J':
        if (subject === 'PC') {
          return 'HSD';
        } else {
          return 'VHS';
        }
      case 'H':
        return 'HHD';
      default:
        return '';
    }
  }, [eduSubjectData]);

  return (
    isLoading ? renderLoadingContent()
      :
      <>
        <LayoutSettingPaper title={t('containers.homeLayoutOptionContent.__paperSetting')} hasListen={hasListen}>
          <StyledHomeLayoutOptionContent className={className} data-testid="HomeLayoutOptionContent">
            {
              deviceData.isDesktop &&
          <>
            <Box mb={5} className='pageTitleBox'>
              <div className="pageTitle">
                {t('containers.homeLayoutOptionContent.__questionView')}
              </div>
              {renderQuestionAnalysisModal()}
            </Box>
            <Box mb={11}>
              <Table className={hasListen ? 'tableBorder' : ''}>
                <Table.Header>
                  <Table.Row textcenter={true}>
                    <Table.Item >
                      {t('containers.homeLayoutOptionContent.__order')}
                    </Table.Item>
                    <Table.Item flex={10}>
                      {t('containers.homeLayoutOptionContent.__questionType')}
                    </Table.Item>
                    <Table.Item flex={1}>
                      {t('containers.homeLayoutOptionContent.__questionCount')}
                    </Table.Item>
                    <Table.Item flex={1}>
                      {t('containers.homeLayoutOptionContent.__answerCount')}
                    </Table.Item>
                    {/* <Table.Item flex={1.5}>每題分數</Table.Item> */}
                    <Table.Item flex={1.5}>
                      {t('containers.homeLayoutOptionContent.__perAnswerScore')}
                    </Table.Item>
                    <Table.Item flex={2}>
                      {t('containers.homeLayoutOptionContent.__subtotal')}
                    </Table.Item>
                  </Table.Row>
                </Table.Header>
                <Table.Body className="tableBody">
                  {
                    keyList && keyList.map((key, index) => (
                      <Table.Row key={key} textcenter={true} >
                        <Table.Item textalign={'center'}>{index + 1}</Table.Item>
                        <Table.Item flex={10} textalign={'flex-start'}>
                          <QuestionTypeName name={tableData[key].name} isListen={tableData[key].isListen} />
                        </Table.Item>
                        <Table.Item flex={1} textalign={'flex-end'}>{tableData[key].inputValue.question}</Table.Item>
                        <Table.Item flex={1} textalign={'flex-end'}>{tableData[key].inputValue.answer}</Table.Item>
                        {/* <Table.Item flex={1.5} textalign={'flex-end'}>
                          <div>
                            <Input allowClear={false} value={tableData[key].inputValue.score.perQues || ''} onChange={(e) => {scoreInputChange(key, 'perQues', e);}} />
                          </div>
                        </Table.Item> */}
                        <Table.Item flex={1.5} textalign={'flex-end'}>
                          <div>
                            <Input allowClear={false} value={tableData[key]?.inputValue.score.perAns || ''} onChange={(e) => { scoreInputChange(key, 'perAns', e); }} />
                          </div>
                        </Table.Item>
                        <Table.Item flex={2} textalign={'flex-end'}>{tableData[key].inputValue.score.allScore}</Table.Item>
                      </Table.Row>
                    ))
                  }
                  <Table.Row>
                    <Table.Item className='tableFooter'>
                      {t('containers.homeLayoutOptionContent.__examPaperTotalScoreData', { questionCount: examPaperTotalScoreData.questionCount, totalAnswer: examPaperTotalScoreData?.totalAnswer, score: examPaperTotalScoreData.score })}
                    </Table.Item>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Box>
          </>
            }
            <ExamPaperSetting
              sendParams={sendParams}
              checkedSend={checkedSend}
              rowData={typesetting?.ordering}
              globalExamPaperData={globalExamPaperData}
              getSendParams={getSendParams}
              changeCheckedSend={changeCheckedSend}
              onSaveExamPaperInfo={onSaveExamPaperInfo}
              paperSizeMap={paperSizeMap}
              wordSettingMap={wordSettingMap}
              paperContent={paperContent}
              analyzeContent={analyzeContent}
              advancedSetting={advancedSetting}
              onlineAdvanced={onlineAdvanced}
              onlineAdvancedSetting={onlineAdvancedSetting}
              hasListen={hasListen}
            />
            {/* 題數配分 */}
            <Drawer
              visible={setupDrawerVisible}
              title={t('containers.homeLayoutOptionContent.__questionAndAllocation')}
              onClose={setupDrawerFalse}
              placement={'bottom'}
              isMobile={true}
              id={'setupDrawer'}
              className={'setupDrawer'}
              key={tableData}
              destroyOnClose={true}
            >
              <div className='setupDrawerTotalScore'>
                <span> {t('containers.homeLayoutOptionContent.__examPaperTotalScoreData', { questionCount: examPaperTotalScoreData.questionCount, totalAnswer: examPaperTotalScoreData?.totalAnswer, score: examPaperTotalScoreData.score })}</span>
                <span className='tipText'>
                  {t('containers.homeLayoutOptionContent.__modifyQuestions')}
                </span>
              </div>
              {
                pickUpQuestionID.length > 0 && renderQuestionAnalysisModal()
              }
              {
                keyList &&
            keyList.map((type) => {
              return (
                <div className='questionTypeBox' key={type}>
                  <div className={'drawerTitle'}>
                    {examPaperData[type]?.name}
                  </div>
                  <div className={'drawerSection'}>
                    <div className={'drawerTable'}>
                      <div>
                        <div>{t('containers.homeLayoutOptionContent.__selectedQuestions')}</div>
                        <div>{t('containers.homeLayoutOptionContent.__answeredQuestions')}</div>
                      </div>
                      <div>
                        <div>
                          {examPaperData[type].inputValue.question}
                        </div>
                        <div>
                          {examPaperData[type].inputValue.answer}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={'drawerSection'}>
                    <div className={'drawerTable'}>
                      <div>
                        {/* <div>每題分數</div> */}
                        <div>{t('containers.homeLayoutOptionContent.__perAnswerScore')}</div>
                      </div>
                      <div className={'setupDrawerScoreBox'}>
                        {/* <div>{examPaperData[type].inputValue.score.perQues || '-'}</div> */}
                        <div>{examPaperData[type].inputValue.score.perAns || '-'}</div>
                      </div>
                    </div>
                  </div>
                  <div className={'drawerTotalScore'}>
                    {t('containers.homeLayoutOptionContent.__drawerTotalScore', { allScore: examPaperData[type].inputValue.score.allScore })}
                  </div>
                </div>
              );
            })
              }
            </Drawer>
          </StyledHomeLayoutOptionContent>
        </LayoutSettingPaper>
        {sendParams.outputType === 'Files' && hasListen && (
          <LayoutSettingPaper title={t('containers.homeLayoutOptionContent.__listeningSetting')} hasListen={hasListen}>
            <EnglishListenSettings sendParams={sendParams} getSendParams={getSendParams} eduSubjectData={eduSubjectData} editCopyExamPaperData={editCopyExamPaperData} />
          </LayoutSettingPaper>
        )}
      </>
  );
};

HomeLayoutOptionContent.propTypes = {
  className: PropTypes.string,
  examPaperData: PropTypes.array,
  keyList: PropTypes.array,
  searchKey: PropTypes.string,
  examPaperTotalScoreData: PropTypes.object,
  getCreateExamPaperParams: PropTypes.func,
  saveData: PropTypes.object,
  globalExamPaperData: PropTypes.object,
  getSaveData: PropTypes.func,
  getCheckedSend: PropTypes.func,
  organizationType: PropTypes.string
};
