/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
// import PropTypes from 'prop-types';
import { getAnomalySelectOption } from 'api/drawUpFast';
import { useMachine } from '@xstate/react';
import {
  Box,
  Table,
  Input,
  Select,
  fetchDataMachine,
  FETCH_DATA_EVENT,
  PickListDropdown,
  sliceTableData,
  Pagination,
  sectionToChinese,
  diffStr,
  search,
  Modal,
  setSelectOptions,
  AuthProvider,
  Drawer,
  Checkbox as CheckboxType,
  CheckboxTree,
  openNotificationWithIcon,
} from '@oneclass/ui-components';
import { Checkbox, Button, RadioGroup } from '@oneclass/onedesign';
import { DIFFICULTY, LANGUAGE_LIST, HISTORIC_EXAM_ID, PAGE_SIZE_OPTIONS } from 'constants/index';
import { useBoolean, useSelections } from 'ahooks';
import { BookSolid1, ChevronRightOutline, ChevronLeftOutline, ListAltSolid, SearchPlusSolid, XmarkOutline, StarSolid, ReturnSolid, XmarkCircleSolid } from '@onedesign/icon';
import { StyledHomeQuestionSelectContent, StyledFilterBar, StyledAreaSelectList, StyledAreaSelectRow, StyledAreaSelectContainer, StyleReportModalContent, StyledMobileFilterContent } from './HomeQuestionSelectContent.style';
import { useQuestionAnalysisModal } from 'utils/hooks/useQuestionAnalysisModal';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { Radio, Tooltip } from 'antd';
import { useTouch } from '../../utils/hooks/useTouch';
import classNames from 'classnames';
import { postGetQuestion } from 'api/query';
import { postQuestionReport } from 'api/question';
import { unGzip, formateQuestionObject, knowledgeArrayToString, numberToFirst } from 'utils/common';
import * as GA from 'utils/googleAnalytics';
import AudioPlayer from 'components/AudioPlayer';
import QuestionTypeName from 'components/QuestionTypeName';
import QuestionDetail from 'components/QuestionDetail/QuestionDetail';
import { useTranslation } from 'react-i18next';
import { questionTypeMapper } from 'locales/translationMapper';
import { sourceMapper } from 'locales/translationMapper/sourceMapper';
import { bookNameMapper } from 'locales/translationMapper';
import { DifferentTag } from 'components/DifferentTag/DifferentTag';
import { difficultyMapper, subSourceMapper } from 'locales/translationMapper';
import _ from 'lodash';
const defaultCountInputValue = {
  answer: '',
  question: '',
  score: {
    perQues: '',
    perAns: '',
    allScore: 0,
  },
  difficulty: {
    BEGIN: '',
    INTERMEDIATE: '',
    EXPERT: '',
  }
};
const showCodeList = ['ANSWER_TYPE', 'QUES_TYPE', 'KNOWLEDGE', 'LEARN_CONTENT', 'SOURCE'];
const filterDifficulty = {
  BEGIN: ['BEGIN'],
  INTERMEDIATE: ['INTERMEDIATE', 'BASIC', 'ADVANCED'],
  EXPERT: ['EXPERT']
};
const { Header, Body, Row, Item } = Table;

export const HomeQuestionSelectContent = ({
  className,
  examPaperInfo,
  prevKeyList,
  searchKey,
  saveData,
  eduSubjectData,
  totalQuesTypeData,
  getQuestions,
  getKeyList,
  getSaveData,
  searchParams,
  renderLoadingContent,
  isLoading,
  setIsLoading,
  globalExamPaperData,
  isEditCopy,
  reCreateSearchParams,
  editCopyExamPaperData,
  isJumpToStep5,
  setIsJumpToStep5,
  setStep,
  filterQuestions,
  selectedBooks,
  tableData,
  setTableData,
}) => {
  const { t } = useTranslation();
  const questionDisplayOptions = [
    {
      name: t('containers.homeQuestionSelectContent.__allQuestions'),
      value: 'all',
    },
    {
      name: t('containers.homeQuestionSelectContent.__selectedQuestions'),
      value: 'checked',
    },
    {
      name: t('containers.homeQuestionSelectContent.__unselectedQuestions'),
      value: 'notCheck',
    },
  ];

  const mobileQuestionDisplayOptions = [
    {
      label: t('containers.homeQuestionSelectContent.__allQuestions'),
      value: 'all',
    },
    {
      label: t('containers.homeQuestionSelectContent.__selectedQuestions'),
      value: 'checked',
    },
    {
      label: t('containers.homeQuestionSelectContent.__unselectedQuestions'),
      value: 'notCheck',
    },
  ];
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const { useAuthDispatch, useAuthState } = AuthProvider;
  const authState = useAuthState();
  const [filterCondition, setFilterCondition] = useState({
    name: ''
  });
  const [modalImageSrc, setModalImageSrc] = useState(null);
  const [nowFilterChapter, setNowFilterChapter] = useState('all');
  const [displayQuestionsType, setDisplayQuestionsType] = useState(isEditCopy ? 'all' : 'checked');
  const [pageState, setPageState] = useState({
    currentPage: 1,
    limit: 10,
  });
  const [modalVisible, { toggle: setModalVisibleToggle, setFalse: setModalVisibleFalse }] = useBoolean(false);
  const [filterChapterVisible, { toggle: setFilterChapterVisibleToggle, setFalse: setFilterChapterVisibleFalse }] = useBoolean(true);
  const [keyList, setKeyList] = useState(prevKeyList);
  const tableRef = useRef(null);
  const [haveChapterData, setHaveChapterData] = useState(false);
  const env = process.env.REACT_APP_NODE_ENV;
  const [questions, setQuestions] = useState([]);
  const [allBookChapters, setAllBookChapters] = useState([]);

  const [statePostGetQuestion, sendPostGetQuestion] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        setIsLoading(true);
        const res = await postGetQuestion(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        const { books, questions } = data;

        if (isSuccess) {
          if (isJumpToStep5) {
            setTimeout(() => {
              setStep(5);
              setIsJumpToStep5(false);
            }, 1000);
          } else {
            setIsLoading(false);
          }
        }
        // 若為 複製編輯流程 一次多選已選題目
        if (isEditCopy) {
          //判斷是否為第一次進入頁面（尚未帶入已選題目）
          let firstTime = true;
          Object.entries(tableData).forEach(([key, value]) => {
            if (value.pickUpQuestions) firstTime = false;
          });
          if (firstTime) selectedQuesForEditCopy(editCopyExamPaperData?.questionGroup);
        }
        setQuestions(transformQuestions(formateQuestionObject(unGzip(questions)), books));
        setAllBookChapters(transformBookChapters(books));
        return {
          isSuccess, systemCode, message,
        };
      },
    },
  });

  // const { filter } = state.context.result || {};
  // const { bookChapters } = statePostGetQuestion.context.result || {};

  const transformKnowledgeList = (array) => {
    if (!array) return [];
    const nextData = array.map((item) => {
      return {
        itemCode: item.code,
        itemName: item.name,
      };
    });
    return nextData;
  };

  const transformBookChapters = (books) => {
    if (!books || JSON.stringify(books) === '{}') return [];
    const nextData = books.map((book) => {
      let chaptersArray = [];

      const chapters = book?.chapters || book?.data || [];
      const isNewData = book?.chapters; // 是否為新式資料格式（紀錄使用者選擇之範圍）
      if (!chapters) return {};

      chapters.forEach((chapter) => {
        const setChapter = (chapter, parentCode = null) => {
          chaptersArray.push({
            code: chapter?.code,
            desc: `[${chapter?.code}] ${chapter?.name}`,
            hasKnowledge: !parentCode,
            knowledgeList: transformKnowledgeList(chapter?.knowledges || chapter?.Knowledges || null),
            name: chapter?.name,
            parentCode,
          });
          if (chapter?.data?.length) {
            chapter.data.forEach(childChapter => {
              setChapter(childChapter, chapter.code);
            });
          }
        };
        setChapter(chapter);
      });
      return {
        bookDesc: isNewData ? book?.volumnName : book?.volume?.name,
        bookID: book?.bookId,
        chapters: chaptersArray
      };
    });

    return nextData;
  };

  const bookChapters = useMemo(() => {
    // 若為舊的資料格式，則會缺少使用者的範圍選擇資料，則以全範圍顯示。
    if (!selectedBooks || selectedBooks.length === 0) {
      if (!allBookChapters || allBookChapters.length === 0) return [];
      return allBookChapters;
    } else {
      return transformBookChapters(selectedBooks);
    }
  }, [selectedBooks, allBookChapters]);



  const [typeOptions, setTypeOptions] = useState([]);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [learnContentOptions, setLearnContentOptions] = useState([]);
  const [difficultyOptions, setDifficultyOptions] = useState([]);
  const [knowledgeOptions, setKnowledgeOptions] = useState([]);
  const [openedQuestionIds, setOpenedQuestionIds] = useState([]);

  const transformTabSelectOptions = (object) => {
    let array = [];
    for (const [key, value] of Object.entries(object)) {
      let subMap = [];
      value.forEach((item) => {
        subMap.push({
          name: item,
          value: item,
        });
      });
      array.push({
        name: key,
        value: key,
        subMap,
      });
    }
    return array;
  };

  const transformQuestions = (data, books) => {
    if (!data) return [];
    const filterArray = editCopyExamPaperData?.questionFilters || filterQuestions || [];

    const questions = data.filter((item) => {
      return ((filterArray && filterArray.length !== 0) ? filterArray.indexOf(item?.uid) === -1 : true);
    }).map(e => {

      const ce = _.cloneDeep(e);
      if (ce?.metaData?.questionType?.name) {
        ce.metaData.questionType.name = t(questionTypeMapper(ce.metaData.questionType.name));
      }

      if (ce.questionGroup) {
        ce.questionGroup = t(questionTypeMapper(ce.questionGroup));
      }


      if (ce?.metaData?.subSource?.name) {
        ce.metaData.subSource.name = t(subSourceMapper(ce.metaData.subSource.name));
      }

      if (ce?.metaData?.subSource?.name) {
        ce.metaData.source.name = t(sourceMapper(ce.metaData.source.name));
      }

      // translation map
      return ce;
    });



    let questionArray = [];
    let learnContentArray = [];
    let difficultyArray = [];
    let knowledgeArray = [];
    let sourceObject = {};
    let quesTypeObject = {};

    questions.forEach((ques) => {
      if (ques?.metaData?.learnContent?.name) {
        if (learnContentArray?.indexOf(ques?.metaData?.learnContent?.name) === -1) {
          learnContentArray?.push(ques?.metaData?.learnContent?.name);
        }
      }
      if (ques?.metaData?.difficulty?.name) {
        if (difficultyArray?.indexOf(ques?.metaData?.difficulty?.name) === -1) {
          difficultyArray?.push(ques?.metaData?.difficulty?.name);
        }
      }
      if (ques?.metaData?.knowledges && ques?.metaData?.knowledges.length > 0) {
        ques?.metaData?.knowledges.forEach((knowledge) => {
          if (knowledgeArray?.indexOf(knowledge?.name) === -1) {
            knowledgeArray?.push(knowledge?.name);
          }
        });
      }

      if (ques.metaData.questionType.name) {
        if (quesTypeObject[ques.questionGroup]) {
          if (quesTypeObject[ques.questionGroup].indexOf(ques.metaData.questionType.name) === -1) {
            quesTypeObject[ques.questionGroup].push(ques.metaData.questionType.name);
          }
        } else {
          quesTypeObject[ques.questionGroup] = [ques.metaData.questionType.name];
        }
      }
      if (ques?.metaData?.subSource?.name) {
        if (sourceObject[ques?.metaData?.source?.name]) {
          if (sourceObject[ques?.metaData?.source?.name]?.indexOf(ques?.metaData?.subSource?.name) === -1) {
            sourceObject[ques?.metaData?.source?.name]?.push(ques?.metaData?.subSource?.name);
          }
        } else {
          sourceObject[ques?.metaData?.source?.name] = [ques?.metaData?.subSource?.name];
        }
      }

      let chapter = {};
      books.forEach((book) => {
        if (!book.data) return null;
        book?.data.forEach((item) => {
          // 從 book.data 裡找出屬於這題（ques）的 knowledge 並設成 chapter
          const setChapter = (chapter_) => {
            if (chapter_?.knowledges?.length) {
              chapter_.knowledges.forEach(knowledge => {
                if (knowledge.code === ques?.metaData?.knowledges[0].code) {
                  chapter = { code: knowledge.code, name: knowledge.name };
                }
              });
            } else if (chapter_?.data) {
              chapter_.data.forEach(setChapter);
            }
          };
          setChapter(item);
        });
      });

      questionArray?.push({
        ...ques,
        chapter,
      });
    });

    setSourceOptions(transformTabSelectOptions(sourceObject));
    setTypeOptions(transformTabSelectOptions(quesTypeObject));

    setLearnContentOptions(learnContentArray?.map((type) => {
      return {
        value: type,
        name: type,
      };
    }));

    setDifficultyOptions(difficultyArray?.map((type) => {
      return {
        value: type,
        name: t(difficultyMapper(type)),
      };
    }));

    setKnowledgeOptions(knowledgeArray?.map((item) => {
      return {
        name: item,
        value: item,
      };
    }));

    return questionArray;
  };

  const FormateFilterQuesType = (arr) => {
  };
  const onTabPickListChange = (key, value, innerKey) => {
    let nextData = { ...filterCondition };
    if (value.length === 0) {
      delete nextData[key][innerKey];
    } else {
      nextData = {
        ...nextData,
        [key]: { ...nextData[key], [innerKey]: value },
      };
    }
    setFilterCondition({
      ...nextData,
    });
  };

  const pickListChange = (key, value) => {
    setFilterCondition({
      ...filterCondition,
      [key]: value,
    });
  };
  const clearFilter = (code) => {
    if (code in filterCondition) {
      const nextData = filterCondition;

      delete nextData[code];
      setFilterCondition({ ...nextData });
    }
  };

  const onTabPickListDropdownClear = (key, value, innerKey) => {
    let nextData = { ...filterCondition };
    delete nextData[key];
    setFilterCondition({
      ...nextData
    });
  };

  const renderFilterBar = (item) => {
    const { code, map, name } = item;
    if (map.length <= 0) return null;
    switch (code) {
      case 'QUES_TYPE':
      case 'SOURCE':
        return (
          <PickListDropdown.TabPickListDropdown
            defaultTitle={name}
            options={FormateFilterQuesType(map) || []}
            onChange={(val, innerKey) => {
              onTabPickListChange(code, val, innerKey);
            }}
            onCheckAll={(val, innerKey) => {
              onTabPickListChange(code, val, innerKey);
            }}
            onClear={(val, innerKey) => {
              onTabPickListDropdownClear(code, val, innerKey);
            }}
            checkedList={filterCondition[code] || []}
          />
        );
      case 'DIFFICULTY':
        return (
          <PickListDropdown.SinglePickListDropdown
            defaultTitle={name}
            clearable={true}
            options={FormateFilterQuesType(map.filter(item => item.code !== 'BASIC' && item.code !== 'ADVANCED')) || []}
            onClick={(value) => pickListChange(code, value)}
            onPickListClear={() => {
              clearFilter(code);
            }}
            value={filterCondition[code] || null}
          />
        );
      default:
        return (
          <PickListDropdown.SinglePickListDropdown
            defaultTitle={name}
            clearable={true}
            options={FormateFilterQuesType(map) || []}
            onClick={(value) => pickListChange(code, value)}
            onPickListClear={() => {
              clearFilter(code);
            }}
            value={filterCondition[code] || null}
          />
        );
    }
  };

  const getParentChapter = (data) => {
    if (!data || data.length <= 0) return [];
    return data.filter(item => item.parentCode === null);
  };
  const getChildChapter = (data) => {
    if (!data || data.length <= 0) return [];
    return data.filter(item => item.parentCode !== null);
  };

  const onAllAreaClick = () => {
    setNowFilterChapter('all');
    let nextData = filterCondition;
    delete filterCondition.chapter;
    setFilterCondition({
      ...filterCondition,
      ...nextData,
    });
    setPageState({
      ...pageState,
      currentPage: 1,
    });
  };
  const onBookChapterClick = (data) => {
    GA.clickRangeFilterChapter();
    const nextData = data.chapters;
    setFilterCondition({
      ...filterCondition,
      chapter: nextData
    });
    setNowFilterChapter(data.bookID);
    setPageState({
      ...pageState,
      currentPage: 1,
    });
  };

  const getRelatedChapters = (chapters, targetCode, isLastHierarchy = false, parentCode = null,) => {
    if (isLastHierarchy) {
      return chapters
        .filter(chapter => chapter.code === targetCode)
        .filter(chapter => parentCode ? chapter.parentCode === parentCode : true);
    } else {
      const matchedChapters = chapters
        .filter(chapter => chapter.parentCode === targetCode);
      return matchedChapters.concat(
        matchedChapters
          .map(chapter => chapter.code)
          .map(code_ => getRelatedChapters(chapters, code_)).flat()
      );
    }
  };

  const onChapterClick = (chapters, targetChapter, isLastHierarchy, parentChapter,) => {
    GA.clickRangeFilterChapter();
    setFilterCondition({
      ...filterCondition,
      chapter: getRelatedChapters(chapters, targetChapter.code, isLastHierarchy, parentChapter?.code,)
    });
    setNowFilterChapter(targetChapter);
    setPageState({
      ...pageState,
      currentPage: 1,
    });
  };

  const scoreInputChange = (quesType, inputKey, e) => {
    if (!new RegExp(/^(\d{0,3})(\.\d{0,1})?$/g).test(e.target.value)) return;
    const value = e.target.value;
    let allScore = 0;
    if (inputKey === 'perQues') {
      allScore = tableData[quesType].inputValue.question ? (value * tableData[quesType].inputValue.question).toFixed(1) : 0;
    } else {
      allScore = tableData[quesType].inputValue.answer ? (value * tableData[quesType].inputValue.answer).toFixed(1) : 0;
    }
    let nextScore = {
      ...defaultCountInputValue.score,
      [inputKey]: value,
      allScore
    };
    let nextData = {};
    nextData = {
      [quesType]: {
        ...tableData[quesType],
        inputValue: {
          ...tableData[quesType].inputValue,
          score: {
            ...nextScore
          }
        }
      }
    };
    setTableData({
      ...tableData,
      ...nextData
    });
  };

  const formateDifficulty = (diff) => {
    let result = '';
    Object.entries(filterDifficulty).forEach(([key, value]) => {
      if (value.includes(diff)) {
        result = key;
      }
    });
    return result;
  };

  const selectedQuesForEditCopy = (Array) => {
    if (!Array) return;
    let selectQuestions = [];
    let setting = {};

    Array.forEach((item, index) => {

      let totalAnswer = 0;

      if (!item?.questionList) return;
      const questionArray = item.questionList.map((ques) => {
        totalAnswer += ques?.answerAmount;
        return ques?.id;
      });

      const newArray = selectQuestions.concat(questionArray);
      selectQuestions = newArray;

      if (!item?.typeName | !item?.scoreType) return;
      let data = { ...setting };
      data = {
        ...data,
        [item?.typeName]: {
          scoreType: item?.scoreType,
          score: item?.scoreType === 'PerAnswer' ? Number(item?.score / totalAnswer) : Number(item?.score / item?.questionList.length) || 0,
        }
      };
      setting = data;
    });

    let nextData = { ...tableData };
    selectQuestions.forEach((id) => {
      Object.entries(nextData).forEach(([key, value]) => {
        const found = value.question.find(ele => ele.uid === id);
        if (found) {
          const scoreType = setting[found?.quesTypeName]?.scoreType === 'PerAnswer' ? 'perAns' : 'perQues';
          const score = setting[found?.quesTypeName]?.score && (setting[found?.quesTypeName]?.score).toFixed(1);

          const newDiff = formateDifficulty(found.difficulty);
          if (nextData[found.quesTypeName]?.pickUpQuestions) {//已有題組
            nextData[found.quesTypeName].pickUpQuestions[newDiff].push(found);
            nextData[found.quesTypeName].inputValue = {
              ...nextData[found.quesTypeName].inputValue,
              answer: nextData[found.quesTypeName].inputValue.answer + found.answerAmount,
              difficulty: {
                ...nextData[found.quesTypeName].inputValue.difficulty,
                [newDiff]: nextData[found.quesTypeName].inputValue.difficulty[newDiff] + 1
              },
              question: nextData[found.quesTypeName].inputValue.question + 1,
              score: {
                ...nextData[found.quesTypeName].inputValue.score,
                allScore:
                  (scoreType === 'perQues') ? (score * (nextData[found.quesTypeName].inputValue.question + 1)) && (score * (nextData[found.quesTypeName].inputValue.question + 1)).toFixed(1) :
                    (score * (nextData[found.quesTypeName].inputValue.answer + found.answerAmount)) && (score * (nextData[found.quesTypeName].inputValue.answer + found.answerAmount)).toFixed(1),
                [scoreType]: score,
              }
            };
          } else {
            const quesType = found.quesTypeName;
            //編輯時因為沒有第2步，沒有全部題組，取編輯前的題組
            const questionGroup = examPaperInfo?.questionGroup || tableData;
            let pickUpQuestions = {
              [DIFFICULTY[0]]: [],
              [DIFFICULTY[1]]: [],
              [DIFFICULTY[2]]: [],
            };
            let newQuestionGroup = {
              [quesType]: {
                ...questionGroup[quesType],
                pickUpQuestions,
                inputValue: defaultCountInputValue
              }
            };
            newQuestionGroup[quesType].pickUpQuestions[newDiff].push(found);
            newQuestionGroup[quesType].inputValue = {
              ...newQuestionGroup[quesType].inputValue,
              answer: parseInt(found?.answerAmount),
              difficulty: {
                ...newQuestionGroup[quesType].inputValue.difficulty,
                [newDiff]: 1
              },
              question: 1,
              score: {
                ...newQuestionGroup[quesType].inputValue.score,
                allScore: (score * ((scoreType === 'perQues') ? 1 : found?.answerAmount)) && (score * ((scoreType === 'perQues') ? 1 : found?.answerAmount)).toFixed(1),
                [scoreType]: score,
              }
            };
            nextData = {
              ...nextData,
              ...newQuestionGroup,
            };
          }
        }
      });
    });
    setTableData({
      ...nextData
    });
    setSelected(selectQuestions);
  };

  const onQuestionCheck = async (e, id, type) => {
    let nextData = { ...tableData };
    if (type ? e : e.target.checked) {
      await Promise.all(
        Object.entries(tableData).map(async ([key, value]) => {
          nextData[key].inputValue = {
            ...nextData[key].inputValue,
          };
          await new Promise(function (resolve, reject) {
            const found = value.question.find(ele => ele.uid === id);
            if (found) {
              const newDiff = formateDifficulty(found.difficulty);
              if (tableData[found.quesTypeName]?.pickUpQuestions) {//已有題組
                nextData[found.quesTypeName].pickUpQuestions[newDiff].push(found);
                nextData[found.quesTypeName].inputValue = {
                  ...nextData[found.quesTypeName].inputValue,
                  answer: nextData[found.quesTypeName].inputValue.answer + found.answerAmount,
                  difficulty: {
                    ...nextData[found.quesTypeName].inputValue.difficulty,
                    [newDiff]: nextData[found.quesTypeName].inputValue.difficulty[newDiff] + 1
                  },
                  question: nextData[found.quesTypeName].inputValue.question + 1,
                  score: {
                    ...nextData[found.quesTypeName].inputValue.score,
                    allScore: nextData[found.quesTypeName].inputValue.score.perQues ? nextData[found.quesTypeName].inputValue.score.perQues * (nextData[found.quesTypeName].inputValue.question + 1) :
                      nextData[found.quesTypeName].inputValue.score.perAns * (nextData[found.quesTypeName].inputValue.answer + found.answerAmount)
                  }
                };
              } else {
                const quesType = found.quesTypeName;
                //編輯時因為沒有第2步，沒有全部題組，取編輯前的題組
                const questionGroup = examPaperInfo?.questionGroup || tableData;
                let pickUpQuestions = {
                  [DIFFICULTY[0]]: [],
                  [DIFFICULTY[1]]: [],
                  [DIFFICULTY[2]]: [],
                };
                let newQuestionGroup = {
                  [quesType]: {
                    ...questionGroup[quesType],
                    pickUpQuestions,
                    inputValue: defaultCountInputValue
                  }
                };
                newQuestionGroup[quesType].pickUpQuestions[newDiff].push(found);
                newQuestionGroup[quesType].inputValue = {
                  ...newQuestionGroup[quesType].inputValue,
                  answer: parseInt(found?.answerAmount),
                  difficulty: {
                    ...newQuestionGroup[quesType].inputValue.difficulty,
                    [newDiff]: 1
                  },
                  question: 1,
                  // score: {
                  //   ...newQuestionGroup[quesType].inputValue.score,
                  //   allScore: 0,
                  //   // newQuestionGroup[quesType].inputValue.score.perQues ? (newQuestionGroup[quesType].inputValue.score.perQues * 1).toFixed(1) :
                  //   // (newQuestionGroup[quesType].inputValue.score.perAns * newQuestionGroup[quesType].inputValue.answer).toFixed(1) ? parseInt(newQuestionGroup[quesType].inputValue.answer) : 0
                  // }
                };
                nextData = {
                  ...nextData,
                  ...newQuestionGroup,
                };
              }
            }
            resolve('done');
          });
        })
      ).then(() => {
        setTableData({
          ...nextData
        });
      });
    } else {
      await Promise.all(
        Object.entries(tableData).map(async ([key, value]) => {
          await new Promise(function (resolve, reject) {
            const found = value.question.find(ele => ele.uid === id);
            if (found) {
              const newDiff = formateDifficulty(found.difficulty);
              if (Object.keys(tableData).includes(found.quesTypeName)) {//已有題組
                const filterArray = [].concat(nextData[found.quesTypeName].pickUpQuestions[formateDifficulty(newDiff)]).filter((item) => {
                  return item?.uid !== id;
                });
                nextData[found.quesTypeName].pickUpQuestions[formateDifficulty(newDiff)] = filterArray;
                let pickUpQuestionsLength = 0;
                Object.entries(nextData[found.quesTypeName].pickUpQuestions).forEach(([key, value]) => {
                  pickUpQuestionsLength += value.length;
                });
                if (pickUpQuestionsLength === 0) {
                  delete nextData[found.quesTypeName].pickUpQuestions;
                }
                nextData[found.quesTypeName].inputValue = {
                  ...nextData[found.quesTypeName].inputValue,
                  answer: nextData[found.quesTypeName].inputValue.answer - found.answerAmount,
                  difficulty: {
                    ...nextData[found.quesTypeName].inputValue.difficulty,
                    [newDiff]: nextData[found.quesTypeName].inputValue.difficulty[newDiff] - 1
                  },
                  question: nextData[found.quesTypeName].inputValue.question - 1,
                  score: {
                    ...nextData[found.quesTypeName].inputValue.score,
                    allScore: nextData[found.quesTypeName].inputValue.score.perQues ? nextData[found.quesTypeName].inputValue.score.perQues * (nextData[found.quesTypeName].inputValue.question - 1) :
                      nextData[found.quesTypeName].inputValue.score.perAns * (nextData[found.quesTypeName].inputValue.answer - found.answerAmount)
                  }
                };
              }
            }
            resolve('done');
          });
        })
      ).then(() => {
        setTableData({
          ...nextData
        });
      });
    }
  };

  const onSearch = (val) => {
    GA.setQuestionsFilter('search');
    setFilterCondition({
      ...filterCondition,
      name: val
    });
  };

  const pageChange = (currentPage, pageSize) => {
    setPageState({
      ...pageState,
      currentPage,
      limit: pageSize,
    });
    goTop();
  };

  const onQuestionDisplayTypeChange = (value) => {
    GA.selectedQuestionFilter(value);
    setDisplayQuestionsType(value);
  };

  const findQuestionsFromDisplayType = (type, data, checkedList) => {
    let result = [];

    switch (type) {
      case 'notCheck':
        result = result.concat(data.filter(item => !checkedList.includes(item.uid)));
        break;
      case 'checked':
        result = result.concat(data.filter(item => checkedList.includes(item.uid)));
        break;
      case 'all':
      default:
        result = data;
        break;
    }
    return result;
  };

  const showQuestionDetail = (src) => {
    setModalImageSrc(src);
    setModalVisibleToggle();
  };

  const allQuestionID = useMemo(() => {
    if (!questions) return [];
    return questions.map(item => item.uid);
  }, [questions]);

  const pickUpQuestionID = useMemo(() => {
    if (!tableData) return [];
    let nextData = [];
    Object.entries(tableData).forEach(([key, value]) => {
      if (value?.pickUpQuestions) {
        Object.entries(value.pickUpQuestions).forEach(([quesKey, quesValue]) => {
          quesValue.forEach(item => {
            nextData = nextData.concat([item.uid]);
          });
        });
      }
    });
    return nextData;
  }, [tableData]);

  const { renderQuestionAnalysisModal, doShowAnalysisModal } = useQuestionAnalysisModal(pickUpQuestionID, searchParams);

  const {
    selected,
    setSelected,
    isSelected,
    toggle,
  } = useSelections(allQuestionID, pickUpQuestionID);

  const filterQuestionData = useMemo(() => {
    if (!questions) return [];
    const filterQuestions = findQuestionsFromDisplayType(displayQuestionsType, questions, selected);
    let nextData = filterQuestions
      .filter((ques) => {
        if (!filterCondition?.name) return true;
        return search(filterCondition?.name, ques?.content);
      })
      ?.filter((ques) => {
        if (!filterCondition['DIFFICULTY']) return true;
        return filterCondition['DIFFICULTY'] === ques?.metaData?.difficulty?.name;
      })
      ?.filter((ques) => {
        if (!filterCondition['LEARN_CONTENT']) return true;
        return filterCondition['LEARN_CONTENT'] === ques?.metaData?.learnContent?.name;
      })
      ?.filter((ques) => {
        if (!filterCondition['KNOWLEDGE']) return true;
        return ques?.metaData?.knowledges.filter((knowledge) => {
          return filterCondition['KNOWLEDGE'] === knowledge?.name;
        }).length > 0;
      })
      ?.filter((ques) => {
        if (!filterCondition['QUES_TYPE'] || JSON?.stringify(filterCondition['QUES_TYPE']) === '{}') return true;
        return filterCondition['QUES_TYPE'][ques?.questionGroup] && filterCondition['QUES_TYPE'][ques?.questionGroup]?.includes(ques?.metaData?.questionType?.name);
      })
      ?.filter((ques) => {
        if (!filterCondition['SOURCE'] || JSON?.stringify(filterCondition['SOURCE']) === '{}') return true;
        return filterCondition['SOURCE'][ques?.metaData?.source?.name] && filterCondition['SOURCE'][ques?.metaData?.source?.name]?.includes(ques?.metaData?.subSource?.name);
      })
      ?.filter((ques) => {
        if (!filterCondition['chapter']) return true;
        return filterCondition['chapter']?.filter((chapter) => {
          return chapter?.knowledgeList && chapter?.knowledgeList?.length > 0 &&
            chapter?.knowledgeList.filter((item) => {
              return ques?.metaData?.knowledges.filter((knowledge) => {
                return item?.itemCode === knowledge?.code;
              }).length > 0;
            }).length > 0;
        }).length > 0;
      });

    return nextData;
  }, [filterCondition, questions, displayQuestionsType, selected]);

  const chapterCheckCountList = useMemo(() => {
    if (!selected || !questions) return [];
    const chapterData = questions.filter(item => selected.includes(item.uid)).map(data => {
      return data.metaData.knowledges;
    });
    return chapterData;
  }, [selected, questions, bookChapters]);

  const getCount = (chapter, allChapters = []) => {
    const countIntersection = (chapterKnowledgeCodes, checkedCodes) => {
      let count = 0;
      for (const chapterKnowledgeCode of chapterKnowledgeCodes) {
        for (const checkedCode of checkedCodes) {
          if (chapterKnowledgeCode === checkedCode) {
            count++;
          }
        }
      }
      return count;
    };
    const checkedCodes = chapterCheckCountList.flat().map(knowledge => knowledge.code);
    let count = 0;
    if (chapter.bookID) {
      const { chapters } = chapter;
      chapters.forEach(chapter => {
        count += countIntersection(chapter.knowledgeList.map(knowledge => knowledge.itemCode), checkedCodes);
      });
    } else if (chapter?.knowledgeList?.length) {
      const chapterKnowledgeCodes = chapter.knowledgeList.map(knowledge => knowledge.itemCode);
      count += countIntersection(chapterKnowledgeCodes, checkedCodes);
    } else {
      const getChildChapters = (chapters, targetChapter) => {
        const matchedChapters = chapters.filter(chapter_ => chapter_.parentCode === targetChapter.code);
        return matchedChapters.concat(
          matchedChapters.map(chapter => getChildChapters(chapters, chapter)).flat()
        );
      };
      const chapterKnowledgeCodes = getChildChapters(allChapters, chapter)
        .map(chapter => chapter.knowledgeList)
        .flat()
        .map(knowledge => knowledge.itemCode);
      count += countIntersection(chapterKnowledgeCodes, checkedCodes);
    }
    return count;
  };

  useEffect(() => {
    if (isEditCopy) {
      if (!reCreateSearchParams) return;
      sendPostGetQuestion(FETCH_DATA_EVENT.FETCH, {
        payload: {
          eduSubject: reCreateSearchParams?.eduSubject,
          paperConfig: reCreateSearchParams?.paperConfig,
          books: reCreateSearchParams?.bookIDs,
          knowledges: reCreateSearchParams?.knowledges,
          sources: reCreateSearchParams?.sources,
          curriculum: reCreateSearchParams?.curriculum,
          year: reCreateSearchParams?.year,
          version: reCreateSearchParams?.version,
        }
      });
    } else {
      if (!searchParams) return;
      sendPostGetQuestion(FETCH_DATA_EVENT.FETCH, {
        payload: searchParams,
      });
    }
  }, [searchParams, reCreateSearchParams]);

  useEffect(() => {
    getSaveData('tableData', tableData);
    const haveDataKey = Object.entries(tableData).filter(([key, value]) => value.pickUpQuestions).map(item => item[0]);
    let nextData = [];
    if (prevKeyList.length < haveDataKey.length) {//4->3
      const newKey = haveDataKey.filter(item => !prevKeyList.includes(item));
      nextData = prevKeyList.concat(newKey);
    } else {
      nextData = prevKeyList.filter(item => haveDataKey.includes(item));
    }
    setKeyList(nextData);
  }, [tableData]);

  useEffect(() => {
    getKeyList(keyList);
  }, [keyList]);

  useEffect(() => {
    if (!questions) return;
    getQuestions(questions);
  }, [questions]);

  // ---- 動作 - 問題回報  ----

  // 問題回報彈窗 顯示控制
  const [reportModalVisible, { toggle: setReportModalToggle, setFalse: setReportModalFalse }] = useBoolean(false);

  const showReportModal = (itemUID) => {
    setReportModalData({
      ...reportModalData,
      education: eduSubjectData.edu,
      subject: eduSubjectData.subject,
      uid: itemUID,
    });
    if (reportModalVisible === false) sendGetReportModalSelectOptionsState(FETCH_DATA_EVENT.FETCH);
    setReportModalToggle();
  };
  //  試題回報內容
  const [reportModalData, setReportModalData] = useState({
    education: '',
    subject: '',
    uid: '',
    errorType: '',
    description: '',
  });

  // 取得錯題回報相關選單
  const [getReportModalSelectOptionsState, sendGetReportModalSelectOptionsState] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context) => {
        const res = await getAnomalySelectOption();
        const { data, isSuccess, systemCode, message } = res;
        return {
          reportModalSelectOptions: setSelectOptions(data) || {}
          , isSuccess, systemCode, message
        };
      },
    },
  });
  const { reportModalSelectOptions } = getReportModalSelectOptionsState.context.result || {};
  // 更改回報類型
  const onSelectChange = (value) => {
    setReportModalData({
      ...reportModalData,
      errorType: value,
    });
  };
  // 更改回報內容
  const onTextAreaChange = (e) => {
    setReportModalData({
      ...reportModalData,
      description: e.target.value,
    });
  };

  // 試題回報API
  const [steatePostQuestionReport, sendPostQuestionReport] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        const res = await postQuestionReport(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) {
          openNotificationWithIcon('success', t('containers.homeQuestionSelectContent.__reportSuccess'));
        } else {
          openNotificationWithIcon('error', message);
        }
        return {
          isSuccess, systemCode, message
        };
      },
    },
  });

  // 確定回報
  const doReport = () => {
    sendPostQuestionReport(FETCH_DATA_EVENT.FETCH, {
      payload: {
        questionUID: reportModalData.uid,
        errorType: reportModalData.errorType,
        description: reportModalData.description,
        eduSubject: reportModalData.education + reportModalData.subject,
      }
    });
    setReportModalToggle();
  };

  // 關閉彈窗時 清除彈窗內資料
  useEffect(() => {
    if (reportModalVisible === false) {
      setReportModalData({
        education: '',
        subject: '',
        uid: '',
        errorType: '',
        description: '',
      });
    }
  }, [reportModalVisible]);

  /// ---- 手機版 相關 ----

  const [questionDetailDrawerVisible, { toggle: questionDetailDrawerToggle, setFalse: questionDetailDrawerFalse }] = useBoolean(false);
  const [setupDrawerVisible, { toggle: setupDrawerToggle, setFalse: setupDrawerFalse }] = useBoolean(false);
  const [filterDrawerVisible, { toggle: filterDrawerToggle, setFalse: filterDrawerFalse }] = useBoolean(false);
  const [reportDrawerVisible, { toggle: reportDrawerToggle, setFalse: reportDrawerFalse }] = useBoolean(false);
  const [filterSelectDrawerVisible, { toggle: filterSelectDrawerToggle, setFalse: filterSelectDrawerFalse }] = useBoolean(false);

  const [filterConditionMobile, setFilterConditionMobile] = useState({});

  const [filterSelectedTitle_QUES_TYPE, setFilterSelectedTitle_QUES_TYPE] = useState('');
  const [filterSelectedTitle_SOURCE, setFilterSelectedTitle_SOURCE] = useState('');

  useEffect(() => {
    if (!filterConditionMobile) return null;
    if (filterConditionMobile['QUES_TYPE']) {
      if (JSON.stringify(filterConditionMobile['QUES_TYPE']) !== '{}') {
        let selectArray = [];
        Object.values(filterConditionMobile['QUES_TYPE']).forEach((items) => {
          items.forEach((item) => {
            selectArray.push(item);
          });
        });
        let titleArray = [];
        transformTypeArray(typeOptions).forEach((type) => {
          if (selectArray.indexOf(type.value) !== -1) titleArray.push(type.name);
        });
        setFilterSelectedTitle_QUES_TYPE(titleArray);
      } else {
        setFilterSelectedTitle_QUES_TYPE([]);
      }
    } else {
      setFilterSelectedTitle_QUES_TYPE([]);
    }
    if (filterConditionMobile['SOURCE']) {
      if (JSON.stringify(filterConditionMobile['SOURCE']) !== '{}') {
        let selectArray = [];
        Object.values(filterConditionMobile['SOURCE']).forEach((items) => {
          items.forEach((item) => {
            selectArray.push(item);
          });
        });
        let titleArray = [];
        transformTypeArray(sourceOptions).forEach((type) => {
          if (selectArray.indexOf(type.value) !== -1) titleArray.push(type.name);
        });
        setFilterSelectedTitle_SOURCE(titleArray);
      } else {
        setFilterSelectedTitle_SOURCE([]);
      }
    } else {
      setFilterSelectedTitle_SOURCE([]);
    }
  }, [filterConditionMobile]);

  const [checkedFilterChangeBtn, setCheckedFilterChangeBtn] = useState(false);

  // 錯誤回報 抽屜
  const showReportDrawer = (uid) => {
    setReportModalData({
      ...reportModalData,
      education: eduSubjectData.edu,
      subject: eduSubjectData.subject,
      uid,
    });
    if (reportModalVisible === false) sendGetReportModalSelectOptionsState(FETCH_DATA_EVENT.FETCH);
    reportDrawerToggle();
  };

  const onReportTypeChange = e => {
    setReportModalData({
      ...reportModalData,
      errorType: e.target.value,
    });
  };

  const doMobileReport = () => {
    sendPostQuestionReport(FETCH_DATA_EVENT.FETCH, {
      payload: {
        questionUID: reportModalData.uid,
        errorType: reportModalData.errorType,
        description: reportModalData.description,
        eduSubject: reportModalData.education + reportModalData.subject,
      }
    });
    reportDrawerFalse();
  };

  // 關閉彈窗時 清除彈窗內資料
  useEffect(() => {
    if (reportDrawerVisible === false) {
      setReportModalData({
        education: '',
        subject: '',
        uid: '',
        errorType: '',
        description: '',
      });
    }
  }, [reportDrawerVisible]);

  // ---- 單一題目詳情頁面 -----
  const [questionDetailData, setQuestionDetailData] = useState({});
  const [detailIndex, setDetailIndex] = useState(0);

  // 上一題 下一題 控制
  const preQues = () => {
    setDetailIndex(detailIndex - 1);
    setQuestionDetailData(filterQuestionData[detailIndex - 1]);
  };

  const nextQues = () => {
    setDetailIndex(detailIndex + 1);
    setQuestionDetailData(filterQuestionData[detailIndex + 1]);
  };

  const isShowPreQuesBtn = detailIndex === 0 ? false : true;
  const isShowNextQuesBtn = detailIndex === (filterQuestionData.length - 1) ? false : true;

  // 關閉 背景滑動
  useEffect(() => {
    document.body.classList.remove('mobileScrollingStop');
    if (!deviceData.isDesktop) {
      if (setupDrawerVisible | questionDetailDrawerVisible | filterDrawerVisible | reportDrawerVisible) {
        document.body.classList.add('mobileScrollingStop');
      }
    }
  }, [setupDrawerVisible, questionDetailDrawerVisible, filterDrawerVisible, reportDrawerVisible]);

  const mobileShowQuestionDetail = (item, index) => {
    setQuestionDetailData(item);
    setDetailIndex(index);
    questionDetailDrawerToggle();
  };

  //  判斷左右滑動事件
  const { onTouchStart, onTouchEnd } = useTouch({
    leftToRight: () => {
      if (detailIndex !== 0) setTimeout(preQues(), 100);
    },
    rightToLeft: () => {
      if (detailIndex !== (filterQuestionData.length - 1)) setTimeout(nextQues(), 100);
    },
    XGap: 50,
  });

  // ---- 題數與配分 抽屜 ----
  const [setupDrawerData, setSetupDrawerData] = useState(tableData);

  const totalSetupQuesTypeData = useMemo(() => {
    let nextData = {
      score: 0,
      questionCount: 0
    };
    if (!setupDrawerData) return nextData;

    let score = 0;
    let questionCount = 0;
    let totalAnswer = 0;
    Object.entries(setupDrawerData).forEach(([key, value]) => {
      score += Number(value.inputValue.score.allScore) || 0;
      questionCount += value.inputValue.question ? parseInt(value.inputValue.question) : 0;
      totalAnswer += Number(value?.inputValue?.answer) || 0;
    });
    nextData = {
      score: numberToFirst(score),
      questionCount: parseInt(questionCount) || 0, // 總題數。
      totalAnswer: Number(totalAnswer) || 0, // 總答數。
    };
    return nextData;
  }, [setupDrawerData]);

  const setupDrawerScoreInputChange = (quesType, inputKey, e) => {
    if (!new RegExp(/^(\d{0,3})(\.\d{0,1})?$/g).test(e.target.value)) return;
    const value = e.target.value;
    let allScore = 0;
    if (inputKey === 'perQues') {
      allScore = setupDrawerData[quesType].inputValue.question ? (value * setupDrawerData[quesType].inputValue.question).toFixed(1) : 0;
    } else {
      allScore = setupDrawerData[quesType].inputValue.answer ? (value * setupDrawerData[quesType].inputValue.answer).toFixed(1) : 0;
    }
    let nextScore = {
      ...defaultCountInputValue.score,
      [inputKey]: value,
      allScore
    };
    let nextData = {};
    nextData = {
      [quesType]: {
        ...setupDrawerData[quesType],
        inputValue: {
          ...setupDrawerData[quesType].inputValue,
          score: {
            ...nextScore
          }
        }
      }
    };
    setSetupDrawerData({
      ...setupDrawerData,
      ...nextData
    });
  };

  useEffect(() => {
    if (setupDrawerVisible) setSetupDrawerData(tableData);
  }, [setupDrawerVisible]);

  const setupDrawerDoneBtnDisabled = tableData === setupDrawerData ? true : false;

  const setupDrawerDone = () => {
    setTableData(setupDrawerData);
    setupDrawerFalse();
  };

  // 篩選頁面
  const doShowFilterDrawer = () => {
    filterDrawerToggle();
    setFilterConditionMobile(filterCondition);
    // eslint-disable-next-line no-prototype-builtins
    if (filterCondition.hasOwnProperty('chapter')) {
      setCheckboxTreeData(objectArrayToJson(filterCondition['chapter']));
    } else {
      setCheckboxTreeData([]);
    }
    setDisplayQuestionsTypeMobile(displayQuestionsType);
    setCheckedFilterChangeBtn(false);
  };

  const transformRadio = (array) => {
    if (!array) return [];
    return array.map((item) => {
      return {
        name: item.name,
        code: item.value,
      };
    });
  };

  const goTop = () => {
    const main = document.getElementById('mainLayout');
    main.scrollTop = 0;
  };

  const clearAllFilter = () => {
    if (Object.keys(filterConditionMobile).length > 1 | displayQuestionsTypeMobile !== 'all') {
      setFilterConditionMobile({
        name: '',
      });
      setCheckboxTreeData([]);
      setDisplayQuestionsTypeMobile('all');
      setCheckedFilterChangeBtn(true);
    }
  };

  const [mobileFilterDrawerType, setMobileFilterDrawerType] = useState('');

  const activeClearBtn = useMemo(() => {
    if (filterConditionMobile) {
      switch (mobileFilterDrawerType) {
        case 'QUES_TYPE':
          if (JSON.stringify(filterConditionMobile[mobileFilterDrawerType]) !== '{}' && filterConditionMobile[mobileFilterDrawerType] !== undefined) {
            return true;
          } else {
            return false;
          }
        case 'SOURCE':
          if (JSON.stringify(filterConditionMobile[mobileFilterDrawerType]) !== '{}' && filterConditionMobile[mobileFilterDrawerType] !== undefined) {
            return true;
          } else {
            return false;
          }
        case 'chapter':
          if (filterConditionMobile[mobileFilterDrawerType]?.length > 0) {
            return true;
          } else {
            return false;
          }
        default:
          if (filterConditionMobile[mobileFilterDrawerType]) {
            return true;
          } else {
            return false;
          }
      }
    }
  }, [filterConditionMobile, mobileFilterDrawerType]);

  const mobileFilterDrawerTypeTitle = useMemo(() => {
    switch (mobileFilterDrawerType) {
      case 'QUES_TYPE':
        return <div>{t('containers.homeQuestionSelectContent.__questionType')}<div className={classNames('clearKeyBtn', activeClearBtn ? 'active' : '')} onClick={() => deleteFilterKeyMobile('QUES_TYPE')}>{t('containers.homeQuestionSelectContent.__clear')}</div></div>;
      case 'SOURCE':
        return <div>{t('containers.homeQuestionSelectContent.__source')}<div className={classNames('clearKeyBtn', activeClearBtn ? 'active' : '')} onClick={() => deleteFilterKeyMobile('SOURCE')}>{t('containers.homeQuestionSelectContent.__clear')}</div></div>;
      case 'DIFFICULTY':
        return <div>{t('containers.homeQuestionSelectContent.__difficulty')}<div className={classNames('clearKeyBtn', activeClearBtn ? 'active' : '')} onClick={() => deleteFilterKeyMobile('DIFFICULTY')}>{t('containers.homeQuestionSelectContent.__clear')}</div></div>;
      case 'chapter':
        return <div>{t('containers.homeQuestionSelectContent.__range')}<div className={classNames('clearKeyBtn', activeClearBtn ? 'active' : '')} onClick={() => { deleteFilterKeyMobile('chapter'); setCheckboxTreeData([]); }}>{t('containers.homeQuestionSelectContent.__clear')}</div></div>;
      case 'KNOWLEDGE':
        return <div>{t('containers.homeQuestionSelectContent.__knowledgeDimension')}<div className={classNames('clearKeyBtn', activeClearBtn ? 'active' : '')} onClick={() => deleteFilterKeyMobile('KNOWLEDGE')}>{t('containers.homeQuestionSelectContent.__clear')}</div></div>;
      case 'LEARN_CONTENT':
        return <div>{t('containers.homeQuestionSelectContent.__learningContent')}<div className={classNames('clearKeyBtn', activeClearBtn ? 'active' : '')} onClick={() => deleteFilterKeyMobile('LEARN_CONTENT')}>{t('containers.homeQuestionSelectContent.__clear')}</div></div>;
      default:
        return '';
    }
  }, [mobileFilterDrawerType, filterConditionMobile]);

  const showFilterSelectDrawer = (type) => {
    setMobileFilterDrawerType(type);
    filterSelectDrawerToggle();
  };

  const onTreeChange = ({ valueArray, type }) => {
    if (valueArray.length > 0) {
      const nextData = {
        ...filterConditionMobile,
        [mobileFilterDrawerType]: {
          ...filterConditionMobile[mobileFilterDrawerType],
          [type]: valueArray
        }
      };
      setFilterConditionMobile(nextData);
    } else {
      if (mobileFilterDrawerType in filterConditionMobile) {
        if (type in filterConditionMobile[mobileFilterDrawerType]) {
          let nextData = filterConditionMobile;
          delete nextData[mobileFilterDrawerType][type];
          setFilterConditionMobile({ ...nextData });
        }
      }
    }
    setCheckedFilterChangeBtn(true);
  };

  const transformToTreeData = (Array) => {
    if (!Array) return [];
    return Array.map((quesTypeArray) => {
      return {
        label: quesTypeArray.name,
        value: quesTypeArray.value,
        children: quesTypeArray.subMap.map((item) => {
          return {
            label: item.name,
            value: item.value,
          };
        })
      };
    });
  };

  const [displayQuestionsTypeMobile, setDisplayQuestionsTypeMobile] = useState(isEditCopy ? 'all' : 'checked');


  const onQuestionDisplayTypeMobileChange = (val) => {
    setDisplayQuestionsTypeMobile(val);
    setCheckedFilterChangeBtn(true);
  };

  const reCheckedList = (type, subType) => {
    // eslint-disable-next-line no-prototype-builtins
    if (filterConditionMobile.hasOwnProperty(type) && filterConditionMobile[type].hasOwnProperty(subType)) {
      return filterConditionMobile[type][subType];
    } else {
      return [];
    }
  };

  const checkboxTreeOptions = useMemo(() => {
    if (bookChapters) {
      // const haveChapterData = bookChapters[0]?.chapters[bookChapters[0]?.chapters.length - 1]?.parentCode;
      return bookChapters.map((firItem) => {
        let parentChapters = [];
        let childrenChapters = [];
        const makeChildren = () => {
          firItem.chapters.forEach((item) => {

            if (item.parentCode === null) {
              parentChapters.push({
                ...item,
                value: JSON.stringify(item),
              });
            } else {
              childrenChapters.push({
                ...item,
                label: item.desc,
                value: JSON.stringify(item),
              });
            }
          });

          return parentChapters.map((parentItem) => {
            if (haveChapterData) {
              let name = t('containers.homeQuestionSelectContent.__chapterData', { code: parentItem.code, name: parentItem.name });
            } else {
              let name = t('containers.homeQuestionSelectContent.__classData', { code: parentItem.code, name: parentItem.name });
              if (firItem.bookID.includes(HISTORIC_EXAM_ID)) {
                name = parentItem.name;
              }

              return ({
                ...parentItem,
                label: name,
                children: childrenChapters.filter((childrenItem) =>
                  childrenItem.parentCode === parentItem.code
                )
              });
            }
          });
        };
        return {
          ...firItem,
          label: firItem.bookDesc,
          value: firItem.bookID,
          children: makeChildren()
        };
      });
    }
  }, [bookChapters]);

  const [checkboxTreeData, setCheckboxTreeData] = useState([]);

  const jsonArrayToObject = (array) => {
    return array.map((item) => {
      return JSON.parse(item);
    });
  };

  const objectArrayToJson = (array) => {
    return array.map((item) => {
      return JSON.stringify(item);
    });
  };

  const [mobileFilterChapterTitle, setMobileFilterChapterTitle] = useState('');

  const onCheckboxTreeChange = ({ callBackArray, name }) => {
    setCheckboxTreeData(callBackArray);
    setFilterConditionMobile({
      ...filterConditionMobile,
      chapter: jsonArrayToObject(callBackArray)
    });
    setMobileFilterChapterTitle(name);
    setCheckedFilterChangeBtn(true);
  };

  const onRadioChange = (type, e) => {
    setFilterConditionMobile({
      ...filterConditionMobile,
      [type]: e.target.value,
    });
    setCheckedFilterChangeBtn(true);
  };

  const deleteFilterKeyMobile = (string) => {
    let data = { ...filterConditionMobile };
    delete data[string];
    setFilterConditionMobile(data);
    setCheckedFilterChangeBtn(true);
  };

  const doneFilterDrawerChange = () => {
    setFilterCondition(filterConditionMobile);
    setDisplayQuestionsType(displayQuestionsTypeMobile);
    GA.selectedQuestionFilter(displayQuestionsTypeMobile);
    const selectedFilter =
      Object.entries(filterConditionMobile)
        .filter(([_, value]) => value !== '')
        .map(([key, _]) => key);
    for (const type of selectedFilter) {
      GA.setQuestionsFilter(type);
    }
    filterDrawerFalse();
    setPageState({
      ...pageState,
      currentPage: 1,
    });
  };

  const transformTypeArray = (data) => {
    // if (!filter | !type) return '';
    if (!data) return [];
    let nameArray = [];
    data.forEach((typeItem) => {
      typeItem.subMap.forEach((item) => {
        nameArray.push(item);
      });
    });
    return nameArray;
  };

  // 篩選內容顯示 長度判斷 超過就換成...
  const filterSelectedTitleArray = (filterArray) => {
    if (!filterArray) return '';
    if (clientWidth) {
      let useWidth = 0;
      let joinString = filterArray.join('、');

      const filterTitleBoxWidth = clientWidth - 200;

      const chineseString = new RegExp('[\u4E00-\u9FA5]+');
      const englishString = new RegExp('[A-Za-z]+');
      const numberString = new RegExp('[0-9]+');
      let showString = '';
      const stringLength = joinString.length;
      let index = 0;
      let addText = false;
      for (let i = 0; i <= stringLength; i++) {
        index = i;
        if (useWidth < filterTitleBoxWidth) {
          if (chineseString.test(joinString.charAt(i))) {
            useWidth += 14;
          } else if (englishString.test(joinString.charAt(i))) {
            useWidth += 6.5;
          } else if (numberString.test(joinString.charAt(i))) {
            useWidth += 6.5;
          } else {
            useWidth += 14;
          }
        } else {
          addText = true;
          break;
        }
      }
      showString = joinString.slice(0, index);
      if (addText) showString += '...';
      return showString;
    }
  };

  const filterSelectedTitle = (string) => {
    if (!string) return '';
    if (clientWidth) {
      let useWidth = 0;

      const filterTitleBoxWidth = clientWidth - 200;

      const chineseString = new RegExp('[\u4E00-\u9FA5]+');
      const englishString = new RegExp('[A-Za-z]+');
      const numberString = new RegExp('[0-9]+');

      let showString = '';
      const stringLength = string.length;
      let index = 0;
      let addText = false;
      for (let i = 0; i <= stringLength; i++) {
        index = i;
        if (useWidth < filterTitleBoxWidth) {
          if (chineseString.test(string.charAt(i))) {
            useWidth += 14;
          } else if (englishString.test(string.charAt(i))) {
            useWidth += 6.5;
          } else if (numberString.test(string.charAt(i))) {
            useWidth += 6.5;
          } else {
            useWidth += 14;
          }
        } else {
          addText = true;
          break;
        }
      }
      showString = string.slice(0, index);
      if (addText) showString += '...';
      return showString;
    }
  };

  // 動態新增 題型與配分Btn 到 header 上面
  const addSetupBtnClickEvent = () => {
    const setupBtn = document.getElementById('setupBtn');
    if (setupBtn) {
      setupBtn.addEventListener('click', function () {
        setupDrawerToggle();
      });
    }
  };

  // 難易度 前端隱藏顯示
  const difficultyHide = (item) => {
    if (item.code === 'ADVANCED' | item.code === 'BASIC') {
      return true;
    } else {
      return false;
    }
  };

  // 確認 是否有任何篩選條件
  const checkedFilter = useMemo(() => {
    let answer = false;
    if (filterCondition['QUES_TYPE'] || filterCondition['SOURCE'] || filterCondition['DIFFICULTY'] || filterCondition['KNOWLEDGE'] || filterCondition['LEARN_CONTENT'] || filterCondition['chapter']) {
      answer = true;
    }
    return answer;
  }, [filterCondition]);

  const clientWidth = useMemo(() => {
    return document.body.clientWidth;
  }, []);

  useEffect(() => {
    addSetupBtnClickEvent();
    goTop();
  }, []);

  // 在 chapters 裡面找出 chapter 的最子層
  const getLastChild = (chapter, chapters) => {
    if (chapter?.knowledgeList?.length) {
      return chapter;
    } else {
      const childChapter = chapters.find(chapter_ => chapter.code === chapter_.parentCode);
      return childChapter && getLastChild(childChapter, chapters);
    }
  };


  const renderChildChapter = (chapters, parentChapter) =>
    getChildChapter(chapters)
      .filter(child => child?.parentCode === parentChapter?.code)
      .map((childChapter) => {
        if (!getLastChild(childChapter, chapters)) {
          return '';
        }
        return <StyledAreaSelectList key={childChapter.desc} onClick={(e) => { e.stopPropagation(); onChapterClick(chapters, childChapter, childChapter.knowledgeList.length > 0, parentChapter,); }}>
          <StyledAreaSelectRow active={nowFilterChapter?.code === childChapter?.code && nowFilterChapter?.desc === childChapter?.desc}>
            <div>{childChapter.desc}</div>
            <div className="count count4">{getCount(childChapter, chapters)}</div>
          </StyledAreaSelectRow>
          {!chapters?.knowledgeList?.length && renderChildChapter(chapters, childChapter)}
        </StyledAreaSelectList>;
      }
      );

  return (
    isLoading ? renderLoadingContent()
      : <StyledHomeQuestionSelectContent className={className} data-testid="HomeQuestionSelectContent">
        {
          deviceData.isDesktop ?
            <>
              <Box mb={5} className='pageTitleBox'>
                <div className="pageTitle">
                  {t('containers.homeQuestionSelectContent.__selectedQuestionTypes')}
                </div>
                {
                  pickUpQuestionID.length > 0 && renderQuestionAnalysisModal()
                }
              </Box>
              <Box mb={11} pl={2}>
                <Table>
                  <Table.Header>
                    <Table.Row textcenter={true}>
                      <Table.Item flex={10}>{t('containers.homeQuestionSelectContent.__questionType')}</Table.Item>
                      <Table.Item flex={1}>{t('containers.homeQuestionSelectContent.__numberOfQuestions')}</Table.Item>
                      <Table.Item flex={1}>{t('containers.homeQuestionSelectContent.__numberOfAnswers')}</Table.Item>
                      {/* <Table.Item flex={1.5}>每題分數</Table.Item> */}
                      <Table.Item flex={1.5}>
                        {t('containers.homeQuestionSelectContent.__perAnswerScore')}
                      </Table.Item>
                      <Table.Item flex={2}>
                        {t('containers.homeQuestionSelectContent.__subtotal')}
                      </Table.Item>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body className="tableBody">
                    {
                      keyList && tableData && JSON.stringify(tableData) !== '{}' && keyList.map(key => (
                        <Table.Row key={key} textcenter={true} >
                          <Table.Item flex={10} textalign={'flex-start'}>
                            <QuestionTypeName name={tableData[key].name} isListen={tableData[key].isListen} />
                          </Table.Item>
                          <Table.Item flex={1} textalign={'flex-end'}>{tableData[key].inputValue.question}</Table.Item>
                          <Table.Item flex={1} textalign={'flex-end'}>{tableData[key].inputValue.answer}</Table.Item>
                          {/* <Table.Item flex={1.5} textalign={'flex-end'}>
                            <div>
                              <Input allowClear={false} value={tableData[key].inputValue.score.perQues || ''} onChange={(e) => {scoreInputChange(key, 'perQues', e);}} />
                            </div>
                          </Table.Item> */}
                          <Table.Item flex={1.5} textalign={'flex-end'}>
                            <div>
                              <Input allowClear={false} value={tableData[key]?.inputValue.score.perAns || ''} onChange={(e) => { scoreInputChange(key, 'perAns', e); }} />
                            </div>
                          </Table.Item>
                          <Table.Item flex={2} textalign={'flex-end'}>{tableData[key].inputValue.score.allScore}</Table.Item>
                        </Table.Row>
                      ))
                    }
                    <Table.Row>
                      <Table.Item className='tableFooter'>
                        {t('containers.homeQuestionSelectContent.__totalQuesTypeData', { questionCount: totalQuesTypeData.questionCount, totalAnswer: totalQuesTypeData?.totalAnswer, score: totalQuesTypeData.score })}
                      </Table.Item>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Box>
              <Box mb={5}>
                <div className="flexTitle">
                  <div className="pageTitle">
                    {t('containers.homeQuestionSelectContent.__questionSelection')}
                  </div>
                  <Select allowClear={false} options={questionDisplayOptions} value={displayQuestionsType} onChange={onQuestionDisplayTypeChange} />
                </div>
              </Box>
              <Box mb={4}>
                <StyledFilterBar>
                  <Input.Search
                    placeholder={t('containers.homeQuestionSelectContent.__searchQuestionContent')}
                    onSearch={onSearch} />
                  <PickListDropdown.TabPickListDropdown
                    key={t('containers.homeQuestionSelectContent.__questionType')}
                    defaultTitle={t('containers.homeQuestionSelectContent.__questionType')}
                    options={typeOptions || []}
                    onChange={(val, innerKey) => {
                      GA.setQuestionsFilter('QUES_TYPE');
                      onTabPickListChange('QUES_TYPE', val, innerKey);
                    }}
                    onCheckAll={(val, innerKey) => {
                      onTabPickListChange('QUES_TYPE', val, innerKey);
                    }}
                    onClear={(val, innerKey) => {
                      onTabPickListDropdownClear('QUES_TYPE', val, innerKey);
                    }}
                    checkedList={filterCondition['QUES_TYPE'] || []}
                  />
                  <PickListDropdown.SinglePickListDropdown
                    key={t('containers.homeQuestionSelectContent.__difficulty')}
                    defaultTitle={t('containers.homeQuestionSelectContent.__difficulty')}
                    clearable={true}
                    options={difficultyOptions || []}
                    onClick={(value) => {
                      GA.setQuestionsFilter('DIFFICULTY');
                      pickListChange('DIFFICULTY', value);
                    }}
                    onPickListClear={() => {
                      clearFilter('DIFFICULTY');
                    }}
                    value={filterCondition['DIFFICULTY'] || null}
                  />
                  <PickListDropdown.TabPickListDropdown
                    key={t('containers.homeQuestionSelectContent.__source')}
                    defaultTitle={t('containers.homeQuestionSelectContent.__source')}
                    options={sourceOptions || []}
                    onChange={(val, innerKey) => {
                      GA.setQuestionsFilter('SOURCE');
                      onTabPickListChange('SOURCE', val, innerKey);
                    }}
                    onCheckAll={(val, innerKey) => {
                      onTabPickListChange('SOURCE', val, innerKey);
                    }}
                    onClear={(val, innerKey) => {
                      onTabPickListDropdownClear('SOURCE', val, innerKey);
                    }}
                    checkedList={filterCondition['SOURCE'] || []}
                  />
                  <PickListDropdown.SinglePickListDropdown
                    key={t('containers.homeQuestionSelectContent.__knowledgeDimension')}
                    defaultTitle={t('containers.homeQuestionSelectContent.__knowledgeDimension')}
                    clearable={true}
                    options={knowledgeOptions || []}
                    onClick={(value) => {
                      GA.setQuestionsFilter('KNOWLEDGE');
                      pickListChange('KNOWLEDGE', value);
                    }}
                    onPickListClear={() => {
                      clearFilter('KNOWLEDGE');
                    }}
                    value={filterCondition['KNOWLEDGE'] || null}
                  />
                  <PickListDropdown.SinglePickListDropdown
                    key={t('containers.homeQuestionSelectContent.__learningContent')}
                    defaultTitle={t('containers.homeQuestionSelectContent.__learningContent')}
                    clearable={true}
                    options={learnContentOptions || []}
                    onClick={(value) => {
                      GA.setQuestionsFilter('LEARN_CONTENT');
                      pickListChange('LEARN_CONTENT', value);
                    }}
                    onPickListClear={() => {
                      clearFilter('LEARN_CONTENT');
                    }}
                    value={filterCondition['LEARN_CONTENT'] || null}
                  />
                </StyledFilterBar>
              </Box>
              <Box>
                <div
                  className="areaSelectTag"
                  onClick={() => {
                    setFilterChapterVisibleToggle();
                    GA.clickRangeFilterToggle(filterChapterVisible ? 'close' : 'open');
                  }}
                ><ListAltSolid className="prevSvg" />
                  {filterChapterVisible ?
                    <div>
                      {t('containers.homeQuestionSelectContent.__closeRangeFilterMenu')}<ChevronLeftOutline className="nextSvg" />
                    </div>
                    :
                    <div >
                      {t('containers.homeQuestionSelectContent.__showRangeFilterMenu')}<ChevronRightOutline className="nextSvg" />
                    </div>
                  }
                </div>
                <div className="tableZone">
                  {
                    filterChapterVisible &&
                    <StyledAreaSelectContainer className='selectedBox'>
                      <div className="areaSelectHeader">
                        <div>{t('containers.homeQuestionSelectContent.__range')}</div>
                        <div>{t('containers.homeQuestionSelectContent.__selectedQuestionCount')}</div>
                      </div>
                      <div className="areaSelectContent">
                        <div className="areaSelectContentTitle">
                          <StyledAreaSelectRow active={nowFilterChapter === 'all'} onClick={() => { onAllAreaClick(); }}>
                            <div>{t('containers.homeQuestionSelectContent.__allRanges')}</div>
                            <div className="count count1">
                              {totalQuesTypeData.questionCount}
                            </div>
                          </StyledAreaSelectRow>
                        </div>
                        {
                          bookChapters && bookChapters.filter(book => {
                            /* 是不是這冊的所有章節都有知識向度 */
                            /* 如果是才要顯示這冊 */
                            return book.chapters.filter(chapter => chapter.knowledgeList?.length > 0).length > 0;
                          }).map((item, index) => {
                            return (
                              <StyledAreaSelectList key={item.bookID} onClick={(e) => { e.stopPropagation(); onBookChapterClick(item); }}>
                                <StyledAreaSelectRow className="bookRow" active={nowFilterChapter === item.bookID}>
                                  <div>
                                    <BookSolid1 />
                                    <div>{saveData?.bookOptions
                                      ? `${saveData?.bookOptions.find(book => book.value === item.bookID.substr(-3))
                                        ? saveData?.bookOptions.find(book => book.value === item.bookID.substr(-3)).name
                                        : t(bookNameMapper(item.bookDesc, item.bookDesc))}`
                                      : t('containers.homeQuestionSelectContent.__sectionVolume', { sectionToChinese: index + 1 })}
                                    </div>
                                  </div>
                                  <div className="count count2">{getCount(item)}</div>
                                </StyledAreaSelectRow>
                                {
                                  item.chapters && item.chapters.length > 0 &&
                                  item.chapters
                                    .filter((chapter) => {
                                      if (!chapter?.parentCode) return true;
                                      /* 找 chapter 的最底層 */
                                      const lastChildChapter = getLastChild(chapter, item.chapters);
                                      /* 看最底層有沒有知識向度存在 */
                                      /* 有就代表 chapter 應該要被顯示；沒有就代表 chapter 沒有知識向度，不顯示 */
                                      return lastChildChapter?.knowledgeList.length > 0;
                                    })
                                    .reduce((prev, curr) => {
                                      if (prev?.[prev.length - 1]?.code !== curr.parentCode) {
                                        const insert = item.chapters.find(chapter => chapter.code === curr.parentCode);
                                        if (insert && prev.every(chapter => chapter.code !== insert.code)) {
                                          return prev.concat(insert).concat(curr);
                                        }
                                      }
                                      return prev.concat(curr);
                                    }, [])
                                    .map((chapter, _, arr) => {
                                      // 將語言天地跟有父層的項目移除
                                      if (chapter?.name.includes(t('containers.homeQuestionSelectContent.__languageWorld')) || chapter?.parentCode) {
                                        if (chapter?.parentCode) return '';
                                        if (chapter?.knowledgeList?.length) return '';
                                        if (arr.every(chapter_ => chapter_.parentCode !== chapter.code)) return '';
                                      }
                                      return (
                                        <StyledAreaSelectList key={chapter.desc} onClick={(e) => { e.stopPropagation(); onChapterClick(item.chapters, chapter, chapter?.knowledgeList?.length > 0); }}>
                                          <StyledAreaSelectRow active={nowFilterChapter?.code === chapter?.code && nowFilterChapter?.desc === chapter?.desc}>
                                            {
                                              item.bookID.includes(HISTORIC_EXAM_ID) &&
                                              <div>{chapter.name}</div>
                                            }
                                            {
                                              !item.bookID.includes(HISTORIC_EXAM_ID) &&
                                              //<div>{`第${sectionToChinese(parseInt(chapter.code))}${LANGUAGE_LIST.includes(eduSubjectData?.subject) ? '課' : '章'}_${chapter.name}`}</div>
                                              <div>

                                                {
                                                  t('containers.homeQuestionSelectContent.__sectionChapterName',
                                                    {
                                                      sectionToChinese: chapter.code,
                                                      subject: LANGUAGE_LIST.includes(eduSubjectData?.subject) ? t('containers.homeQuestionSelectContent.__lesson') : t('containers.homeQuestionSelectContent.__chapter'),
                                                      chapterName: chapter.name
                                                    })}
                                              </div>
                                            }
                                            <div className="count count3">{getCount(chapter, item.chapters)}</div>
                                          </StyledAreaSelectRow>
                                          {
                                            arr?.length > 0 && renderChildChapter(arr, chapter)
                                          }
                                        </StyledAreaSelectList>
                                      );
                                    })}
                              </StyledAreaSelectList>
                            );
                          })
                        }
                      </div>
                    </StyledAreaSelectContainer>
                  }
                  <div className="tableContent">
                    <Table ref={tableRef}>
                      <Header>
                        <Row>
                          <Item flex={4.3} textalign='center'>
                            {t('containers.homeQuestionSelectContent.__questionContent')}
                          </Item>
                          <Item flex="0.5" textalign='center'>
                            {t('containers.homeQuestionSelectContent.__selectQuestion')}
                          </Item>
                        </Row>
                      </Header>
                      <Body className="questionTableBody">
                        {
                          filterQuestionData &&
                            filterQuestionData.length > 0 ?
                            sliceTableData(filterQuestionData, pageState.currentPage, pageState.limit).map((item, index) => {
                              const isDetailOpened = openedQuestionIds.includes(item.uid);
                              return (
                                <Row key={index} disabled={item.isImport}>
                                  <Item flex={4.3} className="questionItem">
                                    <div className='questionHeader'>
                                      <DifferentTag mr={2} variant={diffStr[item.metaData.difficulty.code]} />
                                      <div className='questionType'>{item.metaData.questionType.name}</div>
                                      {<Tooltip title={t('containers.homeQuestionSelectContent.__questionDoubt')}>
                                        <div className='questionReport'>
                                          <ReturnSolid onClick={() => { showReportModal(item.uid); }} />
                                        </div>
                                      </Tooltip>}
                                      <div
                                        className="contentDetail"
                                        onClick={() => {
                                          GA.zoomInQuestionSelect(item.uid);
                                          showQuestionDetail(item.image);
                                        }}
                                      ><SearchPlusSolid /></div>
                                    </div>
                                    <div className="questionContent">
                                      {item.metaData?.soundFile && (
                                        <AudioPlayer src={item.metaData.soundFile.Path} defaultDuration={item.metaData.soundFile.Duration} />
                                      )}
                                      {
                                        item.image && item.image !== '' ? <img className="questionImage" src={item.image} alt='img' /> : <div className="questionContentText">{item.content}</div>
                                      }
                                    </div>
                                    {item && <QuestionDetail question={item} isOpened={isDetailOpened} onToggle={() => {
                                      if (isDetailOpened) {
                                        setOpenedQuestionIds((prev) => prev.filter(id => id !== item.uid));
                                      } else {
                                        setOpenedQuestionIds(prev => prev.concat(item.uid));
                                      }
                                    }} />}
                                  </Item>
                                  <Item flex="0.5" textalign="center"><Checkbox disabled={item.isImport} checked={isSelected(item.uid)} onClick={(e) => { toggle(item.uid); onQuestionCheck(e, item.uid); }} /></Item>
                                </Row>
                              );
                            }) : <div className="emptyMsg">{t('containers.homeQuestionSelectContent.__noResults')}</div>
                        }
                      </Body>
                    </Table>
                  </div>
                </div>
                <div className="pageBlock">
                  <Pagination
                    total={filterQuestionData?.length || 0}
                    defaultCurrent={1}
                    current={pageState.currentPage}
                    onChange={pageChange}
                    defaultPageSize={pageState.limit}
                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                  />
                </div>
              </Box>
              {
                modalVisible &&
                <div className="questionImageModal">
                  <div className="questionImageModalContent">
                    <div className="questionImageModalClose" onClick={() => { setModalVisibleFalse(); }}><XmarkOutline /></div>
                    <div className="questionImageModalContentImage">
                      <img src={modalImageSrc} alt="img" />
                    </div>
                  </div>
                </div>
              }
              <Modal
                visible={reportModalVisible}
                zIndex={1001}
                onCancel={setReportModalFalse}
                title={t('containers.homeQuestionSelectContent.__errorReport')}
                width="576px"
                footer={
                  <>
                    <Button className='reportModalBtn' variant='ui01' onClick={setReportModalFalse}>
                      {t('containers.homeQuestionSelectContent.__cancel')}
                    </Button>
                    <Button className='reportModalBtn' variant={!reportModalData.errorType || !reportModalData.description ? 'ui02' : 'brand2'}
                      disabled={!reportModalData.errorType || !reportModalData.description}
                      onClick={doReport}>
                      {t('containers.homeQuestionSelectContent.__confirm')}
                    </Button>
                  </>
                }
              >
                <StyleReportModalContent>
                  <div>
                    <div>{t('containers.homeQuestionSelectContent.__reason')}</div>
                    <Select className='select' options={reportModalSelectOptions}
                      value={reportModalSelectOptions?.value} onChange={onSelectChange}
                      allowClear={false} placeholder={t('containers.homeQuestionSelectContent.__select')}>
                    </Select>
                  </div>
                  <div>
                    <div>{t('containers.homeQuestionSelectContent.__explanation')}</div>
                    <Input.TextArea
                      className='textArea'
                      autoSize={true}
                      onChange={onTextAreaChange}
                      placeholder={t('containers.homeQuestionSelectContent.__required')}
                    ></Input.TextArea>
                  </div>
                </StyleReportModalContent>
              </Modal>
            </>
            :
            //// ------ 手機版 -------
            <>
              <Box mb={2} className={'filterBox'}>
                <div>
                  {t('containers.homeQuestionSelectContent.__selectedQuestionRange')}
                </div>
                <div className={checkedFilter ? 'selectedFilter' : ''} onClick={doShowFilterDrawer}>
                  <img src={checkedFilter ? '/assets/slidersCheckSolid.svg' : '/assets/slidersHSolid.svg'} alt={'filterImg'} />
                  {t('containers.homeQuestionSelectContent.__filter')}
                </div>
              </Box>
              <Box>
                <div className="tableZone">
                  <div className="tableContent">
                    <Table>
                      <Body className="questionTableBody">
                        {
                          filterQuestionData &&
                            filterQuestionData.length > 0 ?
                            sliceTableData(filterQuestionData, pageState.currentPage, pageState.limit).map((item, index) => {
                              return (
                                <Row className={'rowStyle'} key={index} disabled={item.isImport}>
                                  <Item flex="0.5" textalign="center"><Checkbox disabled={item.isImport} checked={isSelected(item.uid)} onClick={(e) => { toggle(item.uid); onQuestionCheck(e, item.uid); }} /></Item>
                                  <Item flex={4.3} onClick={() => { mobileShowQuestionDetail(item, index); }}>
                                    <div className="questionContent">
                                      {
                                        item.content ?
                                          <div className="questionContentText">{item.content}</div>
                                          : <img className="questionImage" src={item.image} alt='img' />
                                      }
                                    </div>
                                  </Item>
                                </Row>
                              );
                            }) : <div className="emptyMsg">{t('containers.homeQuestionSelectContent.__noResultsFound')}</div>
                        }
                      </Body>
                    </Table>
                  </div>
                </div>
                <div className="pageBlock">
                  <Pagination
                    total={filterQuestionData?.length || 0}
                    defaultCurrent={1}
                    current={pageState.currentPage}
                    onChange={pageChange}
                    defaultPageSize={pageState.limit}
                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                  />
                </div>
              </Box>
              {
                modalVisible &&
                <div className="questionImageModal">
                  <div className="questionImageModalContent">
                    <div className="questionImageModalClose" onClick={() => { setModalVisibleFalse(); }}><XmarkOutline /></div>
                    <div className="questionImageModalContentImage">
                      <img src={modalImageSrc} alt="img" />
                    </div>
                  </div>
                </div>
              }
              {/* 試題詳情彈窗 */}
              {
                questionDetailData && JSON.stringify(questionDetailData) !== '{}' &&
                <Modal
                  id={'questionDetailDrawer'}
                  visible={questionDetailDrawerVisible}
                  zIndex={1001}
                  onCancel={questionDetailDrawerFalse}
                  width="100%"
                  className={reportDrawerVisible ? 'isHidden' : ''}
                  closeIcon={<ChevronRightOutline className={'mobileCloseBtn'} />}
                >
                  <div
                    onTouchEnd={(e) => { onTouchEnd(e); }}
                    onTouchStart={(e) => { onTouchStart(e); }}
                  >
                    <div className='detailImgBox'>
                      <img src={questionDetailData.image} alt={questionDetailData.uid}></img>
                      {
                        isShowPreQuesBtn &&
                        <div className='preQuesBtn' onClick={preQues}><ChevronLeftOutline /></div>
                      }
                      {
                        isShowNextQuesBtn &&
                        <div className='nextQuesBtn' onClick={nextQues}><ChevronRightOutline /></div>
                      }
                    </div>
                    <div className='detailControlBox'>
                      <div>
                        {
                          env === 'dev' &&
                          <div><StarSolid />{t('containers.homeQuestionSelectContent.__favorite')}</div>
                        }
                        <div onClick={() => { showReportDrawer(questionDetailData.uid); }}>
                          <ReturnSolid />
                          {t('containers.homeQuestionSelectContent.__report')}
                        </div>
                      </div>
                      <div className={isSelected(questionDetailData.uid) ? 'checked' : ''} onClick={(e) => { toggle(questionDetailData.uid); onQuestionCheck(e, questionDetailData.uid); }}>
                        <Checkbox disabled={questionDetailData.isImport} checked={isSelected(questionDetailData.uid)} />
                        {t('containers.homeQuestionSelectContent.__selectThisQuestion')}
                      </div>
                    </div>
                    <div className='detailContent' id={'questionDetailDrawer'}>
                      <div>
                        <div>{questionDetailData.ANSWER_TYPE}</div>
                        <div>
                          <DifferentTag mr={1} variant={diffStr[questionDetailData?.metadata?.filter(i => i?.code === 'DIFFICULTY')[0]?.content[0]?.code]} />
                        </div>
                      </div>
                      <div>
                        <div>{t('containers.homeQuestionSelectContent.__questionType')}</div>
                        <div>{questionDetailData?.metaData?.questionType?.name}</div>
                      </div>
                      <div>
                        <div>{t('containers.homeQuestionSelectContent.__source')}</div>
                        <div>{ sourceMapper(questionDetailData?.metaData?.source?.name) }</div>
                      </div>
                      <div>
                        <div>{t('containers.homeQuestionSelectContent.__learningContent')}</div>
                        <div>{questionDetailData?.metaData?.learnContent?.name}</div>
                      </div>
                      <div>
                        <div>{t('containers.homeQuestionSelectContent.__knowledgeLevel')}</div>
                        <div>
                          {knowledgeArrayToString(questionDetailData?.metaData?.knowledges)}
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              }
              <Drawer
                visible={reportDrawerVisible}
                title={t('containers.homeQuestionSelectContent.__errorReport')}
                onClose={reportDrawerFalse}
                placement={'bottom'}
                isMobile={true}
                id={'reportDrawer'}
                className={'reportDrawer'}
                footer={
                  <>
                    <Button
                      variant={!reportModalData.errorType || !reportModalData.description ? 'ui02' : 'brand2'}
                      disabled={!reportModalData.errorType || !reportModalData.description}
                      onClick={doMobileReport}>
                      {t('containers.homeQuestionSelectContent.__submit')}
                    </Button>
                    <Button variant='ui01' onClick={reportDrawerFalse}>
                      {t('containers.homeQuestionSelectContent.__cancel')}
                    </Button>
                  </>
                }
              >
                <div className='radioBox'>
                  <div>{t('containers.homeQuestionSelectContent.__reason')}<span>*</span></div>
                  <Radio.Group onChange={onReportTypeChange} value={reportModalData.errorType}>
                    {
                      reportModalSelectOptions &&
                      reportModalSelectOptions.map((item) => {
                        return (<Radio key={item.value} value={item.value}>{item.name}</Radio>);
                      })
                    }
                  </Radio.Group>
                </div>
                <div className='explanationBox'>
                  <div>{t('containers.homeQuestionSelectContent.__explanation')}<span>*</span></div>
                  <Input.TextArea
                    className='textArea'
                    autoSize={true}
                    onChange={onTextAreaChange}
                    key={reportModalData.uid}
                    placeholder={t('containers.homeQuestionSelectContent.__enterExplanation')} />
                </div>
              </Drawer>

              <Drawer
                visible={setupDrawerVisible}
                title={t('containers.homeQuestionSelectContent.__questionCountAndDistribution')}
                onClose={setupDrawerFalse}
                placement={'bottom'}
                isMobile={true}
                id={'setupDrawer'}
                className={'setupDrawer'}
                key={tableData}
                destroyOnClose={true}
                footer={
                  <Button
                    disabled={setupDrawerDoneBtnDisabled}
                    variant={setupDrawerDoneBtnDisabled ? 'ui02' : 'brand2'}
                    className={'setupDrawerFooter'}
                    onClick={() => { setupDrawerDone(); }}
                  >{t('containers.homeQuestionSelectContent.__save')}</Button>
                }
              >
                <div className='setupDrawerTotalScore'>
                  {t('containers.homeQuestionSelectContent.__totalQuesTypeData', { questionCount: totalSetupQuesTypeData.questionCount, totalAnswer: totalSetupQuesTypeData?.totalAnswer, score: totalSetupQuesTypeData.score })}
                </div>
                {
                  pickUpQuestionID.length > 0 && renderQuestionAnalysisModal()
                }
                {
                  keyList &&
                  keyList.map((key) => {
                    return (
                      <div className='questionTypeBox' key={key}>
                        <div className={'drawerTitle'}>
                          {setupDrawerData[key]?.name}
                        </div>
                        <div className={'drawerSection'}>
                          <div className={'drawerTable'}>
                            <div>
                              <div>{t('containers.homeQuestionSelectContent.__selectedQuestionCount')}</div>
                              <div>{t('containers.homeQuestionSelectContent.__answeredQuestionCount')}</div>
                            </div>
                            <div>
                              <div>
                                {setupDrawerData[key].inputValue.question}
                              </div>
                              <div>
                                {setupDrawerData[key].inputValue.answer}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={'drawerSection'}>
                          <div className={'drawerTable'}>
                            <div>
                              {/* <div>每題分數</div> */}
                              <div>{t('containers.homeQuestionSelectContent.__perAnswerScore')}</div>
                            </div>
                            <div className={'setupDrawerScoreBox'}>
                              {/* <Input allowClear={false} value={setupDrawerData[key].inputValue.score.perQues} onChange={(e) => {setupDrawerScoreInputChange(key, 'perQues', e);}}/> */}
                              <Input allowClear={false} value={setupDrawerData[key].inputValue.score.perAns} onChange={(e) => { setupDrawerScoreInputChange(key, 'perAns', e); }} />
                            </div>
                          </div>
                        </div>
                        <div className={'drawerTotalScore'}>
                          {t('containers.homeQuestionSelectContent.__drawerTotalScore', { allScore: setupDrawerData[key].inputValue.score.allScore })}
                        </div>
                      </div>
                    );
                  })
                }
              </Drawer>
              <Modal
                id={'filterDrawer'}
                visible={filterDrawerVisible}
                zIndex={1001}
                onCancel={filterDrawerFalse}
                width='100%'
                title={
                  <>
                    <span>{t('containers.homeQuestionSelectContent.__filter')}</span>
                    <div className={classNames('clearAllFilterBtn', Object.keys(filterConditionMobile).length > 1 | displayQuestionsTypeMobile !== 'all' ? 'active' : '')} onClick={clearAllFilter}>
                      {t('containers.homeQuestionSelectContent.__clearAll')}
                    </div>
                  </>
                }
                footer={
                  <>
                    <Button
                      className='reportModalBtn'
                      variant={!checkedFilterChangeBtn ? 'ui02' : 'brand2'}
                      disabled={!checkedFilterChangeBtn}
                      onClick={doneFilterDrawerChange}
                    >{t('containers.homeQuestionSelectContent.__apply')}</Button>
                  </>
                }
              >
                <div className='filterDrawerContent'>
                  <div>
                    <div>{t('containers.homeQuestionSelectContent.__question')}</div>
                    <RadioGroup
                      key={'mobileQuestionDisplayOptions'}
                      name={'mobileQuestionDisplayOptions'}
                      className='radioList' buttonStyle='solid'
                      optionType='button'
                      options={mobileQuestionDisplayOptions}
                      value={displayQuestionsTypeMobile}
                      onChange={(e) => { onQuestionDisplayTypeMobileChange(e.target.value); }}
                    />
                  </div>
                  <div>
                    <div>{t('containers.homeQuestionSelectContent.__questionType')}</div>
                    <div
                      className={JSON.stringify(filterConditionMobile?.QUES_TYPE) !== '{}' && filterConditionMobile?.QUES_TYPE !== undefined ? 'activeText' : 'defaultText'}
                      onClick={() => { showFilterSelectDrawer('QUES_TYPE'); }}
                    >
                      {
                        filterSelectedTitle_QUES_TYPE?.length > 0
                          ?
                          <div>
                            <span>{filterSelectedTitleArray(filterSelectedTitle_QUES_TYPE)}</span>
                            <div className='totalCountIcon'>{filterSelectedTitle_QUES_TYPE.length}</div>
                          </div>
                          :
                          <div>{t('containers.homeQuestionSelectContent.__selectQuestionType')}</div>
                      }
                    </div>
                  </div>
                  <div>
                    <div>{t('containers.homeQuestionSelectContent.__source')}</div>
                    <div
                      className={JSON.stringify(filterConditionMobile?.SOURCE) !== '{}' && filterConditionMobile?.SOURCE !== undefined ? 'activeText' : 'defaultText'}
                      onClick={() => { showFilterSelectDrawer('SOURCE'); }}
                    >
                      {
                        filterSelectedTitle_SOURCE?.length > 0
                          ?
                          <div>
                            <span>{filterSelectedTitleArray(filterSelectedTitle_SOURCE)}</span>
                            <div className='totalCountIcon'>{filterSelectedTitle_SOURCE.length}</div>
                          </div>
                          :
                          <div>{t('containers.homeQuestionSelectContent.__selectSource')}</div>
                      }
                    </div>
                  </div>
                  <div>
                    <div>{t('containers.homeQuestionSelectContent.__difficulty')}</div>
                    <div key={filterConditionMobile?.DIFFICULTY} className={filterConditionMobile?.DIFFICULTY ? 'activeText' : 'defaultText'} onClick={() => { showFilterSelectDrawer('DIFFICULTY'); }}>
                      {
                        filterConditionMobile?.DIFFICULTY
                          ?
                          <div>
                            <span>{filterConditionMobile?.DIFFICULTY}</span>
                            <XmarkCircleSolid className='clearBtn' onClick={(e) => { e.stopPropagation(); deleteFilterKeyMobile('DIFFICULTY'); }} />
                          </div>
                          :
                          <div>{t('containers.homeQuestionSelectContent.__selectDifficulty')}</div>
                      }
                    </div>
                  </div>
                  <div>
                    <div>{t('containers.homeQuestionSelectContent.__range')}</div>
                    <div className={filterConditionMobile?.chapter?.length > 0 ? 'activeText' : 'defaultText'} onClick={() => { showFilterSelectDrawer('chapter'); }}>
                      {
                        filterConditionMobile?.chapter?.length > 0
                          ?
                          <div>
                            <span>{filterSelectedTitle(mobileFilterChapterTitle)}</span>
                            <XmarkCircleSolid className='clearBtn' onClick={(e) => { e.stopPropagation(); deleteFilterKeyMobile('chapter'); setCheckboxTreeData([]); }} />
                          </div>
                          :
                          <div>{t('containers.homeQuestionSelectContent.__selectScope')}</div>
                      }
                    </div>
                  </div>
                  <div>
                    <div>{t('containers.homeQuestionSelectContent.__knowledgeDimension')}</div>
                    <div key={filterConditionMobile?.KNOWLEDGE} className={filterConditionMobile?.KNOWLEDGE ? 'activeText' : 'defaultText'} onClick={() => { showFilterSelectDrawer('KNOWLEDGE'); }}>
                      {
                        filterConditionMobile?.KNOWLEDGE
                          ?
                          <div>
                            <span>{filterSelectedTitle(filterConditionMobile?.KNOWLEDGE)}</span>
                            <XmarkCircleSolid className='clearBtn' onClick={(e) => { e.stopPropagation(); deleteFilterKeyMobile('KNOWLEDGE'); }} />
                          </div>
                          :
                          <div>{t('containers.homeQuestionSelectContent.__selectKnowledgeDimension')}</div>
                      }
                    </div>
                  </div>
                  <div>
                    <div>{t('containers.homeQuestionSelectContent.__learningContent')}</div>
                    <div key={filterConditionMobile?.LEARN_CONTENT} className={filterConditionMobile?.LEARN_CONTENT ? 'activeText' : 'defaultText'} onClick={() => { showFilterSelectDrawer('LEARN_CONTENT'); }}>
                      {
                        filterConditionMobile?.LEARN_CONTENT
                          ?
                          <div>
                            <span>{filterSelectedTitle(filterConditionMobile?.LEARN_CONTENT)}</span>
                            <XmarkCircleSolid className='clearBtn' onClick={(e) => { e.stopPropagation(); deleteFilterKeyMobile('LEARN_CONTENT'); }} />
                          </div>
                          :
                          <div>{t('containers.homeQuestionSelectContent.__selectLearningContent')}</div>
                      }
                    </div>
                  </div>
                </div>
              </Modal>

              <Modal
                id={'filterSelectDrawer'}
                visible={filterSelectDrawerVisible}
                zIndex={1001}
                onCancel={filterSelectDrawerFalse}
                width='100%'
                title={mobileFilterDrawerTypeTitle}
                closeIcon={<ChevronRightOutline className={'mobileCloseBtn'} />}
              >
                {/* ---- 題型 篩選 ---- */}
                <StyledMobileFilterContent>
                  {
                    mobileFilterDrawerType === 'QUES_TYPE' && typeOptions && typeOptions.length > 0 &&
                    transformToTreeData(typeOptions).map((item) => {
                      return (
                        <CheckboxType
                          typeTitle={{ label: item.label, value: item.value }}
                          options={item.children}
                          checkedList={reCheckedList('QUES_TYPE', item.value)}
                          onChangeList={onTreeChange}
                          key={item.value}
                        />
                      );
                    })
                  }
                </StyledMobileFilterContent>

                {/* ---- 出處 篩選 ---- */}
                <StyledMobileFilterContent>
                  {
                    mobileFilterDrawerType === 'SOURCE' && sourceOptions && sourceOptions.length > 0 &&
                    transformToTreeData(sourceOptions).map((item) => {
                      return (
                        <CheckboxType
                          typeTitle={{ label: item.label, value: item.value }}
                          options={item.children}
                          checkedList={reCheckedList('SOURCE', item.value)}
                          onChangeList={onTreeChange}
                          key={item.value}
                        />
                      );
                    })
                  }
                </StyledMobileFilterContent>

                {/* ---- 難易度 篩選 ---- */}
                <StyledMobileFilterContent>
                  {
                    mobileFilterDrawerType === 'DIFFICULTY' &&
                    <Radio.Group
                      onChange={(e) => { onRadioChange('DIFFICULTY', e); }}
                      value={filterConditionMobile?.DIFFICULTY}
                    >
                      {
                        difficultyOptions && difficultyOptions.length > 0 &&
                        transformRadio(difficultyOptions).map((item) => {
                          return (
                            <Radio className={difficultyHide(item) ? 'hideRadio' : ''} key={item.code} value={item.code}>{item.name}</Radio>
                          );
                        })
                      }
                    </Radio.Group>
                  }
                </StyledMobileFilterContent>

                {/* ---- 範圍 篩選 ---- */}
                <StyledMobileFilterContent>
                  {
                    mobileFilterDrawerType === 'chapter' && checkboxTreeOptions &&
                    checkboxTreeOptions.map((item) => {
                      return (
                        <CheckboxTree
                          grandparentOptions={item}
                          checkedList={checkboxTreeData}
                          onClickCallBack={onCheckboxTreeChange}
                        />
                      );
                    })
                  }
                </StyledMobileFilterContent>

                {/* ---- 知識向度 篩選 ---- */}
                <StyledMobileFilterContent>
                  {
                    mobileFilterDrawerType === 'KNOWLEDGE' &&
                    <Radio.Group
                      onChange={(e) => { onRadioChange('KNOWLEDGE', e); }}
                      value={filterConditionMobile?.KNOWLEDGE}
                    >
                      {
                        knowledgeOptions && knowledgeOptions.length > 0 &&
                        transformRadio(knowledgeOptions).map((item) => {
                          return (
                            <Radio key={item.code} value={item.code}>{item.name}</Radio>
                          );
                        })
                      }
                    </Radio.Group>
                  }
                </StyledMobileFilterContent>

                {/* ---- 學習內容 篩選 ---- */}
                <StyledMobileFilterContent>
                  {
                    mobileFilterDrawerType === 'LEARN_CONTENT' &&
                    <Radio.Group
                      onChange={(e) => { onRadioChange('LEARN_CONTENT', e); }}
                      value={filterConditionMobile?.LEARN_CONTENT}
                    >
                      {
                        learnContentOptions && learnContentOptions.length > 0 &&
                        transformRadio(learnContentOptions).map((item) => {
                          return (
                            <Radio key={item.code} value={item.code}>{item.name}</Radio>
                          );
                        })
                      }
                    </Radio.Group>
                  }
                </StyledMobileFilterContent>

              </Modal>
            </>
        }
      </StyledHomeQuestionSelectContent>
  );
};

// HomeQuestionSelectContent.propTypes = {
//   className: PropTypes.string,
//   examPaperInfo: PropTypes.object,
//   prevKeyList: PropTypes.array,
//   searchKey: PropTypes.string,
//   getQuestions: PropTypes.func,
//   saveData: PropTypes.object,
//   memberEduSubject: PropTypes.object,
//   getSaveData: PropTypes.func,
//   getKeyList: PropTypes.func,
// };