import React, { useRef, useEffect, useState, useMemo, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useBoolean, useClickAway } from 'ahooks';
import {
  FrontLayout,
  Dropdown,
  AuthProvider,
  ThemeProvider,
  Modal,
} from '@oneclass/ui-components';
import { OneTestHeader } from 'containers';
import { Button } from '@oneclass/onedesign';
import RenderRoutes from 'routes/RenderRoutes';
import {
  UserSolid,
  UserCogSolid,
  BackstageManagementSolid,
  SignOutSolid,
  XmarkOutline,
  ChevronDownToBottomSolid,
  ChevronUpToTopSolid,
  SearchSolid,
  FolderOpenSolid,
  ScrollSolid,
  CaretDownSolid,
} from '@onedesign/icon';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useEduSubjectModal } from 'utils/hooks/useEduSubjectModal';
import { useSize } from 'ahooks';
import {
  StyledMainLayout,
  UiUserBox,
  StyledFooterModal,
  StyledMenuBox,
  StyledPageHeader,
} from './MainLayout.style';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { getAllRemoteConfig } from 'utils/hooks/useFirebaseRemoteConfig';
import { useLoadingContent } from 'utils/hooks/useLoadingContent';
import { useLoadingMark } from 'utils/hooks/useLoadingMark';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { default as firebaseRemoteConfigProvider } from 'providers/firebaseRemoteConfigProvider.js';
import { default as UserProvider } from 'providers/UserProvider.js';
import * as GA from 'utils/googleAnalytics';
import { useBitrix } from 'utils/hooks/useBitrix';
import { RouteLeaveProvider } from 'providers/routeLeaveProvider';
import LogoutAlert from 'components/LogoutAlert/LogoutAlert';
import { useTranslation } from 'react-i18next';

const version = process.env.REACT_APP_VERSION;

const goTop = () => {
  const main = document.getElementById('mainLayout');
  main.scrollTop = 0;
};

const goBottom = () => {
  const main = document.getElementById('mainLayout');
  main.scrollTop = main.scrollHeight;
};

export const MainLayout = ({ className, routes = [] }) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = useCallback((language) => {
    i18n.changeLanguage(language);
  }, [i18n]);

  const { deviceData, deviceDataProviderChange } = useContext(
    deviceProvider.deviceProviderContext
  );
  const { userData } = useContext(UserProvider.UserProviderContext);
  const { userInfo } = userData;
  const { remoteConfigData, RemoteConfigDataProviderChange } = useContext(
    firebaseRemoteConfigProvider.firebaseRemoteConfigProviderContext
  );
  const history = useHistory();
  const env = process.env.REACT_APP_NODE_ENV;
  const { useAuthDispatch, useAuthState } = AuthProvider;
  const { logout, settingEduSubject } = useAuthDispatch();
  const authState = useAuthState();
  const location = useLocation();
  const [state, { toggle, setFalse }] = useBoolean(false);
  const [
    modalVisible,
    { toggle: setModalToggle, setFalse: setImportModalFalse },
  ] = useBoolean(false);
  const [
    copyrightModalVisible,
    { toggle: copyrightModalToggle, setFalse: copyrightModalFalse },
  ] = useBoolean(false);
  const drawRef = useRef();
  const modalRef = useRef();
  const [scrollTop, setScrollTop] = useState(0);
  const [showCustomerServiceTip, setShowCustomerServiceTip] = useState(false);
  const {
    renderEduSubjectModal,
    setEduSubjectModalToggle,
    setEduSubjectModalFalse,
  } = useEduSubjectModal(
    authState.context.userDetails?.preference,
    'mainLayout',
    location.pathname
  );
  const layoutRef = useRef();
  const size = useSize(layoutRef);
  useClickAway(() => {
    setFalse();
  }, drawRef);
  useClickAway(() => {
    setImportModalFalse();
  }, modalRef);
  const { renderLoadingContent, isLoading, setIsLoading } = useLoadingContent();
  const {
    renderLoadingMark,
    isLoading: isLoadingMark,
    setIsLoading: setIsLoadingMark,
  } = useLoadingMark();
  const props = {
    setIsLoadingMark,
  };
  const [configData, setConfigData] = useState({});
  const { showButton, hideButton } = useBitrix();
  const [isLogoutAlertOpen, setIsLogoutAlertOpen] = useState(false);

  const scrollPosition = useMemo(() => {
    const main = document.getElementById('mainLayout');
    if (scrollTop === 0) return 'top';
    if (main.offsetHeight + scrollTop >= main.scrollHeight) return 'bottom';
    return 'middle';
  }, [scrollTop]);

  const isPro = useMemo(() => {
    return userInfo.userType === 'Premium'; //(userData.organizationType === 'Agency' || userData.organizationType === 'Tutoring') ? true : false;
  }, [userInfo]);

  const isTest = useMemo(() => {
    return userInfo.onePaperIdentity === 'TestCram';
  }, [userInfo]);

  const goToSearchPage = () => {
    history.push('/SearchPaperListPage');
  };

  const addNowPageClassName = useMemo(() => {
    switch (location.pathname) {
      case '/login':
        return 'loginPage';
      case '/computerQuestion':
      case '/manualProposition':
      case '/knowledgeProposition':
      case '/equationDojo':
      case '/studentIntelligentProposition':
        return 'questioningProcess';
      case '/examPaperList':
      case '/studentExamPaperList':
        return 'examPaperList';
      case '/':
        return 'homePage';
      case '/SearchPaperListPage':
        return 'SearchPaperListPage';
      default:
        return '';
    }
  }, [location]);

  useEffect(() => {
    const main = document.getElementById('mainLayout');
    main.addEventListener('scroll', (e) => {
      setScrollTop(e.target.scrollTop);
    });
  }, []);

  // 針對手機 瀏覽器 上下方工具列 導致vh變化 做調整
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }, []);

  // 取得 firebase RemoteConfig 資料
  const getConfigData = async () => {
    const data = await getAllRemoteConfig();
    setIsLoading(false);
    setConfigData(data);
  };

  const isRepairing = useMemo(() => {
    if (!configData || JSON.stringify(configData) === '{}') return;
    return JSON.parse(configData?.maintenancePage?._value)?.isRepairing;
  }, [configData]);

  useEffect(() => {
    if (!configData || JSON.stringify(configData) === '{}') return;
    RemoteConfigDataProviderChange(configData);
  }, [configData]);

  // 隨時監控 螢幕寬度並改變 全域參數
  const getScreenWidth = () => {
    const width = document.body.clientWidth;
    deviceDataProviderChange('screenWidth', width);
  };

  useEffect(() => {
    getConfigData();

    window.addEventListener('resize', getScreenWidth);
    return () => {
      window.removeEventListener('resize', getScreenWidth);
    };
  }, []);

  useEffect(() => {
    if (!isRepairing) return;
    if (isRepairing) {
      history.push('/maintenancePage');
    }
  }, [isRepairing]);

  useEffect(() => {
    if (['/'].includes(location.pathname)) {
      showButton();
    } else {
      hideButton();
    }
  }, [location.pathname]);

  return (
    <DndProvider backend={!deviceData.isDesktop ? TouchBackend : HTML5Backend}>
      <ThemeProvider isPro={isPro}>
        <RouteLeaveProvider>
          <StyledMainLayout
            className={className}
            id="mainLayout"
            data-testid="MainLayout"
            ref={layoutRef}
            isTest={isTest}
            pathname={location.pathname}
          >
            {isLoading ? (
              renderLoadingContent()
            ) : (
              <>
                <div className="scrollBtnZone">
                  {(scrollPosition === 'bottom' ||
                    scrollPosition === 'middle') && (
                    <div
                      className="scrollBtn"
                      onClick={() => {
                        goTop();
                      }}
                    >
                      <ChevronUpToTopSolid />
                    </div>
                  )}
                  {(scrollPosition === 'top' || scrollPosition === 'middle') && (
                    <div
                      className="scrollBtn"
                      onClick={() => {
                        goBottom();
                      }}
                    >
                      <ChevronDownToBottomSolid />
                    </div>
                  )}
                </div>
                <FrontLayout
                  className={addNowPageClassName}
                  header={
                    <>
                      {userInfo?.onePaperIdentity ? (
                        <>
                          {isTest ? (
                            <>
                              <OneTestHeader
                                logout={logout}
                                userData={userInfo}
                                authStateValue={authState.value}
                                env={env}
                                history={history}
                                openEduSubjectModalToggle={
                                  setEduSubjectModalToggle
                                }
                              />
                              {renderEduSubjectModal(
                                authState.context.userDetails?.onePaperIdentity
                              )}
                            </>
                          ) : (
                            <>
                              {location.pathname === '/login' ? (
                                ''
                              ) : (
                                <StyledPageHeader
                                  isPro={isPro}
                                  nowheight={size.height || null}
                                >
                                  {isTest ? (
                                    <div></div>
                                  ) : (
                                    <div
                                      className="logo"
                                      onClick={() => {
                                        history.push('/');
                                      }}
                                    >
                                      {isPro ? <img src='/assets/OnePaperProLogo.png' alt="logo"
                                        height="46"/> :
                                        <img
                                          src='/assets/logo.svg'
                                          alt="logo"
                                        />}
                                      {isPro && (
                                        <div className="logoProText">
                                          {t('layouts.mainLayout.__professionalEdition')}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  <div className="headerRightBox">
                                    <div className="pcHeader">
                                      <Dropdown
                                        className="userAvatar"
                                        list={[
                                          {
                                            name: 'Tiếng Việt',
                                            onClick: () => changeLanguage('vi'),
                                          },
                                          {
                                            name: 'English',
                                            onClick: () => changeLanguage('en'),
                                          },
                                          {
                                            name: t('layouts.mainLayout.__traditionalChinese'),
                                            onClick: () => changeLanguage('zh'),
                                          },
                                        ]}
                                      >
                                        <UiUserBox>
                                          {t('language')}
                                          <CaretDownSolid />
                                        </UiUserBox>
                                      </Dropdown>
                                      {env === 'dev' && (
                                        <div
                                          className="searchBtn"
                                          onClick={goToSearchPage}
                                        >
                                          <SearchSolid></SearchSolid>
                                          {t('layouts.mainLayout.__search')}
                                        </div>
                                      )}

                                      {userInfo.identity !== 'Student' &&
                                        location.pathname !== '/' &&
                                        location.pathname !==
                                        '/computerQuestion' &&
                                        location.pathname !==
                                        '/manualProposition' &&
                                        location.pathname !==
                                        '/knowledgeProposition' &&
                                        location.pathname !== '/equationDojo' && (
                                        <>
                                          <Button
                                            borderRadius="24px"
                                            variant={
                                              isPro
                                                ? 'linearGradient'
                                                : 'brand1'
                                            }
                                            className="makeATextPaperBtn"
                                            onClick={setEduSubjectModalToggle}
                                          >
                                            <ScrollSolid />
                                            {t('layouts.mainLayout.__createTest')}
                                          </Button>
                                          {renderEduSubjectModal(
                                            authState.context.userDetails
                                              ?.onePaperIdentity
                                          )}
                                        </>
                                      )}
                                      {location.pathname === '/login' ||
                                        isTest ? (
                                          ''
                                        ) : (
                                          <Dropdown
                                            className="userAvatar"
                                            list={[
                                              (userInfo.identity === 'Admin' ||
                                              userInfo.identity === 'Editor') && {
                                                name: t('layouts.mainLayout.__backendManagement'),
                                                icon: <BackstageManagementSolid />,
                                                onClick: () => {
                                                  window.location.href = `https://onepaper-cms${
                                                    env !== 'release'
                                                      ? `-${env}`
                                                      : ''
                                                  }.oneclass.vn/`;
                                                },
                                              },
                                              userInfo.identity === 'Student'
                                                ? {
                                                  name: t('layouts.mainLayout.__myPracticePapers'),
                                                  icon: <FolderOpenSolid />,
                                                  onClick: () => {
                                                    history.push(
                                                      '/studentExamPaperList'
                                                    );
                                                  },
                                                }
                                                : {
                                                  name: t('layouts.mainLayout.__examList'),
                                                  icon: <FolderOpenSolid />,
                                                  onClick: () => {
                                                    history.push('/examPaperList');
                                                  },
                                                },
                                              {
                                                name: t('layouts.mainLayout.__personalSettings'),
                                                icon: <UserCogSolid />,
                                                onClick: () => {
                                                  GA.clickUserSetting();
                                                  history.push('/memberCenter');
                                                },
                                              },
                                              {
                                                name: t('layouts.mainLayout.__logout'),
                                                icon: <SignOutSolid />,
                                                onClick: () => {
                                                  setIsLogoutAlertOpen(true);
                                                },
                                              },
                                            ]}
                                          >
                                            <UiUserBox>
                                              <UserSolid />
                                              {authState.context.userDetails
                                                ?.userStatus.name || ''}
                                              <CaretDownSolid />
                                            </UiUserBox>
                                          </Dropdown>
                                        )}
                                    </div>
                                  </div>
                                  <div className="mobileHeader">
                                    <input
                                      id="nav-toggle"
                                      type="checkbox"
                                    ></input>
                                    <label htmlFor="nav-toggle" className="ham">
                                      <div className="ham-origin">
                                        <div className="ham-bar ham-bar--top"></div>
                                        <div className="ham-bar ham-bar--middle"></div>
                                        <div className="ham-bar ham-bar--bottom"></div>
                                      </div>
                                    </label>
                                    <StyledMenuBox>
                                      <div className="menuBtnBox">
                                        {userInfo.identity !== 'Student' &&
                                          location.pathname !== '/' &&
                                          location.pathname !==
                                          '/computerQuestion' &&
                                          location.pathname !==
                                          '/manualProposition' &&
                                          location.pathname !==
                                          '/knowledgeProposition' &&
                                          location.pathname !==
                                          '/equationDojo' && (
                                          <>
                                            <Button
                                              borderRadius="24px"
                                              variant={
                                                isPro
                                                  ? 'linearGradient'
                                                  : 'brand1'
                                              }
                                              className="makeATextPaperBtn"
                                              onClick={setEduSubjectModalToggle}
                                            >
                                              {t('layouts.mainLayout.__createTest')}
                                            </Button>
                                            {renderEduSubjectModal(
                                              authState.context.userDetails
                                                ?.onePaperIdentity
                                            )}
                                          </>
                                        )}
                                        {env === 'dev' && (
                                          <Button
                                            textcolor={isPro ? '#fff' : '#000'}
                                            type="solid"
                                            borderRadius="24px"
                                            className="makeATextPaperBtn searchBtn"
                                            onClick={goToSearchPage}
                                          >
                                            <SearchSolid></SearchSolid>
                                            {t('layouts.mainLayout.__search')}
                                          </Button>
                                        )}
                                      </div>
                                      <ul className="menu">
                                        <li className="mobileAvatar">
                                          <UserSolid />
                                          {authState.context.userDetails
                                            ?.userStatus?.name || ''}
                                        </li>
                                        {(userInfo.identity === 'Admin' ||
                                          userInfo.identity === 'Editor') && (
                                          <li>
                                            <Link
                                              to={`https://onepaper-cms${
                                                env !== 'release' ? `-${env}` : ''
                                              }.oneclass.vn/`}
                                            >
                                              <BackstageManagementSolid />
                                              {t('layouts.mainLayout.__backendManagement')}
                                            </Link>
                                          </li>
                                        )}
                                        {userInfo.identity === 'Student' ? (
                                          <li>
                                            <Link to={'studentExamPaperList'}>
                                              <FolderOpenSolid />
                                              {t('layouts.mainLayout.__myPracticePapers')}
                                            </Link>
                                          </li>
                                        ) : (
                                          <li>
                                            <Link to={'examPaperList'}>
                                              <FolderOpenSolid />
                                              {t('layouts.mainLayout.__examList')}
                                            </Link>
                                          </li>
                                        )}
                                        <li onClick={GA.clickUserSetting}>
                                          <Link to={'memberCenter'}>
                                            <UserCogSolid />
                                            {t('layouts.mainLayout.__personalSettings')}
                                          </Link>
                                        </li>
                                        <li>
                                          <div
                                            onClick={() => {
                                              setIsLogoutAlertOpen(true);
                                            }}
                                          >
                                            <SignOutSolid />
                                            {t('layouts.mainLayout.__logout')}
                                          </div>
                                        </li>
                                      </ul>
                                      <div className="menuBackground"></div>
                                    </StyledMenuBox>
                                  </div>
                                </StyledPageHeader>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  }
                  footer={
                    <>
                      <div className="footerLeft">
                        {!isTest && !isPro && (
                          <div>
                            <div>
                              <a href="https://trans.nani.vn/NaniTeacher/" onClick={() => GA.clickFooterItem('nani_teacher')}>
                                {t('layouts.mainLayout.__naniTeacher')}
                              </a>
                            </div>
                            <div>
                              <a href="https://onebox2.oneclass.vn/" onClick={() => GA.clickFooterItem('one_box')}>
                                OneBox2.0
                              </a>
                            </div>
                            <div>
                              <a href="/fonts" onClick={() => GA.clickFooterItem('fonts')}>
                                <img src='/assets/fontSolid.svg' alt='' />{t('layouts.mainLayout.__fontPackageDownload')}
                              </a>
                            </div>
                          </div>
                        )}
                        <div>
                          © NANI BOOK ENTERPRISE CO.,LTD. All Rights Reserved. v
                          {version || '1.1.1'}
                        </div>
                      </div>
                      <div className="browserSuggestAll" ref={modalRef}>
                        <div
                          className="browserSuggest"
                          onClick={() => {
                            copyrightModalToggle();
                          }}
                        >
                          {t('layouts.mainLayout.__copyrightStatement')}
                        </div>
                        <div
                          className="browserSuggest"
                          onClick={() => {
                            setModalToggle();
                          }}
                        >
                          {t('layouts.mainLayout.__browserRecommendation')}
                        </div>

                        <StyledFooterModal isShow={modalVisible}>
                          <div className="modalContent">
                            <div className="title">
                              <span>{t('layouts.mainLayout.__browserRecommendation')}</span>{' '}
                              <div
                                className="close"
                                onClick={() => {
                                  setImportModalFalse();
                                }}
                              >
                                <XmarkOutline />
                              </div>
                            </div>

                            <div>
                              {t('layouts.mainLayout.__recommendedBrowsers')}：IE
                              Edge、Google Chrome {t('layouts.mainLayout.__or')} Firefox。
                            </div>
                            <div>
                              {t('layouts.mainLayout.__browserCompatibilityNote')}
                            </div>
                          </div>
                        </StyledFooterModal>

                        <Modal
                          id={'copyrightModal'}
                          visible={copyrightModalVisible}
                          zIndex={1000}
                          onCancel={copyrightModalFalse}
                          width={!deviceData.isDesktop ? '100%' : '600px'}
                          title={t('layouts.mainLayout.__copyrightStatement')}
                        >
                          <div className="modalContent">
                            {userInfo.onePaperIdentity !== 'TestCram' ? (
                              <>
                                <div>
                                  {t('layouts.mainLayout.__cloudQuestionContent')}
                                </div>
                                <div>
                                  {t('layouts.mainLayout.__copyrightStatementDetails')}
                                </div>
                                <div>
                                  {t('layouts.mainLayout.__infringementWarning')}
                                </div>
                              </>
                            ) : (
                              <>
                                <div>
                                  OnePaper{t('layouts.mainLayout.__naniBookstoreEnterpriseCopyrightStatement')}
                                </div>
                                <div>
                                  {t('layouts.mainLayout.__copyrightStatementDetails')}
                                </div>
                                <div>
                                  {t('layouts.mainLayout.__infringementWarning')}
                                </div>
                              </>
                            )}
                          </div>
                        </Modal>
                      </div>
                      {/* {
                        location.pathname === '/login'
                        &&
                        <>
                          <img
                            src={'/assets/crmButton.svg'}
                            alt=""
                            style={{
                              position: 'fixed',
                              bottom: '56px',
                              right: '56px',
                              zIndex: '999'
                            }} />
                          {
                            showCustomerServiceTip && (
                              <>
                                <img src={'./assets/customerServiceTip.svg'}
                                  alt=""
                                  style={{
                                    position: 'fixed',
                                    bottom: '50px',
                                    right: '120px',
                                    width: '308px',
                                    height: '105px',
                                  }}
                                />
                                <img src={'./assets/cancelIcon.svg'}
                                  alt=""
                                  onClick={() => setShowCustomerServiceTip(!showCustomerServiceTip)}
                                  style={{
                                    position: 'fixed',
                                    right: '135px',
                                    bottom: '135px',
                                  }}
                                />
                              </>
                            )
                          }

                        </>
                      } */}
                    </>
                  }
                >
                  {(authState.value === 'loggedIn' ||
                    authState.value === 'settingEduSubject' ||
                    location.pathname === '/login' ||
                    location.pathname === '/maintenancePage') && (
                    <RenderRoutes routes={routes} props={props} />
                  )}
                </FrontLayout>
              </>
            )}
          </StyledMainLayout>
          {/* ------ loadingMark ------ */}
          <LogoutAlert
            show={isLogoutAlertOpen}
            onLeave={logout}
            onStay={() => setIsLogoutAlertOpen(false)}
          />
          {renderLoadingMark()}
        </RouteLeaveProvider>
      </ThemeProvider>
    </DndProvider>
  );
};

MainLayout.propTypes = {
  className: PropTypes.string,
  routes: PropTypes.array,
};
