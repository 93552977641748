const PAPER_LAYOUT = [
  {
    name: '直書單欄',
    value: 'HSS',
    t_key: 'singleColumnStraightBook'
  },
  {
    name: '直書雙欄',
    value: 'HSD',
    t_key: 'zhiShuDoubleColumn'
  },
  {
    name: '橫書單欄',
    value: 'VHS',
    t_key: 'horizontalSingleColumn'
  },
  {
    name: '橫書雙欄A',
    value: 'VHD',
    t_key: 'horizontalSingleColumnA'
  },
  {
    name: '橫書雙欄B',
    value: 'HHD',
    t_key: 'horizontalDoubleColumnA'
  }
];

export const paperLayoutMapper = (value) => {
  let layout = PAPER_LAYOUT.find(e => e.value === value || e.name === value);

  if (!layout) {
    return undefined;
  }
  return `constants.paperLayout.${layout.t_key}`;
};
