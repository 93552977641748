// BACK-END TRANSLATION
// THERE IS SOME VALUE ARE COMING FROM BACKEND

// how to get all possible value
// just god know
const SOURCE = [
  {
    value: '指考試題',
    t_key: 'referringToTheTestQuestion'
  },
  {
    value: '課本',
    t_key: 'textbook'
  },
  {
    value: '實驗活動手冊',
    t_key: 'experimentalActivityManual'
  },
  {
    value: '番茄報報',
    t_key: 'tomatoNews'
  },
  {
    value: '分科測驗試題',
    t_key: 'subjectBasedTestQuestions'
  },
  {
    value: '超越盃試題',
    t_key: 'transcendenceCupQuestions'
  },
  {
    value: '家教試聽後測',
    t_key: 'tutorAuditionPostTest'
  },
  {
    value: '南一精選',
    t_key: 'nanYiSelection'
  },
  {
    value: '家教試聽前測',
    t_key: 'tutorAuditionPreTest'
  },
  {
    value: '歷屆試題',
    t_key: 'pastExamPapers'
  },
  {
    value: '活動紀錄簿B',
    t_key: 'activityRecordBookB'
  },
  {
    value: '素養類題',
    t_key: 'competencyBasedQuestions'
  },
  {
    value: '指定考試類題',
    t_key: 'designatedExamQuestions'
  },
  {
    value: '計算機試題',
    t_key: 'computerTestQuestions'
  },
  {
    value: '實習手冊',
    t_key: 'internshipManual'
  },
  {
    value: '習作',
    t_key: 'exercise'
  },
  {
    value: '精選試題',
    t_key: 'selectedTestQuestions'
  },
  {
    value: '延伸讀本',
    t_key: 'extendedReading'
  },
  {
    value: '教師手冊',
    t_key: 'teachersManual'
  },
  {
    value: '輔助教材',
    t_key: 'supplementaryTeachingMaterials'
  },
  {
    value: '會考模擬題本',
    t_key: 'jointExamSimulationBook'
  },
  {
    value: '語文習作',
    t_key: 'languageExercise'
  },
  {
    value: '學習講義',
    t_key: 'learningLectureNotes'
  },
  {
    value: '補充教材',
    t_key: 'additionalTeachingMaterials'
  },
  {
    value: '各校試題',
    t_key: 'schoolSpecificTestQuestions'
  },
  {
    value: '中華文化基本教材習作',
    t_key: 'basicChineseCulturalTextExercises'
  },
  {
    value: '十分鐘大作戰',
    t_key: 'tenMinuteBattle'
  },
  {
    value: '混合題組',
    t_key: 'mixedQuestionSet'
  },
  {
    value: '跨科整合試題',
    t_key: 'interdisciplinaryIntegratedTestQuestions'
  },
  {
    value: '國際試題',
    t_key: 'internationalTestQuestions'
  },
  {
    value: '探討活動紀錄簿',
    t_key: 'explorationActivityRecordBook'
  },
  {
    value: '紀錄簿習題',
    t_key: 'recordBookExercises'
  },
  {
    value: 'TASA類題',
    t_key: 'tasaTypeQuestions'
  },
  {
    value: '每日數學',
    t_key: 'dailyMathematics'
  },
  {
    value: '素養題本',
    t_key: 'competencyBasedTextbook'
  },
  {
    value: '習作類題',
    t_key: 'exerciseBasedQuestions'
  },
  {
    value: 'EZ講義',
    t_key: 'ezLecture'
  },
  {
    value: '素養閱讀綜合試題',
    t_key: 'competencyReadingComprehensiveTestQuestions'
  },
  {
    value: '補充試題',
    t_key: 'supplementaryTestQuestions'
  },
  {
    value: '課本習題',
    t_key: 'textbookExercises'
  },
  {
    value: '補救教學',
    t_key: 'remedialTeaching'
  },
  {
    value: '課本試題',
    t_key: 'textbookTestQuestions'
  },
  {
    value: '學測類題',
    t_key: 'collegeEntranceExaminationTypeQuestions'
  },
  {
    value: '課本習題',
    t_key: 'textbookExercises'
  }
];


// BOOK NAME = VOLUME
export const sourceMapper = (value) => {
  const element = SOURCE.find(e => e.value === value);
  if (!element) {
    window.console.warn('Can\'t find element for backend translation');
    return undefined;
  }
  return `constants.source.${element.t_key}`;
};
