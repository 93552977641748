/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Radio, RadioGroup } from '@oneclass/onedesign';
import { Radio as AntRadio, } from 'antd';
import { fetchDataMachine, FETCH_DATA_EVENT, PickListDropdown } from '@oneclass/ui-components';
import { RECITATION_INTERVAL_OPTIONS, LISTEN_AUDIO_RATE, LISTEN_DEFAULT_SETTINGS } from 'constants/index';
import { StyledEnglishListenSettings, StyledEnglishListenSettingsRows, StyledTryListen } from './EnglishListenSettings.style';
import { useMachine } from '@xstate/react';
import { getDistricts } from 'api/examPaper';
import { getSchools } from 'api/examPaper';
import { getAudioSettings } from 'api/member';
import { schoolYearOptions } from 'utils/common';
import { SEMESTER_OPTIONS, GRADE_OPTIONS, EXAM_CATEGORY_OPTIONS, FORMAT_OPTIONS } from 'constants/index';
import deviceProvider from 'providers/deviceProvider';
import { useTranslation } from 'react-i18next';
const { SinglePickListDropdown } = PickListDropdown;

const {
  majorSectionInterval,
  minorSectionInterval,
  repetitionTimesPerQuestion,
  repetitionInterval
} = RECITATION_INTERVAL_OPTIONS;

const TryListen = ({ src }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const { t } = useTranslation();
  const playAudio = () => {
    if (isPlaying) return;
    const audio = new Audio(src);
    audio.onplay = () => setIsPlaying(true);
    audio.onended = () => setIsPlaying(false);
    audio.play();
  };

  return (
    <StyledTryListen onClick={playAudio} isPlaying={isPlaying}>
      <img src='/assets/volumeDown.svg' alt='' />
      <span>{t('components.englishListenSettings.__tryListen')}</span>
    </StyledTryListen>
  );
};
TryListen.propTypes = {
  src: PropTypes.string,
};

export const EnglishListenSettings = ({ sendParams, getSendParams, eduSubjectData, editCopyExamPaperData }) => {
  const { deviceData } = useContext(deviceProvider.deviceProviderContext);
  const { isDesktop } = deviceData || {};
  const { t } = useTranslation();
  const { audioTitle, recitation, outputAudioFormat, outputAudioRate } = sendParams.engAudioSetting || {};
  const { schoolLocation } = audioTitle || {};

  const [isRowsChecked, setIsRowsChecked] = useState({
    city: true,
    district: true,
    school: true,
    schoolYear: true,
    semester: true,
    grade: true,
    examCategory: true
  });

  const [citiesData, sendCities] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async () => {
        const res = await getDistricts();
        const { data, isSuccess, systemCode, message } = res;
        return {
          isSuccess, systemCode, message,
          cities: data.cities
        };
      }
    }
  });
  const { cities } = citiesData.context.result || {};

  const [schoolsData, sendSchools] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_, event) => {
        const res = await getSchools(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        return {
          isSuccess, systemCode, message,
          schools: data.schools
        };
      }
    }
  });
  const { schools } = schoolsData.context.result || {};

  const citiesOptions = useMemo(() =>
    (cities || []).map(city => ({ name: city.cityName, value: city.cityCode }))
  , [cities]);

  const districtsOptions = useMemo(() => {
    const { cityCode } = schoolLocation || {};
    if (cityCode && cities) {
      return (cities)
        .find(city => city.cityCode === cityCode)?.districts
        ?.map(district => ({ name: district.districtName, value: district.districtCode })) || [];
    }
    return [];
  }
  , [cities, schoolLocation?.cityCode]);

  const schoolsOptions = useMemo(() => {
    if (schoolLocation?.districtCode && schools) {
      return schools.map(school => ({ name: school.schoolName, value: school.schoolId }));
    }
    return [];
  }
  , [schools, schoolLocation?.districtCode]);

  const [audioSettingsData, sendAudioSettings] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_, event) => {
        const res = await getAudioSettings(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        return {
          isSuccess, systemCode, message,
          data
        };
      }
    }
  });
  const { data: audioSettings } = audioSettingsData.context.result || {};

  const updateAudioTitle = (value) => {
    getSendParams({
      engAudioSetting: {
        ...sendParams.engAudioSetting,
        audioTitle: {
          ...audioTitle,
          ...value
        }
      }
    });
  };

  const updateSchoolLocation = (value) => {
    getSendParams({
      engAudioSetting: {
        ...sendParams.engAudioSetting,
        audioTitle: {
          ...audioTitle,
          schoolLocation: {
            ...schoolLocation || {},
            ...value
          }
        }
      }
    });
  };

  const updateRecitation = (value) => {
    getSendParams({
      engAudioSetting: {
        ...sendParams.engAudioSetting,
        recitation: {
          ...recitation,
          ...value
        }
      }
    });
  };

  useEffect(() => {
    // 取得縣市、使用者英聽設定
    sendCities(FETCH_DATA_EVENT.FETCH);
    sendAudioSettings(FETCH_DATA_EVENT.FETCH, {
      payload: {
        eduSubject: eduSubjectData.edu + eduSubjectData.subject
      }
    });
  }, []);

  useEffect(() => {
    // 套用使用者前次設定，如果是編輯試卷（會有 editCopyExamPaperData.engAudioSetting），
    // 則套用 editCopyExamPaperData.engAudioSetting，而不是 audioSettings
    if (editCopyExamPaperData?.engAudioSetting) {
      const { engAudioTitle, outputAudioFormat, outputAudioRate, recitationSettings } = editCopyExamPaperData.engAudioSetting;
      getSendParams({
        engAudioSetting: {
          ...sendParams.engAudioSetting,
          audioTitle: engAudioTitle,
          outputAudioFormat,
          outputAudioRate,
          recitation: recitationSettings
        }
      });
    } else {
      if (audioSettings) {
        getSendParams({ engAudioSetting: audioSettings });
      }
    }
  }, [editCopyExamPaperData, audioSettings]);

  useEffect(() => {
    const { cityCode, districtCode } = schoolLocation || {};
    if (cityCode && districtCode) {
      sendSchools(FETCH_DATA_EVENT.FETCH, { payload: { districtCode } });
    }
  }, [schoolLocation?.districtCode]);

  // 清空選項
  useEffect(() => {
    updateSchoolLocation({ districtCode: null });
  }, [schoolLocation?.cityCode]);
  useEffect(() => {
    updateSchoolLocation({ schoolId: null });
  }, [schoolLocation?.districtCode]);

  useEffect(() => {
    const { cityCode, districtCode, schoolId } = schoolLocation || {};
    if (schoolId) {
      const cityName = citiesOptions.find((city) => city.value === cityCode)?.name;
      const districtName = districtsOptions.find((district) => district.value === districtCode)?.name;
      const schoolName = schoolsOptions.find((school) => school.value === schoolId)?.name;
      updateSchoolLocation({ cityName, districtName, schoolName });
    }
  }, [schoolLocation?.schoolId, citiesOptions, districtsOptions, schoolsOptions]);

  useEffect(() => {
    let newSettings = { ...audioTitle };
    Object.entries(isRowsChecked).forEach(([row, isChecked]) => {
      if (!isChecked) {
        switch (row) {
          case 'city':
            newSettings = {
              ...newSettings,
              schoolLocation: null
            };
            break;
          case 'district':
            newSettings = {
              ...newSettings,
              schoolLocation: {
                ...schoolLocation,
                districtCode: null,
                schoolId: null
              }
            };
            break;
          case 'school':
            newSettings = {
              ...newSettings,
              schoolLocation: {
                ...schoolLocation,
                schoolId: null,
                ...(isDesktop ? { cityCode: null } : {})
              }
            };
            break;
          case 'schoolYear':
            newSettings = {
              ...newSettings,
              schoolYear: null,
            };
            break;
          case 'semester':
            newSettings = {
              ...newSettings,
              semester: null,
            };
            break;
          case 'grade':
            newSettings = {
              ...newSettings,
              grade: null
            };
            break;
          case 'examCategory':
            newSettings = {
              ...newSettings,
              examCategory: null
            };
            break;
          default:
            break;
        }
      }
    });
    updateAudioTitle(newSettings);
  }, [isRowsChecked]);

  return (
    <StyledEnglishListenSettings>
      <div className='setting'>
        <div className='header'>
          <span className='title'>{t('components.englishListenSettings.__englishListenSettings')}</span>
          <div className='hint'>
            <img src='/assets/headphones.svg' alt='' />
            <span>{t('components.englishListenSettings.__audioTitleContent')}</span>
          </div>
        </div>
        <StyledEnglishListenSettingsRows>
          <div className='row check'>
            <div className='checkItem'>
              <Checkbox
                className='checkbox'
                checked={isRowsChecked.city}
                onClick={(e) => {
                  const { checked } = e.target;
                  if (isDesktop) {
                    setIsRowsChecked(prev => ({ ...prev, city: checked, district: checked, school: checked }));
                  } else {
                    setIsRowsChecked(prev => ({ ...prev, city: checked }));
                  }
                }}
              />
              <div className='selectItem'>
                <div className='label'>{t('components.englishListenSettings.__county')}</div>
                <SinglePickListDropdown
                  defaultTitle=''
                  className='dropdown'
                  disabled={!isRowsChecked.city}
                  options={citiesOptions}
                  onClick={(value) => updateSchoolLocation({ cityCode: value })}
                  value={schoolLocation?.cityCode}
                />
              </div>
            </div>
            <div className='checkItem'>
              {!isDesktop && <Checkbox
                className='checkbox'
                checked={isRowsChecked.district}
                onClick={(e) => setIsRowsChecked(prev => ({ ...prev, district: e.target.checked }))}
              />}
              <div className='selectItem'>
                <div className='label'>{t('components.englishListenSettings.__area')}</div>
                <SinglePickListDropdown
                  defaultTitle=''
                  className='dropdown'
                  disabled={!isRowsChecked.district}
                  options={districtsOptions}
                  onClick={(value) => updateSchoolLocation({ districtCode: value })}
                  value={schoolLocation?.districtCode}
                />
              </div>
            </div>
            <div className='checkItem'>
              {!isDesktop && <Checkbox
                className='checkbox'
                checked={isRowsChecked.school}
                onClick={(e) => setIsRowsChecked(prev => ({ ...prev, school: e.target.checked }))}
              />}
              <div className='selectItem'>
                <div className='label'>{t('components.englishListenSettings.__schoolName')}</div>
                <SinglePickListDropdown
                  defaultTitle=''
                  className='dropdown'
                  disabled={!isRowsChecked.school}
                  options={schoolsOptions}
                  onClick={(value) => updateSchoolLocation({ schoolId: value })}
                  value={schoolLocation?.schoolId}
                />
              </div>
            </div>
          </div>
          <div className='row check'>
            <div className='checkItem'>
              <Checkbox
                className='checkbox'
                checked={isRowsChecked.schoolYear}
                onClick={(e) => {
                  const { checked } = e.target;
                  if (isDesktop) {
                    setIsRowsChecked(prev => ({ ...prev, schoolYear: checked, semester: checked, grade: checked }));
                  } else {
                    setIsRowsChecked(prev => ({ ...prev, schoolYear: checked }));
                  }
                }}
              />
              <div className='selectItem'>
                <div className='label'>{t('components.englishListenSettings.__schoolYear')}</div>
                <SinglePickListDropdown
                  defaultTitle=''
                  className='dropdown'
                  disabled={!isRowsChecked.schoolYear}
                  options={schoolYearOptions()}
                  onClick={(value) => updateAudioTitle({ schoolYear: value })}
                  value={audioTitle?.schoolYear}
                />
              </div>
            </div>
            <div className='checkItem'>
              {!isDesktop && <Checkbox
                className='checkbox'
                checked={isRowsChecked.semester}
                onClick={(e) => setIsRowsChecked(prev => ({ ...prev, semester: e.target.checked }))}
              />}
              <div className='selectItem'>
                <div className='label'>{t('components.englishListenSettings.__semester')}</div>
                <SinglePickListDropdown
                  defaultTitle=''
                  className='dropdown'
                  disabled={!isRowsChecked.semester}
                  options={SEMESTER_OPTIONS}
                  onClick={(value) => updateAudioTitle({ semester: value })}
                  value={audioTitle?.semester}
                />
              </div>
            </div>
            <div className='checkItem'>
              {!isDesktop && <Checkbox
                className='checkbox'
                checked={isRowsChecked.grade}
                onClick={(e) => setIsRowsChecked(prev => ({ ...prev, grade: e.target.checked }))}
              />}
              <div className='selectItem'>
                <div className='label'>{t('components.englishListenSettings.__grade')}</div>
                <SinglePickListDropdown
                  defaultTitle=''
                  className='dropdown'
                  disabled={!isRowsChecked.grade}
                  options={GRADE_OPTIONS}
                  onClick={(value) => updateAudioTitle({ grade: value })}
                  value={audioTitle?.grade}
                />
              </div>
            </div>
          </div>
          <div className='row check'>
            <div className='checkItem'>
              <Checkbox
                className='checkbox'
                checked={isRowsChecked.examCategory}
                onClick={(e) => setIsRowsChecked(prev => ({ ...prev, examCategory: e.target.checked }))}
              />
              <div className='selectItem'>
                <div className='label'>{t('components.englishListenSettings.__examCategory')}</div>
                <SinglePickListDropdown
                  defaultTitle=''
                  className='dropdown'
                  disabled={!isRowsChecked.examCategory}
                  options={EXAM_CATEGORY_OPTIONS}
                  onClick={(value) => updateAudioTitle({ examCategory: value })}
                  value={audioTitle?.examCategory}
                />
              </div>
            </div>
          </div>
        </StyledEnglishListenSettingsRows>
      </div>
      {!isDesktop && <div className='hr' />}
      <div className='setting'>
        <div className='header'>
          <span className='title'>{t('components.englishListenSettings.__presentationMode')}</span>
          <Button
            className='actionButton'
            onClick={() => {
              getSendParams({ engAudioSetting: { ...sendParams.engAudioSetting, recitation: LISTEN_DEFAULT_SETTINGS.recitation } });
            }}
          >
            <img src='/assets/redoCircle.svg' alt='' />
            <span>{t('components.englishListenSettings.__useExamSetting')}</span>
          </Button>
        </div>
        <StyledEnglishListenSettingsRows>
          <div className='row noCheck'>
            <div className='subtitle'>{t('components.englishListenSettings.__outputAudioFile')}</div>
            <div className='radioGroup'>
              <RadioGroup
                value={outputAudioFormat}
                onChange={(e) => getSendParams({ engAudioSetting: { ...sendParams.engAudioSetting, outputAudioFormat: e.target.value } })}
              >
                {FORMAT_OPTIONS.map((format) => (
                  <Radio key={format.value} value={format.value}>{format.name}</Radio>
                ))}
              </RadioGroup>
            </div>
          </div>
          <div className='row noCheck'>
            <div className='item'>
              <div className='label'>{t('components.englishListenSettings.__majorSectionInterval')}</div>
              <div className='select'>
                <SinglePickListDropdown
                  defaultTitle=''
                  className='dropdown'
                  options={majorSectionInterval}
                  onClick={(value) => updateRecitation({ majorSectionInterval: value })}
                  value={recitation?.majorSectionInterval}
                />
                <span className='unit'>{t('components.englishListenSettings.__second')}</span>
              </div>
            </div>
            <div className='item'>
              <div className='label'>{t('components.englishListenSettings.__minorSectionInterval')}</div>
              <div className='select'>
                <SinglePickListDropdown
                  defaultTitle=''
                  className='dropdown'
                  options={minorSectionInterval}
                  onClick={(value) => updateRecitation({ minorSectionInterval: value })}
                  value={recitation?.minorSectionInterval}
                />
                <span className='unit'>{t('components.englishListenSettings.__second')}秒</span>
              </div>
            </div>
            <div className='item'>
              <div className='label'>{t('components.englishListenSettings.__repetitionTimesPerQuestion')}</div>
              <div className='select'>
                <SinglePickListDropdown
                  defaultTitle=''
                  className='dropdown'
                  options={repetitionTimesPerQuestion}
                  onClick={(value) => updateRecitation({ repetitionTimesPerQuestion: value })}
                  value={recitation?.repetitionTimesPerQuestion}
                />
                <span className='unit'>{t('components.englishListenSettings.__secondRate')}</span>
              </div>
            </div>
            <div className='item'>
              <div className='label'>{t('components.englishListenSettings.__repetitionInterval')}</div>
              <div className='select'>
                <SinglePickListDropdown
                  defaultTitle=''
                  className='dropdown'
                  options={repetitionInterval}
                  onClick={(value) => updateRecitation('repetitionInterval', value)}
                  value={recitation?.repetitionInterval}
                />
                <span className='unit'>{t('components.englishListenSettings.__second')}</span>
              </div>
            </div>
          </div>
        </StyledEnglishListenSettingsRows>
      </div>
      {!isDesktop && <div className='hr' />}
      <div className='setting'>
        <div className='header'>
          <span className='title'>{t('components.englishListenSettings.__playbackSpeed')}</span>
          <div className='hint'>{t('components.englishListenSettings.__demoAudioFiles')}</div>
        </div>
        <StyledEnglishListenSettingsRows>
          <div className='row options'>
            <RadioGroup
              className='radioGroup'
              buttonStyle='solid'
              optionType='button'
              value={outputAudioRate}
              onChange={(e) => getSendParams({ engAudioSetting: { ...sendParams.engAudioSetting, outputAudioRate: e.target.value } })}
            >
              {LISTEN_AUDIO_RATE.map(({ value, name, src }) => (
                <div key={value} className='option'>
                  <AntRadio.Button value={value}>
                    {name}
                  </AntRadio.Button>
                  <TryListen src={src} />
                </div>
              ))}
            </RadioGroup>
          </div>
        </StyledEnglishListenSettingsRows>
      </div>
      {!isDesktop && <div className='hr' />}
      <div className='setting'>
        <div className='header'>
          <span className='title'>{t('components.englishListenSettings.__extendedFunctions')}</span>
          <div className='hint'>{t('components.englishListenSettings.__demoAudioFiles')}</div>
        </div>
        <StyledEnglishListenSettingsRows>
          <div className='row options'>
            <div className='option'>
              <Checkbox
                className='checkbox'
                checked={recitation?.needExamStartAudio}
                onClick={(e) => updateRecitation({ needExamStartAudio: e.target.checked })}
              >
                {t('components.englishListenSettings.__frontMusic')}
              </Checkbox>
              <TryListen />
            </div>
            <div className='option'>
              <Checkbox
                className='checkbox'
                checked={recitation?.needExamEndAudio}
                onClick={(e) => updateRecitation({ needExamEndAudio: e.target.checked })}
              >
                {t('components.englishListenSettings.__endMusic')}
              </Checkbox>
              <TryListen />
            </div>
          </div>
        </StyledEnglishListenSettingsRows>
      </div>
    </StyledEnglishListenSettings>
  );
};