/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import { Tooltip } from 'antd';
import { Button } from '@oneclass/onedesign';
import {
  CopySolid,
  CheckCircleSolid
} from '@onedesign/icon';
import { StyledTestCodeModalContent } from './TestCodeModalContent.style';
import * as GA from 'utils/googleAnalytics';
import { useTranslation } from 'react-i18next';

export const TestCodeModalContent = ({ className, examLink, modalInfo, shareModalData }) => {

  const linkRef = useRef(null);
  const textRef = useRef(null);
  const { t, i18n } = useTranslation();

  const copyLink = async(type, e) => {
    if (e) {
      const clipboardObj = navigator.clipboard;
      await clipboardObj.writeText(type.current.value);
    }
  };

  const textForCopy = t('components.testCodeModalContent.copyText', { month: shareModalData?.endTime?.month, day: shareModalData?.endTime?.day, time: shareModalData?.endTime?.time, examLink, quizCodeTop4: shareModalData?.quizCode?.top4, quizCodeLast4: shareModalData?.quizCode?.last4 });

  return (
    <StyledTestCodeModalContent className={className} data-testid="TestCodeModalContent">
      <div className='testModalContent'>
        <div className='testModalLinkBox'>
          <div className='testModalLinkTitle'>{modalInfo?.QuizName}</div>
          <div className='endTime'>
            <span> {t('components.testCodeModalContent.__testModalTitle')}</span>
            {i18n.language === 'zh' ? (
              <div>{`${shareModalData?.endTime?.month}/${shareModalData?.endTime?.day} ${shareModalData?.endTime?.time}`}</div>
            ) : (
              <div>{`${shareModalData?.endTime?.day}/${shareModalData?.endTime?.month} ${shareModalData?.endTime?.time}`}</div>
            )}
          </div>
          <div className='testModalLink' id="testCodeModalLink">
            <div className='testCode'><span>{modalInfo.QuizCode.substr(0, 4)}</span> <span>{modalInfo.QuizCode.substr(4)}</span></div>
            <input disabled className='testModalLinkData' ref={linkRef} value={`${modalInfo.QuizCode}`} />
            <div className='copyImgBox'>
              <Tooltip
                trigger='click'
                placement="top"
                getPopupContainer={() => document.getElementById('testCodeModalLink') || document.body}
                title={
                  <div className='copyHint'>
                    <div className='copyImg'>
                      <CheckCircleSolid />
                    </div>
                    {t('components.testCodeModalContent.__copyHint')}
                  </div>
                }
                onVisibleChange={(visible) => {copyLink(linkRef, visible);}}>
                <CopySolid className='copyBtn'/>
              </Tooltip>
            </div>
          </div>
          <ul className='testModalLinkText'>
            <li> {t('components.testCodeModalContent.__testModalLinkText1')}{examLink}</li>
            <li> {t('components.testCodeModalContent.__examLinkText2', { examLink, top4: shareModalData?.quizCode?.top4, last4: shareModalData?.quizCode?.last4 })}</li>
          </ul>
        </div>
        <div className='testModalManualBox'>
          <div className='testModalManualTitle'> {t('components.testCodeModalContent.__testModalManualIntro')}</div>
          <textarea disabled style={{ display: 'none' }} ref={textRef} value={textForCopy} />
          <ul className='testModalManualList'>
            {i18n.language === 'zh' ? (
              <li>{t('components.testCodeModalContent.__testModalManualText1')} {shareModalData?.endTime?.month}/{shareModalData?.endTime?.day} {shareModalData?.endTime?.time}</li>
            ) : (
              <li>{t('components.testCodeModalContent.__testModalManualText1')} {shareModalData?.endTime?.day}/{shareModalData?.endTime?.month} {shareModalData?.endTime?.time}</li>
            )}
            <li> {t('components.testCodeModalContent.__testModalManualText2')}<a href={examLink} target='_blank' rel='noreferrer'>{examLink}</a></li>
            <li>{t('components.testCodeModalContent.__testModalManualText3')}{shareModalData?.quizCode?.top4} {shareModalData?.quizCode?.last4}</li>
            <li>{t('components.testCodeModalContent.__testModalManualText4')}</li>
            <li>{t('components.testCodeModalContent.__testModalManualText5')} <a href={examLink} target='_blank' rel='noreferrer'>{examLink}</a>{t('components.testCodeModalContent.__testModalLinkText3', { top4: shareModalData?.quizCode?.top4, last4: shareModalData?.quizCode?.last4 })}</li>
          </ul>
          <div className='testModalManualButton' id='testCodeModalManualList'>
            <Tooltip
              trigger='click'
              placement="top"
              getPopupContainer={() => document.getElementById('testCodeModalManualList') || document.body}
              title={
                <div className='copyHint'>
                  <div className='copyImg'>
                    <CheckCircleSolid />
                  </div>
                  {t('components.testCodeModalContent.__copyHint')}
                </div>
              }
              onVisibleChange={(visible) => {
                GA.copyShareExamManual('test_code');
                copyLink(textRef, visible);
              }}>
              <Button className='copyTextBtn' type='solid'><CopySolid />{t('components.testCodeModalContent.__copyTextBtn')}</Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </StyledTestCodeModalContent>
  );
};