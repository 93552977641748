import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Box, DistributedCard, fetchDataMachine, FETCH_DATA_EVENT, FETCH_DATA_STATE, } from '@oneclass/ui-components';
import { useMachine } from '@xstate/react';
import { getDrawUpAutoCacheQuery } from 'api/drawUpAuto';
import { RadioGroup } from '@oneclass/onedesign';
import { Radio } from 'antd';
import { ExclamationCircleSolid } from '@onedesign/icon';
import { DIFFICULTY } from 'constants/index';
import { StyledStudentIpExamSettingContent } from './StudentIpExamSettingContent.style';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { useTranslation } from 'react-i18next';

const goTop = () => {
  const main = document.getElementById('mainLayout');
  main.scrollTop = 0;
};
export const StudentIpExamSettingContent = ({
  className,
  memberEduSubject,
  saveData,
  onChangeFilterUsedQuestions,
  getCreateExamPayload,
  renderLoadingContent,
  isLoading,
  questionGroup,
}) => {
  const { t } = useTranslation();
  const [difficultyDistributionArea, setDifficultyDistributionArea] = useState([20, 40, 40]);//易中難
  const [questionCount, setQuestionCount] = useState(0);
  const [activeCardId, setActiveCardId] = useState('A');
  // const { isDesktop } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);

  // const [stateGetDrawUpAutoCacheQuery, sendGetDrawUpAutoCacheQuery] = useMachine(fetchDataMachine, {
  //   services: {
  //     fetchData: async(_context, event) => {
  //       const res = await getDrawUpAutoCacheQuery(event.pattern, event.payload);
  //       const { data, isSuccess, systemCode, message } = res;
  //       // const { curriculumMap = {}, bookMap = {}, sourceMap = {}, chapterMap = {}, yearMap = {} } = data?.selection || {};
  //       if (isSuccess) { goTop();}
  //       const { questionGroup = {}, searchKey = '' } = data || {};
  //       return {
  //         isSuccess, systemCode, message,
  //         questionGroup,
  //         searchKey
  //       };
  //     },
  //   },
  // });

  // const { questionGroup, searchKey } = stateGetDrawUpAutoCacheQuery.context.result || {};

  const distributedCardList = [
    {
      title: t('containers.studentIpExamSettingContent.__examA'),
      id: 'A',
      data: [
        {
          label: t('containers.studentIpExamSettingContent.__difficult'),
          key: DIFFICULTY[2],
          percent: 40
        },
        {
          label: t('containers.studentIpExamSettingContent.__medium'),
          key: DIFFICULTY[1],
          percent: 40
        },
        {
          label: t('containers.studentIpExamSettingContent.__easy'),
          key: DIFFICULTY[0],
          percent: 20
        },
      ]
    },
    {
      title: t('containers.studentIpExamSettingContent.__examB'),
      id: 'B',
      data: [
        {
          label: t('containers.studentIpExamSettingContent.__difficult'),
          key: DIFFICULTY[2],
          percent: 20
        },
        {
          label: t('containers.studentIpExamSettingContent.__medium'),
          key: DIFFICULTY[1],
          percent: 50
        },
        {
          label: t('containers.studentIpExamSettingContent.__easy'),
          key: DIFFICULTY[0],
          percent: 30
        },
      ]
    },
    {
      title: t('containers.studentIpExamSettingContent.__examC'),
      id: 'C',
      data: [
        {
          label: t('containers.studentIpExamSettingContent.__difficult'),
          key: DIFFICULTY[2],
          percent: 10
        },
        {
          label: t('containers.studentIpExamSettingContent.__medium'),
          key: DIFFICULTY[1],
          percent: 50
        },
        {
          label: t('containers.studentIpExamSettingContent.__easy'),
          key: DIFFICULTY[0],
          percent: 40
        },
      ]
    },
  ];

  const questionCountList = [
    {
      label: `10${t('containers.studentIpExamSettingContent.__question')}`,
      value: 10,
    },
    {
      label: `20${t('containers.studentIpExamSettingContent.__question')}`,
      value: 20,
    },
    {
      label: `25${t('containers.studentIpExamSettingContent.__question')}`,
      value: 25,
    },
    {
      label: `40${t('containers.studentIpExamSettingContent.__question')}`,
      value: 40,
    },
    {
      label: `50${t('containers.studentIpExamSettingContent.__question')}`,
      value: 50,
    },
  ];

  const onCardClick = (card) => {
    setActiveCardId(card.id);

    const diffArea = card.data.map(item => item.percent).reverse();
    setDifficultyDistributionArea(diffArea);
  };

  const onQuestionCountChange = (e) => {
    setQuestionCount(e.target.value);
  };

  const getRandomDifficulty = (difficultyDistribution) => {
    const totalDistribution = difficultyDistribution.reduce((acc, v) => acc + v, 0);
    let dice = Math.floor(Math.random() * totalDistribution);
    let difficulty = DIFFICULTY[0];
    for (let i = 0; i < difficultyDistribution.length; ++i) {
      dice -= difficultyDistribution[i];
      if (dice <= 0) {
        difficulty = DIFFICULTY[i];
        break;
      }
    }
    return difficulty;
  };

  const checkPickQuestionIsMax = (difficulty, remainingQuestions, pickUpQuestions, questionCount, difficultyDistribution) => {
    const maxDiffQuestionCount = difficultyDistribution.map(item => ((questionCount * item) / 100));
    if (remainingQuestions[difficulty].length <= 0) return false;
    let result = true;
    switch (difficulty) {
      case DIFFICULTY[1]:
        if (pickUpQuestions[difficulty].length === maxDiffQuestionCount[1] &&
          remainingQuestions[DIFFICULTY[0]].length > maxDiffQuestionCount[0] &&
          remainingQuestions[DIFFICULTY[2]].length > maxDiffQuestionCount[2]
        ) result = false;

        break;
      case DIFFICULTY[2]:
        if (pickUpQuestions[difficulty].length === maxDiffQuestionCount[2] &&
          remainingQuestions[DIFFICULTY[1]].length > maxDiffQuestionCount[1] &&
          remainingQuestions[DIFFICULTY[0]].length > maxDiffQuestionCount[0]
        ) result = false;

        break;
      case DIFFICULTY[0]:
        if (pickUpQuestions[difficulty].length === maxDiffQuestionCount[0] &&
            remainingQuestions[DIFFICULTY[1]].length > maxDiffQuestionCount[1] &&
            remainingQuestions[DIFFICULTY[2]].length > maxDiffQuestionCount[2]
        ) result = false;

        break;
      default:
        break;
    }
    return result;
  };

  const distributeByQuestionCount = (questionCount, difficultyDistributionArea) => {
    if (!questionGroup) return;
    const calData = { ...questionGroup } || {};
    const maxDiffQuestionCount = difficultyDistributionArea.map(item => ((questionCount * item) / 100));
    let count = 0;
    let pickUpQuestions = {
      [DIFFICULTY[0]]: [],
      [DIFFICULTY[1]]: [],
      [DIFFICULTY[2]]: [],
    };
    let remainingQuestions = {
      [DIFFICULTY[0]]: [].concat(calData[DIFFICULTY[0]] || []),
      [DIFFICULTY[1]]: [].concat(calData[DIFFICULTY[1]] || []),
      [DIFFICULTY[2]]: [].concat(calData[DIFFICULTY[2]] || []),
    };
    let stop = 0;
    let difficultyDistribution = [].concat(difficultyDistributionArea);
    if (remainingQuestions[DIFFICULTY[0]].length > maxDiffQuestionCount[0] &&
      remainingQuestions[DIFFICULTY[1]].length > maxDiffQuestionCount[1] &&
      remainingQuestions[DIFFICULTY[2]].length > maxDiffQuestionCount[2]
    ) {
      while (count < questionCount && stop < 100000) {
        stop = stop + 1;
        const difficulty = getRandomDifficulty(difficultyDistribution);
        if (checkPickQuestionIsMax(difficulty, remainingQuestions, pickUpQuestions, questionCount, difficultyDistribution)) {
          const pickUp = Math.floor(Math.random() * remainingQuestions[difficulty].length);
          const [question] = remainingQuestions[difficulty].splice(pickUp, 1);
          pickUpQuestions[difficulty].push(question);
          ++count;
        } else {
          if (difficulty === DIFFICULTY[0]) {
            difficultyDistribution[0] = 0;
          } else if (difficulty === DIFFICULTY[1]) {
            difficultyDistribution[1] = 0;
          } else if (difficulty === DIFFICULTY[2]) {
            difficultyDistribution[2] = 0;
          }
        }
      }
    } else {
      while (count < questionCount && stop < 100000) {
        stop = stop + 1;
        const difficulty = getRandomDifficulty(difficultyDistribution);
        if (remainingQuestions[difficulty].length > 0) {
          const pickUp = Math.floor(Math.random() * remainingQuestions[difficulty].length);
          const [question] = remainingQuestions[difficulty].splice(pickUp, 1);
          pickUpQuestions[difficulty].push(question);
          ++count;
        } else {
          if (difficulty === DIFFICULTY[0]) {
            difficultyDistribution[0] = 0;
          } else if (difficulty === DIFFICULTY[1]) {
            difficultyDistribution[1] = 0;
          } else if (difficulty === DIFFICULTY[2]) {
            difficultyDistribution[2] = 0;
          }
        }
      }
    }



    return pickUpQuestions;
  };

  const allQuestionCount = useMemo(() => {
    if (!questionGroup || JSON.stringify(questionGroup) === '{}') return 0;
    let count = 0;
    Object.entries(questionGroup).forEach(([key, value]) => {
      if (value) {
        count += value.length;
      }

    });
    return count;
  }, [questionGroup]);


  useEffect(() => {
    if (questionCount <= 0) return;
    const pickUpQuestions = distributeByQuestionCount(questionCount, difficultyDistributionArea);
    let totalQuesNum = 0;
    if (!pickUpQuestions) return;
    let questionGroupData = {};
    Object.entries(pickUpQuestions).forEach(([key, value]) => {
      value.forEach(question => {
        totalQuesNum++;
        questionGroupData = {
          ...questionGroupData,
          [question.quesType]: questionGroupData[question.quesType] ? questionGroupData[question.quesType].concat(question.uid) : [].concat(question.uid)
        };
      });
    });
    const perScore = parseInt(100 / totalQuesNum);
    let payloadQuestionGroup = [];
    Object.entries(questionGroupData).forEach(([key, value]) => {
      payloadQuestionGroup.push({
        typeCode: key,
        scoreType: 'PerQuestion',
        score: perScore,
        questionList: value
      });
    });
    getCreateExamPayload([].concat(payloadQuestionGroup));
  }, [questionCount, difficultyDistributionArea]);

  // useEffect(() => {
  //   if (!saveData) return;
  //   const { year, keys, filterUsed, bookIDs } = saveData.areaParams;
  //   sendGetDrawUpAutoCacheQuery(FETCH_DATA_EVENT.FETCH, {
  //     pattern: 'AutoPattern',
  //     payload: {
  //       year,
  //       education: memberEduSubject?.edu || 'J',
  //       subject: memberEduSubject?.subject || 'GE',
  //       bookIDs,
  //       keys,
  //       filterUsed
  //     }
  //   });
  // }, [saveData.areaParams]);


  return (
    isLoading ?
      renderLoadingContent()
      :
      <StyledStudentIpExamSettingContent className={className} data-testid="StudentIpExamSettingContent">
        <Box mb={5}>
          <div className="pageTitle">
            {t('containers.studentIpExamSettingContent.__examSelection')}
          </div>
        </Box>
        <Box mb={5} pl={deviceData.isDesktop ? 2 : 0}>
          <div className='cardList'>
            {
              distributedCardList.map(card => (
                <DistributedCard active={activeCardId === card.id} title={card.title} data={card.data} onClick={() => {onCardClick(card);}} />
              ))
            }
          </div>

        </Box>
        <Box mb={5}>
          <div className="pageTitle">
            {t('containers.studentIpExamSettingContent.__questionSelection')}
          </div>
        </Box>
        {/* <Box mb={4} pl={2}>
        <Checkbox checked={saveData?.areaParams?.filterUsed} onClick={onChangeFilterUsedQuestions} >過濾已出過的試題</Checkbox>
      </Box> */}
        <Box mb={2} pl={deviceData.isDesktop ? 2 : 0}>
          <div className='quesCount'>
            {t('containers.studentIpExamSettingContent.__quesCount',{allQuestionCount})}
          </div>
        </Box>
        <Box pb={5} pl={deviceData.isDesktop ? 2 : 0}>

          <div className='radioList'>
            <RadioGroup buttonStyle='solid' optionType='button' value={questionCount} onChange={(e) => {onQuestionCountChange(e);}} >
              {
                questionCountList && questionCountList.map(item => (
                  item.value > allQuestionCount ?
                    <Radio.Button disabled={true} value={item.value}>{item.label}</Radio.Button> :
                    <Radio.Button value={item.value}>{item.label}</Radio.Button>
                ))
              }
            </RadioGroup>
            {
              allQuestionCount < 10 &&
            <div className="message">
              <ExclamationCircleSolid/>
              {t('containers.studentIpExamSettingContent.__insufficientQuestions')}
            </div>
            }

          </div>
        </Box>
      </StyledStudentIpExamSettingContent>
  );
};