export const reportErrorTypeMapper = (value) => {
  const element = REPORT_ERROR_TYPE.find(e => e.code === value);
  if (!element) {
    return undefined;
  }
  return `constants.reportErrorType.${element.t_key}`;
};



export const REPORT_ERROR_TYPE = [
  {
    code: 'Content',
    name: '試題內容有誤',
    t_key: 'content',
  },
  {
    code: 'Answer',
    name: '答案有誤',
    t_key: 'answer',

  },
  {
    code: 'Range',
    name: '超出課綱範圍',
    t_key: 'range',
  },
  {
    code: 'Suggestion',
    name: '試題修改建議',
    t_key: 'suggestion',
  }
];

/*


{
     code: 'Content',
     name: 'The content of the test question is incorrect',
     t_key: '',
   },
   {
     code: 'Answer',
     name: 'Answer is wrong',
     t_key: '',

   },
   {
     code: 'Range',
     name: 'Outside the scope of the syllabus',
     t_key: '',
   },
   {
     code: 'Suggestion',
     name: 'Test question modification suggestions',
     t_key: '',
   }
];

*/