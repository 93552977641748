import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useBoolean } from 'ahooks';
import update from 'immutability-helper';
import { Button } from '@oneclass/onedesign';
import { Radio } from 'antd';
import { SearchPlusSolid, XmarkOutline, ReturnSolid, UndoSolid, ExclamationCircleSolid } from '@onedesign/icon';
import { Box, Table, sectionToChinese, Modal, Drawer, FETCH_DATA_EVENT, fetchDataMachine, setSelectOptions, Input, openNotificationWithIcon, Select } from '@oneclass/ui-components';
import { formateQuestionList, shuffle } from 'utils/common';
import { StyledEquationDojoQuestionReviewContent, StyleReportModalContent, StyledActionIcon } from './EquationDojoQuestionReviewContent.style';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { useMachine } from '@xstate/react';
import { getAnomalySelectOption } from 'api/drawUpFast';
import { postQuestionReport } from 'api/question';
import { Tooltip } from 'antd';
import * as GA from 'utils/googleAnalytics';
import { useTranslation } from 'react-i18next';

const defaultCountInputValue = {
  answer: '',
  question: '',
  score: {
    perQues: '',
    perAns: '',
    allScore: 0,
  },
};

export const EquationDojoQuestionReviewContent = ({
  className,
  prevKeyList,
  questions,
  examPaperTotalScoreData,
  getKeyList,
  getSaveData,
  memberEduSubject,
  eduSubjectData,
  tableData,
  setTableData,
}) => {
  const { t } = useTranslation();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const [modalImageSrc, setModalImageSrc] = useState(null);
  const [modalVisible, { toggle: setModalVisibleToggle, setFalse: setModalVisibleFalse }] = useBoolean(false);
  const [keyList, setKeyList] = useState(prevKeyList);
  const [questionList, setQuestionList] = useState({});
  const [targetPosition, setTargetPosition] = useState({
    first: '',
    second: '',
  });
  const [questionImageSizeMap, setQuestionImageSizeMap] = useState({});

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      GA.dragQuestionTypeOrderPreview();
      const dragCard = keyList[dragIndex];
      setKeyList(
        update(keyList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      );
      // 初始化 更換試題機制
      setTargetPosition({
        first: '',
        second: '',
      });
    },
    [keyList],
  );

  useEffect(() => {
    if (Object.keys(questionList).length === 0 || !keyList) return;
    const nextData = keyList.filter(item => Object.keys(questionList).includes(item));
    setKeyList(nextData);
  }, [questionList]);

  const showQuestionDetail = (src) => {
    setModalImageSrc(src);
    setModalVisibleToggle();
  };

  useEffect(() => {
    if (Object.keys(tableData).length === 0) return [];
    let result = {};
    if (questionList && Object.keys(questionList).length > 0) {
      Object.entries(tableData).forEach(([key, value], index) => {
        const quesTypeData = formateQuestionList(value?.pickUpQuestions, questions);
        let nextQuesTypeData = [];
        if (quesTypeData.length > 0) {
          if (questionList[key]) {
            const prevData = questionList[key];
            const prevDataUid = prevData.map(item => item.uid);
            if (prevData.length < quesTypeData.length) {
              const diffData = quesTypeData.filter(item => !prevDataUid.includes(item.uid));
              nextQuesTypeData = prevData.concat(diffData);
            } else {
              const quesTypeDataUid = quesTypeData.map(item => item.uid);
              nextQuesTypeData = prevData.filter(item => quesTypeDataUid.includes(item.uid));
            }
            result = { ...result, [key]: nextQuesTypeData };
          } else {
            result = { ...result, [key]: quesTypeData };
          }
        }
      });
    } else {
      Object.entries(tableData).forEach(([key, value], index) => {
        const quesTypeData = formateQuestionList(value?.pickUpQuestions, questions);
        if (quesTypeData.length > 0) {
          result = { ...result, [key]: quesTypeData };
        }
      });
    }
    setQuestionList(result);
  }, [tableData, questions]);

  useEffect(() => {
    getKeyList(keyList);
  }, [keyList]);

  useEffect(() => {
    getSaveData('quesReviewQuestionRenderList', questionList);
  }, [questionList]);

  useEffect(() => {
    if (Object.keys(tableData).length === 0) return [];
    let nextData = { ...tableData };
    Object.values(tableData).forEach(item => {
      nextData = {
        ...nextData,
        [item.name]: {
          ...nextData[item.name],
          goThrough: 'step3',
        }
      };
    });
    setTableData(nextData);

    // 取得各題型的題目中，最小的寬高，以避免更換試題時高度變化造成 UX 不良
    Object.values(tableData).forEach((datum) => {
      let minWidth = 9999, minHeight = 9999;
      datum.questions.forEach((question) => {
        const image = new Image();
        function setSize() {
          if (this.width < minWidth) {
            minWidth = this.width;
          }
          if (this.height < minHeight) {
            minHeight = this.height;
          }
          setQuestionImageSizeMap(prev => ({
            ...prev,
            [datum.name]: {
              minWidth,
              minHeight
            }
          }));
        }
        image.onload = setSize;
        image.src = question.imageUrl;
      });
    });
  }, []);

  const scoreInputChange = (quesType, inputKey, e) => {
    if (!new RegExp(/^(\d{0,3})(\.\d{0,1})?$/g).test(e.target.value)) return;
    const value = e.target.value;
    let allScore = 0;
    if (inputKey === 'perQues') {
      allScore = tableData[quesType].inputValue.question ? (value * tableData[quesType].inputValue.question).toFixed(1) : 0;
    } else {
      allScore = tableData[quesType].inputValue.answer ? (value * tableData[quesType].inputValue.answer).toFixed(1) : 0;
    }
    let nextScore = {
      ...defaultCountInputValue.score,
      [inputKey]: value,
      allScore
    };
    let nextData = {};
    nextData = {
      [quesType]: {
        ...tableData[quesType],
        inputValue: {
          ...tableData[quesType].inputValue,
          score: {
            ...nextScore
          }
        }
      }
    };
    setTableData({
      ...tableData,
      ...nextData
    });
  };

  const [setupDrawerVisible, { toggle: setupDrawerToggle, setFalse: setupDrawerFalse }] = useBoolean(false);

  // 錯題回報
  const [reportDrawerVisible, { toggle: reportDrawerToggle, setFalse: reportDrawerFalse }] = useBoolean(false);


  // ---- 動作 - 問題回報  ----

  // 問題回報彈窗 顯示控制
  const [reportModalVisible, { toggle: setReportModalToggle, setFalse: setReportModalFalse }] = useBoolean(false);

  const showReportModal = (itemUID) => {
    setReportModalData({
      ...reportModalData,
      education: eduSubjectData.edu,
      subject: eduSubjectData.subject,
      uid: itemUID,
    });
    if (reportModalVisible === false) sendGetReportModalSelectOptionsState(FETCH_DATA_EVENT.FETCH);
    setReportModalToggle();
  };

  //  試題回報內容
  const [reportModalData, setReportModalData] = useState({
    education: '',
    subject: '',
    uid: '',
    anomalyType: '',
    description: '',
  });

  // 取得錯題回報相關選單
  const [getReportModalSelectOptionsState, sendGetReportModalSelectOptionsState] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context) => {
        const res = await getAnomalySelectOption();
        const { data, isSuccess, systemCode, message } = res;
        return {
          reportModalSelectOptions: setSelectOptions(data) || {}
          , isSuccess, systemCode, message
        };
      },
    },
  });
  const { reportModalSelectOptions } = getReportModalSelectOptionsState.context.result || {};

  // 更改回報類型
  const onSelectChange = (value) => {
    setReportModalData({
      ...reportModalData,
      errorType: value,
    });
  };
  // 更改回報內容
  const onTextAreaChange = (e) => {
    setReportModalData({
      ...reportModalData,
      description: e.target.value,
    });
  };

  // 試題回報API
  const [steatePostQuestionReport, sendPostQuestionReport] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        const res = await postQuestionReport(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) {
          openNotificationWithIcon('success', t('containers.equationDojoQuestionReviewContent.__reportSuccess'));
        } else {
          openNotificationWithIcon('error', message);
        }
        return {
          isSuccess, systemCode, message
        };
      },
    },
  });

  // 關閉彈窗時 清除彈窗內資料
  useEffect(() => {
    if (reportModalVisible === false) {
      setReportModalData({
        education: '',
        subject: '',
        uid: '',
        anomalyType: '',
        description: '',
      });
    }
  }, [reportModalVisible]);

  const onReportTypeChange = e => {
    setReportModalData({
      ...reportModalData,
      anomalyType: e.target.value,
    });
  };

  const doMobileReport = () => {
    sendPostQuestionReport(FETCH_DATA_EVENT.FETCH, {
      payload: {
        questionUID: reportModalData.uid,
        errorType: reportModalData.anomalyType,
        description: reportModalData.description,
        eduSubject: reportModalData.education + reportModalData.subject,
      }
    });
    reportDrawerFalse();
  };

  // 確定回報
  const doReport = () => {
    sendPostQuestionReport(FETCH_DATA_EVENT.FETCH, {
      payload: {
        questionUID: reportModalData.uid,
        errorType: reportModalData.anomalyType,
        description: reportModalData.description,
        eduSubject: reportModalData.education + reportModalData.subject,
      }
    });
    setReportModalToggle();
  };

  // 關閉彈窗時 清除彈窗內資料
  useEffect(() => {
    if (reportDrawerVisible === false) {
      setReportModalData({
        education: '',
        subject: '',
        uid: '',
        anomalyType: '',
        description: '',
      });
    }
  }, [reportDrawerVisible]);

  // 動態新增 題型與配分Btn 到 header 上面
  const addSetupBtnClickEvent = () => {
    const setupBtn = document.getElementById('setupBtn');
    if (setupBtn) {
      setupBtn.addEventListener('click', function() {
        setupDrawerToggle();
      });
    }
  };

  useEffect(() => {
    addSetupBtnClickEvent();
  }, []);

  // ---------------------------- 更換試題 ----------------------------
  const [optionalQuestion, setOptionalQuestion] = useState({});


  const optionalQuestionList = (data, allQuestion) => {
    let allDiffQuestion = [];
    if (!data) return [];
    Object.entries(data).forEach(([key, value]) => {
      allDiffQuestion = allDiffQuestion.concat(value.map(item => item?.uid));
    });
    const filterQuestions = allQuestion.filter(item => allDiffQuestion.indexOf(item.uid) === -1);
    return shuffle(filterQuestions);
  };

  useEffect(() => {
    if (!tableData) return [];
    //題組沒選到的題目
    let filterQuestions = {};
    Object.entries(tableData).forEach(([key, value], index) => {
      const quesTypeData = optionalQuestionList(value?.pickUpQuestions, value?.questions);
      if (quesTypeData.length > 0) {
        filterQuestions = { ...filterQuestions, [key]: quesTypeData };
      }
    });
    setOptionalQuestion(filterQuestions);
  }, []);

  //知道有多少題目可以換
  const countBallPool = (ques) => {
    if (!ques) return [];
    if (!optionalQuestion[ques?.name]) return [];
    const filterQuestions = optionalQuestion[ques?.name].map(item => item);
    return filterQuestions || [];

  };

  // 更換題目 相關邏輯。
  const changeQuestionHandler = (item, index, i) => {
    if (!item) return;
    setTargetPosition({
      first: index,
      second: i,
    });
    let nextQuestion = item;
    //  符合更換條件的題目
    if (!questions) return;
    if (!optionalQuestion[item?.name]) return [];
    const filterQuestions = optionalQuestion[item?.name].map((item) => {
      return item;});
    if (!filterQuestions || filterQuestions.length === 0) {
      return null;
    }

    nextQuestion = filterQuestions.shift();
    filterQuestions.push(item);
    setOptionalQuestion({
      ...optionalQuestion,
      [item.name]: filterQuestions,
    });

    let array = [];
    array = array.concat(questionList[tableData[item?.name]?.name]);
    array[i] = nextQuestion ;
    let newQuestionList = { ...questionList };
    newQuestionList = {
      ...newQuestionList,
      [tableData[item?.name]?.name]: array
    };
    setQuestionList(newQuestionList);

    changeTableData(item, nextQuestion);
  };
  const changeTableData = (oldQues, newQues) => {
    const typeName = oldQues?.name;
    let nextData = { ...tableData };
    let Array = [];
    const found = nextData[typeName]?.questions.find(ele => ele.uid === newQues.uid);
    const old_found = nextData[typeName]?.questions.find(ele => ele.uid === oldQues.uid);
    if (!found || !old_found) return null;

    // delete
    const deleteArray = Array.concat(nextData[typeName].pickUpQuestions.questionsList).filter((item) => {
      return item.uid !== oldQues.uid;
    });
    nextData = {
      ...nextData,
      [typeName]: {
        ...nextData[typeName],
        pickUpQuestions: {
          ...nextData[typeName].pickUpQuestions,
          questionsList: deleteArray
        },
        inputValue: {
          ...nextData[old_found.name].inputValue,
          answer: nextData[old_found.name].inputValue.answer - old_found.answerAmount,
          question: nextData[old_found.name].inputValue.question - 1,
          score: {
            ...nextData[old_found.name].inputValue.score,
            allScore: nextData[old_found.name].inputValue.score.perQues
              ? nextData[old_found.name].inputValue.score.perQues * (nextData[old_found.name].inputValue.question - 1)
              : nextData[old_found.name].inputValue.score.perAns * (nextData[old_found.name].inputValue.answer - old_found.answerAmount)
          }
        },
      }
    };

    // add
    let addArray = Array.concat(nextData[typeName].pickUpQuestions.questionsList);
    addArray.push(found);
    nextData = {
      ...nextData,
      [typeName]: {
        ...nextData[typeName],
        pickUpQuestions: {
          ...nextData[typeName].pickUpQuestions,
          questionsList: addArray
        },
        inputValue: {
          ...nextData[found.name].inputValue,
          answer: nextData[found.name].inputValue.answer + old_found.answerAmount,
          question: nextData[found.name].inputValue.question + 1,
          score: {
            ...nextData[found.name].inputValue.score,
            allScore: nextData[found.name].inputValue.score.perQues
              ? nextData[found.name].inputValue.score.perQues * (nextData[found.name].inputValue.question + 1)
              : nextData[found.name].inputValue.score.perAns * (nextData[found.name].inputValue.answer + old_found.answerAmount)
          }
        },
      }
    };

    setTableData({
      ...nextData
    });
  };

  const isShowText = (question, firstIndex, secondIndex) => {
    let res = false;
    if (firstIndex === targetPosition.first && secondIndex === targetPosition.second && (optionalQuestion[question.name] === 0)) {
      res = true;
    }
    return res;
  };

  return (
    <StyledEquationDojoQuestionReviewContent className={className} data-testid="EquationDojoQuestionReviewContent" id={'EquationDojoQuestionReviewContent'}>
      <Box mb={5} className='pageTitleBox'>
        <div className="pageTitle">
          {t('containers.equationDojoQuestionReviewContent.__questionTypeView')}
        </div>
        {/* {deviceData.isDesktop && renderQuestionAnalysisModal()} */}
      </Box>
      <Box mb={deviceData.isDesktop ? 11 : 8} pl={deviceData.isDesktop ? 2 : 0}>
        <Table>
          <Table.Header>
            <Table.Row textcenter={true} className="tableHeader">
              <Table.Item flex={0.75} textalign={'center'} >
                {t('containers.equationDojoQuestionReviewContent.__order')}
              </Table.Item>
              <Table.Item flex={10} textalign={'left'}>
                {t('containers.equationDojoQuestionReviewContent.__questionType')}
              </Table.Item>
              {
                deviceData.isDesktop &&
                  <>
                    <Table.Item flex={1}>
                      {t('containers.equationDojoQuestionReviewContent.__questions')}
                    </Table.Item>
                    <Table.Item flex={1}>
                      {t('containers.equationDojoQuestionReviewContent.__answers')}
                    </Table.Item>
                    <Table.Item flex={1.5}>
                      {t('containers.equationDojoQuestionReviewContent.__perAnswerScore')}
                    </Table.Item>
                    <Table.Item flex={2}>
                      {t('containers.equationDojoQuestionReviewContent.__subtotal')}
                    </Table.Item>
                  </>
              }
            </Table.Row>
          </Table.Header>
          <Table.Body className="tableBody">
            {
              keyList && keyList.map((item, index) => (
                <Table.TableDndRow textcenter={true} id={tableData[item].name} index={index} key={tableData[item].name} moveCard={moveCard}>
                  <Table.Item flex={10} textalign={'flex-start'}>{tableData[item].name}</Table.Item>
                  {
                    deviceData.isDesktop &&
                      <>
                        <Table.Item flex={1} textalign={'flex-end'}>{tableData[item].inputValue.question || 0}</Table.Item>
                        <Table.Item flex={1} textalign={'flex-end'}>{tableData[item].inputValue.answer || 0}</Table.Item>
                        <Table.Item flex={1.5} textalign={'flex-end'}>
                          <div>
                            <Input allowClear={false} value={tableData[item].inputValue.score.perAns || ''} onChange={(e) => { scoreInputChange(item, 'perAns', e); }} />
                          </div>
                        </Table.Item>
                        <Table.Item flex={2} textalign={'flex-end'}>{tableData[item].inputValue.score.allScore}</Table.Item>
                      </>
                  }
                </Table.TableDndRow>
              ))
            }
            <Table.Row>
              <Table.Item className='tableFooter'>
                {t('containers.equationDojoQuestionReviewContent.__examPaperTotalScoreData', { questionCount: examPaperTotalScoreData.questionCount,totalAnswer: examPaperTotalScoreData?.totalAnswer, score: examPaperTotalScoreData.score })}
              </Table.Item>
            </Table.Row>
            { !deviceData.isDesktop && <div className='blackDiv'></div>}
          </Table.Body>
        </Table>
      </Box>
      {
        keyList && keyList.length > 0 && keyList.filter(item => Object.keys(questionList).includes(item)).map((key, index) => {
          if (questionList[key]) {
            return (
              questionList[key] &&
                <Box mb={deviceData.isDesktop ? 11 : 8} key={key}>
                  <Box mb={5} className={'quesTypeTitle'}>
                    <div className="pageTitle">{`${sectionToChinese(index + 1)}、${key}`}</div>
                  </Box>
                  <Table>
                    <Table.Header className="questionTableHeader">
                      <Table.Row textcenter={true}>
                        <Table.Item flex="0 1 auto" width="60px" textalign={'center'}>
                          {t('containers.equationDojoQuestionReviewContent.__questionNumber')}
                        </Table.Item>
                        <Table.Item flex="1">
                          {t('containers.equationDojoQuestionReviewContent.__questionContent')}
                        </Table.Item>
                        <Table.Item flex="0 1 auto" width="96px">
                          {t('containers.equationDojoQuestionReviewContent.__changeQuestion')}
                        </Table.Item>
                        <Table.Item flex="0 1 auto" width="96px">
                          {t('containers.equationDojoQuestionReviewContent.__errorReport')}
                        </Table.Item>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body className="questionTableBody">
                      {
                        questionList[key] && questionList[key].map((question, i) => {
                          const columnWidth = document.querySelector('.questionTableBody')?.offsetWidth - 30 - 60 - 96 - 96;
                          const minHeight = questionImageSizeMap?.[key] ? (columnWidth / questionImageSizeMap[key].minWidth) * questionImageSizeMap[key].minHeight : 'unset';
                          return (
                            <Table.Row DndItemFlex={'0.5'} textcenter={true} id={question?.uid} index={i} key={question?.uid} >
                              <Table.Item flex="0 1 auto" width="60px" textalign={'center'}>{i + 1}</Table.Item>
                              <Table.Item className="questionItem" flex="1">
                                <div className="questionContent">
                                  {
                                    question?.imageUrl &&
                                  <img className="questionContentText" src={question?.imageUrl} alt='img' style={{ minHeight, objectFit: 'contain' }}/>
                                  }
                                  {
                                    deviceData.isDesktop &&
                                  <div
                                    className="contentDetail"
                                    onClick={() => {
                                      GA.zoomInQuestionPreview(question.uid);
                                      showQuestionDetail(question?.imageUrl);
                                    }}
                                  ><SearchPlusSolid/></div>
                                  }
                                </div>
                              </Table.Item>
                              <Table.Item flex="0 1 auto" width="96px" textalign={'center'}>
                                {
                                  countBallPool(question).length > 0
                                    ?
                                    <Tooltip
                                      trigger={isShowText(question, index, i) ? 'hover' : ''}
                                      placement="top"
                                      getPopupContainer={() => document.getElementById('EquationDojoQuestionReviewContent') || document.body}
                                      title={
                                        <div className='tooltipText'>
                                          <ExclamationCircleSolid />
                                          {t('containers.equationDojoQuestionReviewContent.__allQuestionsDisplayed')}
                                        </div>
                                      }
                                      key={question?.uid}
                                    >
                                      <UndoSolid
                                        className='createQuizBtn iconBtn'
                                        onClick={(e) => {changeQuestionHandler(question, index, i);}}
                                      />
                                    </Tooltip>
                                    :
                                    <Tooltip
                                      trigger={deviceData.isDesktop ? 'hover' : 'click'}
                                      placement="top"
                                      title={
                                        <span className='tooltipText' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                                          {t('containers.equationDojoQuestionReviewContent.__noSimilarQuestions')}
                                        </span>
                                      }
                                      zIndex={100}
                                      color='#4e4e5a'
                                      getPopupContainer={() => document.getElementById('EquationDojoQuestionReviewContent') || document.body}
                                    >
                                      <StyledActionIcon
                                        disabled={true}
                                        className='createQuizBtn'
                                        onClick={(e) => {e.stopPropagation();changeQuestionHandler(question, index, i);}}
                                      >
                                        <UndoSolid/>
                                      </StyledActionIcon>
                                    </Tooltip>
                                }
                              </Table.Item>
                              {
                                deviceData.isDesktop &&
                              <Table.Item flex="0 1 auto" width="96px" textalign={'center'}>
                                <ReturnSolid className={'iconBtn'} onClick={(e) => {e.stopPropagation();showReportModal(question?.uid);}}/>
                              </Table.Item>
                              }
                            </Table.Row>
                          );})
                      }
                    </Table.Body>
                  </Table>
                </Box>
            );
          }


        })
      }

      {
        modalVisible &&
        <div className="questionImageModal">
          <div className="questionImageModalContent">
            <div className="questionImageModalClose" onClick={() => {setModalVisibleFalse();}}><XmarkOutline/></div>
            <div className="questionImageModalContentImage">
              <img src={modalImageSrc} alt="img" />
            </div>
          </div>
        </div>
      }

      {/* 錯誤回報視窗 */}
      <Drawer
        visible={reportDrawerVisible}
        title={t('containers.equationDojoQuestionReviewContent.__errorReport')}
        onClose={reportDrawerFalse}
        placement={'bottom'}
        isMobile={true}
        id={'reportDrawer'}
        className={'reportDrawer'}
        footer={
          <>
            <Button
              variant={!reportModalData.anomalyType || !reportModalData.description ? 'ui02' : 'brand2'}
              disabled={!reportModalData.anomalyType || !reportModalData.description}
              onClick={doMobileReport}>
              {t('containers.equationDojoQuestionReviewContent.__submit')}
            </Button>
            <Button variant='ui01' onClick={reportDrawerFalse}>
              {t('containers.equationDojoQuestionReviewContent.__cancel')}
            </Button>
          </>
        }
      >
        <div className='radioBox'>
          <div>{t('containers.equationDojoQuestionReviewContent.__reason')}<span>*</span></div>
          <Radio.Group onChange={onReportTypeChange} value={reportModalData.anomalyType}>
            {
              reportModalSelectOptions &&
              reportModalSelectOptions.map((item) => {
                return (<Radio key={item.value} value={item.value}>{item.name}</Radio>);
              })
            }
          </Radio.Group>
        </div>
        <div className='explanationBox'>
          <div>{t('containers.equationDojoQuestionReviewContent.__explanation')}<span>*</span></div>
          <Input.TextArea
            className='textArea'
            autoSize={true}
            onChange={onTextAreaChange}
            key={reportModalData.uid}
            placeholder={t('containers.equationDojoQuestionReviewContent.__enterExplanation')}/>
        </div>
      </Drawer>

      {/* 題數配分 */}
      <Drawer
        visible={setupDrawerVisible}
        title={t('containers.equationDojoQuestionReviewContent.__questionAndDistribution')}
        onClose={setupDrawerFalse}
        placement={'bottom'}
        isMobile={true}
        id={'setupDrawer'}
        className={'setupDrawer'}
        key={tableData}
        destroyOnClose={true}
      >
        <div className='setupDrawerTotalScore'>
          <span>
            {t('containers.equationDojoQuestionReviewContent.__examPaperTotalScoreData', { questionCount: examPaperTotalScoreData.questionCount,totalAnswer: examPaperTotalScoreData?.totalAnswer, score: examPaperTotalScoreData.score })}
          </span>
          <span className='tipText'>{t('containers.equationDojoQuestionReviewContent.__modifyQuestions')}</span>
        </div>
        {
          keyList &&
            keyList.map((type) => {
              return (
                <div className='questionTypeBox' key={type}>
                  <div className={'drawerTitle'}>
                    {tableData[type]?.name}
                  </div>
                  <div className={'drawerSection'}>
                    <div className={'drawerTable'}>
                      <div>
                        <div>{t('containers.equationDojoQuestionReviewContent.__selectedQuestionCount')}</div>
                        <div>{t('containers.equationDojoQuestionReviewContent.__answeredQuestionCount')}</div>
                      </div>
                      <div>
                        <div>
                          {tableData[type].inputValue.question}
                        </div>
                        <div>
                          {tableData[type].inputValue.answer}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={'drawerSection'}>
                    <div className={'drawerTable'}>
                      <div>
                        <div>{t('containers.equationDojoQuestionReviewContent.__perAnswerScore')}</div>
                      </div>
                      <div className={'setupDrawerScoreBox'}>
                        <div>{tableData[type].inputValue.score.perAns || '-'}</div>
                      </div>
                    </div>
                  </div>
                  <div className={'drawerTotalScore'}>
                    {t('containers.equationDojoQuestionReviewContent.__drawerTotalScore', { allScore: tableData[type].inputValue.score.allScore })}
                  </div>
                </div>
              );
            })
        }
      </Drawer>

      <Modal
        visible={reportModalVisible}
        zIndex={1001}
        onCancel={setReportModalFalse}
        title={t('containers.equationDojoQuestionReviewContent.__errorReport')}
        width="576px"
        footer={
          <>
            <Button className='reportModalBtn' variant='ui01' onClick={setReportModalFalse}>
              {t('containers.equationDojoQuestionReviewContent.__cancel')}
            </Button>
            <Button className='reportModalBtn' variant={!reportModalData.errorType || !reportModalData.description ? 'ui02' : 'brand2'}
              disabled={!reportModalData.errorType || !reportModalData.description}
              onClick={doReport}>
              {t('containers.equationDojoQuestionReviewContent.__confirm')}
            </Button>
          </>
        }
      >
        <StyleReportModalContent>
          <div>
            <div>{t('containers.equationDojoQuestionReviewContent.__reason')}</div>
            <Select className='select' options={reportModalSelectOptions}
              value={reportModalSelectOptions?.value} onChange={onSelectChange}
              allowClear={false} placeholder={t('containers.equationDojoQuestionReviewContent.__selectReason')}>
            </Select>
          </div>
          <div>
            <div>{t('containers.equationDojoQuestionReviewContent.__explanation')}</div>
            <Input.TextArea className='textArea' autoSize={true}
              onChange={onTextAreaChange} placeholder={t('containers.equationDojoQuestionReviewContent.__required')}></Input.TextArea>
          </div>
        </StyleReportModalContent>
      </Modal>

    </StyledEquationDojoQuestionReviewContent>
  );
};

EquationDojoQuestionReviewContent.propTypes = {
  className: PropTypes.string,
  prevKeyList: PropTypes.array,
  questions: PropTypes.array,
  saveData: PropTypes.object,
  examPaperTotalScoreData: PropTypes.object,
  getKeyList: PropTypes.func,
  getSaveData: PropTypes.func,
  searchKey: PropTypes.string,
  memberEduSubject: PropTypes.object,
};