import React from 'react';
import PropTypes from 'prop-types';
import { isAfter, isBefore } from 'date-fns';
import { StyledStatusLabel } from './StatusLabel.style';
import { useTranslation } from 'react-i18next';

export const StatusLabel = ({ isNew, startTime, endTime }) => {
  const currentTime = new Date();
  const startTime_ = new Date(startTime);
  const endTime_ = new Date(endTime);
  const { t } = useTranslation();

  if (isNew) {
    return <StyledStatusLabel className='new'> {t('components.statusLabel.__newLabel')}</StyledStatusLabel>;
  }
  if (isBefore(currentTime, endTime_) && isAfter(currentTime, startTime_)) {
    return <StyledStatusLabel className='processing'> {t('components.statusLabel.__processingLabel')}</StyledStatusLabel>;
  } else if (isBefore(currentTime, startTime_)) {
    return <StyledStatusLabel className='preparing'> {t('components.statusLabel.__preparingLabel')}</StyledStatusLabel>;
  } else if (isAfter(currentTime, endTime_)) {
    return <StyledStatusLabel className='finished'> {t('components.statusLabel.__finishedLabel')}</StyledStatusLabel>;
  }
  return '';
};

StatusLabel.propTypes = {
  isNew: PropTypes.bool,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
};