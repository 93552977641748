/* eslint-disable react/prop-types */
import { useState, useMemo, useEffect, useRef, useContext } from 'react';
import {
  Modal,
  Table,
  FETCH_DATA_EVENT,
  fetchDataMachine,
  Pagination,
  sliceTableData,
  openNotificationWithIcon,
  CreateExamPaperParamsProvider,
} from '@oneclass/ui-components';
import { useMachine } from '@xstate/react';
import { useBoolean, useSelections } from 'ahooks';
import { getExamContent } from 'api/examPaper';
import styled from 'styled-components';
import { Tooltip } from 'antd';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { Checkbox, Button } from '@oneclass/onedesign';
import { getTime, format } from 'date-fns';
import { EXAM_TYPE } from 'constants/index';
import { v4 as uuidv4 } from 'uuid';
import { useLoadingContent } from 'utils/hooks/useLoadingContent';
import * as GA from 'utils/googleAnalytics';
import { useTranslation } from 'react-i18next';
const StyledModal = styled(Modal)`
    .ant-modal{
        width: 860px !important;
        min-height: 536px !important;

        .ant-modal-header{
            padding: 24px 24px;
            color: ${({ theme }) => theme.colors.text.text_07};
        }

        .ant-modal-body{
            padding: 0px 24px 24px;
        }

        .ant-modal-footer{
            padding: 0px 24px 24px 24px;
            position: relative;
            z-index: 1050;
        }
       .ant-modal-close-x {
           line-height: 72px;
       }
    }

    .tableContent{
        >div{
            >div:first-child{
                >div{
                    >div{
                        padding: 12px 15px;
                        color: ${({ theme }) => theme.colors.text.text_10};
                    }
                }
            }
        }
    }

    .modalTitle{
        margin-bottom: 25px;
        color: ${({ theme }) => theme.colors.text.text_08};
    }

    .allSelectedBox{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 17px;

        >div:first-child{
            >span{
                margin-left: 8px;
            }
        }

        .countBox{
          color: ${({ theme }) => theme.colors.text.text_06};
        }

    }

    .itemBox{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        @media (max-width: 1024px) {
            font-size: 14px;
            color: ${({ theme }) => theme.colors.text.text_07};
            >div{
                margin-bottom: 8px;
            }
        }
    }

    .paperTableBody{
        >div{
            >div{
                padding: 12px 15px;
                color: ${({ theme }) => theme.colors.text.text_07};
            }
        }
    }

    .subStringText{
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      @media (max-width: 1024px) {
        white-space: normal;
      }
    }

    .paperName{
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }

    .timeBox{
      white-space: nowrap;
      @media (max-width: 1024px) {
        color: ${({ theme }) => theme.colors.text.text_05};
      }
    }

    .pageBlock{
        margin-top: 24px;
    }

    .footerBtnBox{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        >div{
            margin-right: unset;
            padding: 8px 16px !important;
            width: 88px;
            height: 40px;
            &:not(:last-child){
                margin-right: 16px;
            }
        }
    }

    // 提示視窗
    >div:nth-child(2){
        .ant-modal{
              width: 600px !important;
              height: 222px !important;
              min-height: unset !important;
              min-width: unset !important;
            .ant-modal-close-x {
                line-height: 72px;
            }
          }
    }

    .emptyMsg{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        color: ${({ theme }) => theme.colors.text.text_06};
    }

    @media (max-width: 1024px) {

        // 共用 Modal 樣式
        .modalContent{
        display: flex;
        flex-direction: column;
        }
        .ant-modal{
            position: absolute;
            bottom: 0;
            max-width:100%;
            padding: 0 0;
            margin: 0 0;
        }
        .ant-modal-content{
            position: absolute;
            bottom: 0;
            border-radius: 12px 12px 0 0;
            width: 100vw;
            height: 97vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
            height: calc(var(--vh, 1vh) * 97);
            .ant-modal-close{
                right: 0;
            }
        }
        .ant-modal-header{
            display: flex;
            justify-content:center;
            align-items:center;
            padding: 21px 40px 21px;
        }
        .ant-modal-close-x{
            display: flex;
            justify-content:center;
            align-items:center;
            width: 50px;
            height:71px;
        }
        .ant-modal-body{
            padding: 16px 24px 16px;
            background-color: #fff;
            height: calc(calc(var(--vh, 1vh) * 97) - 64px);
            overflow: auto;
        }
        //// ------

        .ant-modal-body{
            height: calc(calc(var(--vh, 1vh) * 97) - 64px - 97px);
            overflow: auto;
        }
        .ant-modal-footer{
            padding: 10px 24px 42px 24px !important;
            background-color: #fff;
            box-shadow: rgb(0 0 0 / 10%) 0px -1px 4px;
            border-radius: 0px;
            >div{
                width: 100%;
                >div{
                    width: 100%;
                    margin-right: 0px;
                }
            }
        }

        .footerBtnBox{
            margin-right: 0px;

            >div{
                width: 100%;
            }
        }

        // 提示視窗
        >div:nth-child(2){
            .ant-modal{
                top: unset !important;
                width: unset !important;
                height: unset !important;
                min-height: unset !important;
                min-width: unset !important;
                bottom: 0px !important;
            }
            .ant-modal-body{
                height: auto !important;
                overflow: auto;
            }
            .ant-modal-content{
                /* top: 0 !important; */
                bottom: 0 !important;
                height: auto !important;
            }
            .ant-modal-footer{
                box-shadow: none !important;
                .footerBtn{
                    div{
                        margin-right: unset !important;
                    }
                }
            }
        }
    }
`;

export const useFilterQuestionModal = (doSomeThing) => {

  const tableRef = useRef(null);
  const { Header, Body, Row, Item } = Table;
  const { renderLoadingContent, isLoading, setIsLoading } = useLoadingContent();
  const [pageState, setPageState] = useState({
    currentPage: 1,
    limit: 10,
  });
  const [data, setData] = useState([]);
  //   const { isDesktop } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const [filterPaperUID, setFilterPaperUID] = useState([]);
  const [filterQuestions, setFilterQuestions] = useState([]);
  const [isChangeFilter, setIsChangeFilter] = useState(false);
  const [haveSelectedQuestion, setHaveSelectedQuestion] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const { createExamPaperParams } = useContext(CreateExamPaperParamsProvider.createExamPaperContext);
  const { t } = useTranslation();
  // 取得 試卷列表資料
  const [stateGetExamContent, sendGetExamContent] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        setIsLoading(true);
        const res = await getExamContent(event.eduSubject);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) {
          setIsLoading(false);
          setData(data);
        } else {
          setIsLoading(false);
          openNotificationWithIcon('error', message);
        }
        return {
          isSuccess, systemCode, message,
        };
      },
    },
  });

  // 彈窗顯示控制
  const [filterQuestionModalVisible, { toggle: setFilterQuestionModalToggle, setFalse: setFilterQuestionModalFalse }] = useBoolean(false);

  const filterQuestionModalToggle = (pickUpArray, haveSelected) => {
    setSelected(pickUpArray || []);
    setFilterQuestionModalToggle();
    setHaveSelectedQuestion(haveSelected);
  };

  // 置頂
  const goTop = () => {
    const main = document.querySelector('#filterQuestionModal >div:nth-child(1) .ant-modal-body');
    main.scrollTop = 0;
  };

  // 翻頁
  const pageChange = (currentPage, pageSize) => {
    setPageState({
      ...pageState,
      currentPage,
      limit: pageSize,
    });
    goTop();
  };

  // 控制勾選狀態
  const allPaperUID = useMemo(() => {
    if (!data || data.length === 0) return [];
    return data.map((paper) => {
      return paper?.uid;
    });
  }, [data]);

  const {
    selected,
    setSelected,
    isSelected,
    toggle,
    allSelected,
    toggleAll,
    partiallySelected,
  } = useSelections(allPaperUID, []);

  // 當前頁面 所有試題ID (Array)
  const nowPageAllPaperArray = useMemo(() => {
    if (!allPaperUID || allPaperUID.length === 0) return [];
    const startIndex = (pageState.currentPage - 1) * pageState.limit;
    const endIndex = pageState.currentPage * pageState.limit;
    return allPaperUID.slice(startIndex, endIndex) || [];
  }, [pageState.currentPage, pageState.limit, allPaperUID]);

  // 全選當前頁面中的所有試卷
  const selectedOnePageAllPaper = (e) => {
    let array = [];
    if (e.target.checked) {
      const newArray = selected.concat(nowPageAllPaperArray);
      const filterArray = new Set();
      newArray.forEach(item => {
        if (!filterArray.has(item)) filterArray.add(item);
      });
      array = Array.from(filterArray);
    } else {
      const filterArray = selected.filter((item) => {
        return nowPageAllPaperArray.indexOf(item) === -1;
      });
      array = filterArray;
    }
    setSelected(array);
  };

  // 判斷 當前頁面 是否已全部勾選
  const checkOnePageAllSelected = useMemo(() => {
    if (!selected || selected.length === 0) return false;
    const selectedArray = selected.filter((item) => {
      return nowPageAllPaperArray.indexOf(item) !== -1;
    });
    return selectedArray.length;
  }, [selected, nowPageAllPaperArray]);

  // 將字串 逐字判斷類型(中文/英文數字) 轉換成長度
  const transformStringWidth = (String) => {
    if (!String) return 0;
    let chineseNum = 0;
    let englishNum = 0;
    let numberNum = 0;
    let symbolNum = 0; // 特殊符號 for 全行括號

    const chineseString = new RegExp('[\u4E00-\u9FA5]+');
    const englishString = new RegExp('[A-Za-z]+');
    const numberString = new RegExp('[0-9]+');

    const stringLength = String.length;
    for (let i = 0; i <= stringLength - 1; i++) {
      if (chineseString.test(String.charAt(i))) chineseNum++;
      if (englishString.test(String.charAt(i))) englishNum++;
      if (numberString.test(String.charAt(i))) numberNum++;
      if (!chineseString.test(String.charAt(i)) && !englishString.test(String.charAt(i)) && !numberString.test(String.charAt(i))) symbolNum++;
    }
    return chineseNum * 14 + (englishNum + numberNum) * 9.3515 + symbolNum * 14;
  };

  // 試卷範圍顯示
  const renderPaperRange = (bookArray) => {
    if (!bookArray) return null;

    // 針對 冊次 章節 進行排序
    const sortChapter = bookArray.map((book) => {
      if (!book?.chapters) return null;
      return {
        ...book,
        chapters: book.chapters.sort((a, b) => {
          return a?.code.substr(0, 2) - b?.code.substr(0, 2);
        })
      };

    });

    if (!sortChapter) return null;
    const sortBook = sortChapter.sort((a, b) => {
      return a?.code - b?.code;
    });

    let showString = '';

    sortBook.forEach((book, bookIndex) => {
      let chapterString = '';
      if (!book?.chapters) return null;
      book.chapters.forEach((chapter, chapterIndex) => {
        if (chapterIndex === 0) {
          chapterString += chapter?.code;
        } else {
          chapterString += ('、' + chapter?.code);
        }
      });
      const bookString = `${book?.name}（${chapterString}）`;

      if (bookIndex === 0) {
        showString += bookString;
      } else {
        showString += ('、' + bookString);
      }
    });

    if (transformStringWidth(showString) > 255) {
      return (
        <Tooltip
          placement="top"
          title={<span>{showString}</span>}
          zIndex={1009}
          color='#4e4e5a'
        >
          <span className='subStringText'>{showString}</span>
        </Tooltip>
      );
    } else {
      return showString;
    }
  };

  // 已勾選試卷的 所有試題 (Array)
  const selectedQuestion = useMemo(() => {
    if (!data || !selected) return [];
    const selectPaper = data.filter((item) => {
      return selected.indexOf(item?.uid) !== -1;
    });

    // 取出所有試題，組為一個陣列。
    let allQuestionArray = [];
    selectPaper.forEach((paper) => {
      let concatArray = [...allQuestionArray];
      allQuestionArray = concatArray.concat(paper?.questions);
    });

    // 過濾重複試題。
    const set = new Set();
    allQuestionArray.forEach(item => {
      if (!set.has(item)) set.add(item);
    });

    return Array.from(set);
  }, [data, selected]);

  // 已選取的試卷資料(for 後端紀錄)
  const paperFilter = useMemo(() => {
    if (!data || !selected) return [];
    const selectPaper = data.filter((item) => {
      return selected.indexOf(item?.uid) !== -1;
    });

    // 重新整理資料
    return selectPaper.map((item) => {
      return {
        paperId: item?.uid,
        questions: item?.questions,
      };
    });
  }, [data, selected]);

  // 判斷 使否有勾選試卷 顯示對應提示彈窗
  const handlerFilterBtn = () => {
    if (haveSelectedQuestion) {
      setHintTextModalToggle();
    } else {
      doFilterQuestion();
    }
  };

  //實際 更改動作
  const doFilterQuestion = () => {
    setFilterQuestions(selectedQuestion);
    setFilterPaperUID(selected);
    setFilterQuestionModalFalse();
    setIsChangeFilter(true);
    openNotificationWithIcon('success', t('hooks.useFilterQuestionModal.__filteredQuestions'));
    doSomeThing && doSomeThing();
  };

  const hintTextDoneBtn = () => {
    setHintTextModalFalse();
    doFilterQuestion();
  };

  // 提示 視窗
  const [hintTextModalVisible, { toggle: setHintTextModalToggle, setFalse: setHintTextModalFalse }] = useBoolean(false);

  // 過濾按鈕 判斷是否可以使用
  const disabled = useMemo(() => {
    return !isChange || data.length === 0 || isLoading;
  }, [isChange, data, isLoading]);

  // 彈窗開啟時 關閉背景滑動
  useEffect(() => {
    document.body.classList.remove('mobileScrollingStop');
    if (!deviceData.isDesktop) {
      if (filterQuestionModalVisible) {
        document.body.classList.add('mobileScrollingStop');
      }
    }
  }, [filterQuestionModalVisible]);

  useEffect(() => {
    if (!filterQuestionModalVisible) {
      setIsChange(false);
    } else {
      if (!createExamPaperParams) return;
      sendGetExamContent(FETCH_DATA_EVENT.FETCH, {
        eduSubject: (createExamPaperParams?.edu + createExamPaperParams?.subject),
      });
    }
  }, [filterQuestionModalVisible]);

  const renderFilterQuestionModal = () => {
    return (
      <>
        <StyledModal
          id={'filterQuestionModal'}
          visible={filterQuestionModalVisible}
          zIndex={1001}
          onCancel={setFilterQuestionModalFalse}
          title={t('hooks.useFilterQuestionModal.__filterQuestionModalTitle')}
          width="60%"
          footer={
            <div className="footerBtnBox">
              {
                deviceData.isDesktop &&
                <Button variant='ui01' onClick={setFilterQuestionModalFalse}>{t('hooks.useFilterQuestionModal.__cancel')}</Button>
              }
              <Button
                disabled={disabled}
                variant={disabled ? 'ui02' : 'brand2'}
                onClick={() => {
                  handlerFilterBtn();
                  GA.filterUsedQuestions('submit');
                }}
              >
                {t('hooks.useFilterQuestionModal.__confirmFilter')}
              </Button>
            </div>
          }
        >
          <div className='modalTitle'>
            {t('hooks.useFilterQuestionModal.__filterDescription')}
          </div>
          {
            isLoading
              ?
              renderLoadingContent()
              :
              <>
                <div className='allSelectedBox'>
                  <div>
                    <Checkbox
                      disabled={data.length === 0 || !data}
                      indeterminate={partiallySelected}
                      checked={allSelected}
                      onClick={(e) => { setIsChange(true); toggleAll(); }}
                    />
                    <span>{t('hooks.useFilterQuestionModal.__selectAll')}</span>
                  </div>
                  <div className='countBox'>{t('hooks.useFilterQuestionModal.__selectedPapersCount', { lengthSelected: selected.length || 0, lengthSelectedQuestion: selectedQuestion.length || 0 })}</div>
                </div>

                <div className="tableContent">
                  <Table ref={tableRef}>
                    <Header>
                      <Row>
                        <Item flex={deviceData.isDesktop ? '48px' : 0.5} textalign='center'>
                          <Checkbox
                            disabled={nowPageAllPaperArray.length === 0 || !nowPageAllPaperArray}
                            indeterminate={checkOnePageAllSelected && checkOnePageAllSelected !== 0 && checkOnePageAllSelected !== nowPageAllPaperArray.length}
                            checked={checkOnePageAllSelected === nowPageAllPaperArray.length}
                            onClick={(e) => { setIsChange(true); selectedOnePageAllPaper(e); }}
                          />
                        </Item>
                        {
                          deviceData.isDesktop
                            ?
                            <>
                              <Item flex={'302px'}>{t('hooks.useFilterQuestionModal.__paperName')}</Item>
                              {/* <Item flex={'90px'}>考試別</Item> */}
                              <Item flex={'303px'}>{t('hooks.useFilterQuestionModal.__examRange')}</Item>
                              <Item flex={'159px'}>{t('hooks.useFilterQuestionModal.__publishTime')}</Item>
                            </>
                            :
                            <Item flex={4.3}>{t('hooks.useFilterQuestionModal.__testPaper')}</Item>
                        }

                      </Row>
                    </Header>
                    <Body id={'paperTableBody'} className="paperTableBody">
                      {
                        data &&
                          data.length > 0 ?
                          sliceTableData(data, pageState.currentPage, pageState.limit).map((item, index) => {
                            return (
                              <Row key={uuidv4()} disabled={item.isImport}>
                                <Item flex={deviceData.isDesktop ? '48px' : 0.5} textalign="center">
                                  <Checkbox
                                    disabled={item.isImport}
                                    checked={isSelected(item?.uid)}
                                    onClick={(e) => { setIsChange(true); toggle(item?.uid); }}
                                  />
                                </Item>
                                {
                                  deviceData.isDesktop
                                    ?
                                    <>
                                      <Item flex={'302px'}>
                                        {item?.name}
                                      </Item>
                                      {/* <Item flex={'90px'}>
                                        {EXAM_TYPE[item?.style?.examType]}
                                      </Item> */}
                                      <Item flex={'303px'}>
                                        {renderPaperRange(item?.detail?.volumeSheet)}
                                      </Item>
                                      <Item className={'timeBox'} flex={'159px'}>
                                        {format(getTime(new Date(item?.createTime)), 'yyyy/dd/MM HH:mm')}
                                      </Item>
                                    </>
                                    :
                                    <Item flex={4.3} className={'itemBox'}>
                                      <div className='paperName'>
                                        {item?.name}
                                      </div>
                                      {/* <div>
                                        {EXAM_TYPE[item?.style?.examType]}
                                      </div> */}
                                      <div>
                                        {renderPaperRange(item?.detail?.volumeSheet)}
                                      </div>
                                      <div className={'timeBox'}>
                                        {format(getTime(new Date(item?.createTime)), 'yyyy/dd/MM HH:mm')}{t('hooks.useFilterQuestionModal.__createTime')}
                                      </div>
                                    </Item>
                                }

                              </Row>
                            );
                          }) : <div className="emptyMsg">{t('hooks.useFilterQuestionModal.__noPastPapers')}</div>
                      }
                    </Body>
                  </Table>
                </div>
                <div className="pageBlock">
                  <Pagination
                    total={data?.length || 0}
                    defaultCurrent={1}
                    current={pageState.currentPage}
                    onChange={pageChange}
                    defaultPageSize={pageState.limit}
                  />
                </div>
              </>
          }

          <Modal
            visible={hintTextModalVisible}
            onCancel={setHintTextModalFalse}
            className={'hintTextModal'}
            id={'hintTextModal'}
            zIndex={1050}
            centered={deviceData.isDesktop}
            getContainer={document.querySelector('#filterQuestionModal')}
            title={
              <div>{t('hooks.useFilterQuestionModal.__changeFilterConfirmation')}</div>
            }
            footer={
              <div className="footerBtn footerBtnBox">
                <Button variant='ui01' onClick={() => { setHintTextModalFalse(); }}>{t('hooks.useFilterQuestionModal.__cancelChange')}</Button>
                <Button variant={'brand1'} onClick={() => { hintTextDoneBtn(); }}>{t('hooks.useFilterQuestionModal.__confirmChange')}</Button>
              </div>
            }
          >
            <div className="modalContent">
              <div>{t('hooks.useFilterQuestionModal.__changeDescription')}</div>
            </div>
          </Modal>
        </StyledModal>
      </>
    );
  };

  return {
    renderFilterQuestionModal,
    filterQuestionModalToggle,
    setFilterQuestionModalFalse,
    filterPaperUID,
    filterQuestions,
    isChangeFilter,
    setIsChangeFilter,
    setFilterPaperUID,
    paperFilter
  };
};
