import React, { useEffect, useState, useMemo } from 'react';
import { Tab, TabPane } from '@oneclass/ui-components';
import { Button } from '@oneclass/onedesign';
import { useBoolean } from 'ahooks';
import { Tooltip } from 'antd';
import { StyledStudentExamPaperListPage } from './StudentExamPaperListPage.style';
import { StudentExamPaperList, StudentWrongQuestionList } from 'containers';
import { useLoadingContent } from 'utils/hooks/useLoadingContent';
import { useTranslation } from 'react-i18next';

export const StudentExamPaperListPage = ({ className }) => {
	const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState('1');
  const { renderLoadingContent, isLoading, setIsLoading } = useLoadingContent();

  // const handleChangeTab = (str) => {
  //   setCurrentTab(str);
  // };

  const onTabChange = (val) => {
    setCurrentTab(val);
  };

  return (
    <StyledStudentExamPaperListPage className={className} data-testid="StudentExamPaperListPage">
      <Tab activeKey={currentTab} onChange={onTabChange}>
        <TabPane tab={t('views.studentExamPaperListPage.__studentPracticePapers')} key="1">
          <StudentExamPaperList
            currentTab={currentTab}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </TabPane>
        {/* <TabPane tab="複習錯題" key="2">
          <StudentWrongQuestionList
            currentTab={currentTab}
            handleChangeTab={onTabChange}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </TabPane> */}
      </Tab>
    </StyledStudentExamPaperListPage>
  );
};