import styled from 'styled-components';
import { variant, space } from 'styled-system';

export const StyledDifferentTag = styled.div.attrs(props => ({
  className: props.className
}))`
display: inline-flex;
border-radius: 2px;
padding:2px 4px;
${space}
  ${variant({
    variants: {
      easy: {
        color: '#4CAF50',
        backgroundColor: '#EEF7EF'
      },
      middle: {
        color: '#FF9800',
        backgroundColor: '#FFF6E8'
      },
      hard: {
        color: '#F44336',
        backgroundColor: '#FFEDEC'
      },
    }
  })}
  `;