import { useState, useEffect, useMemo, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ChalkboardOutline, ChevronLeftSolid, DownloadSolid, LinkSolid, MoreOutline } from '@onedesign/icon';
import { Button } from '@oneclass/onedesign';
import { useMachine } from '@xstate/react';
import { FETCH_DATA_EVENT, fetchDataMachine, Tab, Table, TabPane } from '@oneclass/ui-components';
import { format } from 'date-fns';
import { Dropdown, Menu } from 'antd';

import { StyledBackToList, StyledTestDetailPage, StyledTestInfo, StyledTestList, } from './TestDetailPage.style';
import { postQuizDetail } from 'api/quiz';
import StatusLabel from 'components/StatusLabel';
import { StyledShareModal, StyledHintModal } from 'containers/TestListContent/TestListContent.style';
import TestLinkModalContent from 'components/TestLinkModalContent';
import TestCodeModalContent from 'components/TestCodeModalContent';
import TestQrCodeModalContent from 'components/TestQrCodeModalContent';
import { postQuizCSV } from 'api/quiz';
import { downloadByUrl } from 'utils/common';
import { useCreateQuizExamModal } from 'utils/hooks/useCreateQuizExamModal';
import { deleteQuiz } from 'api/quiz';
import { getCookie } from 'utils/common';
import { useLoadingContent } from 'utils/hooks/useLoadingContent';
import behaviorProvider from 'providers/behaviorProvider';
import * as GA from 'utils/googleAnalytics';
import { formatSeconds } from 'utils/common';
import { useTranslation } from 'react-i18next';

const dateFormat = (date) => format(new Date(date), 'yyyy/dd/MM HH:mm');
const EXAM_DOMAIN = process.env.REACT_APP_ONE_EXAM_DOMAIN;

export const TestDetailPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { quizId } = useParams();
  const { behaviorDataProviderChange } = useContext(behaviorProvider.behaviorProviderContext);
  const { renderLoadingContent, isLoading, setIsLoading } = useLoadingContent();
  const [isGettingCSV, setIsGettingCSV] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [shareTabType, setShareTabType] = useState('testLink');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [statePostTestDetail, sendPostTestDetail] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        setIsLoading(true);
        const res = await postQuizDetail(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        setIsLoading(false);
        return {
          isSuccess, systemCode, message,
          testDetail: data,
        };
      },
    },
  });
  const { testDetail } = statePostTestDetail.context.result || {};
  const { answerResults, quizInfo } = testDetail || {};
  const hasAnswers = answerResults?.length > 0;
  const getTestDetail = () => sendPostTestDetail(FETCH_DATA_EVENT.FETCH, { payload: { quizId } });

  const [, sendDeleteTest] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        setIsDeleting(true);
        const res = await deleteQuiz(event.jwt, event.quizId);
        const { isSuccess, systemCode, message } = res;
        isSuccess && backToList();
        setIsDeleting(false);
        return {
          isSuccess, systemCode, message,
        };
      },
    },
  });
  const deleteTest = () => {
    GA.deleteExam();
    sendDeleteTest(FETCH_DATA_EVENT.FETCH, {
      jwt: JSON.parse(getCookie('nani_oneclass_login_token')).jwt,
      quizId,
    });
  };

  const [, sendPostTestCSV] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        setIsGettingCSV(true);
        const res = await postQuizCSV(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        isSuccess && downloadByUrl(res.data.csvUrl);
        setIsGettingCSV(false);
        return {
          isSuccess, systemCode, message,
          csvUrl: data.csvUrl
        };
      },
    },
  });
  const getTestCSV = () => {
    GA.exportScore();
    sendPostTestCSV(FETCH_DATA_EVENT.FETCH, { payload: { quizId } });
  };

  const shareModalProps = useMemo(() => {
    if (!quizInfo) return;
    const endTime = new Date(quizInfo.endAt);
    return {
      examLink: EXAM_DOMAIN,
      modalInfo: {
        QuizCode: quizInfo?.quizCode || '',
        QuizName: quizInfo.quizName,
      },
      shareModalData: {
        endTime: {
          month: format(endTime, 'MM'),
          day: format(endTime, 'dd'),
          time: format(endTime, 'HH:mm'),
        },
        quizCode: {
          top4: quizInfo?.quizCode?.slice(0, 4),
          last4: quizInfo?.quizCode?.slice(4, 9),
        }
      }
    };
  }, [quizInfo]);

  const { renderCreateQuizExamModal, createQuizExamModalToggle } = useCreateQuizExamModal(getTestDetail, 'create');

  const openEditModal = () => {
    if (!quizInfo) return;
    const params = {
      QuizUID: quizId,
      ExamUID: quizInfo.examUid,
      StartTime: quizInfo.startAt,
      EndTime: quizInfo.endAt,
      Duration: quizInfo.duration,
      QuizName: quizInfo.quizName,
      IsAutoCheck: quizInfo.isAutoCheck,
    };
    createQuizExamModalToggle(params, 'edit');
  };

  const backToList = () => {
    behaviorDataProviderChange('goToExamList', true);
    history.push('/examPaperList');
  };

  const goToPreview = () => window.open(`${EXAM_DOMAIN}/paper/preview/${quizInfo.examUid}`);

  const goToReview = () => {
    const { schoolYear, quizCode, quizUid } = quizInfo || {};
    window.open(`${EXAM_DOMAIN}/quiz/review/${schoolYear}/${quizCode}/${quizUid}`);
  };

  const closeDeleteModal = () => setIsDeleteModalOpen(false);

  useEffect(() => {
    if (!quizId) return;
    getTestDetail();
  }, [quizId]);

  return (
    <StyledTestDetailPage data-testid="TestDetailPage">
      <StyledBackToList onClick={backToList}>
        <ChevronLeftSolid/>
        <span>{t('views.testDetailPage.__backToList')}</span>
      </StyledBackToList>
      {isLoading ? renderLoadingContent() :
        <>
          <StyledTestInfo>
            <div className='pageTitle'>{t('views.testDetailPage.__testDetail')}</div>
            <div className='testTitle'>
              <div className='testTitle__name'>
                {quizInfo?.quizName}
              </div>
              <div className='testTitle__actions'>
                <Button
                  onClick={() => setIsShareModalOpen(true)}
                >
                  <LinkSolid />{t('views.testDetailPage.__testLink')}
                </Button>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key='預覽試卷' onClick={goToPreview}>{t('views.testDetailPage.__previewTestPaper')}</Menu.Item>
                      <Menu.Item key='編輯測驗設定' onClick={openEditModal}>{t('views.testDetailPage.__editTestSettings')}</Menu.Item>
                      <Menu.Item key='刪除測驗' style={{ color: '#F44336' }} onClick={() => setIsDeleteModalOpen(true)}>{t('views.testDetailPage.__deleteTest')}</Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <Button>
                    <MoreOutline/>{t('views.testDetailPage.__moreActions')}
                  </Button>
                </Dropdown>
              </div>
            </div>
            <div className='testInfo'>
              <div className='testInfo__item'>
                <div className='testInfo__label'>{t('views.testDetailPage.__testTime')}</div>
                <div className='testInfo__value'>
                  {quizInfo && <>
                    <span style={{ marginRight: '8px' }}>
                      {dateFormat(quizInfo.startAt)}～{dateFormat(quizInfo.endAt)}
                    </span>
                    <StatusLabel startTime={quizInfo.startAt} endTime={quizInfo.endAt} />
                  </>
                  }
                </div>
              </div>
              <div className='testInfo__item'>
                <div className='testInfo__label'>{t('views.testDetailPage.__publishScores')}</div>
                <div className='testInfo__value'>{quizInfo?.isAutoCheck ? t('views.testDetailPage.__publishOnSubmission') : t('views.testDetailPage.__publishOnTestEnd')}</div>
              </div>
              <div className='testInfo__item'>
                <div className='testInfo__label'>{t('views.testDetailPage.__duration')}</div>
                <div className='testInfo__value'>{quizInfo?.duration} {t('views.testDetailPage.__minutes')}</div>
              </div>
            </div>
          </StyledTestInfo>
          {answerResults && (
            <StyledTestList>
              <div className='list__header'>
                <div className='list__header__title'>
                  {t('views.testDetailPage.__testListTitle', { answerResults: answerResults.length || 0 })}
                </div>
                <div className='list__header__actions'>
                  <Button
                    variant={hasAnswers ? 'brand2' : 'ui02'}
                    onClick={goToReview}
                    disabled={!hasAnswers}
                  >
                    <ChalkboardOutline />{t('views.testDetailPage.__reviewTest')}
                  </Button>
                  <Button
                    variant={hasAnswers ? 'brand2' : 'ui02'}
                    onClick={getTestCSV}
                    disabled={!hasAnswers}
                    isLoading={isGettingCSV}
                  >
                    <DownloadSolid />{t('views.testDetailPage.__exportScores')}
                  </Button>
                </div>
              </div>
              <div className='list__table'>
                <Table>
                  <Table.Header className='list__table__header'>
                    <Table.Row>
                      <Table.Item width='120px' flex='none'>{t('views.testDetailPage.__studentNumber')}</Table.Item>
                      <Table.Item>{t('views.testDetailPage.__studentName')}</Table.Item>
                      <Table.Item width='120px' flex='none'>{t('views.testDetailPage.__score')}</Table.Item>
                      <Table.Item width='120px' flex='none'>{t('views.testDetailPage.__timeSpent')}</Table.Item>
                      <Table.Item width='160px' flex='none'>{t('views.testDetailPage.__submissionTime')}</Table.Item>
                      <Table.Item width='120px' flex='none'></Table.Item>
                    </Table.Row>
                  </Table.Header>
                  {answerResults.length > 0 ? (
                    <Table.Body bodyMaxHeight={true}>
                      {answerResults.map(result => (
                        <Table.Row key={result.seatNo}>
                          <Table.Item width='120px' flex='none'>{result.seatNo}</Table.Item>
                          <Table.Item>{result.name}</Table.Item>
                          <Table.Item width='120px' flex='none'>{result.score}</Table.Item>
                          <Table.Item width='120px' flex='none'>{formatSeconds(result.costTime)}</Table.Item>
                          <Table.Item width='160px' flex='none'>{dateFormat(result.submitTime)}</Table.Item>
                          <Table.Item width='120px' flex='none'>
                            <Button
                              onClick={() => window.open(result.studentInfoUrl)}
                              className='button'
                            >
                              {t('views.testDetailPage.__testResults')}
                            </Button>
                          </Table.Item>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  ) : (
                    <div className='list__table__noData'>
                      {t('views.testDetailPage.__noData')}
                    </div>
                  )}
                </Table>
              </div>
            </StyledTestList>
          )}
        </>
      }
      {shareModalProps && (
        <StyledShareModal
          visible={isShareModalOpen}
          width={'860px'}
          onCancel={() => setIsShareModalOpen(false)}
        >
          <Tab activeKey={shareTabType} onChange={setShareTabType} size='large'>
            <TabPane tab={t('views.testDetailPage.__testLink')} key="testLink">
              <TestLinkModalContent {...shareModalProps} />
            </TabPane>
            <TabPane tab={t('views.testDetailPage.__testCode')} key="testCode">
              <TestCodeModalContent {...shareModalProps} />
            </TabPane>
            <TabPane tab={t('views.testDetailPage.__qrCode')} key="qrCode">
              <TestQrCodeModalContent {...shareModalProps} />
            </TabPane>
          </Tab>
        </StyledShareModal>
      )}
      {renderCreateQuizExamModal()}
      <StyledHintModal
        visible={isDeleteModalOpen}
        title={t('views.testDetailPage.__deleteConfirmation')}
        onCancel={closeDeleteModal}
        centered={true}
        footer={
          <div className="footerBtn footerBtnBox">
            <Button variant='ui01' onClick={closeDeleteModal}>{t('views.testDetailPage.__cancel')}</Button>
            <Button
              className={'confirmBtn'}
              onClick={deleteTest}
              isLoading={isDeleting}
            >
              {t('views.testDetailPage.__confirmDelete')}
            </Button>
          </div>
        }
      >
        <span className='hintModalContent'>{t('views.testDetailPage.__deleteWarning')}</span>
      </StyledHintModal>
    </StyledTestDetailPage>
  );
};